import React, { useContext, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import ManageOrderCard from "./manage_order_card";
import Button from "../shared_components/button";
import camelize from "../../utils/camelizeString";
import { gql, useMutation } from "@apollo/client";
import { PopupContext } from "../../popup_context";
import CardDetailItem from "../create_listing/card_detail_item";
import Select from "react-select";
import HelpText from "../../utils/help_text";
import { ConfirmationContext } from "../../confirmation_context";
import { TRACKING_URL_REGEX } from "../../utils/constants";

const CREATE_TRACKING_INFO = gql`
    mutation CreatePurchaseSellerTracking(
        $input: CreatePurchaseSellerTrackingInput!
    ) {
        result: createPurchaseSellerTracking(input: $input) {
            trackingInformation {
                id
                logisticsProvider
                trackingNumber
                trackingUrl
                expectedDateOfArrival
                shipmentDate
            }
            errors
            success
        }
    }
`;

const UPDATE_PURCHASE_SELLER_STATUS = gql`
    mutation UpdatePurchaseSellerStatus(
        $input: UpdatePurchaseSellerStatusInput!
    ) {
        result: updatePurchaseSellerStatus(input: $input) {
            purchaseSeller {
                status
            }
            errors
            success
        }
    }
`;

const trackingInfoSchema = yup.object().shape({
    fulfillmentOptionId: yup.string(),
    purchaseSellerId: yup.string().required(),
    logisticsProvider: yup.string().required("Input logistics provider"),
    trackingNumber: yup.string().required("Input tracking number"),
    expectedDateOfArrival: yup.string(),
    trackingUrl: yup.string().matches(TRACKING_URL_REGEX, "Enter valid url"),
});

const ManageOrderForm = ({
    purchaseSeller,
    trackingInformation,
    reloadTransactionData,
    sellerFulfillmentOptions,
    fulfillmentOption,
}) => {
    const showCancelButton = purchaseSeller.status == "Received by Seller" ? true : !!trackingInformation;
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);
    const [createTrackingInfo, { loading: createTrackingLoading }] = useMutation(CREATE_TRACKING_INFO, {
        onCompleted: (data) => {
            const result = data.result;
            if (result.success) {
                setShowPopup(true);
                setPopupAction("success");
                setMessage("Tracking information saved");
                reloadTransactionData();
            } else {
                setShowPopup(true);
                setPopupAction("warn");
                setMessage(result.errors.join(", "));
            }
        },
        onError: () => {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage("Something went wrong.");
        },
    });

    const [updatepurchaseSellerStatus, { loading: updateStatusLoading }] = useMutation(
        UPDATE_PURCHASE_SELLER_STATUS,
        {
            onCompleted: (data) => {
                const result = data.result;
                if (result.success) {
                    setShowPopup(true);
                    setPopupAction("success");
                    setMessage("Status Updated");
                    reloadTransactionData();
                } else {
                    setShowPopup(true);
                    setPopupAction("warn");
                    setMessage(result.errors.join(", "));
                }
            },
            onError: () => {
                setShowPopup(true);
                setPopupAction("warn");
                setMessage("Something went wrong.");
            },
        }
    );

    const confirm = useContext(ConfirmationContext);
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const initialValues = {
        fulfillmentOptionId: fulfillmentOption.id,
        purchaseSellerId: purchaseSeller.id,
        logisticsProvider: "",
        trackingNumber: "",
        trackingUrl: "",
        expectedDateOfArrival: "",
    };
    const statusDescription = {
        readyToShip: `Please arrange shipment with your courier and ${
            purchaseSeller.isFulfillmentTracked
                ? "enter the tracking details to "
                : ""
        }confirm shipment.`,
        shipped: "We have informed the buyer that their order is on the way",
        orderCompleted: "Order is now complete",
    };

    const fulfillmentOptions = sellerFulfillmentOptions
        ?.filter((o) =>
            purchaseSeller.isFulfillmentTracked
                ? !o.fulfillmentType.includes("untracked")
                : o.fulfillmentType.includes("untracked")
        )
        .map((o) => ({
            label: `${o.displayedFulfillmentType} Shipping`,
            value: o.id,
        }));

    const handleFormSubmit = (values) => {
        confirm({
            title: "Add tracking information",
            text: "Transaction will be marked as shipped. Continue?",
            onConfirm: () => createTrackingInfo({
                                variables: {
                                    input: {
                                        trackingInformation: {
                                            ...values,
                                        },
                                    },
                                },
                            })
        });
    };

    const handleCancelOrder = () => {
        confirm({
            title: "Proceed to Order Cancellation?",
            text: "Do you want to cancel this order? We will take you to the order cancellation page.",
            onConfirm: () => {
              window.location.href = "/seller_center/orders_and_shipping/" + purchaseSeller.id + "/cancel_order";
            }
        });
    };

    const handleShipUntrackedTransaction = () => {
        confirm({
            title: "Ship order",
            text: "Transaction will be marked as shipped. Continue?",
            onConfirm: () => updatepurchaseSellerStatus({
                                variables: {
                                    input: {
                                        id: purchaseSeller.id,
                                        status: "shipped",
                                    },
                                },
                            })
        });
    };

    return (
        <ManageOrderCard>
            <div className="space-y-3">
                <span className="font-semibold text-primary uppercase">
                    {purchaseSeller.sellerDisplayedStatus}
                </span>
                <p>
                    {
                        statusDescription[
                            camelize(purchaseSeller.sellerDisplayedStatus)
                        ]
                    }
                </p>
            </div>
            {!trackingInformation &&
                !purchaseSeller.isFulfillmentTracked &&
                purchaseSeller.status === "Received by Seller" && (
                    <div className="w-1/2">
                        <Button
                            primary
                            type="button"
                            text="Confirm shipment"
                            onClick={() => handleShipUntrackedTransaction()}
                        />
                    </div>
                )}
            {!trackingInformation && purchaseSeller.isFulfillmentTracked && (
                <Formik
                    onSubmit={(values) => handleFormSubmit(values)}
                    validationSchema={trackingInfoSchema}
                    initialValues={initialValues}>
                    {(form) => (
                        <Form className="grid space-y-6">
                            <div className="grid space-y-2 w-full">
                                <div className="flex items-center space-x-2">
                                    <span className="text-sm font-body">
                                        Shipping method
                                    </span>
                                    <HelpText text="You can update shipping method based on buyers shipping details if needed." />
                                </div>
                                <div className="text-gray-700 space-y-2">
                                    <Select
                                        className="rounded text-gray-700 text-sm"
                                        onChange={(v) => {
                                            form.setFieldValue(
                                                "fulfillmentOptionId",
                                                v.value
                                            );
                                        }}
                                        options={fulfillmentOptions}
                                        defaultValue={fulfillmentOptions.filter(
                                            (o) =>
                                                o.value === fulfillmentOption.id
                                        )}
                                    />
                                    <ErrorMessage name="fulfillmentOptionId">
                                        {(msg) => (
                                            <div className="text-red-400 text-xs">
                                                {msg}
                                            </div>
                                        )}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div className="grid space-y-2 w-full">
                                <span className="text-sm font-body mb-1">
                                    Logistics provider
                                </span>
                                <div className="text-gray-700 space-y-2">
                                    <input
                                        type="text"
                                        name="logisticsProvider"
                                        autoComplete="off"
                                        className="w-full rounded text-gray-700 text-sm"
                                        placeholder="Ex. DHL, Fedex"
                                        onChange={(e) =>
                                            form.setFieldValue(
                                                "logisticsProvider",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <ErrorMessage name="logisticsProvider">
                                        {(msg) => (
                                            <div className="text-red-400 text-xs">
                                                {msg}
                                            </div>
                                        )}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div className="grid space-y-2 w-full">
                                <span className="text-sm font-body mb-1">
                                    Tracking number
                                </span>
                                <div className="text-gray-700 space-y-2">
                                    <input
                                        type="text"
                                        name="trackingNumber"
                                        autoComplete="off"
                                        className="w-full rounded text-gray-700 text-sm"
                                        onChange={(e) =>
                                            form.setFieldValue(
                                                "trackingNumber",
                                                e.target.value
                                            )
                                        }
                                    />
                                    <ErrorMessage name="trackingNumber">
                                        {(msg) => (
                                            <div className="text-red-400 text-xs">
                                                {msg}
                                            </div>
                                        )}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div
                                className={`${
                                    !showAdditionalFields && "hidden"
                                } space-y-6`}>
                                <div className="grid space-y-2 w-full">
                                    <span className="text-sm font-body mb-1">
                                        Tracking URL [optional]
                                    </span>
                                    <div className="text-gray-700 space-y-2">
                                        <input
                                            type="text"
                                            placeholder="http://domain.com"
                                            name="trackingUrl"
                                            autoComplete="off"
                                            className="w-full rounded text-gray-700 text-sm"
                                            onChange={(e) =>
                                                form.setFieldValue(
                                                    "trackingUrl",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <ErrorMessage name="trackingUrl">
                                            {(msg) => (
                                                <div className="text-red-400 text-xs">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div className="grid space-y-2 w-full">
                                    <span className="text-sm font-body mb-1">
                                        Expected date of arrival [optional]
                                    </span>
                                    <div className="text-gray-700 space-y-2">
                                        <input
                                            type="date"
                                            name="expectedDateOfArrival"
                                            min={new Date()
                                                .toJSON()
                                                .slice(0, 10)}
                                            autoComplete="off"
                                            className="w-full rounded text-gray-700 text-sm"
                                            onChange={(e) =>
                                                form.setFieldValue(
                                                    "expectedDateOfArrival",
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <ErrorMessage name="expectedDateOfArrival">
                                            {(msg) => (
                                                <div className="text-red-400 text-xs">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    className="cursor-pointer"
                                    type="button"
                                    onClick={() =>
                                        setShowAdditionalFields(
                                            !showAdditionalFields
                                        )
                                    }>
                                    <span className="text-sm underline">
                                        {`${
                                            showAdditionalFields
                                                ? "Hide"
                                                : "Show"
                                        }`}{" "}
                                        additional fields
                                    </span>
                                </button>
                            </div>
                            <div className="w-1/2">
                                <Button
                                    primary
                                    type="submit"
                                    text="Confirm shipment"
                                    disabled={createTrackingLoading || updateStatusLoading}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
            {trackingInformation && (
                <>
                    <CardDetailItem
                        title="Ship Date"
                        value={trackingInformation.shipmentDate}
                        fullWidth
                    />
                    <CardDetailItem
                        title="Logistics Provider"
                        value={trackingInformation.logisticsProvider}
                        fullWidth
                    />
                    <CardDetailItem
                        title="Tracking Number"
                        value={trackingInformation.trackingNumber}
                        fullWidth
                    />
                    {trackingInformation.trackingUrl && (
                        <CardDetailItem
                            title="Tracking Url"
                            value={trackingInformation.trackingUrl}
                            fullWidth
                            link
                        />
                    )}
                </>
            )}

            {showCancelButton && (
                <div className="pt-12 border-t-2 border-gray-300 border-opacity-40">
                    <Button
                        type="button"
                        text="Cancel this order"
                        onClick={() => handleCancelOrder()}
                        customClass="w-full py-2 rounded border border-white"
                    />
                </div>
            )}
        </ManageOrderCard>
    );
};

export default ManageOrderForm;

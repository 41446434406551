import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "view_components";
import "date_filter";
import trophyRoom from "../trophy_room";
import browseCards from "../browse_cards";
import marketplace from "../marketplace";
import seller_center from "../seller_center";
import StandAloneUpdateOwnedCard from "../standalone_update_owned_card";
import "../stylesheet/application.scss";
import withdrawal_modal from "../modal/withdrawal_modal";
import initSubscribeModal from "../modal/subscribe_modal";
import initSubscribeCancelModal from "../modal/subscribe_cancel_modal";

Rails.start();
ActiveStorage.start();

trophyRoom();
browseCards();
marketplace();
seller_center();
StandAloneUpdateOwnedCard();
withdrawal_modal();
initSubscribeModal();
initSubscribeCancelModal();

document.addEventListener("DOMContentLoaded", function () {
    const carousel1 = document.getElementById("tradingCardCarousel");
    const mostPopular = document.getElementById("mostPopularCarousel");
    const mostPopularSliderLeft = document.getElementById(
        "mostPopularSliderLeft"
    );
    const mostPopularSliderRight = document.getElementById(
        "mostPopularSliderRight"
    );
    const recentCardsSliderLeft = document.getElementById(
        "recentCardsSliderLeft"
    );
    const recentCardsSliderRight = document.getElementById(
        "recentCardsSliderRight"
    );
    const recentCardsCarousel = document.getElementById("recentCardsCarousel");

    function moveLeft(activeCard, leftCard, rightCard) {
        activeCard.classList.remove("active");
        activeCard.classList.add("is-left");
        leftCard.classList.remove("is-left");
        leftCard.classList.add("is-right");
        rightCard.classList.remove("is-right");
        rightCard.classList.add("active");
    }

    function moveRight(activeCard, leftCard, rightCard) {
        activeCard.classList.remove("active");
        activeCard.classList.add("is-right");
        leftCard.classList.remove("is-left");
        leftCard.classList.add("active");
        rightCard.classList.remove("is-right");
        rightCard.classList.add("is-left");
    }

    if (carousel1) {
        carousel1.addEventListener("click", (e) => {
            const activeCard = carousel1.querySelector(".active");
            const leftCard = carousel1.querySelector(".is-left");
            const rightCard = carousel1.querySelector(".is-right");

            if (e.target === rightCard) {
                moveLeft(activeCard, leftCard, rightCard);
            } else {
                moveRight(activeCard, leftCard, rightCard);
            }
        });
    }

    if (mostPopularSliderLeft) {
        let mostPopularActive = mostPopular.querySelector(".active");

        mostPopularSliderLeft.addEventListener("click", (e) => {
            let next = mostPopularActive.previousElementSibling;
            if (!next) {
                next = mostPopular.lastElementChild;
            }
            mostPopularActive.classList.remove("active");
            next.classList.add("active");
            mostPopularActive = next;
        });
    }

    if (mostPopularSliderRight) {
        let mostPopularActive = mostPopular.querySelector(".active");

        mostPopularSliderRight.addEventListener("click", (e) => {
            let next = mostPopularActive.nextElementSibling;
            if (!next) {
                next = mostPopular.firstElementChild;
            }
            mostPopularActive.classList.remove("active");
            next.classList.add("active");
            mostPopularActive = next;
        });
    }

    if (recentCardsSliderLeft) {
        let scrollLeft;
        recentCardsSliderLeft.addEventListener("mousedown", (e) => {
            e.preventDefault();
            scrollLeft = setInterval(() => {
                recentCardsCarousel.scrollLeft -= 10;
            }, 10);
        });

        recentCardsSliderLeft.addEventListener("mouseup", (e) => {
            e.preventDefault();
            window.clearInterval(scrollLeft);
        });

        recentCardsSliderLeft.addEventListener("touchstart", (e) => {
            e.preventDefault();
            scrollLeft = setInterval(() => {
                recentCardsCarousel.scrollLeft -= 10;
            }, 10);
        });

        recentCardsSliderLeft.addEventListener("touchend", (e) => {
            e.preventDefault();
            window.clearInterval(scrollLeft);
        });
    }

    if (recentCardsSliderRight) {
        let scrollRight;
        recentCardsSliderRight.addEventListener("mousedown", (e) => {
            e.preventDefault();
            scrollRight = setInterval(() => {
                recentCardsCarousel.scrollLeft += 10;
            }, 10);
        });

        recentCardsSliderRight.addEventListener("mouseup", (e) => {
            e.preventDefault();
            window.clearInterval(scrollRight);
        });
        recentCardsSliderRight.addEventListener("touchstart", (e) => {
            e.preventDefault();
            scrollRight = setInterval(() => {
                recentCardsCarousel.scrollLeft += 10;
            }, 10);
        });

        recentCardsSliderRight.addEventListener("touchend", (e) => {
            e.preventDefault();
            window.clearInterval(scrollRight);
        });
    }

    initMobileMenu();
    initAccordion();
    initAccountSidebar();
});

function initMobileMenu() {
    const $mBtn = document.getElementById("mobile-btn");
    const $menu = document.getElementById("mobile-menu");

    if ($mBtn) {
        $mBtn.addEventListener("click", () => {
            // $menu.classList.toggle('show');
            if ($menu.classList.contains("hidden")) {
                $menu.classList.remove("hidden");
            } else {
                $menu.classList.add("hidden");
            }
        });
    }
}

function initAccordion() {
    const accordionEls = document.getElementsByClassName("merqaryAccordionBtn");
    Array.from(accordionEls).forEach((el) => {
        el.addEventListener("click", function () {
            const targetId = this.dataset.targetId;
            const panelEl = document.getElementById(targetId);

            this.classList.toggle("active");
            panelEl.classList.toggle("hidden");
        });
    });
}

function initAccountSidebar() {
    const accountSidebar = document.querySelector(".js-account-sidebar");
    const backdrop = document.querySelector(".js-account-backdrop");
    const sidebarTrigger = document.querySelector(".js-account-trigger");

    function triggerBodyOverflow(el) {
        if (el.classList.contains("is-hidden")) {
            document.body.classList.remove("sidebar-visible");
        } else {
            document.body.classList.add("sidebar-visible");
        }
    }

    if (accountSidebar && backdrop) {
        backdrop.addEventListener("click", () => {
            accountSidebar.classList.toggle("is-hidden");
            triggerBodyOverflow(accountSidebar);
        });

        if (sidebarTrigger) {
            sidebarTrigger.addEventListener("click", () => {
                accountSidebar.classList.toggle("is-hidden");
                triggerBodyOverflow(accountSidebar);
            });
        }
    }
}

import React from "react";
import CardDetailItem from "../../seller_center/create_listing/card_detail_item";

const ListingPreviewDescription = ({
    printRun,
    printRunOrdinality,
    graderName,
    grade,
    description,
    condition,
    details,
    sportName,
    startYear,
    endYear,
    cardNumber,
    manufacturerName,
    productName,
    cardSetName,
    isBase,
    hasJersey,
    hasPatch,
    hasAutograph,
    isRookie,
    isVerified,
    hasInsert,
    hasLogoman,
}) => {
    const cardAttributes = [
        { name: "Base", present: isBase },
        { name: "Jersey", present: hasJersey },
        { name: "Patch", present: hasPatch },
        { name: "Autograph", present: hasAutograph },
        { name: "Rookie", present: isRookie },
        { name: "Verified", present: isVerified },
        { name: "Insert", present: hasInsert },
        { name: "Logoman", present: hasLogoman },
    ]
        .filter((a) => a.present)
        .map((a) => a.name)
        .join(" ");

    return (
        <div className="divide divide-y divide-gray-575 space-y-8 text-md">
            <div className="px-6 grid grid-cols-1 gap-6">
                <CardDetailItem
                    title="Print Run"
                    value={
                        printRun ? `#${printRunOrdinality || ""}/${printRun}` : ""
                    }
                />
                <CardDetailItem
                    title="Grading"
                    value={
                        graderName && grade
                            ? `${graderName.toUpperCase()} ${grade}`
                            : null
                    }
                />

                <CardDetailItem
                    title="Condition"
                    value={`${condition} ${details ? `- ${details}` : ""}`}
                />
                <CardDetailItem title="Details" value={description} />
            </div>
            <div className="px-6 pt-8 gap-8 grid grid-cols-2">
                <CardDetailItem title="Sport" value={sportName} col />
                <CardDetailItem
                    title="Year"
                    value={`${startYear} ${endYear ? ` - ${endYear}` : ""}`}
                    col
                />

                <CardDetailItem title="Card Number" value={cardNumber} col />
                <CardDetailItem
                    title="Manufacturer"
                    value={manufacturerName}
                    col
                />

                <CardDetailItem title="Product" value={productName} col />
                <CardDetailItem
                    title="Card Attribute"
                    value={cardAttributes}
                    col
                />

                <CardDetailItem title="Card Set" value={cardSetName} col />
            </div>
        </div>
    );
};

export default ListingPreviewDescription;


const DEFAULT_SORT_OPTIONS = [
    {
        label: 'Recently Added',
        value: {
            sortCategory: 'added',
            sortOrder: 'desc',
        }
    },
    {
        label: 'Oldest',
        value: {
            sortCategory: 'added',
            sortOrder: 'asc',
        }
    },
    {
        label: 'Highest Score',
        value: {
            sortCategory: 'score',
            sortOrder: 'desc',
        }
    },
    {
        label: 'Lowest Score',
        value: {
            sortCategory: 'score',
            sortOrder: 'asc',
        }
    },
    {
        label: 'New Release',
        value: {
            sortCategory: 'year',
            sortOrder: 'desc',
        }
    },
    {
        label: 'Oldest Release',
        value: {
            sortCategory: 'year',
            sortOrder: 'asc',
        }
    }
]


const MARKETPLACE_SORT_OPTIONS = [
    // { label: 'Relevance', value: JSON.stringify(DEFAULT_SORT) },
    {
        label: 'Recently Added',
        value:  {
            sortCategory: 'added',
            sortOrder: 'desc',
        }
    },
    {
        label: 'Oldest',
        value: {
            sortCategory: 'added',
            sortOrder: 'asc',
        }
    },
    {
        label: 'Highest Price',
        value: {
            sortCategory: 'price',
            sortOrder: 'desc',
        }
    },
    {
        label: 'Lowest Price',
        value: {
            sortCategory: 'price',
            sortOrder: 'asc',
        }
    },
    {
        label: 'New Release',
        value: {
            sortCategory: 'year',
            sortOrder: 'desc',
        }
    },
    {
        label: 'Oldest Release',
        value: {
            sortCategory: 'year',
            sortOrder: 'asc',
        }
    }
]

const ORDERS_SORT_OPTIONS = [
    {
        label: 'Recently Added',
        value: {
            sortCategory: 'added',
            sortOrder: 'desc',
        }
    },
    {
        label: 'Oldest',
        value: {
            sortCategory: 'added',
            sortOrder: 'asc',
        }
    }
]

export {
    DEFAULT_SORT_OPTIONS,
    MARKETPLACE_SORT_OPTIONS,
    ORDERS_SORT_OPTIONS
}
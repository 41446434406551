import React from 'react'

const ManageOrderCard = ({ title, children }) => {
    return (
        <div className='p-8 px-12 bg-gray-800 rounded-md space-y-6'>
            <span className='font-semibold text-lg py-12'>
                {title}
            </span>
            {children}
        </div>
    )
}

ManageOrderCard.defaultProps = {
    title: null
}

export default ManageOrderCard

import { Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import formatPrice from "../../utils/format_price";
import HelpText from "../../utils/help_text";

const SellerShippingMethods = ({
    bgWhenSelected,
    sellerId,
    shippingMethods,
    buyerLocationId,
    cardLocationIds
}) => {
    const { values, setFieldValue } = useFormikContext();
    const sellerIndex = values.purchaseDetails.findIndex(
        (detail) => detail.sellerId === sellerId
    );

    const [selected, setSelected] = useState(null);
    const allowedShippingMethods = filterAllowedShippingMethods(shippingMethods, buyerLocationId, cardLocationIds);

    useEffect(() => {
        if (Boolean(allowedShippingMethods.length)) {
            setFieldValue(`purchaseDetails[${sellerIndex}][fulfillmentOptionId]`, allowedShippingMethods[0].id);
            setSelected(allowedShippingMethods[0].id);
        }
    }, [])

    return (
        <div>
            {allowedShippingMethods?.map((shippingMethod) => {
                const isSelected = shippingMethod.id === selected && bgWhenSelected;
                const labelClassName = [
                    "p-2 rounded-sm flex space-x-2 items-center cursor-pointer",
                    isSelected ? "bg-gray-300 bg-opacity-10" : ""
                ].join(" ");
                const isShippingUntracked = shippingMethod.fulfillmentType.includes("untracked");
                return (
                    <label
                        key={shippingMethod.id}
                        htmlFor={`shipping-${shippingMethod.id}`}
                        className={labelClassName}
                    >
                        <Field
                            type="radio"
                            className="text-primary focus:ring-0 w-5 h-5"
                            name={`purchaseDetails[${sellerIndex}][fulfillmentOptionId]`}
                            value={shippingMethod.id}
                            id={`shipping-${shippingMethod.id}`}
                        />
                        <div className="flex flex-col">
                            <span className="text-inherit text-lg">
                                {shippingMethod.displayedFulfillmentType}{" "}
                                {shippingMethod.description
                                    ? `- ${shippingMethod.description}`
                                    : ""}
                            </span>
                            <div className="text-md flex flex-col">
                                {(shippingMethod.shippingFee && shippingMethod.shippingFee > 0) ? (
                                    <span>{formatPrice(shippingMethod.shippingFee, "V C")}</span>
                                ) : null}
                                {shippingMethod.id === selected && shippingMethod.shippingType}
                                {isShippingUntracked ? (
                                    <HelpText
                                        text="Untracked orders are non-refundable."
                                        iconColor="text-primary"
                                        size={16}
                                        icon="info"
                                        sideText="Non-refundable"
                                        url="/terms_and_conditions"
                                        urlText="Read more"
                                    />
                                ) : null}
                            </div>
                        </div>
                    </label>
                );
            })}
        </div>
    );
};

const filterAllowedShippingMethods = (shippingMethods, buyerLocationId, cardLocationIds) => {
    const singleCountry = cardLocationIds.length === 1;
    let type = "international";

    if (singleCountry && buyerLocationId === cardLocationIds[0]) {
        type = "domestic";
    }

    return shippingMethods.filter(s => s.isActive && s.fulfillmentType.includes(type));
}

SellerShippingMethods.defaultProps = {
    bgWhenSelected: false,
};

export default SellerShippingMethods;

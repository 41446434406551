import React from 'react';

const PageNumberButton = ({ onClick, children, currentPage, buttonClass }) => {
    const c = [
        buttonClass,
        currentPage
            ? 'text-primary pointer-events-none'
            : 'text-white hover:text-primary',
    ].join(' ');

    return (
        <button disabled={currentPage} className={c} onClick={onClick}>
            {children}
        </button>
    );
};

export default PageNumberButton;

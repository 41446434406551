import React, { useContext, useRef, useState } from "react";
import { Heart } from "react-feather";
import IconGoldenEdit from "icon-golden-edit.svg";
import { PopupContext } from "../../popup_context";
import IconGoldenAddCart from "icon-golden-add-cart.svg";
import formatPrice from "../../utils/format_price";
import Checkbox from "../../checkbox";
import CardImage from "../../card_image";
import updateCartCount from "../../utils/updateCartCount";
import formatCardPlayers from "../../utils/format_card_players";
import { ConfirmationContext } from "../../confirmation_context";
import { Link } from "react-router-dom";
import useCartItemsMutation from "../../mutations/cart_items_mutation";
import useMarketListingMutation from "../../mutations/market_listing_mutation";
import HelpText from "../../utils/help_text";
import PopupMenu from "../../seller_center/shared_components/popup_menu";
import PageVariablesContext from "../../page_variables_context";

const MarketCard = ({
    isRow,
    price,
    marketListingId,
    ownedCardId,
    frontImage,
    currency,
    cardName,
    currentOwnerId,
    userWantCount,
    playersJson,
    dealFormat,
    alterable,
    status,
    location,
    condition,
    showFlip,
    showWantIcon,
    showCartIcon,
    withCheckbox,
    withTeamNames,
    isMarketPlace,
    smallerList,
    userId: listingUserId,
    unlistedList,
    newListingList,
    sellerProfileList,
    graderName,
    grade,
    draftMarketListing,
    activeMarketListingId,
    isMarketListable,
    isPublished,
    id, // also ownedCardId
    cardId,
    isPrivate,
    callback = () => {},
}) => {
    const { userId } = useContext(PageVariablesContext);
    const vaulted = currentOwnerId === userId;
    const confirm = useContext(ConfirmationContext);

    const [flipped, setFlipped] = useState(false);
    const canAddToCart = userId !== currentOwnerId && showCartIcon;
    const ref = useRef(null);
    const [showOtherMenu, setShowOtherMenu] = useState(false);
    const { setShowPopup, setPopupAction, setMessage } = useContext(PopupContext);
    const showStatusBadge = !["sold", "completed"].includes(status);

    const displayCardPath = !unlistedList
                                ? isMarketPlace && !sellerProfileList
                                    ? `/marketplace/listings/${marketListingId}`
                                    : `/seller_center/my_listings/${marketListingId}`
                                : `/browse_cards/preview/${cardId}/vaulted/${ownedCardId || id}`;

    const cardClassName = [
        "trophyRoom-card w-full",
        "focus:outline-none flex-shrink-0 border-white rounded-md",
        flipped ? "flip" : "",
        isRow && smallerList
            ? "w-2/3 h-80 mx-auto lg:w-24 lg:h-32 lg:mx-10 border-4"
            : isRow && newListingList
                ? "w-2/3 h-80 mx-auto lg:w-24 lg:h-32 lg:mx-10"
                : isRow
                    ? "w-36 h-48 mr-8 border-8"
                    : "mx-auto h-64 border-8",

    ].join(" ");

    const cardContainerClassName = [
        "PlayerCard--container text-white",
        !newListingList
            ? "p-3 hover:bg-black-300 bg-gray-600 shadow hover:shadow-md flex-shrink-0 space-y-2"
            : "py-6 border-b-2 border-gray-300 border-opacity-40",
        !newListingList
            ? smallerList
                ? "pb-12 rounded-md" : "rounded-lg"
            : "",
        isRow
            ? "col-span-full md:col-span-2 lg:col-span-full"
            : "col-span-full md:col-span-1",
        "animate__animated animate__fadeIn",
        "h-full",
    ].join(" ");

    const listingDetailsClassName = [
        "flex flex-col",
        isRow ? "pt-6 lg:py-6 space-y-2 lg:w-1/3" : "",
    ].join(" ");

    const cardListingClassName = [
        "flex px-3 w-full h-full pb-2",
        isRow ? "flex-col lg:flex-row space-x-6" : "flex-col space-y-4",
    ].join(" ");

    function handleClickOther(e) {
        function handleOutsideClick(ev) {
            if (!ref?.current?.contains(ev.target)) {
                setShowOtherMenu(false);
                removeOutsideClick();
            }
        }

        function removeOutsideClick() {
            document.removeEventListener("click", handleOutsideClick);
        }
        document.addEventListener("click", handleOutsideClick);
        setShowOtherMenu(!showOtherMenu);
    }

    const deleteListingCallback = (data) => {
        if (data.deleteMarketListing.result) {
            setShowPopup(true);
            setPopupAction("success");
            setMessage("Listing deleted.");
        } else {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage(data.deleteMarketListing.errors.join(", "));
        }
        callback();
    }

    const updateListingCallback = (data) => {
        const result = data.updateMarketListing;
        if (result.result) {
            setShowPopup(true);
            setPopupAction("success");
            setMessage("Market listing status updated");
            callback();
        } else {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage(result.errors.join(", "));
        }
    };

    const addToCartCallback = (data) => {
        const result = data.addMarketListingToCart;
        if (result.success) {
            setShowPopup(false);
            setShowPopup(true);
            setPopupAction("success");
            setMessage(`${cardName} has been added to your cart`);
            updateCartCount(1);
        } else {
            setShowPopup(false);
            setShowPopup(true);
            setPopupAction("warn");
            setMessage(result.errors.join(", "));
        }
    };

    const { deleteListing } = useMarketListingMutation(deleteListingCallback);
    const { updateListing } = useMarketListingMutation(updateListingCallback);
    const { addToCart } = useCartItemsMutation(addToCartCallback);

    const handleAddtoCart = () => {
        if (!userId) {
            window.location.replace("/users/sign_in");
            return;
        }

        addToCart({
            variables: {
                input: {
                    userId: userId,
                    marketListingId: marketListingId,
                },
            },
        });
    };

    const handleStatusChange = () => {
        updateListing({
            variables: {
                input: {
                    id: marketListingId,
                    listing: {
                        userId: listingUserId,
                        ownedCardId: ownedCardId,
                        condition: condition,
                        price: price,
                        dealFormat: dealFormat,
                        currency: currency,
                        location: location?.id,
                        status: "active",
                    },
                },
            },
        });
    };

    const deleteMarketListing = () => {
        confirm({
            onConfirm: () =>
                deleteListing({
                    variables: {
                        input: {
                            id: marketListingId,
                        },
                    },
                }),
            title: "Delete this marketplace listing?",
            text: "Are you sure you want to delist your card from the marketplace?",
            confirmText: "Yes, delete",
        });
    };

    const handleListCard = () => {
        if (!isPublished || !isMarketListable) {
            setShowPopup(false);
            setShowPopup(true);
            setPopupAction('warn');
            setMessage(`Please complete this cards information and publish to list this card`);
            return;
        }
        window.location.replace(`/seller_center/create_listing/${id}`);
    }

    return (
        <div className={cardContainerClassName}>
            <div className="flex justify-between items-center">
                <div>{withCheckbox && <Checkbox customClass="p-1" />}</div>
                <div className="flex items-center space-x-3">
                    {userWantCount > 0 && showWantIcon ? (
                        // ## add to want list???
                        <button className="bg-transparent text-primary flex items-center">
                            <Heart size={16} />
                            <span className="ml-1">{userWantCount}</span>
                        </button>
                    ) : null}
                    {canAddToCart ? (
                        <button
                            className={`bg-transparent text-primary flex items-center`}
                            onClick={() => handleAddtoCart()}>
                            <img src={IconGoldenAddCart} className="w-4 h-4" />
                        </button>
                    ) : null}
                </div>
            </div>
            <div className={cardListingClassName}>
                <div className={cardClassName}>
                    <a
                        href={displayCardPath}
                        className="h-full rounded-md text-white hover:bg-gray-100 flex items-center p-2">
                        <CardImage
                            isFront
                            image={frontImage}
                            hideMoreText={isRow}
                        />
                    </a>
                    <div className="w-full flex items-center mb-4">
                        {!isRow && showFlip ? (
                            <button
                                type="button"
                                className="text-white text-xs uppercase pl-2 ml-auto outline-none focus:outline-none"
                                onClick={() => setFlipped(!flipped)}>
                                Flip
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className={listingDetailsClassName}>
                    <a href={displayCardPath}>
                        <p className="text-sm leading-6 merqary-truncate two-lines">
                            <abbr
                                title={cardName}
                                className="!no-underline border-0">
                                {cardName}
                            </abbr>
                        </p>
                        {withTeamNames && (
                            <p className="text-sm text-gray-30 leading-6 merqary-truncate two-lines">
                                <abbr
                                    title={formatCardPlayers(playersJson)}
                                    className="!no-underline border-0">
                                    {formatCardPlayers(playersJson)}
                                </abbr>
                            </p>
                        )}
                        {vaulted && !isMarketPlace && !newListingList ? (
                            <div
                                className={
                                    smallerList ? "flex ml-2 mt-4 hidden lg:block" : "flex"
                                }>
                                <div className="icon-vaulted">Vaulted</div>
                            </div>
                        ) : null}
                    </a>
                    {isMarketPlace ? (
                        <p className="font-semibold text-primary text-sm">
                            {formatPrice(price, "V", currency)}
                        </p>
                    ) : null}
                    {vaulted && isRow && isMarketPlace ? (
                        <div
                            className={
                                smallerList ? "flex md:ml-2 md:mt-4" : "flex"
                            }>
                            <div className="icon-vaulted">Vaulted</div>
                        </div>
                    ) : null}
                </div>
                {!isMarketPlace && isRow ? (
                    <div
                        className={`flex flex-col lg:flex-row lg:items-center space-y-8 lg:space-y-0 lg:justify-between lg:w-1/2 ${
                            smallerList ? "" : "lg:px-12",
                            !newListingList ? "lg:px-12" : ""
                        }`}>
                            {!unlistedList ? (
                                <>
                                    <div className="space-y-2 text-center">
                                        {showStatusBadge ? (
                                            <div className="text-sm hidden lg:block">
                                                {dealFormat}{isPrivate ? " - Private" : null}
                                            </div>
                                        ) : null}
                                        <div className="flex lg:flex-col justify-between items-center">
                                            <div className="font-semibold text-primary text-lg lg:text-md">
                                                {formatPrice(price, "V", currency)}
                                            </div>
                                            {showStatusBadge ? (
                                                <div className="mt-2 w-24 text-center p-2 bg-gray-200 bg-opacity-20 uppercase text-xs font-semibold rounded-sm">
                                                    {status}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="text-lg items-center">
                                        <div className={`flex items-center space-x-4 h-8 ${(status === "active" || alterable) ? "justify-between" : "justify-end"}`}>
                                            {status === "active" ? (
                                                <HelpText
                                                    text={`${window.location.origin}/marketplace/listings/${marketListingId}`}
                                                    sideText="Share"
                                                    sideTextClass="text-xs "
                                                    icon="share"
                                                    size={12}
                                                    copyToClipboard
                                                    showTextOnHover={false}
                                                />
                                            ) : null}
                                            {alterable && (
                                                <a
                                                    href={`/seller_center/my_listings/${marketListingId}`}
                                                    className="h-full rounded-md text-white flex items-center p-2">
                                                    <img
                                                        src={IconGoldenEdit}
                                                        className="w-3 h-3"
                                                    />
                                                    <span className="ml-1 text-xs">Edit</span>
                                                </a>
                                            )}
                                            <PopupMenu
                                                text="More"
                                                onClick={handleClickOther}
                                                placement="left"
                                                visible={showOtherMenu}
                                                elementRef={ref}>
                                                <a className="text-left" href={`/seller_center/my_listings/${marketListingId}/preview`}>
                                                    Live preview
                                                </a>
                                                {status === "draft" && (
                                                    <button
                                                        className="text-left"
                                                        onClick={() => handleStatusChange()}>
                                                        Place on marketplace
                                                    </button>
                                                )}
                                                {alterable && (
                                                    <button
                                                        className="text-left"
                                                        onClick={() => deleteMarketListing()}>
                                                        Delete listing
                                                    </button>
                                                )}
                                            </PopupMenu>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex flex-col mt-4 lg:mt-0 lg:items-center text-sm text-white-400 space-y-1">
                                        <span className="font-semibold">Grading</span>
                                        <span className="uppercase">
                                            <abbr title={`${graderName || '--'} ${grade || '--'}`} className="!no-underline border-0 break-all">
                                                {`${graderName || '--'} ${grade || '--'}`}
                                            </abbr>
                                        </span>
                                    </div>
                                    <div className="col-span-2 my-auto">
                                        {(draftMarketListing || activeMarketListingId) && (
                                            <div className="flex justify-center items-center">
                                                <span className="bg-primary px-2 py-1 rounded text-black-100 font-semibold text-sm">
                                                    {(draftMarketListing) && 'Draft'}
                                                    {(activeMarketListingId) && 'Listed'}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    {!activeMarketListingId && !draftMarketListing && (
                                        <div className="flex justify-center items-center">
                                            <button
                                                className="p-1 rounded bg-transparent w-full lg:w-32 border border-2 border-gray-200 text-center cursor-pointer text-sm"
                                                onClick={() => handleListCard()}
                                            >
                                                List this card
                                            </button>
                                        </div>
                                    )}
                                    {(draftMarketListing || activeMarketListingId) && (
                                        <div className="flex justify-center items-center">
                                            <Link
                                                to={`/seller_center/my_listings/${activeMarketListingId || draftMarketListing?.id}`}
                                                className="p-1 rounded bg-transparent w-full lg:w-32 border border-2 border-gray-200 text-center cursor-pointer text-sm"
                                            >
                                                View listing
                                            </Link>
                                        </div>
                                    )}
                                </>
                            )}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default MarketCard;

import React from "react";
import IconCollection from "icon-collection.svg";

const EmptyList = ({ isViewOnly, hasListSelected, bgColor }) => {
    const containerClassName = [
        "flex flex-col items-center justify-center",
        "text-lg text-white",
        "p-4 h-72 rounded-md",
        bgColor,
    ].join(" ");

    return (
        <div className={containerClassName}>
            {hasListSelected ? (
                <>
                    <img src={IconCollection} className="w-16 h-16 mb-4" />
                    <p className="text-center font-semibold mb-2 mx-auto">
                        {isViewOnly
                            ? "No cards in collection."
                            : "You have no cards in your collection."}
                    </p>
                    {!isViewOnly ? (
                        <p className="text-center mx-auto">
                            To add cards,{" "}
                            <a
                                href="/browse_cards"
                                className="underline hover:text-primary">
                                Search for a card
                            </a>{" "}
                        </p>
                    ) : null}
                </>
            ) : (
                <p className="text-center font-semibold mb-2 mx-auto">
                    {`Please select${isViewOnly ? "" : " or create"} a list`}
                </p>
            )}
        </div>
    );
}

EmptyList.defaultProps = {
    isViewOnly: false,
    hasListSelected: false,
    bgColor: "bg-gray-750"
}

export default EmptyList;

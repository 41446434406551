const cartCount = document.getElementById("cart_count");

const updateCartCount = (count) => {
    if (cartCount.classList.contains("invisible")) {
        cartCount.classList.remove("invisible");
        cartCount.classList.add("visible");
        cartCount.innerText = 0;
    }
    const newCount = Number(cartCount.innerText) + count;
    if (newCount < 1) cartCount.classList.add("invisible");
    cartCount.innerText = newCount;
};

export default updateCartCount;
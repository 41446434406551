import React, { useContext, useRef } from "react";
import IconUserGold from "icon-user-gold.svg";
import IconFilledHeartGold from "icon-filled-heart-gold.svg";
import formatPrice from "../../utils/format_price";
import CardDetailItem from "../../seller_center/create_listing/card_detail_item";
import { PopupContext } from "../../popup_context";
import Button from "../../seller_center/shared_components/button";
import cartItemsMutation from "../../mutations/cart_items_mutation";
import updateCartCount from "../../utils/updateCartCount";
import PageVariablesContext from "../../page_variables_context";

const ListingInformation = ({
    draftPreview = false,
    userWantCount,
    currentOwnerUsername,
    countryName,
    price,
    shippingOptions = [],
    currency,
    marketListingId,
    ownedByCurrentUser,
    cardName,
    status,
}) => {
    const { userId } = useContext(PageVariablesContext);
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);
    const buyNow = useRef(false);

    const { addToCart } = cartItemsMutation(handleAddToCartSuccess);
    const handleAddtoCart = () => {
        if (draftPreview || ownedByCurrentUser) return;
        if (!userId) {
            window.location.replace("/users/sign_in");
            return;
        }
        addToCart({
            variables: {
                input: {
                    userId: userId,
                    marketListingId: marketListingId,
                },
            },
        });
    };

    const checkoutCartItemsCallback = (data) => {
        const result = data.checkoutCartItems;
        if (result.success) {
            window.location.replace(result.redirectUrl);
        } else {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage(result.errors.join(", "));
        }
    };
    const { checkoutCartItems } = cartItemsMutation(checkoutCartItemsCallback);

    function handleAddToCartSuccess(data) {
        const result = data.addMarketListingToCart;

        if (buyNow.current && result.cartItem.id) {
            checkoutCartItems({
                variables: {
                    input: { cartItemIds: [result.cartItem.id] },
                },
            });
        } else {
            if (result.success) {
                setShowPopup(false);
                setShowPopup(true);
                setPopupAction("success");
                setMessage(`${cardName} has been added to your cart`);
                updateCartCount(1);
            } else {
                setShowPopup(false);
                setShowPopup(true);
                setPopupAction("warn");
                setMessage(result.errors.join(", "));
            }
        }
    };
    const opacity = draftPreview && "opacity-70";
    const shippingOptionsDisplay = shippingOptions
        ?.filter((o) => o.isActive)
        .map((o) => o.fulfillmentType.replace("_", " "))
        .join(", ");

    const showActionButtons =
        (status === "active" && !ownedByCurrentUser) ||
        (status === "active" && draftPreview);
    return (
        <div className="text-gray-30 space-y-8">
            <div className={`flex space-x-4 items-center text-sm ${opacity}`}>
                <div className="flex space-x-2 items-center">
                    <img
                        src={IconUserGold}
                        alt="User icon"
                        className="icon w-4 h-4 cursor-pointer"
                    />
                    <div className="space-x-2">
                        <span>Owned by</span>
                        <a
                            href={`/marketplace/s/${currentOwnerUsername}`}
                            className="underline cursor-pointer">
                            {currentOwnerUsername || "No owner name"}
                        </a>
                    </div>
                </div>
                <div className="flex space-x-2 items-center">
                    <img
                        src={IconFilledHeartGold}
                        alt="Heart icon"
                        className="icon w-4 h-4 cursor-pointer"
                    />
                    <span>{userWantCount} people want this</span>
                </div>
                <div
                    className="fb-share-button"
                    data-href={window.location.href}
                    data-layout="button_count"
                    data-size="small">
                    <a
                        target="_blank"
                        href={
                            "https://www.facebook.com/sharer/sharer.php?u=" +
                            encodeURIComponent(window.location.href) +
                            "&amp;src=sdkpreparse"
                        }
                        className="fb-xfbml-parse-ignore">
                        <span className="ml-2">Share</span>
                    </a>
                </div>
            </div>
            <div
                className={`px-2 md:px-10 py-8 grid space-y-8 ${
                    !draftPreview && "bg-gray-800 rounded-lg"
                }`}>
                <div className="grid space-y-2">
                    <span>Current Price</span>
                    <span className="text-3xl text-primary font-semibold">
                        {formatPrice(price, "V C", currency)}
                    </span>
                </div>
                <div className="space-y-2">
                    <CardDetailItem title="Location" value={countryName} />
                    <CardDetailItem
                        title="Fulfillment"
                        value={shippingOptionsDisplay}
                        capitalize
                    />
                </div>
                {showActionButtons && (
                    <div className={`flex space-x-3 items-center ${opacity}`}>
                        <Button
                            text="Buy now"
                            primary
                            onClick={() => {
                                if (draftPreview) return;
                                buyNow.current = true;
                                handleAddtoCart();
                            }}
                            disabled={draftPreview}
                        />
                        <Button
                            text="Add to cart"
                            secondary
                            onClick={() => {
                                if (draftPreview) return;
                                handleAddtoCart();
                            }}
                            disabled={draftPreview}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ListingInformation;

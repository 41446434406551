import React, { useState } from "react";
import { Edit2 } from "react-feather";
import Button from "../../seller_center/shared_components/button";
import CardContainer from "./card_container";
import SellerShippingMethods from "./seller_shipping_methods";
import Modal from "../../modal";
import ModalPortal from "../../modal/modal_portal";
import CardImage from "../../card_image";
import formatCardPlayers from "../../utils/format_card_players";
import formatPrice from "../../utils/format_price";

const cardClassName = [
    "focus:outline-none",
    "trophyRoom-card",
    "relative",
    "flex-shrink-0",
    "border-2 border-white rounded-md",
    "w-full",
    "w-16 h-20",
].join(" ");

const OrderSummary = ({ checkoutSellers, showShippingMethod, purchaseSellerStatus, buyerLocationId, cardLocationIds }) => {
    const totalItems = checkoutSellers.reduce(
        (count, seller) => count + seller.items.length,
        0
    );
    const showEditShippingPerSeller =
        checkoutSellers.length > 1 && showShippingMethod;
    const [mountShippingModal, setMountShippingModal] = useState(false);
    const [seller, setSeller] = useState(null);

    const getPreviewUrl = (id, cardId, ownedCardId, itemStatus) => {
        const status = purchaseSellerStatus || itemStatus;
        return ["completed", "sold"].includes(status.toLowerCase())
            ? `/marketplace/listings/${id}`
            : `/browse_cards/preview/${cardId}/vaulted/${ownedCardId}`;
    };

    return (
        <CardContainer
            title="Order Summary"
            subText={`${totalItems} items`}
            subTextAlignment="row"
            titleBorder
            fullWidthHeader>
            <div className="text-md divide-y-2 divide-gray-300 divide-opacity-40">
                {checkoutSellers.map((summary) => (
                    <div
                        key={summary?.seller?.username}
                        className="p-6 space-y-6">
                        <div className="flex space-x-2">
                            <span>Fulfilled by: </span>
                            <a
                                href={`/marketplace/s/${summary?.seller?.username}`}
                                className="underline">
                                {summary?.seller?.username}
                            </a>
                        </div>
                        {showEditShippingPerSeller && (
                            <div className="p-4 w-full lg:w-1/2 rounded border boder-2 border-gray-300 border-opacity-30 bg-gray-700 flex space-x-3 items-center">
                                <span>
                                    Shipping:{" "}
                                    {
                                        summary?.seller?.shippingMethods[0]
                                            ?.displayedFulfillmentType
                                    }
                                </span>
                                <div className="flex items-center">
                                    <Edit2
                                        size={12}
                                        className="text-primary"
                                        fill="currentColor"
                                    />
                                    <Button
                                        text="Change"
                                        transparent
                                        type="button"
                                        onClick={() => {
                                            setSeller(summary?.seller);
                                            setMountShippingModal(true);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="space-y-6">
                            {summary.items.map((item) => (
                                <div key={item?.id}>
                                    <a
                                        href={getPreviewUrl(
                                            item.id,
                                            item.cardId,
                                            item.ownedCardId,
                                            item.status
                                        )}>
                                        <div className="flex space-x-6">
                                            <div className={cardClassName}>
                                                <div className="h-full rounded-md text-white hover:bg-gray-100 flex items-center p-2">
                                                    <CardImage
                                                        isFront
                                                        image={
                                                            item?.frontImage
                                                        }
                                                        hideMoreText
                                                    />
                                                </div>
                                            </div>
                                            <div className="grid">
                                                <span>
                                                    {item?.cardTitle}
                                                </span>
                                                <span>
                                                    {formatCardPlayers(
                                                        item?.playersJson
                                                    )}
                                                </span>
                                                <div className="font-semibold">
                                                    {formatPrice(
                                                        item?.price,
                                                        "V",
                                                        item?.currency
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <ModalPortal id="sellerShippingMethodModal">
                {mountShippingModal && (
                    <Modal
                        title="Preferred Shipping Method"
                        preventESC
                        size="xs"
                        closeModal={() => setMountShippingModal(false)}
                        className="p-10 text-black-100"
                        closeIcon="x"
                        modalCustomClass="rounded"
                        titleClass="text-gray-650 text-md font-semibold">
                        {({ hideModal }) => (
                            <div className="p-6 space-y-4">
                                <span className="text-black-100">
                                    How do you want your package shipped to you?
                                </span>
                                <SellerShippingMethods
                                    bgWhenSelected
                                    sellerId={seller?.id}
                                    shippingMethods={seller?.shippingMethods}
                                    buyerLocationId={buyerLocationId}
                                    cardLocationIds={cardLocationIds}
                                />
                                <div className="flex justify-end space-x-6">
                                    <Button
                                        text="Cancel"
                                        type="button"
                                        secondary
                                        onClick={() => hideModal()}
                                    />
                                    <Button
                                        text="Confirm"
                                        type="button"
                                        primary
                                        onClick={() => hideModal()}
                                    />
                                </div>
                            </div>
                        )}
                    </Modal>
                )}
            </ModalPortal>
        </CardContainer>
    );
};

OrderSummary.defaultProps = {
    checkoutSellers: [],
    showShippingMethod: true,
};

export default OrderSummary;

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import PageVariablesContext from "../../page_variables_context";

// TODO: update Ratings and Addresses url once available.
//       insert new links here
const LINKS = [
    {
        text: "My Listings",
        url: "/seller_center/my_listings",
        disabled: false,
        inErb: false,
    },
    {
        text: "Orders and shipping",
        url: "/seller_center/orders_and_shipping",
        disabled: false,
        inErb: false,
    },
    {
        text: "My earnings",
        url: "/seller_center/my_earnings/upcoming",
        disabled: false,
        inErb: true,
    },
    {
        text: "My balance",
        url: "/seller_center/top_up_and_withdrawals",
        disabled: false,
        inErb: true,
    },
    {
        text: "Recent charges",
        url: "/seller_center/charges",
        disabled: false,
        inErb: true,
    },
    {
        text: "Ratings",
        url: "/marketplace/s/:username",
        disabled: false,
        inErb: true,
        query: "?tab=reviews"
    },
    {
        text: "Addresses",
        url: "/seller_center/my_earnings/upcoming",
        disabled: true,
        inErb: true,
    }
];

const NavItem = ({
    text,
    url,
    disabled,
    inErb,
    fontSize,
    query,
}) => {
    const linkClassName = [
        "pl-8 border-primary mb-6",
        "text-sm font-body text-white-400",
        disabled ? "opacity-20 pointer-events-none" : "",
        fontSize,
    ].join(" ");

    const { username } = useContext(PageVariablesContext);

    let formattedURL = url.replace(/:username/g, username);
    if (query) formattedURL += query;

    return (
        <>
            {inErb ? (
                <a href={formattedURL}
                    className={linkClassName}>
                    {text}
                </a>
            ) : (
                <NavLink
                    to={formattedURL}
                    exact
                    activeClassName="font-semibold border-l-2 border-primary"
                    className={linkClassName}>
                    {text}
                </NavLink>
            )}
        </>
    );
};

const SideBar = ({ fontSize }) => {
    return (
        <div
            className="flex flex-col w-full mt-4"
            style={{ minWidth: "200px", maxWidth: "250px" }}>
                {LINKS.map(link => (
                    <NavItem key={link.text} {...link} fontSize={fontSize} />
                ))}
        </div>
    );
};

SideBar.defaultProps = {
    fontSize: "text-sm",
};

export default SideBar;

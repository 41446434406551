import React, { useContext, useEffect, useState } from "react";
import { SEARCH_HAVE_CARDS, SEARCH_LISTINGS } from "../../queries";
import useFilterOptions from "../../hooks/useFilterOptions";
import MarketCardList from "../../marketplace/browse_listings/card_market_list";
import SpinnerLoader from "../../spinner_loader";
import { useHistory, useLocation } from "react-router-dom";
import useFilter from "../../filter/use_filter";
import { MARKETPLACE_SORT_OPTIONS } from "../../sort_options";
import NavigationTabs from "../shared_components/navigation_tabs";
import { PopupContext, POPUP_TYPES } from "../../popup_context";
import { Plus } from "react-feather";
import FilterOverlay from "../../filter/filter_overlay";
import Button from "../shared_components/button";
import FilterButton from "../../filter_button";
import SellerCenterContainer from "../seller_center_container";
import PageVariablesContext from "../../page_variables_context";

const MyListings = (props) => {
    const {
        userId,
        isSellingAllowed,
        hasNegativeBalance
    } = useContext(PageVariablesContext);

    const tabs = [
        { text: "Published" },
        { text: "Sold" },
        { text: "Unlisted", disabled: !isSellingAllowed },
        { text: "Drafts", disabled: !isSellingAllowed },
    ];
    const history = useHistory();
    const location = useLocation();
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);
    const searchParams = new URLSearchParams(location.search.substring(1));
    const q = Object.fromEntries(searchParams);
    const [selectedTab, setSelectedTab] = useState((q.tab || tabs[0].text).toLocaleLowerCase());
    const { _search, initialPage } = useFilterOptions();
    const [searchString] = _search;
    const [isDisplayFilter, setDisplayFilter] = useState(false);
    const [cardNodes, setCardNodes] = useState([]);
    const listingsStatuses = {
        published: "active",
        drafts: "draft",
        sold: "completed",
    };
    const isUnlisted = selectedTab === "unlisted";
    const QUERY = isUnlisted ? SEARCH_HAVE_CARDS : SEARCH_LISTINGS;
    const [DEFAULT_VARIABLES, setDefaultVariables] = useState({
        userId: userId,
        searchString: searchString || "*",
        options: {
            page: Number(initialPage),
            itemsPerPage: 10,
            status: listingsStatuses[selectedTab.toLocaleLowerCase()],
            showPrivateListings: true,
            sortCategory: "added",
            sortOrder: "desc",
        },
    });

    const {
        Filters,
        SearchField,
        ResultInfo,
        SelectedFilters,
        setVariables,
        Pagination,
        variables,
        results,
        loading,
    } = useFilter(QUERY, DEFAULT_VARIABLES, MARKETPLACE_SORT_OPTIONS);

    useEffect(() => {
        const cardName = props.location?.state?.listedCardName;
        if (cardName) {
            setShowPopup(true);
            setPopupAction(POPUP_TYPES.SUCCESS);
            setMessage(`${cardName} was listed and is now active`);
        }
        if (!isSellingAllowed) {
            setShowPopup(true);
            setPopupAction(POPUP_TYPES.WARN);
            setMessage("Some of your seller center features are currently disabled. Please upgrade your subscription plan to fully use your seller center");
        }
    }, []);

    useEffect(() => {
        const tab = tabs.find(t => t.text.toLocaleLowerCase() === selectedTab);
        if (tab?.disabled)
            setSelectedTab(tabs[0].text);
        else
            fetchListings();
    }, [selectedTab]);

    const fetchListings = () => {
        setVariables({
            ...DEFAULT_VARIABLES,
            ...(isUnlisted && {haveListId: "*"}),
            options: {
                ...DEFAULT_VARIABLES.options,
                status: listingsStatuses[selectedTab.toLocaleLowerCase()],
                ...(isUnlisted && { listingStatus: "unlisted" }),
            },
        });
        q.tab = selectedTab;
        history.push({
            pathname: location.pathname,
            search: `?${new URLSearchParams(q).toString()}`,
        });
    };

    useEffect(() => {
        setCardNodes(results?.cards || []);
        setDefaultVariables({
            ...DEFAULT_VARIABLES,
            searchString: results?.filters?.searchString,
            ...(isUnlisted && {haveListId: "*"}),
            options: {
                ...DEFAULT_VARIABLES.options,
                ...(isUnlisted && { listingStatus: "unlisted" }),
                status: listingsStatuses[selectedTab.toLocaleLowerCase()],
            },
        });
    }, [results]);

    if (hasNegativeBalance)  {
        window.location.replace("/seller_center/top_up/required");
        return true;
    }

    return (
        <SellerCenterContainer>
            <div className="flex flex-col w-full">
                <NavigationTabs
                    tabs={tabs}
                    selectedTab={selectedTab}
                    customClass="pt-0"
                    setSelectedTab={setSelectedTab}>
                        <Button
                            primary
                            text="Add New Listing"
                            icon={<Plus className="w-4 h-4" />}
                            url="/seller_center/new_listing"
                            disabled={!isSellingAllowed}
                            customClass="ml-auto md:ml-0"
                        />
                </NavigationTabs>
                <FilterOverlay displayFilter={isDisplayFilter}>
                    <Filters
                        visible={true}
                        setVisibility={setDisplayFilter}
                        overlay
                        showHideButton
                    />
                </FilterOverlay>
                <div className="flex flex-col-reverse px-1 md:px-6 md:flex-row items-center justify-between pt-8">
                    <div className="flex flex-col mt-6 lg:mt-0 text-white-400 pr-4 py-4 font-body text-sm w-full md:w-5/12">
                        {searchString &&
                            variables?.searchString !== "*" && (
                                <span className="">
                                    <ResultInfo textClass="text-md text-gray-30" />
                                </span>
                            )}
                        <SelectedFilters />
                    </div>
                    {!loading ? (
                        <div className="flex space-x-4 items-center pr-4">
                            <SearchField
                                customParams={{
                                    name: "tab",
                                    value: selectedTab,
                                }}
                                darkMode
                            />
                            <FilterButton
                                lgHidden={false}
                                textColor="text-white"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDisplayFilter(!isDisplayFilter);
                                }}
                            />
                        </div>
                    ) : null}
                </div>
                {loading ? (
                    <div className="px-8 py-4">
                        <SpinnerLoader />
                    </div>
                ) : (
                    <>
                        {Boolean(cardNodes.length) ? (
                            <div className="flex flex-col space-y-8 pb-8">
                                <MarketCardList
                                    rowView
                                    listings={cardNodes}
                                    callback={fetchListings}
                                    withCheckbox
                                    smallerList
                                    withTeamNames
                                    unlistedList={isUnlisted}
                                />
                                <Pagination />
                            </div>
                        ) : (
                            <div className="p-4 flex justify-center w-full">
                                <div className="text-4xl text-white">
                                    No cards listed.
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </SellerCenterContainer>
    );
};

export default MyListings;

import React, { Fragment, useState, useEffect } from "react";
import FilterHeader from "../filter_header";
import Checkbox from "../checkbox";
import { Search, X, ChevronUp } from "react-feather";

const FilterItem = ({
    title = "Filter Title",
    expanded = true,
    searchEnabled = false,
    singleValue = false,
    options = [],
    selected = [],
    variables,
    darkMode,
    setVariables = () => {},
}) => {
    const getShowedOptions = () => {
        let selectedOptions =
            selected.length > 0
                ? options.filter((i) => {
                      if (!i) return false;
                      return selected.includes(i);
                  })
                : [];
        if (selectedOptions.length < 5) {
            const unselectedOptions = options.filter(
                (i) => !selected.includes(i)
            );
            selectedOptions = [
                ...selectedOptions,
                ...unselectedOptions.slice(0, 5 - selectedOptions.length),
            ];
        }
        return selectedOptions;
    };
    const [searchString, setSearchString] = useState("");
    const [showedOptions, setShowOptions] = useState([]);
    const [showSelection, setShowSelection] = useState(false);
    const [showAll, setShowAll] = useState(false);
    useEffect(() => {
        setShowSelection(expanded || selected.length > 0);
    }, [expanded]);

    useEffect(() => {
        setShowOptions(getShowedOptions());
    }, [selected]);

    const handleFilterSearch = (e) => {
        setSearchString(e.target.value.toLowerCase())
        if (e.target.value.length < 1) {
            setShowOptions(options.slice(0, 5))
            return
        }
        let searchedOptions = [];
        options.forEach((i) => {
            if (i && i.toLowerCase().includes(searchString))
                searchedOptions.push(i);
        });
        // const searchedOptions = options.filter(i => {
        //     if (!i) return false
        //     return i.toLowerCase().find(searchString) !== -1
        // })

        setShowOptions(searchedOptions);
        setShowAll(false);
    };

    const handleSearchClear = () => {
        if (searchString !== "") {
            setSearchString("");
            setShowAll(true);
            setShowOptions(getShowedOptions());
            return;
        }
    };

    const handleShowAll = (show) => {
        if (show)
            setShowOptions(
                options.length > 200 ? options.slice(0, 200) : options
            );
        else setShowOptions(getShowedOptions());
        setShowAll(show);
    };

    const handleChange = (e, item) => {
        const checked = e.target.checked;
        let filterValues = checked
            ? [...selected, item]
            : [...selected].filter((i) => i != item);
        if (!checked && singleValue) filterValues = [];
        if (checked && singleValue) filterValues = [item];
        const filterKey = camelize(title.replace("_", " "));

        const updatedVariables = {
            ...variables,
            options: {
                ...variables.options,
                [filterKey]: filterValues,
            },
        };
        setVariables(updatedVariables);
    };

    return (
        <Fragment>
            <div className="py-4">
                <FilterHeader
                    title={title.replace("_", " ")}
                    hasSelected={Boolean(selected.length)}
                    showSelection={showSelection}
                    setShowSelection={setShowSelection}
                />
                {showSelection && (
                    <div className="space-y-6 text-white mt-4">
                        {searchEnabled && (
                            <div
                                className={`p-2 flex flex-row-reverse
                                lg:flex-row items-center overflow-hidden
                                ${
                                    darkMode
                                        ? "bg-gray-70 rounded-sm pr-4"
                                        : "bg-white rounded lg:pr-16 "
                                }
                                relative py-2 h-10`}>
                                <label
                                    className={`text-fs ${
                                        darkMode
                                            ? "text-white-400 pr-0 pl-1"
                                            : "text-primary pr-0 px-2"
                                    } py-2 border-l lg:border-none`}
                                    htmlFor="searchField">
                                    <Search size={`${darkMode ? "18" : ""}`} />
                                </label>
                                <div className="flex items-center relative w-full">
                                    <input
                                        id="searchField"
                                        name={`${title.replace(" ", "")}Search`}
                                        className={`${
                                            darkMode
                                                ? "bg-gray-70 rounded-sm placeholder-white-500 text-white-500"
                                                : "placeholder-gray-100 text-black-100"
                                        } text-sm border-0 outline-none focus:outline-none focus:ring-0 w-full`}
                                        type="text"
                                        placeholder={`Search ${title.toLowerCase()}...`}
                                        onChange={(e) => handleFilterSearch(e)}
                                        value={searchString}
                                    />
                                </div>
                                <button
                                    type="reset"
                                    className={`${
                                        searchString ? "" : "hidden"
                                    } flex items-center px-4 ${
                                        darkMode ? "pr-0" : ""
                                    } text-fs-15 text-black hover:text-primary focus:outline-none focus:ring-0`}>
                                    <X
                                        className={`${
                                            darkMode
                                                ? "block text-white-400"
                                                : "hidden"
                                        } w-4 h-4`}
                                        onClick={() => handleSearchClear()}
                                    />
                                    {/* <span className={`${darkMode ? "hidden" : "" } lg:underline lg:border-r border-gray-500 ml-1 lg:pr-4 lg:mr-0 text-xs text-gray-500`}
                                        style={{ paddingRight: "0.8rem" }}>
                                        Clear
                                    </span> */}
                                </button>
                            </div>
                        )}
                        <div>
                            {showedOptions.map(f => {
                                if (!f) return null;
                                return (
                                    <Checkbox
                                        id={f}
                                        key={f}
                                        name="filter"
                                        onChange={(e) => handleChange(e, f)}
                                        label={
                                            title === "attributes"
                                                ? f
                                                      .replace("has_", "")
                                                      .replace("is_", "")
                                                : f
                                        }
                                        checked={selected.includes(f)}
                                        value={f}
                                    />
                                )
                            })}
                        </div>
                        <div className="text-xs font-semibold">
                            <FilterItemsAction
                                options={options}
                                showedOptions={showedOptions}
                                showAll={showAll}
                                handleShowAll={handleShowAll}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

const FilterItemsAction = ({
    showAll,
    handleShowAll,
    options
}) => {
    if (options.length <= 5) return null;

    let text = "Show first 5";
    const iconClass = [
        "text-primary",
        !showAll ? "transform rotate-180" : "" //changing actual icon(chevrondown) triggers filter close
    ].join(" ");
    if (!showAll) text = options.length > 200
                            ? "Show all 200"
                            : `Show all ${options.length}`;

    return (
        <button
            type="button"
            className="flex items-center"
            onClick={() => handleShowAll(!showAll)}>
                {text}
                <ChevronUp size={20} className={iconClass}/>
        </button>
    );
}

const camelize = (str) => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
};

export default FilterItem;

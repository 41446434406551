import React from 'react';
import badgeBg from './badge.svg';
import badgeBgLong from './badge__long.svg';
import badgeBgOutline from './badge-outline.svg';
import badgeBgOutlineLong from './badge-outline__long.svg';

function bgUrl(outline, long) {
    switch (long) {
        case true:
            return outline ? badgeBgOutlineLong : badgeBgLong;
        default:
            return outline ? badgeBgOutline : badgeBg;
    }
}

const Badge = ({ outline, long, children, className = '' }) => {
    const c = [
        'flex items-center justify-center font-bold',
        'w-20 lg:w-28 pr-1.5 lg:pr-0',
        'bg-contain bg-center bg-no-repeat uppercase text-xxs lg:text-xs',
        outline ? 'text-primary' : 'text-black',
        className,
    ].join(' ');
    return (
        <div
            className={c}
            style={{
                height: '1.5rem',
                backgroundImage: `url(${bgUrl(outline, long)})`,
            }}
        >
            {children}
        </div>
    );
};

export default Badge;

/*
<svg
            viewBox="0 0 148 52"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            width={height * 2.85}
            height={height}
        >
            <path
                d="M257.72561,202.36473L272.24195,226.88107L257.72561,251.39741L257.72561,251.39741L141.15816,251.39741L141.15816,251.39741L126.64182,226.88107L141.15816,202.36473L141.15816,202.36473L257.72561,202.36473Z"
                transform="matrix(1 0 0 1 -125.441885 -200.88107)"
                fill={outline ? 'transparent' : 'currentColor'}
                stroke="currentColor"
                strokeWidth="2"
                strokeMiterlimit="1"
            />
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={22}
                fontWeight="700"
                fontFamily="Geomanist"
                letterSpacing="0.1"
                fill={outline ? 'currentColor' : 'black'}
            >
                {text.toUpperCase()}
            </text>
        </svg>
*/


import React from "react";
import IconUserGold from 'icon-user-gold.svg';

const Offers = () => (
    <>
        <table className="w-full merqary-table">
            <thead className="border-b border-gray-575">
                <tr>
                    <th className="text-base font-body font-semibold w-2/12">Price</th>
                    <th className="text-sm font-body font-semibold w-2/12">Offer Date</th>
                    <th className="text-sm font-body font-semibold w-2/12">Expiration</th>
                    <th className="text-sm font-body font-semibold w-2/12">From</th>
                </tr>
            </thead>
            <tbody>
                <tr className="border-b border-gray-575">
                    <td className="text-base font-body w-2/12 p-0">
                        <span className="text-sm text-primary font-semibold">
                            $85.00
                        </span>
                    </td>
                    <td className="text-sm font-body w-2/12 p-0">September 1, 2021</td>
                    <td className="text-sm font-body w-2/12 p-0">October 1, 2021</td>
                    <td className="text-sm font-body w-2/12 p-0 flex items-center">
                        <img src={IconUserGold} alt="User icon" className="icon w-4 h-4 cursor-pointer" />
                        <span className="ml-2 underline">Momomogu</span>
                    </td>
                </tr>
                <tr className="border-b border-gray-575">
                    <td className="text-base font-body w-2/12 p-0">
                        <span className="text-sm text-primary font-semibold">
                            $85.00
                        </span>
                    </td>
                    <td className="text-sm font-body w-2/12 p-0">September 8, 2021</td>
                    <td className="text-sm font-body w-2/12 p-0">October 1, 2021</td>
                    <td className="text-sm font-body w-2/12 p-0 flex items-center">
                        <img src={IconUserGold} alt="User icon" className="icon w-4 h-4 cursor-pointer" />
                        <span className="ml-2 underline">fumiko</span>
                    </td>
                </tr>
                <tr className="">
                    <td className="text-base font-body w-2/12 p-0">
                        <span className="text-sm text-primary font-semibold">
                            $85.00
                        </span>
                    </td>
                    <td className="text-sm font-body w-2/12 p-0">September 9, 2021</td>
                    <td className="text-sm font-body w-2/12 p-0">October 1, 2021</td>
                    <td className="text-sm font-body w-2/12 p-0 flex items-center">
                        <img src={IconUserGold} alt="User icon" className="icon w-4 h-4 cursor-pointer" />
                        <span className="ml-2 underline">harryroque</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </>
)

export default Offers
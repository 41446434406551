import React, { Fragment } from "react";
import EmptyList from "./empty_list";
import CardList from "./card_list";
import SpinnerLoader from "../spinner_loader";

const OtherListContainer = ({
    listId,
    nodes,
    loading,
    hasCards,
    name,
    pagination,
}) => {
    return (
        <Fragment>
            <div className="mt-8 lg:mt-0 md:ml-8 flex flex-col w-full  animate__animated animate__fadeIn">
                <div className="mb-6 flex items-center">
                    <h1 className="text-white text-2xl mr-auto">
                        {name || "-"}
                    </h1>
                </div>

                {nodes?.length > 0 ? (
                    <CardList
                        cards={nodes}
                        loading={loading}
                        isHaveList={true}
                        isViewOnly={true}
                        listId={listId}
                    />
                ) : loading ? (
                    <SpinnerLoader />
                ) : (
                    <EmptyList isViewOnly={true} hasListSelected={true} />
                )}
                {hasCards && pagination}
            </div>
        </Fragment>
    );
};

export default OtherListContainer;

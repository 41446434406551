import React from "react"
import {
    ChevronRight,
} from 'react-feather';
import { matchPath, useLocation } from "react-router-dom";

const NEW_LISTING = {
    path: '/seller_center/new_listing',
    items: [
        {
            title: 'My listings',
            url: '/seller_center/my_listings'
        },
        {
            title: 'Add new listing',
            url: "/seller_center/new_listing"
        }
    ]
}
const CREATE_LISTING = {
    path: '/seller_center/create_listing/:id',
    items: [
        ...NEW_LISTING.items,
        {
            title: 'Enter details',
            url: null
        }
    ]
}

const SHOP_SETTINGS = {
    path: '/seller_center/shop_settings',
    items: [
        {
            title: 'Seller Center',
            url: '/seller_center/my_listings'
        },
        {
            title: 'Shop settings',
            url: null
        }
    ]
}

const UPDATE_LISTING = {
    path: '/seller_center/my_listings/:id',
    items: [
        {
            title: 'My listings',
            url: '/seller_center/my_listings'
        },
        {
            title: 'Update listing',
            url: null
        }
    ]
}

const DRAFT_PREVIEW = {
    path: '/seller_center/my_listings/:id/preview',
    items: [
        {
            title: 'My listings',
            url: '/seller_center/my_listings'
        },
        {
            title: 'Preview',
            url: null
        }
    ]
}

const MAANGE_ORDER = {
    path: '/seller_center/orders_and_shipping/:id/manage_order',
    items: [
        {
            title: 'Orders and shipping',
            url: '/seller_center/orders_and_shipping'
        },
        {
            title: 'Manage order',
            url: null
        }
    ]
}

const BREAD_CRUMBS = [
    NEW_LISTING,
    CREATE_LISTING,
    SHOP_SETTINGS,
    UPDATE_LISTING,
    MAANGE_ORDER,
    DRAFT_PREVIEW
]

const useBreadCrumbs = () => {
    const location = useLocation()
    let crumbs = BREAD_CRUMBS.filter(item =>
        matchPath(location.pathname, {
            path: item.path,
            exact: true,
            strict: false
        })
    )
    const idPattern = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/
    const hasCrumbs = crumbs.length > 0

    const BreadCrumbs = () => {
        return (
            <>
                {hasCrumbs && (
                    <div className='flex items-center justify-start space-x-4 text-black-100 merqary-banner py-2 px-14 w-full text-sm capitalize '>
                        {crumbs[0].items.map((item, i) => (
                            <div key={i} className='flex space-x-4'>
                                {item.url && i < crumbs[0].items.length - 1 ? (
                                    <a
                                        className="underline"
                                        href={formatUrl(item.url)}
                                    >
                                        {item.title}
                                    </a>
                                ) : (
                                    <span>{item.title}</span>
                                )}
                                {i < crumbs[0].items.length - 1 && (
                                    <ChevronRight size={18} className="text-gray-70" style={{ strokeWidth: "3px", marginTop: "1px" }} />
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </>
        )
    }

    const formatUrl = (url) => {
        const idParam = location.pathname.split("/").filter(e => idPattern.test(e))
        if (!Boolean(idParam.length)) return url
        return url.replace(":id", idParam[0])
    }

    return {
        hasCrumbs,
        BreadCrumbs
    }
}

export default useBreadCrumbs
import React, { createContext, useState } from 'react';

const VariablesContext = createContext({
    variables: {},
    setVariables: () => {},
    isTrophyRoom: false,
    setIsTrophyRoom: () => {},
});

const VariablesContextProvider = ({ props, children }) => {
    const [variables, setVariables] = useState({});
    const [isTrophyRoom, setIsTrophyRoom] = useState('');

    const value = { variables, setVariables, isTrophyRoom, setIsTrophyRoom };

    return (
        <VariablesContext.Provider value={value}>
            {children}
        </VariablesContext.Provider>
    );
};

export { VariablesContextProvider, VariablesContext };

import React from "react";
import { ApolloProvider } from "@apollo/client";
import client from "./client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: window.MERQARY_APP_VERSION,
    enabledReleaseStages: ["production", "merqary-dev", "merqary-staging", "merqary-sandbox"],
    releaseStage: process.env.RAILS_CUSTOM_STAGE || process.env.NODE_ENV,
    plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const ErrorView = () => (
    <div className="h-screen v-screen flex items-center justify-center bg-black-100">
        <h2 className="text-white">Something went wrong</h2>
    </div>
);

const ApolloProviderClient = ({ children }) => {
    return (
        <ErrorBoundary FallbackComponent={ErrorView}>
            <ApolloProvider client={client}>{children}</ApolloProvider>
        </ErrorBoundary>
    );
};

export default ApolloProviderClient;

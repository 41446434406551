import { useQuery } from "@apollo/client";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useRef, useState } from "react";
import { GET_LISTING_CONSTANTS } from "../../queries";
import * as yup from "yup";
import Select from "react-select";
import SpinnerLoader from "../../spinner_loader";
import DealFormat from "./deal_format";
import { HelpCircle } from "react-feather";
import ShippingMethod from "./shipping_method";
import FormFooter from "./form_footer";
import EstimatedEarnings from "./estimated_earnings";
import { PopupContext } from "../../popup_context";
import { useHistory } from "react-router-dom";
import MapPin from "icon-dark-gray-pin-drop.svg";
import useMarketListingMutation from "../../mutations/market_listing_mutation";
import Checkbox from "../../checkbox";
import HelpText from "../../utils/help_text";
import PageVariablesContext from "../../page_variables_context";


const listingSchema = yup.object().shape({
    ownedCardId: yup.string().required(),
    condition: yup.string().required("Select card condition"),
    details: yup.string().nullable(),
    price: yup
        .number()
        .min(0.50, "Input listing price (minimum 0.50)")
        .max(50000, `Price max is at ${50000}`)
        .test("maxDecimal",
            "Price must be in maximum of 2 decimal places",
            (price) => /^\d+(\.\d{1,2})?$/.test(price)
        )
        .required("Input listing price"),
    dealFormat: yup.string().required("Select a deal format"),
    currency: yup.string().required("select a currency"),
    userId: yup.string().required(),
    location: yup.string().required("Input location").nullable(),
    status: yup.string(),
    isPrivate: yup.boolean()
});

const ListingForm = ({
    fulfillmentOptions,
    ownedCardId,
    cardName,
    forUpdate = false,
    allowSubmit = true,
    countryId,
    defaultValue = null,
    submitText = "Publish listing",
    currentOwner,
}) => {
    const history = useHistory();
    const { userId } = useContext(PageVariablesContext);
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);

    const createListingCallback = (data) => {
        const result = data.listOwnedCardToMarketplace;
        const redirectionPage = redirectionLink.current;
        if (result.result && redirectionPage === "preview") {
            setTimeout(() => {
                history.push({
                    pathname: `/seller_center/my_listings/${result.marketListing.id}/preview`,
                    state: { listedCardName: cardName },
                });
            }, 100);
        }
        if (result.result && redirectionPage === "drafts") {
            window.location.replace("/seller_center/my_listings?tab=Drafts");
        }
        if (result.result && redirectionPage) {
            setTimeout(() => {
                history.push({
                    pathname: redirectionPage,
                    state: { listedCardName: cardName },
                });
            }, 100);
        }
        if (!result.result) {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage(result.errors.join(", "));
        }
    };

    const updateListingCallback = (data) => {
        const result = data.updateMarketListing;
        if (result.result) {
            setShowPopup(true);
            setPopupAction("success");
            setMessage("Market listing updated");
            if (redirectionLink.current === "drafts")
                redirectionLink.current = "/seller_center/my_listings?tab=Drafts";
            setTimeout(() => {
                history.push(redirectionLink.current);
            }, 100);
        } else {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage(result.errors.join(", "));
        }
    };

    const { createListing, createListingLoading } = useMarketListingMutation(createListingCallback);
    const { updateListing, updateListingLoading } = useMarketListingMutation(updateListingCallback);

    const { data: constantsData, loading: constantsLoading } = useQuery(
        GET_LISTING_CONSTANTS,
        { fetchPolicy: "cache-first" }
    );

    const constants = constantsData?.constants;
    const conditionOptions = constants?.cardConditions.map((c) => ({
        label: c,
        value: c,
    }));
    const locationOptions = constants?.countries.map((c) => ({
        label: c.name,
        value: c.id,
    }));
    const dealFormats = constants?.dealFormats;
    const shippingMethods = constants?.fulfillmentOptions; //constants
    const userShippingMethods = fulfillmentOptions; //seller shipping methods
    const defaultCurrency = constants?.defaultCurrency;
    const feePercentage = constants?.feePercentage;
    const [showEstimatedEarnings, setShowEstimatedEarnings] = useState(false);
    const redirectionLink = useRef("/seller_center/my_listings");
    const userActiveShippingMethods = userShippingMethods?.filter(
        (s) => s.isActive
    );
    const hasPrivateListingPrivilege = currentOwner?.hasPrivateListingPrivilege || false;

    const initialValues = defaultValue || {
        ownedCardId: ownedCardId,
        condition: constants?.cardConditions[0],
        price: 0,
        dealFormat: dealFormats?.filter((d) => d === "For Sale")[0],
        currency: defaultCurrency,
        location: countryId || "",
        status: "active",
        userId: userId,
        details: "",
        isPrivate: false
    };

    const handleFormSubmit = ({ ...values }, redirectionPage = null) => {
        if (redirectionPage) redirectionLink.current = redirectionPage;

        if (
            !Boolean(userActiveShippingMethods.length) &&
            values.status === "active"
        ) {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage("Please enable at least one shipping method to proceed");
            return;
        }

        const variables = {
            ...(forUpdate && { id: values.id }),
            listing: {
                ...(({ id, countryName, ...v }) => v)(values),
                price: Number(values.price),
            },
        };

        if (forUpdate) {
            if (!allowSubmit) {
                setShowPopup(true);
                setPopupAction("warn");
                setMessage("Listing with transaction cannot be updated!");
                return;
            }
            updateListing({
                variables: {
                    input: variables,
                },
            });
        }

        if (!forUpdate) {
            createListing({
                variables: {
                    input: variables,
                },
            });
        }
    };


    const defaultLocation = locationOptions?.filter(
        (o) => o.value === countryId
    );

    return (
        <div className="flex flex-col pt-8 space-y-8">
            <span className="text-md font-semibold text-gray-30">
                About your listing
            </span>
            {constantsLoading && <SpinnerLoader />}
            {!constantsLoading && (
                <Formik
                    onSubmit={(values) =>
                        handleFormSubmit({ ...values, status: "active" })
                    }
                    validationSchema={listingSchema}
                    initialValues={initialValues}>
                    {(form) => (
                        <Form className="w-full divide divide-y-2 divide-opacity-40 space-y-12">
                            <div className="space-y-8">
                                {hasPrivateListingPrivilege ? (
                                    <div className="flex space-x-2 items-center w-full">
                                        <Checkbox
                                            id="isPublic"
                                            name="isPublic"
                                            onChange={(e) => form.setFieldValue("isPrivate", e.target.checked)}
                                            label="Private Listing"
                                            inputDimension={5}
                                            defaultChecked={defaultValue?.isPrivate}
                                            labelClass="cursor-pointer text-gray-30 text-lg space-x-3"
                                            inputBGColor="bg-gray-650"
                                        />
                                        <HelpText
                                            text="Your listing will only be available for users that have your listing URL"
                                            sideTextClass="uppercase text-lg font-semibold text-primary"
                                            iconColor="text-primary"
                                            size={18}
                                        />
                                    </div>
                                ) : null}
                                <div className="grid space-y-2 w-full">
                                    <span className="text-sm font-body mb-1">
                                        Card Condition
                                    </span>
                                    <div className="text-gray-700 space-y-2">
                                        <Select
                                            className="w-full md:w-5/12 rounded text-gray-700 text-sm"
                                            onChange={(v) => {
                                                form.setFieldValue(
                                                    "condition",
                                                    v.value
                                                );
                                            }}
                                            options={conditionOptions}
                                            defaultValue={
                                                defaultValue
                                                    ? conditionOptions.filter(
                                                          (o) =>
                                                              o.value ===
                                                              defaultValue.condition
                                                      )
                                                    : conditionOptions[0]
                                            }
                                        />
                                        <ErrorMessage name="condition">
                                            {(msg) => (
                                                <div className="text-red-400 text-xs">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div className="grid space-y-2 w-full">
                                    <span className="text-sm font-body mb-1">
                                        Add more details [flaws, markings, etc.]
                                    </span>
                                    <div className="text-gray-700 space-y-2">
                                        <input
                                            type="text"
                                            name="details"
                                            className="w-full rounded text-gray-700 text-sm"
                                            placeholder="Any more information you want to add"
                                            onChange={(e) =>
                                                form.setFieldValue(
                                                    "details",
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                defaultValue &&
                                                defaultValue.details
                                            }
                                        />
                                        <ErrorMessage name="details">
                                            {(msg) => (
                                                <div className="text-red-400 text-xs">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-8 space-y-8">
                                <div className="space-y-6">
                                    <span className="text-md font-semibold text-gray-30">
                                        Sale Format
                                    </span>
                                    <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center pr-4 pt-2 pb-4 mb-4 font-body text-sm md:space-x-4">
                                        {dealFormats.map((dealFormat, i) => (
                                            <DealFormat
                                                key={i}
                                                dealFormat={dealFormat}
                                                selectedDeal="For Sale"
                                                // handleDealChange={handleDealChange} // Fixed price available for now
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="space-y-6">
                                    <span className="text-md font-semibold text-gray-30">
                                        Price your listing
                                    </span>
                                    <div className="space-y-2">
                                        <div className="flex items-center rounded bg-white relative w-full md:w-5/12">
                                            <label className="text-md font-semibold text-black-100 px-6 border-r-2 border-gray-200 border-opacity-60">
                                                {defaultCurrency}
                                            </label>
                                            <input
                                                name="price"
                                                className="w-full border-0 outline-none focus:outline-none focus:ring-0 rounded text-gray-700 text-sm"
                                                type="number"
                                                min={0.50}
                                                max={50000}
                                                step="any"
                                                onChange={(e) =>
                                                    form.setFieldValue(
                                                        "price",
                                                        e.target.value
                                                    )
                                                }
                                                onWheel={(e) => e.target.blur()}
                                                defaultValue={
                                                    defaultValue &&
                                                    defaultValue.price
                                                }
                                            />
                                        </div>
                                        <ErrorMessage name="price">
                                            {(msg) => (
                                                <div className="text-red-400 text-xs">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div className="text-gray-30 text-sm" hidden>
                                    <span className="font-semibold">
                                        You will earn: USD 50.00
                                    </span>
                                    <div className="flex space-x-4">
                                        <span>
                                            Your estimated earnings, after
                                            commissions.
                                        </span>
                                        <button
                                            className="flex space-x-2 items-center cursor-pointer"
                                            type="button"
                                            onClick={() =>
                                                setShowEstimatedEarnings(true)
                                            }>
                                            <HelpCircle className="w-5 h-5 text-primary" />
                                            <span className="font-semibold">
                                                Explain this
                                            </span>
                                        </button>
                                    </div>
                                    <EstimatedEarnings
                                        mountModal={showEstimatedEarnings}
                                        setMountModal={setShowEstimatedEarnings}
                                        feePercentage={feePercentage}
                                    />
                                </div>
                            </div>
                            <div className="pt-8 space-y-6">
                                {userShippingMethods && (
                                    <ShippingMethod
                                        shippingMethods={shippingMethods}
                                        handleFormSubmit={handleFormSubmit}
                                        userShippingMethods={
                                            userShippingMethods
                                        }
                                        forUpdate={forUpdate}
                                    />
                                )}
                                <div className="grid space-y-2 w-full">
                                    <span className="text-sm font-body mb-1">
                                        Card Location
                                    </span>
                                    <div className="space-y-2">
                                        <div className="flex items-center rounded bg-white relative w-full md:w-5/12">
                                            <span className="text-md font-semibold px-4">
                                                <img
                                                    src={MapPin}
                                                    alt={MapPin}
                                                    className="w-6 h-5"
                                                />
                                            </span>
                                            <Select
                                                className="w-full border-0 outline-none focus:outline-none focus:ring-0 rounded text-gray-700 text-sm"
                                                onChange={(v) =>
                                                    form.setFieldValue(
                                                        "location",
                                                        v.value
                                                    )
                                                }
                                                options={locationOptions}
                                                defaultValue={defaultLocation}
                                            />
                                        </div>
                                        <ErrorMessage name="location">
                                            {(msg) => (
                                                <div className="text-red-400 text-xs">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                            </div>
                            <FormFooter
                                history={history}
                                locationOptions={locationOptions}
                                handleFormSubmit={handleFormSubmit}
                                submitText={submitText}
                                listingId={defaultValue?.id}
                                loading={createListingLoading || updateListingLoading}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default ListingForm;

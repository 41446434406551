import React, { Fragment, useEffect, useState } from "react";
import { SEARCH_LISTINGS } from "../../queries";
import SpinnerLoader from "../../spinner_loader";
import useFilterOptions from "../../hooks/useFilterOptions";
import MarketCardList from "./card_market_list";
import BgLights from "bg-lights-darker.png";
import MainBreadcrumbs from "../main_breadcrumbs";
import useFilter from "../../filter/use_filter";
import { MARKETPLACE_SORT_OPTIONS } from "../../sort_options";
import IconGrayArticle from "icon-gray-article.svg";
import BentoMenu from "bento_menu";
import FilterButton from "../../filter_button";

const BrowseListings = () => {
    const { _search, initialPage } = useFilterOptions();
    const [searchString] = _search;
    const DEFAULT_VARIABLES = {
        searchString: searchString || "*",
        options: {
            page: Number(initialPage),
            status: "active",
            sortCategory: "added",
            sortOrder: "desc",
            itemsPerPage: 20,
        },
    };

    const {
        Filters,
        SearchField,
        SortField,
        Pagination,
        ResultInfo,
        SelectedFilters,
        results,
        loading,
    } = useFilter(SEARCH_LISTINGS, DEFAULT_VARIABLES, MARKETPLACE_SORT_OPTIONS);

    const cardNodes = results?.cards || [];
    const hasCards = Boolean(cardNodes.length);
    const [isDisplayFilter, setDisplayFilter] = useState(false);

    useEffect(() => {
        /** show the filters on wider widths initially */
        if (document.documentElement.clientWidth > 1024) {
            setDisplayFilter(true);
        }
    }, []);

    useEffect(() => {
        if (document.documentElement.clientWidth <= 1024) {
            document.body.style.overflow = isDisplayFilter ? "hidden" : "auto";
        }
    }, [isDisplayFilter]);

    return (
        <Fragment>
            <MainBreadcrumbs isMainMarketPlace />
            <div
                className="px-8 pb-8 animate__animated animate__fadeIn bg-fixed bg-cover bg-center bg-no-repeat min-h-screen"
                style={{ backgroundImage: `url(${BgLights})` }}>
                <div className="merqary-container mx-auto flex flex-row items-start">
                    <Filters
                        showAsDefault
                        visible={isDisplayFilter}
                        setVisibility={setDisplayFilter}
                        isFloating
                    />
                    <div className="flex flex-col w-full">
                        {!loading ? (
                            <div className="pt-8 pb-4 md:pl-8 pr-0 w-full">
                                <SearchField />
                            </div>
                        ) : null}
                        {loading ? (
                            <div className="px-8">
                                <SpinnerLoader />
                            </div>
                        ) : null}

                        {!loading ? (
                            <div className="mt-4 flex flex-col w-full">
                                <div className="flex flex-col md:flex-row items-center pb-4 pl-8 w-full mb-2">
                                    {!loading ? (
                                        <>
                                            {searchString && (
                                                <div className="flex flex-col">
                                                    <ResultInfo />
                                                </div>
                                            )}
                                            <SelectedFilters />
                                        </>
                                    ) : null}
                                    <div className="ml-auto flex items-center space-x-2">
                                        <SortField />
                                        <FilterButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDisplayFilter(
                                                    !isDisplayFilter
                                                );
                                            }}
                                        />

                                        <div className="hidden md:visible flex items-center">
                                            <img
                                                src={IconGrayArticle}
                                                className="w-7 h-7"
                                            />
                                            <BentoMenu backColor="bg-white-400" />
                                        </div>
                                    </div>
                                </div>
                                {hasCards ? (
                                    <div className="h-full">
                                        <MarketCardList
                                            listings={cardNodes}
                                            showFlip={false}
                                            showWantIcon={false}
                                            showCartIcon
                                            isMarketPlace
                                            numTruncatedCardName={40}
                                            withTeamNames
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <div className="text-4xl py-4 text-sm text-white w-full text-center">
                                            No cards listed.
                                        </div>
                                    </div>
                                )}
                                {hasCards && (
                                    <Pagination />
                                    // <PaginationContainer
                                    //     {...pageInfo}
                                    //     goToPage={goToPage}
                                    // />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default BrowseListings;

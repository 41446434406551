import React, { useState } from 'react'
import ListingPreviewDescription from './listing_preview_description'
import OtherListings from './other_listings'
import Offers from '../../marketplace/listing_preview/listing_offers'
import NavigationTabs from '../../seller_center/shared_components/navigation_tabs'

const ListingPreviewBody = ({
    card,
    listing,
    ownedCard,
    draftPreview,
}) => {
    const tabs = ["Description"]
    const [selectedTab, setSelectedTab] = useState(tabs[0])
    return (
        <>
            <NavigationTabs
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
            />
            <div className='divide-y divide-gray-575 space-y-8'>
                {selectedTab === "Description" && (
                    <ListingPreviewDescription
                        {...card}
                        {...listing}
                        {...ownedCard}
                    />
                )}
                {/* TODO Offers feature */}
                {selectedTab === "Offers" && (
                    <Offers />
                )}
                <OtherListings
                    userId={listing.userId}
                    listingId={listing.marketListingId || null}
                    limit={2}
                    draftPreview={draftPreview}
                />
            </div>
        </>
    )
}

export default ListingPreviewBody

import React, { useContext, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import BgLightsDarker from "bg-lights-darker.png";
import CardImageContainer from "../../seller_center/create_listing/card_image_container";
import CardDetails from "../../seller_center/create_listing/card_details";
import ListingInformation from "./listing_information";
import ListingPreviewBody from "./listing_preview_body";
import { gql, useLazyQuery } from "@apollo/client";
import MainBreadcrumbs from "../../marketplace/main_breadcrumbs";
import DarkGoldenArrow from "icon-dark-golden-right-arrow.svg";
import OtherListings from "./other_listings";
import PageVariablesContext from "../../page_variables_context";

const GET_MARKET_LISTING = gql`
    query GetMarketListing($id: ID!) {
        listing: getMarketListing(id: $id) {
            marketListingId: id
            price
            currency
            dealFormat
            alterable
            condition
            status
            userId
            details
            location {
                id
                name
            }
            card {
                cardId: id
                cardName
                userWantCount
                playersJson
                printRun
                sportName
                cardNumber
                startYear
                endYear
                manufacturerName: nameManufacturer
                productName: nameProduct
                cardSetName: nameCardSet
                isBase
                hasJersey
                hasPatch
                hasAutograph
                isRookie
                isVerified
                hasInsert
                hasLogoman
            }
            user {
                username
                fulfillmentOptions {
                    id
                    fulfillmentType
                    description
                    isActive
                }
            }
            ownedCard {
                ownedCardId: id
                currentOwnerId
                currentOwnerUsername
                printRunOrdinality
                graderName
                grade
                description
                countryName
                userWantCount
                frontImage
                backImage
            }
        }
    }
`;

const ListingPreview = () => {
    const { id } = useParams();
    const { userId } = useContext(PageVariablesContext);
    const [getListing, { data, loading, called }] = useLazyQuery(
        GET_MARKET_LISTING,
        {
            variables: {
                id: id,
            },
            fetchPolicy: "cache-and-network",
        }
    );

    const pageClass = [
        "animate__animated animate__fadeIn",
        "bg-fixed bg-cover bg-center bg-no-repeat",
        "min-h-screen",
        "text-gray-1000",
    ].join(" ");

    useEffect(() => {
        if (!called) {
            getListing();
        }
    }, []);

    const listing = data?.listing;
    const card = listing?.card;
    const ownedCard = listing?.ownedCard;
    const fulfillmentOptions = listing?.user?.fulfillmentOptions;
    const ownedByCurrentUser = listing?.userId === userId;
    const draft = listing?.status === "draft";

    return (
        <div
            className={pageClass}
            style={{ backgroundImage: `url(${BgLightsDarker})` }}>
            {card && listing && !loading && !draft && (
                <>
                    <MainBreadcrumbs>
                        <NavLink
                            to="/marketplace"
                            activeClassName="font-semibold"
                            className="text-sm text-primary underline">
                            Browse&nbsp;Listings
                        </NavLink>
                        <img src={DarkGoldenArrow} className="h-5 w-5" />
                        <span className="text-sm text-primary border-primary truncate">
                            {card?.cardName || ""}
                        </span>
                    </MainBreadcrumbs>
                    <div className="merqary-container-sm items-start justify-center flex flex-col md:flex-row w-full py-12 px-8 mx-auto">
                        <div
                            className="flex-col text-white pb-4 mb-4 text-center mx-auto md:ml-0 md:mr-5 flex-shrink-0"
                            style={{ width: "208px" }}>
                            <CardImageContainer
                                {...ownedCard}
                                showZoom={true}
                            />
                        </div>
                        <div className="space-y-8">
                            <CardDetails
                                isPreview
                                {...card}
                                {...listing}
                                ownedByCurrentUser={ownedByCurrentUser}
                                reloadOnUpdate={getListing}
                            />
                            <ListingInformation
                                {...card}
                                {...listing}
                                {...(ownedCard && ownedCard)}
                                shippingOptions={fulfillmentOptions}
                                ownedByCurrentUser={ownedByCurrentUser}
                            />
                            <ListingPreviewBody
                                card={card}
                                ownedCard={ownedCard}
                                listing={listing}
                            />
                            <OtherListings />
                        </div>
                    </div>
                </>
            )}
            {!card && !loading && called || draft && (
                <div className="flex justify-center w-full">
                    <div className="text-4xl text-white">Card not found</div>
                </div>
            )}
        </div>
    );
};

export default ListingPreview;

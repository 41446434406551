import React, { Fragment, useContext, useEffect, useState } from "react";
import BgLightsDarker from "bg-lights-darker.png";
import { useHistory, useParams } from "react-router-dom";
import PageTitle from "../create_listing/page_title";
import { X } from "react-feather";
import { gql, useLazyQuery } from "@apollo/client";
import { GET_USER_FULFILLMENT_OPTIONS } from "../../queries";
import CardImageContainer from "../create_listing/card_image_container";
import CardDetails from "../create_listing/card_details";
import ListingForm from "../create_listing/listing_form";
import PageInfo from "../shared_components/page_info";
import Button from "../shared_components/button";
import PageVariablesContext from "../../page_variables_context";

const GET_MARKET_LISTING = gql`
    query GetMarketListing($id: ID!, $userId: ID) {
        listing: getMarketListing(id: $id, userId: $userId) {
            id
            marketListingId: id
            price
            currency
            dealFormat
            alterable
            condition
            status
            userId
            details
            locationId
            isPrivate
            location {
                id
                name
            }
            card {
                id
                cardId: id
                cardName
                userWantCount
                playersJson
                printRun
                sportName
                cardNumber
                startYear
                endYear
                nameManufacturer
                nameProduct
                nameCardSet
                isBase
                hasJersey
                hasPatch
                hasAutograph
                isRookie
                isVerified
                hasInsert
                hasLogoman
            }
            user {
                username
                hasPrivateListingPrivilege
            }
            ownedCard {
                id
                ownedCardId: id
                printRunOrdinality
                graderName
                grade
                description
                countryName
                currentOwnerId
                currentOwnerUsername
                currentOwnerScore
                currentOwnerCurrency
                hasLogoman
                playersJson
                score
                userWantCount
                manufacturerName
                productName
                cardSetName
                startYear
                endYear
                sportName
                gradedBy
                isPublic
                countryId
                frontImage
                backImage
                haveLists {
                    id
                    name
                }
                activeMarketListing {
                    id
                }
                draftMarketListing {
                    id
                }
            }
        }
    }
`;

const UpdateListing = () => {
    const { id } = useParams();
    const history = useHistory();
    const {
        userId,
        isSellingAllowed,
        hasNegativeBalance
    } = useContext(PageVariablesContext);

    const [getMarketListing, { data, loading, called }] = useLazyQuery(
        GET_MARKET_LISTING,
        {
            variables: { id, userId },
            fetchPolicy: "cache-and-network",
        }
    );
    const [getShippingMethods, { data: shippingData, called: shippingCalled }] =
        useLazyQuery(GET_USER_FULFILLMENT_OPTIONS, {
            variables: { userId: userId },
        });

    const pageClass = [
        "px-8 py-8 md:px-18 md:py-12 lg:px-20 xl:px-32 2xl:px-60",
        "animate__animated animate__fadeIn",
        "bg-fixed bg-cover bg-center bg-no-repeat",
        "min-h-screen",
        "text-gray-1000",
    ].join(" ");

    useEffect(() => {
        if (!shippingCalled) {
            getShippingMethods();
        }
        if (!called) {
            getMarketListing();
        }
    }, []);

    const listing = data?.listing;
    const card = listing?.card;
    const ownedCard = listing?.ownedCard;
    const currentOwner = listing?.user;
    const [images, setImages] = useState({});
    const archived = ["sold", "completed"].includes(listing?.status);

    if (archived) {
        window.location.replace(`/seller_center/my_listings/${listing.id}/preview`);
    }

    useEffect(() => {
        if (ownedCard) {
            setImages({
                front: ownedCard?.frontImage,
                back: ownedCard?.backImage,
            });
        }
    }, [ownedCard]);

    const onImageUpdate = (img, side) => {
        if (side === "front") setImages({ ...images, front: img });
        if (side === "back") setImages({ ...images, back: img });
    };

    const defaultValue = {
        id: listing?.marketListingId,
        ownedCardId: listing?.ownedCard?.ownedCardId,
        condition: listing?.condition,
        price: listing?.price,
        dealFormat: listing?.dealFormat,
        currency: listing?.currency,
        location: listing?.locationId,
        status: listing?.status,
        userId: listing?.userId,
        details: listing?.details,
        isPrivate: listing?.isPrivate
    };

    if (hasNegativeBalance)  {
        window.location.replace("/seller_center/top_up/required");
        return true;
    }

    return (
        <div
            className={pageClass}
            style={{ backgroundImage: `url(${BgLightsDarker})` }}>
                {isSellingAllowed ? (
                    <Fragment>
                        {listing && !loading && (
                            <div className="merqary-container-sm md:px-12 bg-gray-800 rounded mx-auto">
                                <PageTitle title="Update listing">
                                    <Button
                                        transparent
                                        type="button"
                                        onClick={() => history.goBack()}
                                        icon={<X className="w-4 h-4" />}
                                        text="Cancel"
                                        customClass="text-sm"
                                    />
                                </PageTitle>
                                <div className="grid grid-cols-10 gap-0 py-8 space-y-6 lg:space-y-0">
                                    <div className="col-span-full lg:col-span-3 flex justify-center lg:justify-start">
                                        <CardImageContainer
                                            {...card}
                                            {...ownedCard}
                                            frontImage={images.front}
                                            backImage={images.back}
                                            width="w-2/3 lg:w-3/4"
                                        />
                                    </div>
                                    <div className="divide divide-y-2 divide-opacity-40 px-6 space-y-12 col-span-full lg:col-span-7">
                                        <CardDetails
                                            {...card}
                                            {...ownedCard}
                                            onImageUpdate={onImageUpdate}
                                            reloadOnUpdate={getMarketListing}
                                            longerHeader
                                        />
                                        <ListingForm
                                            {...card}
                                            {...ownedCard}
                                            fulfillmentOptions={
                                                shippingData?.fulfillmentOptions
                                            }
                                            forUpdate
                                            allowSubmit={listing.alterable}
                                            reloadOnUpdate={getMarketListing}
                                            defaultValue={defaultValue}
                                            submitText={
                                                listing.status === "active"
                                                    ? "Update Listing"
                                                    : "Publish Listing"
                                            }
                                            currentOwner={currentOwner}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {!listing && !loading && called && (
                            <div className="flex justify-center w-full">
                                <div className="text-4xl text-white">Card not found</div>
                            </div>
                        )}
                    </Fragment>
                ) : <PageInfo type="sellingNotAllowed" />}
        </div>
    );
};

export default UpdateListing;

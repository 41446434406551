import React from "react";

const Button = ({
    type,
    text,
    onClick,
    url,
    primary,
    secondary,
    transparent,
    disabled,
    fontBold,
    customClass,
    buttonRef,
    icon = null,
    paddings,
    borderColor,
}) => {
    const buttonClass = [
        " text-fs18 rounded flex items-center",
        fontBold ? "font-semibold" : "font-medium",
        primary && !secondary && !transparent
            ? "bg-primary text-black-100"
            : "",
        secondary && !primary && !transparent
            ? `border-0.5 bg-transparent ${borderColor} border-opaicity-60 text-inherit hover:border-gray-1000`
            : "",
        transparent && !primary && !secondary
            ? "border-0 bg-transparent text-inherit"
            : "",
        customClass,
        paddings,
    ].join(" ");

    return (
        <>
            {url && (
                <a href={!disabled ? url : "#"} className={buttonClass}>
                    {icon}
                    <span className={icon ? "ml-2" : "mx-auto"}>{text}</span>
                </a>
            )}
            {!url && (
                <button
                    className={buttonClass}
                    type={type}
                    onClick={onClick}
                    disabled={disabled}
                    ref={buttonRef}>
                    {icon}
                    <span className={icon ? "ml-2" : "mx-auto"}>{text}</span>
                </button>
            )}
        </>
    );
};

Button.defaultProps = {
    type: "button",
    text: "button",
    onClick: () => {},
    primary: false,
    secondary: false,
    transparent: false,
    url: null,
    disabled: false,
    fontBold: false,
    customClass: "",
    buttonRef: null,
    paddings: "px-4 py-2",
    borderColor: "border-gray-500",
};

export default Button;

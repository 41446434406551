import { gql, useMutation } from "@apollo/client";
import { useContext } from "react";
import { PopupContext } from "../popup_context";

const DELETE_CART_ITEMS = gql`
    mutation DeleteCartItems($input: DeleteCartItemsInput!) {
        deleteCartItems(input: $input) {
            deletedCartItems
            success
            errors
        }
    }
`;

const CHECKOUT_CART_ITEMS = gql`
    mutation CheckoutCartItems($input: CheckoutCartItemsInput!) {
        checkoutCartItems(input: $input) {
            redirectUrl
            success
            errors
        }
    }
`;

const ADD_TO_CART = gql`
    mutation AddMarketListingToCart($input: AddMarketListingToCartInput!) {
        addMarketListingToCart(input: $input) {
            success
            errors
            cartItem {
                id
            }
        }
    }
`;

const useCartItemsMutation = (callback = null) => {
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);

    const onError = () => {
        setShowPopup(false);
        setShowPopup(true);
        setPopupAction("warn");
        setMessage("Something went wrong.");
    };

    const checkoutCartItemsCallback = (data) => {
        const result = data.checkoutCartItems;
        if (result.success) {
            window.location.replace(result.redirectUrl);
        } else {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage(result.errors.join(", "));
        }
    };

    const [deleteCartItems] = useMutation(DELETE_CART_ITEMS, {
        onCompleted: (data) => callback(data),
        onError: onError
    });

    const [addToCart, { loading: addToCardLoading }] = useMutation(ADD_TO_CART, {
        onCompleted: (data) => callback(data),
        onError: onError
    });

    const [checkoutCartItems, { loading: checkoutCartItemsLoading }] =
        useMutation(CHECKOUT_CART_ITEMS, {
            onCompleted: (data) => callback ? callback(data) : checkoutCartItemsCallback(data),
            onError: onError
        });

    return {
        deleteCartItems,
        checkoutCartItems,
        addToCart,
        checkoutCartItemsLoading,
        addToCardLoading,
    };
};

export default useCartItemsMutation;

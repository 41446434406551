import { useState } from "react";

const useZoomImage = (card, flipped) => {
    const [zoom, setZoom] = useState(false);

    const showZoom = () => {
        setZoom(true);
    };

    const closeZoom = () => {
        setZoom(false);
    };

    const zoomDisabled =
        !flipped && card?.frontImage
            ? false
            : flipped && card?.backImage
            ? false
            : true;

    return {
        zoom,
        showZoom,
        closeZoom,
        zoomDisabled,
    };
};

export default useZoomImage;

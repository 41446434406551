import React, { useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { X } from "react-feather";

const ZoomPortal = ({ id, children }) => {
    let ref = useRef(null);
    useEffect(() => {
        const portal = document.getElementById(id);
        if (!portal) {
            ref.current = document.createElement("div");
            ref.current.id = id;
            document.body.append(ref.current);
        } else {
            ref.current = portal;
        }

        return () => {
            if (ref.current && ref.current.parent === document.body)
                document.body.removeChild(ref.current);
        };
    }, []);

    if (!ref.current) {
        return null;
    }

    return createPortal(children, ref.current);
};

const ZoomContainer = ({ children, show, handleClose }) => {
    const ref = useRef(null);
    function onKeyUp(e) {
        if (e?.key?.toLowerCase() === "escape") {
            handleClose();
        }
    }

    useEffect(() => {
        document.body.style.overflow = show ? "hidden" : "auto";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [show]);

    useEffect(() => {
        if (show) {
            ref?.current?.focus();
        }
    }, [ref, show]);

    return (
        <ZoomPortal id="zoom">
            {show && (
                <div
                    ref={ref}
                    tabIndex={0}
                    onKeyUp={onKeyUp}
                    className="bg-gray fixed h-screen w-screen inset-0 overflow-auto flex items-center justify-center"
                    style={{ zIndex: 100 }}>
                    <button
                        onClick={handleClose}
                        className="top-2 right-2 fixed text-white text-lg">
                        <X className="w-5 h-5" />
                    </button>
                    {children}
                </div>
            )}
        </ZoomPortal>
    );
};

export default ZoomContainer;

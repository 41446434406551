const formatCardPlayers = (playersJson, format = ['names', 'teams'], teamWithBrackets = true, unique = false) => {
    if (!playersJson) return '';
    let players = filterPlayers(playersJson, format, teamWithBrackets);

    if (!players) return null;


    if (unique && format.length === 1) {
        players = Array.from(new Set(players));
    }

    return players.join(", ");
}

const filterPlayers = (playersJson, format, teamWithBrackets) => {
    if (!playersJson) return null;

    return JSON.parse(playersJson).map(player => {
        let playerName = [];
        if (format.includes('names')) playerName.push(player.player_name);
        if (format.includes('teams') && teamWithBrackets) playerName.push(`[${player.team_name}]`)
        if (format.includes('teams') && !teamWithBrackets) playerName.push(player.team_name)
        return playerName.join(" ");
    });
}

export default formatCardPlayers;
import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { VariablesContextProvider } from '../variables_context';
import { PopupContextProvider } from '../popup_context';
import ApolloProviderClient from '../apollo_provider_client';
import { GAComponent } from '../hooks/useReactGA';
import Cart from './cart/cart';
import MyOrders from './my_orders/my_orders';
import ListingPreview from './listing_preview/listing_preview';
import Checkout from './checkout/checkout';
import BrowseListings from './browse_listings/browse_listings';
import SellerProfile from './seller_profile/seller_profile';
import SuccessfulOrderPlacement from './checkout/successful_order_placement';
import OrderDetails from './my_orders/order_details';
import { ConfirmationContextProvider } from '../confirmation_context';
import PageVariablesContext from '../page_variables_context';

const App = () => {
    return (
        <Fragment>
            <Router>
                <Switch>
                    <Route
                        path="/marketplace"
                        component={BrowseListings}
                        exact
                    />
                    <Route
                        path="/marketplace/listings/:id"
                        component={ListingPreview}
                        exact
                    />
                    <Route path="/marketplace/cart" component={Cart} exact />
                    <Route
                        path="/marketplace/my_orders"
                        component={MyOrders}
                        exact
                    />
                    <Route
                        path="/marketplace/s/:username"
                        component={SellerProfile}
                        exact
                    />
                    <Route
                        path="/marketplace/checkout/:id"
                        component={Checkout}
                        exact
                    />
                    <Route
                        path="/marketplace/purchases/:id"
                        component={SuccessfulOrderPlacement}
                        exact
                    />
                    <Route
                        path="/marketplace/my_orders/:id"
                        component={OrderDetails}
                        exact
                    />
                </Switch>
                <Route path="*" component={GAComponent} />
            </Router>
        </Fragment>
    );
};

export default () => {
    document.addEventListener('DOMContentLoaded', function () {
        const container = document.getElementById('marketplace');
        if (container) {
            const userId = container?.dataset?.userId;
            const cardName = container?.dataset?.cardName;
            const pageVars = { cardName, userId };

            render(
                <ApolloProviderClient>
                    <PopupContextProvider>
                        <VariablesContextProvider>
                            <PageVariablesContext.Provider value={pageVars}>
                                    <ConfirmationContextProvider>
                                        <App />
                                    </ConfirmationContextProvider>
                            </PageVariablesContext.Provider>
                        </VariablesContextProvider>
                    </PopupContextProvider>
                </ApolloProviderClient>,
                container
            );
        }
    });
};

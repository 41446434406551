import React from "react";
import { Link, useParams } from "react-router-dom";
import OrderSummary from "./order_summary";
import BgLightsDarker from "bg-lights-darker.png";
import { useQuery } from "@apollo/client";
import { GET_ORDER_DETAILS } from "../../queries";
import PaymentSummary from "./payment_summary";
import SpinnerLoader from "../../spinner_loader";

const pageClass = [
    "px-8 py-8 md:px-18 md:py-12 lg:px-20 xl:px-32 2xl:px-60",
    "animate__animated animate__fadeIn",
    "bg-fixed bg-cover bg-center bg-no-repeat",
    "min-h-screen",
    "text-white",
].join(" ");

const SuccessfulOrderPlacement = () => {
    const { id } = useParams();

    const queryParams = {
        variables: { id: id },
        fetchPolicy: "network-only",
    };
    const { data, loading } = useQuery(GET_ORDER_DETAILS, queryParams);

    const orderDetails = data?.getOrderDetails;
    const paymentMethodDetails = orderDetails?.paymentMethodDetails;
    const orderSummary = orderDetails?.orderSummary;
    const payment = orderDetails?.payment;
    const purchase = orderDetails?.purchase;

    return (
        <div
            className={pageClass}
            style={{ backgroundImage: `url(${BgLightsDarker})` }}>
            {loading ? (
                <SpinnerLoader />
            ) : null}
            {purchase && !loading ? (
                <div className="flex flex-col justify-center space-y-8 text-gray-30">
                    <div className="flex flex-col items-center text-gray-1000 justify-center pt-16 pb-4">
                        <span className="text-lg font-body font-semibold mb-2">
                            Your card orders have been received!
                        </span>
                        <span className="text-center md:text-left text-base font-body mb-2">
                            We have informed the card sellers to start shipping your
                            cards.
                        </span>
                        <Link
                            to="/marketplace"
                            className="text-base font-body mb-4 cursor-pointer underline">
                            Back to Marketplace
                        </Link>
                    </div>
                    <div className="mx-auto w-full md:w-4/5 space-y-8">
                        <PaymentSummary
                            paymentMethodDetails={paymentMethodDetails}
                            currency={paymentMethodDetails?.currency}
                            {...payment}
                            {...purchase}
                        />
                        <OrderSummary
                            checkoutSellers={orderSummary}
                            showShippingMethod={false}
                        />
                    </div>
                    <div className="flex justify-center">
                        <Link
                            to="/marketplace"
                            className="font-body cursor-pointer underline">
                            Back to Marketplace
                        </Link>
                    </div>
                </div>
            ) : null}
            {!loading && !purchase ? (
                <div className="flex justify-center w-full">
                    <div className="text-4xl text-white">Purchase not found</div>
                </div>
            ) : null}
        </div>
    );
};

export default SuccessfulOrderPlacement;

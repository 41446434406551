import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { UNSTASH_CARD, updateUnstash } from '../mutations';
import CardImage from '../card_image';
import { Check, X } from 'react-feather';

const AddToListCheckbox = ({ id, checked, onChange }) => {
    const domId = `list-cb-${id}`;
    const labelClassname = [
        'h-4 w-4 rounded flex',
        'ml-2',
        'items-center justify-center',
        checked ? 'bg-primary text-black' : 'bg-white text-transparent',
        'hover:bg-primary',
    ].join(' ');
    return (
        <div>
            <label
                htmlFor={domId}
                className={labelClassname}
                style={{ transition: 'all 0.3s ease-in-out' }}
            >
                {checked && <Check size={16} />}
            </label>
            <input
                type="checkbox"
                className="hidden"
                onChange={onChange}
                id={domId}
                checked={checked}
                value={id}
            />
        </div>
    );
};

const StashedCard = ({
    card,
    selected,
    addToList,
    removeFromList,
    width = 'w-full',
    updateList = () => {}
}) => {
    const [unstashCard, { loading, data }] = useMutation(UNSTASH_CARD, {
        onCompleted: (data) => {
            if (data?.deleteStashedCard.result) updateList([card?.id]);
        },
        update: (a, b) => updateUnstash(a, b, card?.id),
    });

    const handleClick = () => {
        unstashCard({
            variables: {
                input: {
                    cardIds: [card?.id],
                },
            },
        });
    };

    const handleChange = (e) => {
        const v = e.target.value;
        if (selected) {
            removeFromList(v);
        } else {
            addToList(v);
        }
    };

    const cardClassName = [
        'focus:outline-none',
        'trophyRoom-card',
        'relative',
        '',
        'border-4 border-white rounded-md',
        'm-4 w-full relative',
        'w-24 h-32',
        'text-xs',
        'flex-shrink-0',
    ].join(' ');

    useEffect(() => {
        if (data?.deleteStashedCard?.resut) {
            removeFromList(card?.id);
        }
    }, [data?.deleteStashedCard?.resut]);

    const playersJson = card?.playersJson;
    const _players =
        playersJson && JSON.parse(playersJson)
            ? JSON.parse(playersJson).map(({ player_name, team_name }) => ({
                  name: player_name,
                  teamName: team_name,
              }))
            : [];
    const teamNames = Array.from(new Set(_players.map((p) => p.teamName)));

    return (
        <div
            className={`${width} bg-gray-775 rounded-md flex items-center relative shadow-md py-4 px-2 space-x-2`}
        >
            <AddToListCheckbox
                id={card?.id}
                checked={selected}
                onChange={handleChange}
            />
            <div className={cardClassName}
                style={{ marginLeft: '1rem', marginRight: "1rem" }}>
                <CardImage isFront image={card?.frontImage} hideMoreText />
            </div>
            <p className="text-sm text-white flex flex-col space-y-1 w-5/12">
                <span className="font-semibold text-sm">{card?.cardName}</span>
                <span className="text-xs">{_players?.map((p) => p.name).join(', ')}</span>
                <span className="text-xs">{teamNames?.join(', ')}</span>
            </p>

            <button
                disabled={loading}
                type="button"
                onClick={handleClick}
                className="text-white absolute top-2 right-2"
            >
                <X size={16}/>
            </button>
        </div>
    );
};

export default StashedCard;

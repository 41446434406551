import { gql, useMutation } from "@apollo/client"
import { useContext } from "react"
import { ConfirmationContext } from "../confirmation_context";
import { PopupContext } from "../popup_context"

const UPDATE_OWNED_CARD = gql`
    mutation updateCard($input: UpdateOwnedCardInput!) {
        result: updateOwnedCard(input: $input) {
            errors
            ownedCard {
                id
                printRunOrdinality
                grade
                gradedBy
                countryId
                description
                isPublic
            }
        }
    }
`;

const OwnedCardMutation = (callback = () => {}) => {
    const confirm = useContext(ConfirmationContext);
    const { setShowPopup, setPopupAction, setMessage } = useContext(PopupContext);

    const onError = () => {
        setShowPopup(false)
        setShowPopup(true)
        setPopupAction('warn')
        setMessage("Something went wrong.")
    }

    const [updateCard, { loading: updateLoading }] = useMutation(UPDATE_OWNED_CARD, {
        onCompleted: (data) => callback(data),
        onError: onError
    });

    const confirmUpdate = (values, activeMarketListing, draftMarketListing) => {
        let showConfirmation = false;
        let variables = {
            variables: {
                input: {
                    ownedCardId: values.cardId || values.id,
                    removeAssociatedListing: false,
                    params: {
                        ...(({
                            id,
                            cardId,
                            frontImage,
                            backImage,
                            printRun,
                            cardName,
                            ...v
                        }) => v)(values),
                        printRunOrdinality: Number(values.printRunOrdinality),
                        grade: String(values.grade),
                    },
                },
            },
        };

        if (activeMarketListing && !values.isPublic) showConfirmation = true;
        if (draftMarketListing && !values.isPublic) showConfirmation = true;

        if (showConfirmation) {
            confirm({
                text: "This will unpublish your marketplace listing",
                onConfirm: () => {
                    variables.variables.input.removeAssociatedListing = true;
                    updateCard(variables);
                },
                cancelText: "Keep market listing published",
                onCancel: () => updateCard(variables)
            });
            return;
        }
        updateCard(variables);
    }

    return {
        updateCard,
        confirmUpdate,
        updateLoading
    }
}

export default OwnedCardMutation
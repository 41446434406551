import { gql, useMutation } from "@apollo/client";
import { useContext } from "react";
import { PopupContext } from "../popup_context";

const UPDATE_LISTING = gql`
    mutation UpdateMarketListing($input: UpdateMarketListingInput!) {
        updateMarketListing(input: $input) {
            result
            marketListing {
                id
            }
            errors
        }
    }
`;

const DELETE_MARKET_LISTING = gql`
    mutation DeleteMarketListing($input: DeleteMarketListingInput!) {
        deleteMarketListing(input: $input) {
            result
            errors
        }
    }
`;

const CREATE_LISTING = gql`
    mutation listOwnedCardToMarketplace(
        $input: ListOwnedCardToMarketplaceInput!
    ) {
        listOwnedCardToMarketplace(input: $input) {
            result
            errors
            marketListing {
                id
                status
            }
        }
    }
`;

const useMarketListingMutation = (callback = () => {}) => {
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);

    const onError = () => {
        setShowPopup(false);
        setShowPopup(true);
        setPopupAction("warn");
        setMessage("Something went wrong.");
    };

    const [deleteListing, { loading: deleteListingLoading }] = useMutation(
        DELETE_MARKET_LISTING,
        {
            onCompleted: (data) => callback(data),
            onError: onError
        }
    );

    const [updateListing, { loading: updateListingLoading }] = useMutation(UPDATE_LISTING, {
        onCompleted: (data) => callback(data),
        onError: onError
    });

    const [createListing, { loading: createListingLoading }] = useMutation(CREATE_LISTING, {
        onCompleted: (data) => callback(data),
        onError: onError
    });

    return {
        deleteListing,
        updateListing,
        createListing,
        deleteListingLoading,
        updateListingLoading,
        createListingLoading,
    };
};

export default useMarketListingMutation;

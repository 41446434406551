import React from 'react';

const CardInfo = ({ title, children }) => {
    return (
        <div className="mr-8 flex-1 flex flex-col">
            <span className="font-semibold mb-1">{title}</span>
                {children}
        </div>
    );
};

export default CardInfo;
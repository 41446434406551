import React from 'react'
import { Link } from 'react-router-dom'
import SpinnerLoader from '../../spinner_loader'
import CartSeller from './cart_seller'

const CartSellers = ({ loading, items, fetchCartItems }) => {
    const hasItems = Boolean(items.length)
    return (
        <div className="py-10 md:px-10 md:py-10">
            {loading && (
                <SpinnerLoader />
            )}
            {hasItems && !loading && (
                <div className="space-y-10">
                    {items.map(item => (
                        <CartSeller
                            key={item?.seller?.id}
                            {...item}
                            fetchCartItems={fetchCartItems}
                        />
                    ))}
                </div>
            )}
            {!hasItems && !loading && (
                <div className="container flex flex-col text-white text-gray-1000 relative p-24 mx-auto
                    rounded-lg bg-gray-800 shadow mb-6 text-center"
                >
                    <span className="font-semibold mb-1">You have no items in your cart.</span>
                    <Link to={`/marketplace`} className="underline">
                        Browse the Marketplace
                    </Link>
                </div>
            )}
        </div>
    )
}

CartSellers.defaultProps = {
    loading: false,
    items: []
}

export default CartSellers

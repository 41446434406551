import React from "react";
import { Truck } from "react-feather";
import formatPrice from "../../utils/format_price";
import IconUserGold from "icon-user-gold.svg";
import CopyToClipboard from "../../utils/copy_to_clipboard";
import HelpText from "../../utils/help_text";
import camelize from "../../utils/camelizeString";
import formatCardPlayers from "../../utils/format_card_players";
import CardImageContainer from "../create_listing/card_image_container";
import Button from "../shared_components/button";

const SellerTransaction = ({
    id,
    username,
    status,
    currency,
    totalOrderAmount,
    purchaseSellerItems,
    trackingInfo,
    sellerDisplayedStatus,
    displayedFulfillmentType,
    dateOrdered,
    showShippingWarning,
    transactionNumber,
    selected,
    select,
    deSelect,
    refundStatus,
    cancelledAt,
}) => {
    const transactionItems = [...purchaseSellerItems];
    const previewedTransactionItem = transactionItems.shift();
    const otherTransactionItems = transactionItems;

    const statusHelpText = {
        readyToShip: "Order confirmed and ready to ship",
        shipped: "Order has been shipped to buyer",
        completed: "Order is now complete",
    };

    const refundStatusText = {
        Published: "processing cancellation",
        Success: "cancelled",
        Failed: "cancellation failed"
    }

    const refundStatusHelpText = {
        Published: "Order cancellation is being processed",
        Success: "Order has been cancelled",
        Failed: "Order cancellation failed"
    }

    return (
        <label
            htmlFor={`checkbox-${transactionNumber}`}
            className="cursor-pointer">
            <div className="px-4 md:px-6 py-8 text-gray-30 rounded-md bg-gray-800 shadow w-100">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-1 flex justify-center md:items-center">
                        <input
                            id={`checkbox-${transactionNumber}`}
                            type="checkbox"
                            className="cursor-pointer text-primary rounded focus:outline-none focus:ring-0 h-6 w-6 bg-gray-650"
                            checked={selected.includes(id)}
                            onChange={(e) =>
                                e.target.checked ? select(id) : deSelect(id)
                            }
                        />
                    </div>
                    <div className="col-span-11 space-y-8">
                        <div className="flex flex-col md:flex-row justify-between md:py-6 border-b-2 border-gray-300 border-opacity-40">
                            <div className="flex mb-1 md:mb-0 space-x-2 items-center">
                                <img
                                    src={IconUserGold}
                                    alt="User icon"
                                    className="icon w-4 h-4 cursor-pointer"
                                />
                                <span className="text-lg">{username}</span>
                            </div>
                            <div className="flex space-x-2 items-center">
                                <span className="font-semibold">
                                    Transaction No. {transactionNumber}
                                </span>
                                <CopyToClipboard text={transactionNumber} />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row items-center w-full">
                            <div className="flex items-center mb-4 md:mb-0 md:pr-4 md:mr-auto">
                                <CardImageContainer
                                    frontImage={
                                        previewedTransactionItem?.frontImage
                                    }
                                    height="h-28"
                                    showFlip={false}
                                />

                                <div className="flex flex-col pl-4 space-y-1">
                                    <span>
                                        {previewedTransactionItem.cardTitle}
                                    </span>
                                    <span>
                                        {formatCardPlayers(
                                            previewedTransactionItem.playersJson,
                                            ["names"]
                                        )}
                                    </span>
                                    {Boolean(otherTransactionItems.length) && (
                                        <a
                                            href={`/seller_center/orders_and_shipping/${id}/manage_order`}
                                            className="underline">
                                                {`+${otherTransactionItems.length} more items`}
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row items-center md:pr-4">
                                <span className="text-lg font-semibold pr-4">
                                    {formatPrice(totalOrderAmount, "V C", currency)}
                                </span>

                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <Truck
                                            size={18}
                                            fillOpacity={40}
                                            fill="currentColor"
                                        />
                                    </div>
                                    <div className="pl-2 space-y-1">
                                        <p className="capitalize">
                                            {displayedFulfillmentType} shipping
                                        </p>
                                        {trackingInfo && (
                                            <p className="uppercase">
                                                {trackingInfo.logisticsProvider}{" "}
                                                {trackingInfo.trackingNumber}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-end flex-shrink-0">
                                <div className="flex space-x-2">
                                    {!cancelledAt ? (
                                        <HelpText
                                            text={
                                                statusHelpText[
                                                    camelize(sellerDisplayedStatus)
                                                ]
                                            }
                                            sideText={sellerDisplayedStatus}
                                            sideTextClass="uppercase text-lg font-semibold text-primary"
                                            iconColor="text-primary"
                                            size={18}
                                            icon={
                                                sellerDisplayedStatus !==
                                                "Completed"
                                                    ? "question"
                                                    : "check"
                                            }
                                            reverse={
                                                sellerDisplayedStatus !==
                                                "Completed"
                                            }
                                        />
                                    ) : (
                                        <HelpText
                                            text={refundStatusHelpText[refundStatus]}
                                            sideText={refundStatusText[refundStatus]}
                                            sideTextClass="uppercase text-lg font-semibold text-primary"
                                            iconColor="text-primary"
                                            size={18}
                                            icon={
                                                refundStatus !==
                                                "Success"
                                                    ? ""
                                                    : "check"
                                            }
                                            reverse={
                                                refundStatus !==
                                                "Success"
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row md:justify-between items-center">
                            <div className="flex flex-col md:flex-row md:space-x-4 mb-4 md:mb-0">
                                <div className="flex justify-center md:justify-start space-x-2">
                                    <span>Order date</span>
                                    <span>{dateOrdered}</span>
                                </div>
                                {showShippingWarning && !cancelledAt && (
                                    <HelpText
                                        text="Please ship your product asap to avoid negative feedback"
                                        sideText="needs attention"
                                        sideTextClass="uppercase text-lg font-semibold text-orange"
                                        iconColor="text-orange"
                                        size={18}
                                    />
                                )}
                            </div>
                            {status === "Received by Seller" && !cancelledAt ? (
                                <Button
                                    primary
                                    text="Manage Order"
                                    url={`/seller_center/orders_and_shipping/${id}/manage_order`}
                                />
                            ) : (
                                <Button
                                    secondary
                                    text="View details"
                                    url={`/seller_center/orders_and_shipping/${id}/manage_order`}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </label>
    );
};

export default SellerTransaction;

import { gql } from '@apollo/client';

const FilterDetailsFragment = gql`
    fragment filterDetails on FilterDetails {
        searchString
        filterOptions {
            title
            options
            selected
            ordinality
            searchEnabled
            singleValue
        }
        sortOptions {
            sortCategory
            sortOrder
            sortKey
        }
    }
`;

const PagyFragment = gql`
    fragment pagyDetails on Pagy {
        count
        from
        last
        next
        offset
        page
        pages
        prev
        to
    }
`;

const CardDetailsFragment = gql`
    fragment cardDetails on Card {
        id
        hasPatch
        hasInsert
        hasJersey
        hasLogoman
        hasAutograph
        frontImage
        sport {
            name
        }
        cardName
        cardNumber
        printRun
    }
`;

const OwnedCardDetailsFragment = gql`
    fragment ownedCardDetails on OwnedCard {
        id
        cardId
        hasPatch
        hasInsert
        hasJersey
        hasAutograph
        frontImage
        backImage
        cardName
        printRunOrdinality
        printRun
        grade
        gradedBy
        countryId
        description
        isPublic
        isPublished
        countryName
        graderName
        isMarketListable
        currentOwnerId
        isForSale
        isSold
    }
`;

const GLOBAL_SEARCH_CARDS = gql`
    query SearchCards($searchString: String!, $options: FilterOptionsInput) {
        results: searchAndFilterCards(
            searchString: $searchString
            options: $options
        ) {
            cards {
                ...cardDetails
                playersJson
            }

            filters {
                ...filterDetails
                pagination {
                    ...pagyDetails
                }
            }
        }
    }
    ${CardDetailsFragment}
    ${FilterDetailsFragment}
    ${PagyFragment}
`;

const SEARCH_LISTINGS = gql`
    query SearchMarketListings($searchString: String!, $userId: ID, $options: FilterOptionsInput) {
        results: searchAndFilterMarketListings(
            searchString: $searchString
            userId: $userId
            options: $options
        ) {
            cards: listings {
                marketListingId: id
                price
                currency
                dealFormat
                alterable
                condition
                status
                alterable
                userId
                isPrivate
                card {
                    cardId: id
                    cardName
                    playersJson
                }
                ownedCard {
                    ownedCardId: id
                    currentOwnerId
                    frontImage
                }
            }
            filters {
                ...filterDetails
                pagination {
                    ...pagyDetails
                }
            }
        }
    }
    ${FilterDetailsFragment}
    ${PagyFragment}
`;

const GET_MARKET_LISTING = gql`
    query GetMarketListing($id: ID!) {
        listing: getMarketListing(id: $id) {
            marketListingId: id
            price
            currency
            dealFormat
            alterable
            condition
            status
            userId
            details
            location {
                id
                name
            }
            card {
                cardId: id
                cardName
                userWantCount
                playersJson
                printRun
                sportName
                cardNumber
                startYear
                endYear
                nameManufacturer
                nameProduct
                nameCardSet
                isBase
                hasJersey
                hasPatch
                hasAutograph
                isRookie
                isVerified
                hasInsert
                hasLogoman
            }
            user {
                username
            }
            ownedCard {
                ownedCardId: id
                currentOwnerId
                currentOwnerUsername
                printRunOrdinality
                graderName
                grade
                description
                countryName
                frontImage
                backImage
            }
        }
    }
`

const SEARCH_WANT_CARDS = gql`
    query SearchListCards(
        $searchString: String!
        $wantListId: ID!
        $userId: ID!
        $options: FilterOptionsInput
    ) {
        results: searchAndFilterWantLists(
            searchString: $searchString
            wantListId: $wantListId
            userId: $userId
            options: $options
        ) {
            cards {
                ...cardDetails
                playersJson
                backImage
            }

            filters {
                ...filterDetails
                vaultStatus
                publishStatus
                pagination {
                    ...pagyDetails
                }
            }
        }
    }
    ${CardDetailsFragment}
    ${FilterDetailsFragment}
    ${PagyFragment}
`;

const SEARCH_USER_HAVE_CARDS = gql`
    query SearchUserHaveCards(
        $searchString: String,
        $userId: ID!,
        $haveListId: ID!,
        $options: FilterOptionsInput
    ) {
        results: searchUserHaveCards(
            searchString: $searchString
            userId: $userId
            haveListId: $haveListId
            options: $options
        ) {
            cards {
                userHaveCardId: id
                ownedCard {
                    ...ownedCardDetails
                    playersJson
                    haveLists {
                        id
                        name
                    }
                    activeMarketListing {
                        id
                    }
                    draftMarketListing {
                        id
                    }
                }
            }
            filters {
                ...filterDetails
                vaultStatus
                publishStatus
                pagination {
                    ...pagyDetails
                }
            }
        }
    }
    ${OwnedCardDetailsFragment}
    ${FilterDetailsFragment}
    ${PagyFragment}
`

const SEARCH_HAVE_CARDS = gql`
    query SearchListCards(
        $searchString: String!
        $userId: ID!
        $haveListId: ID!
        $options: FilterOptionsInput
    ) {
        results: searchAndFilterHaveLists(
            searchString: $searchString
            userId: $userId
            haveListId: $haveListId
            options: $options
        ) {
            cards {
                ...ownedCardDetails
                activeMarketListingId
                playersJson
                draftMarketListing {
                    id
                }
            }

            filters {
                ...filterDetails
                vaultStatus
                publishStatus
                pagination {
                    ...pagyDetails
                }
            }
        }
    }
    ${OwnedCardDetailsFragment}
    ${FilterDetailsFragment}
    ${PagyFragment}
`;

const GET_HAVE_LISTS = gql`
    query GetHaveLists($userId: ID!) {
        getHaveLists(userId: $userId) {
            id
            name
            ordinality
            ownedCards {
                id
            }
        }
    }
`;

const GET_LISTING_CONSTANTS = gql`
    query GetListingConstants {
        constants: getListingConstants {
            cardConditions
            currencies
            dealFormats
            defaultCurrency
            feePercentage
            fulfillmentOptions {
                fulfillmentType
                description
            }
            countries {
                id
                name
            }
        }
    }
`;

const GET_USER_FULFILLMENT_OPTIONS = gql`
    query GetUserFulfillmentOptions($userId: ID!) {
        fulfillmentOptions: getUserFulfillmentOptions(userId: $userId) {
            id
            fulfillmentType
            description
            isActive
            shippingFee
            displayedFulfillmentType
        }
    }
`;

const GET_WANT_LISTS = gql`
    query GetWantLists($userId: ID!) {
        getWantLists(userId: $userId) {
            id
            name
        }
    }
`;

const GET_HAVE_CARDS = gql`
    query GetHaveCards($id: ID!, $after: String, $before: String) {
        getHaveCards(haveListId: $id, after: $after, before: $before) {
            edges {
                node {
                    ...ownedCardDetails
                }
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }

    ${OwnedCardDetailsFragment}
`;

const GET_WANT_CARDS = gql`
    query GetWantCards($id: ID!, $after: String, $before: String) {
        getWantCards(wantListId: $id, after: $after, before: $before) {
            edges {
                node {
                    ...cardDetails
                }
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }

    ${CardDetailsFragment}
`;

const GET_ALL_STASHED_CARDS = gql`
    {
        stashedCards: getAllStashedCards {
            ...cardDetails
            playersJson
        }

        haveCards: getAllHaveCards {
            cardId
        }
    }

    ${CardDetailsFragment}
`;

const GET_STASHED_CARDS_WITH_LISTS = gql`
    query getStashedCardsAndLists($userId: ID!) {
        stashedCards: getAllStashedCards {
            ...cardDetails
            playersJson
        }

        haveLists: getHaveLists(userId: $userId) {
            id
            name
        }

        wantLists: getWantLists(userId: $userId) {
            id
            name
        }
    }

    ${CardDetailsFragment}
`;

const GET_USER_LISTS = gql`
    query getStashedCardsAndLists($userId: ID!) {
        haveLists: getHaveLists(userId: $userId) {
            id
            name
        }

        wantLists: getWantLists(userId: $userId) {
            id
            name
        }
    }
`;

const GET_OWNED_CARD = gql`
    query GetOwnedCard($id: ID!, $userId: ID, $isDeleted: Boolean) {
        card: getOwnedCard(id: $id, userId: $userId, isDeleted: $isDeleted) {
            ...ownedCardDetails
            currentOwnerId
            currentOwnerUsername
            currentOwnerScore
            currentOwnerCurrency
            hasLogoman
            playersJson
            score
            userWantCount
            manufacturerName
            productName
            cardSetName
            startYear
            endYear
            sportName
            deleted: deletedAt
            activeMarketListing {
                id
            }
            draftMarketListing {
                id
            }
            soldMarketListing {
                id
            }
            haveLists {
                id
                name
            }
            currentOwner {
                hasPrivateListingPrivilege
            }
        }
    }

    ${OwnedCardDetailsFragment}
`;
const GET_FEATURED_CARDS = gql`
    query GetFeaturedCards {
        featuredCards: getFeaturedCards {
            id
            frontImage
            cardName
            printRun
            playersJson
        }
    }
`;

const GET_CARD = gql`
    query GetCard($id: ID!) {
        card: getCard(id: $id) {
            ...cardDetails
            backImage
            cardSetName
            endYear
            isBase
            isRookie
            manufacturerName
            playersJson
            printRun
            productName
            score
            startYear
            vaultedCount
            userWantCount
            marketListings: activeMarketListings {
                id
                frontImage
                price
                currency
                username
                dealFormat
                ownedCardGraderName
                ownedCardGrade
                isPrivate
            }
        }
        ownedCards: getOwnedCards(cardId: $id) {
            id
            frontImage
            backImage
            grade
            graderName
            cardName
            description
            currentOwnerId
            currentOwnerUsername
            playersJson
            countryName
            printRunOrdinality
            printRun
            marketListing: activeMarketListing {
                listingId: id
                price
                currency
                dealFormat
                isPrivate
            }
        }
    }

    ${CardDetailsFragment}
`;

const GET_CARD_STATUSES_COUNT = gql`
    query CardStatusesCount {
        cardStatusCount: getCardStatusCount {
            listed
            vaulted
            forSale
        }
    }
`
const GET_USER_CART_ITEMS = gql`
    query GetUserCartItems($userId: ID!) {
        cartItemPerSeller: getUserCartItems(userId: $userId) {
            subTotal
            seller {
                id
                username
            }
            cartItems {
                cartItemId: id
                marketListing {
                    marketListingId: id
                    price
                    currency
                    condition
                    status
                    availableForCheckout
                    ownedCard {
                        cardId
                        frontImage
                        cardName
                        playersJson
                        grade
                        graderName
                    }
                }
            }
        }
    }
`

const GET_USER_CONTACT_INFORMATION = gql`
    query GetUserContactInformation($userId: ID!){
        getUserContactInformation(userId: $userId){
            contactInfoId: id
            userId
            fullName
            phoneNumber
            unitNumber
            building
            street
            town
            state
            postalCode
            user {
                fullName
            }
            country {
                id
                name
            }
        }
    }
`

const GET_PAYMENT_DETAILS = gql`
    query GetPaymentDetails($id: ID!) {
        result: getPaymentDetails(id: $id) {
            status
            validForCheckout
        }
    }
`

const GET_SECURE_CHECKOUT_DATA = gql`
    query GetSecureCheckoutData($id: ID!) {
        result: getSecureCheckoutData(id: $id){
            orderSummary {
                total
                currency
                seller {
                    id
                    username
                    shippingMethods: fulfillmentOptions {
                        id
                        fulfillmentType
                        description
                        isActive
                        displayedFulfillmentType
                        shippingFee
                    }
                }
                items {
                    id
                    price
                    currency
                    status
                    ownedCardId
                    locationId
                    cardId
                    frontImage
                    cardTitle
                    playersJson
                }
            }
            shippingAddresses {
                id
                userId
                fullName
                phoneNumber
                unitNumber
                building
                street
                town
                state
                postalCode
                countryName
                countryId
            }
            userPaymentMethods {
                id
                isDefault
                brand
                lastDigits
                expirationMonth
                expirationYear
                externalId
            }
            payment {
                status
                validForCheckout
            }
            purchase {
                id
            }
            buyer {
                fullName
                mobile
            }
            paymentIntentClientSecret
        }
    }
`

const GET_ORDER_DETAILS = gql`
    query GetOrderDetails($id: ID!) {
        getOrderDetails(id: $id) {
            purchase {
                orderNumber
                notifiedEmail
                shippingFeeTotal
            }
            orderSummary {
                seller {
                    id
                    username
                }
                items {
                    id
                    price
                    currency
                    status
                    ownedCardId
                    locationId
                    cardId
                    frontImage
                    cardTitle
                    playersJson
                }
            }
            paymentMethodDetails {
                brand
                last4
                currency
            }
            payment {
                amount
            }
        }
    }
`

const GET_USER_ORDERS = gql`
    query GetUserOrders(
        $userId: ID!,
        $status: String,
        $searchString: String,
        $options: FilterOptionsInput,
        $userType: String!
        ) {
            results: getUserOrders(
                userId: $userId
                status: $status
                searchString: $searchString
                options: $options,
                userType: $userType
            ) {
                orders {
                    id
                    currency
                    totalAmount
                    status
                    sellerUsername
                    username
                    hasReview
                    isFulfillmentTracked
                    sellerDisplayedStatus
                    buyerDisplayedStatus
                    displayedFulfillmentType
                    dateOrdered
                    showShippingWarning
                    transactionNumber
                    shippingFee
                    totalOrderAmount
                    payment {
                        currency
                    }
                    purchaseSellerItems {
                        id
                        price
                        currency
                        cardTitle
                        frontImage
                        playersJson
                        printRun
                        printRunOrdinality
                        cardId
                        ownedCardId
                    }
                    trackingInfo: deliveryTracking {
                        trackingId: id
                        logisticsProvider
                        trackingNumber
                        expectedDateOfArrival
                        trackingUrl
                    }
                    review {
                        rating
                        title
                        content
                        dateReviewed
                        username
                        sellerUsername
                    }
                    cancelledAt
                    refundStatus
                    refunds {
                        amount
                        currency
                    }
                }

                filters {
                    ...filterDetails
                    pagination {
                        ...pagyDetails
                    }
                }
            }
    }
    ${FilterDetailsFragment}
    ${PagyFragment}
`

const GET_PURCHASE_SELLER = gql`
    query GetPurchaseSeller($id: ID!){
        purchaseSeller: getPurchaseSeller(id: $id){
            id
            transactionNumber
            currency
            totalAmount
            sellingFee
            stripeFee
            status
            refundStatus
            cancelledAt
            cancelled
            shipped
            feeCharges
            totalSellerEarnings
            totalEstimatedSellerEarnings
            orderNumber
            sellerDisplayedStatus
            isFulfillmentTracked
            shippingFee
            totalOrderAmount
            hasCommissionFeeDiscount
            sellerHasCommissionFeeDiscount
            shippingAddress {
                fullName
                phoneNumber
                unitNumber
                town
                postalCode
                state
                countryName
                building
                street
            }
            payment {
                paymentDate
                currency
                amount
            }
            paymentMethodDetails {
                brand
                last4
            }
            seller {
                monthlyCommissionFreeSales
                fulfillmentOptions {
                    id
                    fulfillmentType
                    displayedFulfillmentType
                }
            }
            fulfillmentOption {
                id
                displayedFulfillmentType
            }
            trackingInformation: deliveryTracking {
                id
                logisticsProvider
                trackingNumber
                trackingUrl
                expectedDateOfArrival
                shipmentDate
            }
            buyer: user {
                id
                username
            }
            purchaseSellerItems {
                id
                frontImage
                currency
                price
                cardTitle
                playersJson
            }
            refunds {
                amount
                adjustmentAmount
                currency
                sellerRemarks
                userPaymentMethod {
                    brand
                    lastDigits
                }
                amountBreakdown
            }
        }
    }
`

export {
    GLOBAL_SEARCH_CARDS,
    SEARCH_WANT_CARDS,
    SEARCH_HAVE_CARDS,
    GET_HAVE_LISTS,
    GET_WANT_LISTS,
    GET_WANT_CARDS,
    GET_HAVE_CARDS,
    GET_ALL_STASHED_CARDS,
    GET_STASHED_CARDS_WITH_LISTS,
    GET_USER_LISTS,
    GET_OWNED_CARD,
    GET_FEATURED_CARDS,
    GET_CARD,
    GET_CARD_STATUSES_COUNT,
    GET_LISTING_CONSTANTS,
    SEARCH_LISTINGS,
    GET_MARKET_LISTING,
    GET_USER_CART_ITEMS,
    GET_USER_CONTACT_INFORMATION,
    GET_USER_FULFILLMENT_OPTIONS,
    GET_SECURE_CHECKOUT_DATA,
    GET_USER_ORDERS,
    SEARCH_USER_HAVE_CARDS,
    GET_ORDER_DETAILS,
    GET_PURCHASE_SELLER,
    GET_PAYMENT_DETAILS
};

import React, { Fragment, useState } from "react";
import { render } from "react-dom";
import Modal from ".";
import ModalPortal from "./modal_portal";

// FIXME: Convert into a generic cancel modal for .erb views
const SubscribeCancelModal = ({ subscription, url }) => {
    const [mountModal, setMountModal] = useState(false);

    function closeModal() {
        setMountModal(false);
    }

    return (
        <Fragment>
            <button
                type="button"
                className="py-2 px-6 rounded border hover:bg-gray-575"
                onClick={() => {
                    setMountModal(true);
                }}>
                    Cancel
            </button>
            <ModalPortal id="subscribe_modal">
                {mountModal && (
                    <Modal
                        title="Hey, wait!"
                        closeModal={closeModal}
                        size="lg"
                        preventESC
                        closeIcon="x">
                        {() => (
                            <div className="p-6 flex flex-col justify-between w-full font-body text-gray-70">
                                <div className="space-x-1">
                                    <span>Are you sure you want to leave this page without confirming your membership for</span>
                                    <span className="font-semibold">{subscription}</span>?
                                </div>
                                <div className="ml-auto flex text-sm mt-8">
                                    <button
                                        className="font-semibold hover:bg-gray-1000 border py-2 px-8 border-gray-500 hover:border-gray-1000 rounded text-gray-70 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        onClick={closeModal}>
                                        Stay on this page
                                    </button>
                                    <a
                                        className="font-semibold ml-4 px-4 py-2 rounded bg-primary-800 hover:bg-primary-900 text-gray-70 focus:outline-none"
                                        rel="nofollow"
                                        href={url}>
                                        Yes, leave
                                    </a>
                                </div>
                            </div>
                        )}
                    </Modal>
                )}
            </ModalPortal>
        </Fragment>
    );
};

export default () => {
    document.addEventListener("DOMContentLoaded", () => {
        // console.log("Loading modal...");
        const container = document.getElementById("subscribe_cancel_modal");
        if (container) {
            const { subscription, url } = container.dataset;
            render(
                <SubscribeCancelModal
                    subscription={subscription}
                    url={url}
                />,
                container
            );
        }
    });
};

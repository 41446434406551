import React, { Fragment, useEffect, useState } from "react"
import { Star } from "react-feather"

const StarIcon = ({
    filled = false,
    baseValue,
    readOnly,
    iconSize,
    customClass,
    handleValueChange = () => {},
    handleHover = () => {},
    handleMouseLeave = () => {},
}) => {

    const iconClass = [
        filled && "text-primary",
        !readOnly && "cursor-pointer",
    ].join(" ")

    return (
        <Star
            size={iconSize}
            className={iconClass}
            fill={filled ? `currentColor` : `none`}
            onClick={() => {
                if (!readOnly) {
                    handleValueChange(baseValue)
                }
            }}
            onMouseEnter={() => {
                if(!readOnly) {
                    handleHover(baseValue)
                }
            }}
            onMouseLeave={() => {
                if(!readOnly) {
                    handleMouseLeave()
                }
            }}
        />
    )
}

const StarRating = ({
    value,
    starCount,
    readOnly,
    iconSize,
    customClass,
    withText,
    textClass,
    onValueChange,
}) => {
    const [currentValue, setCurrentValue] = useState(Math.round(value))
    const [stars, setStars] = useState([])
    const [defaultValue, setDefaultValue] = useState(currentValue)

    const ratingText = [
        "Terrible",
        "Bad",
        "Neutral",
        "Good",
        "Superb",
    ]

    useEffect(() => {
        setStars(Array.from({length: starCount}, (_, i) => i + 1))
    }, [currentValue])

    const handleValueChange = (value) => {
        setCurrentValue(value)
        onValueChange(value)
        setDefaultValue(value)
    }

    const handleHover = (value) => {
        setCurrentValue(value)
    }

    const handleMouseLeave = () => {
        setCurrentValue(defaultValue)
    }

    const containerClass = [
        "flex space-x-1 items-center",
        customClass
    ].join(" ")

    return (
        <Fragment>
            <div className="flex space-x-2 items-center">
                <div className={containerClass}>
                    {stars.map((star, i) => (
                        <StarIcon
                            key={star}
                            baseValue={star}
                            filled={star <= Math.round(currentValue)}
                            readOnly={readOnly}
                            iconSize={iconSize}
                            handleValueChange={handleValueChange}
                            handleHover={handleHover}
                            handleMouseLeave={handleMouseLeave}
                        />
                    ))}
                </div>
                {withText && (
                    <div className={textClass}>
                        {ratingText[currentValue-1]}
                    </div>
                )}
            </div>
        </Fragment>
    )
}

StarRating.defaultProps = {
    value: 0,
    starCount: 5,
    readOnly: false,
    iconSize: 20,
    customClass: "text-white",
    withText: false,
    textClass: "text-primary uppercase font-semibold text-xs",
    onValueChange: () => {},
}

export default StarRating
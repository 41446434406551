import React from 'react'
import { Check, HelpCircle, Info, MapPin, Share2 } from 'react-feather'
import CopyToClipboard from './copy_to_clipboard'
import MessageGoldIcon from "icon-gold-message.svg";

const HelpText = ({
    text,
    dom,
    size,
    icon,
    iconColor,
    sideText,
    sideTextClass,
    placement,
    reverse,
    urlText,
    url,
    copyToClipboard,
    showTextOnHover,
    onClick,
}) => {
    const icons = {
        info: <Info size={size} className={iconColor} />,
        question: <HelpCircle size={size} className={iconColor} />,
        check: <Check size={size} className={iconColor} />,
        share: <Share2 size={size} className={iconColor} />,
        location: <MapPin size={size} className={iconColor} />,
        message: <img src={MessageGoldIcon} alt="Message icon" className={size} />
    }

    const textAndIconContainerClass = [
        "flex items-center space-x-2",
        reverse && "flex-row-reverse space-x-reverse"
    ].join(" ")

    const textClass = [
        "relative z-10 p-2 shadow-lg rounded",
        "bg-gray-600 bg-opacity-90",
        "text-sm leading-none text-white whitespace-no-wrap",
        "flex space-x-2"
    ].join(" ")

    const textContainerClass = [
        "absolute transform items-center hidden",
        showTextOnHover ? "group-hover:flex" : "",
        placement === 'top' ? "-translate-x-1/4 -translate-y-full": "",
        placement === 'bottom' ? "-translate-x-1/4 translate-y-full": "",
        placement === 'left' ? "-translate-x-full": "",
        placement === 'right' ? "translate-x-1/2": "",
    ].join(" ")

    return (
        <div className="group">
            <div className={textContainerClass}>
                <div className={textClass}>
                    <span>
                        {text || dom}
                    </span>
                    <a href={url} target="_blank" className="underline">
                        {urlText || url}
                    </a>
                </div>
            </div>
            {copyToClipboard ? (
                <div className={textAndIconContainerClass}>
                    {icons[icon]}
                    <span className={sideTextClass}>
                        <CopyToClipboard text={text} actionText={sideText} />
                    </span>
                </div>
            ) : null}
            {!copyToClipboard ? (
                <button className={textAndIconContainerClass} onClick={onClick} type="button">
                    {icons[icon]}
                    <span className={sideTextClass}>
                        {sideText}
                    </span>
                </button>
            ) : null}
        </div>
    )
}

HelpText.defaultProps = {
    text: null,
    dom: null,
    size: 16,
    icon: 'info',
    iconColor: 'text-primary',
    sideText: null,
    sideTextClass: 'text-md',
    reverse: false,
    placement: 'top',
    copyToClipboard: false,
    showTextOnHover: true,
    onClick: () => {},
}

export default HelpText
import React from "react";
import Button from "../../seller_center/shared_components/button";
import CardImage from "../../card_image";
import formatCardPlayers from "../../utils/format_card_players";
import formatPrice from "../../utils/format_price";

const cardClassName = [
    "focus:outline-none",
    "trophyRoom-card",
    "relative",
    "flex-shrink-0",
    "border-2 border-white rounded-md",
    "w-full",
    "w-16 h-20",
].join(" ");

const CartSellerItem = ({
    marketListingId,
    condition,
    price,
    currency,
    ownedCard,
    selected,
    select,
    deSelect,
    deleteCartItem,
    status,
    availableForCheckout,
}) => {
    const bgOpacity = availableForCheckout ? "100" : "40";

    return (
        <label
            htmlFor={`cartItem-${marketListingId}`}
            className="cursor-pointer px-6 lg:px-12 ">
            <div className="text-gray-30 flex space-x-2 border-b-2 border-gray-300 border-opacity-40 md:grid md:grid-cols-12 md:gap-4">
                <div className={`flex items-center opacity-${bgOpacity}`}>
                    <input
                        type="checkbox"
                        id={`cartItem-${marketListingId}`}
                        className="text-primary bg-gray-30 rounded w-5 h-5 focus:ring-0"
                        checked={availableForCheckout ? selected : false}
                        onChange={(e) =>
                            availableForCheckout
                                ? e.target.checked
                                    ? select()
                                    : deSelect()
                                : {}
                        }
                        disabled={!availableForCheckout}
                    />
                </div>
                <div className="pb-6 flex flex-col space-y-3 md:col-span-11 md:space-x-6 md:grid md:grid-cols-5">
                    <div className="flex flex-row space-x-4 md:col-span-3">
                        <div
                            className={`${cardClassName} opacity-${bgOpacity}`}>
                            <a
                                href={`/marketplace/listings/${marketListingId}`}
                                className="h-full rounded-md text-white hover:bg-gray-100 flex items-center p-2">
                                <CardImage
                                    isFront
                                    image={ownedCard?.frontImage}
                                    hideMoreText
                                />
                            </a>
                        </div>
                        <div className="flex items-center">
                            <div className="flex flex-col space-y-2">
                                <a
                                    href={`/marketplace/listings/${marketListingId}`}
                                    className={`opacity-${bgOpacity} flex flex-col`}>
                                    <span>{ownedCard?.cardName}</span>
                                    <span className="hidden lg:inline-block">
                                        {formatCardPlayers(ownedCard?.playersJson)}
                                    </span>
                                    <div className="flex space-x-2 hidden lg:inline-block">
                                        {ownedCard?.graderName ? (
                                            <span className="uppercase">
                                                {ownedCard?.graderName}
                                            </span>
                                        ) : null}
                                        {ownedCard?.grade ? (
                                            <span className="uppercase">
                                                {ownedCard?.grade}
                                            </span>
                                        ) : null}
                                        {condition ? (
                                            <span className="uppercase">
                                                {condition}
                                            </span>
                                        ) : null}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center md:col-span-2">
                        <div className="flex flex-col">
                            <div
                                className={`md:text-center opacity-${bgOpacity}`}>
                                {formatPrice(price, "V C", currency)}
                            </div>
                            {!availableForCheckout && (
                                <span className="text-center bg-gray-300 bg-opacity-70 px-2 py-1 rounded text-gray-1000 font-semibold text-sm uppercase">
                                    {status === "sold"
                                        ? "sold out"
                                        : "unavailable"}
                                </span>
                            )}
                        </div>
                        <div className="flex justify-center">
                            <Button
                                text="Delete"
                                customClass="underline"
                                transparent
                                type="button"
                                onClick={() => deleteCartItem()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </label>
    );
};

export default CartSellerItem;

import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const ModalPortal = ({ children, id = "modalPortal" }) => {
    let ref = useRef(null);
    useEffect(() => {
        const portal = document.getElementById(id);
        if (!portal) {
            ref.current = document.createElement("div");
            ref.current.id = id;
            document.body.append(ref.current);
        } else {
            ref.current = portal;
        }

        return () => {
            if (ref.current && ref.current.parent === document.body)
                document.body.removeChild(ref.current);
        };
    }, []);

    if (!ref.current) {
        return null;
    }

    return createPortal(children, ref.current);
};

export default ModalPortal;

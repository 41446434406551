import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET_WANT_LISTS } from "../queries";
import ListContainer from "./list_container";
import UserIdContext from "./user_id_context";
import BGLightsDarker from "bg-lights-darker.png";
import ListLinksContainer from "./list_links_container";

const ADD_LIST = gql`
    mutation CreateList($input: CreateWantListInput!) {
        createWantList(input: $input) {
            errors
            wantList {
                id
                name
            }
        }
    }
`;

const WantList = (props) => {
    const userId = useContext(UserIdContext);
    const location = useLocation();

    const { setDisplayMenu, isDisplayMenu } = props;

    const [getLists, { data }] = useLazyQuery(GET_WANT_LISTS, {
        fetchPolicy: "cache-and-network",
    });

    const [addList, res] = useMutation(ADD_LIST, {
        variables: {
            input: {
                name: "New List",
                userId,
            },
        },
        update(cache, { data }) {
            const newListFromResponse = data?.createWantList?.wantList;
            const existingLists = cache.readQuery({
                query: GET_WANT_LISTS,
                variables: {
                    userId,
                },
            });

            const lists = [...existingLists?.getWantLists, newListFromResponse];

            if (newListFromResponse) {
                cache.writeQuery({
                    query: GET_WANT_LISTS,
                    data: {
                        getWantLists: lists,
                    },
                    variables: {
                        userId,
                    },
                });
            }
        },
    });

    const q = new URLSearchParams(location.search);
    const currentListId = q.get("list");

    useEffect(() => {
        getLists({
            variables: {
                userId,
            },
        });
    }, [userId, getLists]);

    const lists = data?.getWantLists || [];
    const currentList = lists.find((l, i) =>
        /* return first list if currentListId is undefined or null */
        currentListId ? l.id === currentListId : i === 0
    );

    const [isEmpty, setIsEmpty] = useState(false);
    const setEmptyOnDelete = () => setIsEmpty(true);
    useEffect(() => {
        // set isEmpty to false if selected want list exists on users lists
        const existing = lists.find((l) => l.id === currentListId);
        if (existing) {
            setIsEmpty(false);
        }
    }, [currentListId, lists]);

    return (
        <Fragment>
            <div
                className={`${
                    isDisplayMenu ? "" : "hidden"
                } w-full h-screen divide-y overflow-y-auto divide-gray-600
                space-y-6 bg-black-300 rounded-sm text-white p-2 z-10 absolute`}>
                <ListLinksContainer
                    addList={addList}
                    currentListId={currentListId}
                    isDisabled={res?.loading}
                    isMobile
                    lists={lists}
                    userId={userId}
                    setDisplayMenu={setDisplayMenu}
                    setEmptyOnDelete={setEmptyOnDelete}
                />
            </div>
            <div
                className="p-4 bg-fixed img-cover-basic lg:p-8 min-h-screen"
                style={{ backgroundImage: `url(${BGLightsDarker})` }}>
                <div className="container mx-auto flex flex-col lg:flex-row items-start">
                    <div className="lg:sticky" style={{ top: "6rem" }}>
                        <div className="w-72 flex-shrink-0 md:sticky hidden lg:block">
                            <div
                                className="w-full divide-y overflow-y-auto divide-gray-600 bg-gray-700 rounded-sm text-white p-2">
                                <p className="font-body px-4 py-2 text-lg text-gray-1000 font-semibold">
                                    Want List
                                </p>
                                <ListLinksContainer
                                    addList={addList}
                                    currentListId={currentListId}
                                    isDisabled={res?.loading}
                                    lists={lists}
                                    userId={userId}
                                    setDisplayMenu={setDisplayMenu}
                                    setEmptyOnDelete={setEmptyOnDelete}
                                />
                            </div>
                        </div>
                        <div
                            id="filterPortal"
                            style={{ maxHeight: "50%" }}
                            className="mt-2 w-72 flex-shrink-0 divide-y divide-gray-600 space-y-6 overflow-y-auto bg-gray-700 rounded-sm"
                        />
                    </div>
                    {currentList?.id && (
                        <ListContainer
                            listId={currentList?.id}
                            name={currentList?.name}
                            isEmpty={isEmpty}
                            isWantList
                        />
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default WantList;

import React, { useRef, useState, useEffect } from "react";
import ListLink from "./list_link";
import { X, ChevronUp, ChevronDown } from "react-feather";

const ScrollButton = ({
    position,
    onClick,
}) => {
    let icon = position == "top" ? <ChevronUp/> : <ChevronDown/>;
    return (
        <button type="button" className={`scroll-button ${position}`} onClick={onClick}>{icon}</button>
    )
}

const ListLinksContainer = ({
    addList,
    currentListId,
    isDisabled,
    isMobile = false,
    lists,
    setEmptyOnDelete,
    setDisplayMenu,
    userId,
    isHaveList = false,
}) => {
    const addBtnClass = isMobile
            ? "w-11/12 mx-auto px-2 py-2 lg:py-1 rounded-md"
            : "px-2 py-1 ";

    const listContainer = useRef(null);
    const [topButton, setTopButton] = useState(false);
    const [bottomButton, setBottomButton] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", hideButtons);
        return () => window.removeEventListener("resize", hideButtons);
    }, []);

    function hideButtons() {
        setTopButton(false);
        setBottomButton(false);
    }

    function addListWrapper() {
        addList().then(() => {
            if (listContainer.current) {
                listContainer.current.scrollTo(0, listContainer.current.scrollHeight);
            }
        });
    }


    function scrollListen() {
        let clientHeight = listContainer.current.clientHeight;
        let scrollTop = listContainer.current.scrollTop;
        let scrollHeight = listContainer.current.scrollHeight;

        setTopButton(scrollTop >= (clientHeight / 10));
        setBottomButton(((scrollHeight - clientHeight - scrollTop) > 10)
                        && scrollHeight > clientHeight);
    }

    function scroll(position) {
        if (!listContainer.current) return;

        let location = position == "top" ? 0 : listContainer.current.scrollHeight;
        listContainer.current.scrollTo(0, location);
    }

    return (
        <ul ref={listContainer} className="p-4 space-y-4 text-sm merqary-list-container" onScroll={() => scrollListen()}>
            {isMobile && (
                <button
                    className="flex w-auto ml-auto cursor-pointer items-center justify-end border-b-0"
                    onClick={() => setDisplayMenu(false)}>
                    <X size={14} />
                    <span className="ml-1">Close</span>
                </button>
            )}
            {topButton ? (<ScrollButton position={"top"} onClick={() => scroll("top")} />) : null}

            {lists.map((list, i) => {
                const isActive = currentListId
                    ? list.id === currentListId
                    : i === 0;
                return (
                    <ListLink
                        key={i}
                        isActive={isActive}
                        id={list.id}
                        name={list.name}
                        userId={userId}
                        isHaveList={isHaveList}
                        setEmptyOnDelete={setEmptyOnDelete}
                    />
                );
            })}
            {bottomButton ? (<ScrollButton position="bottom" onClick={() => scroll("bottom")} />) : null}
            <li>
                <button
                    type="button"
                    disabled={isDisabled}
                    onClick={() => addListWrapper()}
                    className={`flex text-sm items-center justify-center border border-white w-full hover:text-primary hover:border-primary ${addBtnClass}`}>
                    Add New List
                </button>
            </li>
        </ul>
    );
}

export default ListLinksContainer;

import { useState } from "react";

const useSelection = (defaultValue = []) => {
    const [selected, setSelected] = useState(defaultValue);

    const select = (value) => {
        if (value.constructor === Array) {
            setSelected([...selected, ...value]);
        } else {
            setSelected([...selected, value]);
        }
    };

    const deSelect = (value) => {
        if (value.constructor === Array) {
            setSelected([...selected.filter((v) => !value.includes(v))]);
        } else {
            setSelected([...selected.filter((v) => v !== value)]);
        }
    };

    return {
        selected,
        select,
        deSelect,
        setSelected,
    };
};

export default useSelection;

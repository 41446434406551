import React from 'react'
import IconGoldenCheck from "icon-golden-check.svg";
import formatPrice from "../../utils/format_price";

const PaymentSummary = ({
    orderNumber,
    notifiedEmail,
    amount: subTotal,
    currency,
    shippingFeeTotal,
    paymentMethodDetails
}) => {
    return (
        <div className="p-4 md:p-8 flex flex-col text-gray-30 relative w-full rounded-lg bg-gray-800 shadow">
            <div className="flex space-x-4">
                <img
                    src={IconGoldenCheck}
                    alt="Check icon"
                    className="w-5 h-5"
                />
                <div className="grid">
                    <span className="font-semibold">
                        Order No. {orderNumber}
                    </span>
                    <span className="text-sm font-body">
                        A record of your transaction and payment has
                        been sent to {notifiedEmail}
                    </span>
                </div>
            </div>
            <hr className="mt-6 bg-gray-575 border-none h-px" />
            <div className="py-4 space-y-4 text-gray-30">
                <SummaryItem
                    title="Order Subtotal"
                    value={formatPrice(subTotal - shippingFeeTotal, "V", currency)}
                />
                <SummaryItem
                    title="Shipping Fee"
                    value={
                        formatPrice(shippingFeeTotal, "V", currency)
                    }
                />
                <SummaryItem
                    title="Total Payment"
                    valueFontWeightBold
                    value={
                        formatPrice(subTotal, "V C", currency)
                    }
                />
                <SummaryItem
                    title="Payment method"
                    value={`${paymentMethodDetails?.brand} ending in ${paymentMethodDetails?.last4}`}
                    capitalizeFirstLetter
                />
            </div>
        </div>
    );
}

const SummaryItem = ({ title, value, valueFontWeightBold, capitalizeFirstLetter }) => {
    const valueClass = [
        valueFontWeightBold ? "font-semibold" : "font-medium",
        capitalizeFirstLetter ? "capitalize-first-letter" : ""
    ].join(" ");

    return (
        <div className="flex flex-wrap">
            <span className="w-48">{title}</span>
            <span className={valueClass}>
                {value}
            </span>
        </div>
    );
}

export default PaymentSummary;

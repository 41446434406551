import React, { useContext, useState } from "react";
import BgLightsDarker from "bg-lights-darker.png";
import OrderSummary from "./order_summary";
import OrderDetails from "./order_details";
import ShippingMethod from "./shipping_method";
import ShippingAddress from "./shipping_address";
import PaymentDetails from "./payment_details";
import { Redirect, useParams } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_PAYMENT_DETAILS, GET_SECURE_CHECKOUT_DATA } from "../../queries";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { PopupContext, POPUP_TYPES } from "../../popup_context";
import checkoutMutation from "../../mutations/checkout_mutation";
import useCartItemsMutation from "../../mutations/cart_items_mutation";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SpinnerLoader from "../../spinner_loader";
import PageVariablesContext from "../../page_variables_context";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// causes error: stripe cannot retrieve card details.
const stripePromise = loadStripe(`${window.STRIPE_PUBLIC_KEY}`);

const pageClass = [
    "px-8 py-8 md:px-18 md:py-12 lg:px-20 xl:px-32 2xl:px-60",
    "animate__animated animate__fadeIn",
    "bg-fixed bg-cover bg-center bg-no-repeat",
    "min-h-screen",
    "text-white",
].join(" ");

const Checkout = () => {
    const { userId } = useContext(PageVariablesContext);
    const { id } = useParams();

    const queryParams = {
        variables: { id: id },
        fetchPolicy: "cache-and-network",
    };
    const { data, loading: checkoutDataLoading } = useQuery(
        GET_SECURE_CHECKOUT_DATA,
        queryParams
    );

    const [getPaymentDetails] = useLazyQuery(
        GET_PAYMENT_DETAILS,
        queryParams
    );

    const checkoutData = data?.result;
    const payment = checkoutData?.payment;
    const status = payment?.status;
    const paymentIntentClientSecret = checkoutData?.paymentIntentClientSecret;
    const purchase = checkoutData?.purchase;

    const expired =
        !checkoutDataLoading &&
        status === "unprocessed" &&
        !payment.validForCheckout;
    const unprocessed =
        !checkoutDataLoading &&
        status === "unprocessed" &&
        payment.validForCheckout;
    const succeeded = !checkoutDataLoading && status === "succeeded";
    const failed = !checkoutDataLoading && status === "failed";
    const stripeOptions = {
        clientSecret: paymentIntentClientSecret,
        appearance: {
            variables: { colorText: "#c2c2c2" },
            rules: { '.Input': { color: '#30313d' } }
        }
      };

    if (!userId) {
        return <Redirect to="/marketplace" />;
    }

    const checkPaymentValidity = (callback = () => {}) => {
        getPaymentDetails().then(res => {
            if (res.data.result.validForCheckout) {
                callback();
            } else {
                window.location.reload();
            }
        });
    }

    return (
        <div
            className={pageClass}
            style={{ backgroundImage: `url(${BgLightsDarker})` }}>
                {checkoutDataLoading ? <SpinnerLoader /> : null}
                {stripePromise && checkoutData && !checkoutDataLoading ? (
                    <>
                        {unprocessed && (
                            <Elements stripe={stripePromise} options={stripeOptions}>
                                <Unprocessed
                                    checkoutData={checkoutData}
                                    userId={userId}
                                    checkPaymentValidity={checkPaymentValidity}
                                />
                            </Elements>
                        )}

                        {expired && <Expired />}
                        {succeeded && <Succeeded purchase={purchase} />}
                        {failed && <Failed />}
                    </>
                ) : null}
        </div>
    );
};

const schema = yup.object().shape({
    userId: yup.string().required(),
    paymentMethodId: yup.string().nullable(),
    paymentId: yup.string().required(),
    contactInformationId: yup.string().nullable(),
    purchaseDetails: yup.array().of(
        yup.object().shape({
            sellerId: yup.string().required(),
            fulfillmentOptionId: yup.string().required(),
        })
    ),
});

const Unprocessed = ({
    checkoutData,
    userId,
    checkPaymentValidity,
}) => {
    const { id } = useParams();
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);
    const checkoutSellers = checkoutData?.orderSummary;
    // TODO: will eventually add isDefault to shipping addresses. always select default
    const shippingAddresses = checkoutData?.shippingAddresses;
    const userPaymentMethods = checkoutData?.userPaymentMethods;
    const defaultPaymentMethod = userPaymentMethods?.filter(
        (item) => item.isDefault
    )[0];
    const buyer = checkoutData?.buyer;
    const showShippingMethod = checkoutSellers?.length === 1;
    const initialValues = {
        userId: userId,
        paymentMethodId: defaultPaymentMethod?.id,
        paymentId: id,
        contactInformationId: shippingAddresses[0]?.id || null,
        purchaseDetails: checkoutSellers?.map((summary) => ({
            sellerId: summary.seller.id,
            fulfillmentOptionId: null,
        })),
    };
    const cardLocationIds = Array.from(
        new Set(checkoutSellers.flatMap(
            summary => summary.items.map(i => i.locationId)
        ))
    );
    const stripe = useStripe();
    const elements = useElements();
    const [stripeLoading, setStripeLoading] = useState(false);


    const updateOrderPaymentStatusCallback = (data) => {
        const result = data.result;
        if (result.success) {
            if (result.newCheckoutSession) {
                setShowPopup(true);
                setPopupAction(POPUP_TYPES.DANGER);
                setMessage(result.errors.join(", "));
                newCheckoutSession(result.newCheckoutSession);
            } else {
                window.location.replace(
                    `/marketplace/purchases/${result?.purchase?.id}`
                );
            }
        } else {
            setShowPopup(true);
            setPopupAction(POPUP_TYPES.DANGER);
            setMessage(`${result.errors.join(", ")}. Please contact administrator`);
        }
    }
    const { checkoutCartItems, checkoutCartItemsLoading } = useCartItemsMutation();
    const { updateOrderPaymentStatus, updateOrderPaymentStatusLoading } =
        checkoutMutation(updateOrderPaymentStatusCallback);

    const newCheckoutSession = (cartItemIds) => {
        checkoutCartItems({
            variables: {
                input: { cartItemIds },
            },
        });
    }

    const placeOrderCallback = (data) => {
        const result = data.result;
        if (result.success) {
            if (result.paymentConfirmed) {
                window.location.replace(
                    `/marketplace/purchases/${result.purchaseId}`
                );
            } else {
                setStripeLoading(true);
                stripe.confirmPayment({
                    elements,
                    redirect: "if_required",
                }).then((res) => {
                    setStripeLoading(false);
                    const paymentDetails = formatPaymentDetails(res);
                    updateOrderPaymentStatus({
                        variables: {
                            input: {
                                purchaseId: result.purchaseId,
                                paymentDetails,
                            }
                        }
                    });
                });
            }
        } else {
            setShowPopup(true);
            setPopupAction(POPUP_TYPES.DANGER);
            setMessage(result.errors.join(", "));
            if (result.newCheckoutSession) {
                newCheckoutSession(result.newCheckoutSession);
            }
        }
    };

    const { placeOrder, placeOrderLoading } =
        checkoutMutation(placeOrderCallback);

    const [paymentErrors, setPaymentErrors] = useState(null);

    const handleFormSubmit = ({ ...values }) => {
        if (!stripe || !elements) return;
        if (paymentErrors) {
            setShowPopup(false);
            setShowPopup(true);
            setPopupAction(POPUP_TYPES.DANGER);
            setMessage(paymentErrors);
            return;
        }

        checkPaymentValidity(() => {
            if (!values.contactInformationId) {
                setShowPopup(true);
                setPopupAction(POPUP_TYPES.WARN);
                setMessage("Please add your shipping address");
                return;
            }
            setShowPopup(false);
            placeOrder({
                variables: {
                    input: { orderDetails: { ...values } },
                },
            });
        })
    };
    return (
        <>
            {(stripeLoading || placeOrderLoading || checkoutCartItemsLoading || updateOrderPaymentStatusLoading) ? (
                <div className="w-full h-full fixed block top-0 left-0 bg-black opacity-80 z-50">
                    <div className="grid top-1/2 my-0 mx-auto block relative h-0 text-center text-white w-full">
                        {/* <img src={}  /> */}
                        <span className="text-3xl font-semibold">
                            Payment Processing
                        </span>
                        <span className="text-xl font-semibold">
                            You will automatically be redirected to a
                            new page
                        </span>
                    </div>
                </div>
            ) : null}
            <Formik
                onSubmit={(values) => handleFormSubmit(values)}
                validationSchema={schema}
                initialValues={initialValues}>
                {(form) => (
                    <Form>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                            <div className="col-span-1 lg:col-span-2 space-y-8">
                                <OrderSummary
                                    checkoutSellers={checkoutSellers}
                                    showShippingMethod={!showShippingMethod}
                                    buyerLocationId={shippingAddresses[0]?.countryId}
                                    cardLocationIds={cardLocationIds}
                                />
                                {showShippingMethod && (
                                    <ShippingMethod
                                        seller={checkoutSellers[0]?.seller}
                                        buyerLocationId={shippingAddresses[0]?.countryId}
                                        cardLocationIds={cardLocationIds}
                                    />
                                )}
                                <ShippingAddress
                                    buyer={buyer}
                                    shippingAddress={shippingAddresses[0]}
                                    checkPaymentValidity={checkPaymentValidity}
                                />
                                <PaymentDetails
                                    userPaymentMethod={defaultPaymentMethod}
                                    checkPaymentValidity={checkPaymentValidity}
                                    setPaymentErrors={setPaymentErrors}
                                />
                            </div>
                            <div className="col-span-1">
                                <OrderDetails
                                    checkoutSellers={checkoutSellers}
                                    disabled={!stripe || !elements}/>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const Expired = () => (
    <div className="flex justify-center">
        <div className="flex flex-col space-y-3 text-center">
            <span className="text-xl font-semibold text-gray-1000">
                Checkout session expired
            </span>
            <a
                href="/marketplace/cart"
                className="underline text-md text-gray-30">
                Back to cart
            </a>
        </div>
    </div>
);

const Failed = () => (
    <div className="flex justify-center">
        <div className="flex flex-col space-y-3 text-center">
            <span className="text-xl font-semibold text-gray-1000">
                Order placement failed
            </span>
            <a
                href="/marketplace/cart"
                className="underline text-md text-gray-30">
                Back to cart
            </a>
        </div>
    </div>
);

const Succeeded = ({ purchase }) => (
    <div className="flex justify-center">
        <div className="flex flex-col space-y-3 text-center">
            <span className="text-xl font-semibold text-gray-1000">
                Order placement successful
            </span>
            <a
                href={`/marketplace/orders/${purchase?.id}`}
                className="underline text-md text-gray-30">
                View order details
            </a>
        </div>
    </div>
);

const formatPaymentDetails = (paymentDetails) => {
    if (!paymentDetails) return null;

    const paymentIntent = paymentDetails.paymentIntent || paymentDetails.error.payment_intent;
    const paymentError = paymentIntent?.last_payment_error || null;
    const paymentMethod = paymentError?.payment_method || null;

    return {
        paymentIntentId: paymentIntent?.id,
        paymentMethodExternalId: paymentMethod?.id,
        paymentIntentStatus: paymentIntent?.status,
        amount: paymentIntent?.amount,
        currency: paymentIntent?.currency,
        expMonth: paymentMethod?.card?.exp_month,
        expYear: paymentMethod?.card?.exp_year,
        brand: paymentMethod?.card?.brand,
        last4: paymentMethod?.card?.last4,
        country: paymentMethod?.card?.country,
        failureCode: paymentError?.code,
        failureMessage: paymentError?.message,
        receiptEmail: paymentIntent?.receipt_email,
        chargeId: paymentError?.charge
    };
};

export default Checkout;

import { useFormikContext } from "formik";
import React, { useContext } from "react";
import { ConfirmationContext } from "../../confirmation_context";
import Button from "../shared_components/button";

const FormFooter = ({
    history,
    handleFormSubmit,
    submitText,
    listingId,
    loading,
}) => {
    const { values } = useFormikContext();
    const confirm = useContext(ConfirmationContext);

    const previewListing = () => {
        const previewUrl = `/seller_center/my_listings/${listingId}/preview`;
        if (listingId) {
            history.push({
                pathname: previewUrl,
            });
        } else {
            handleFormSubmit(
                {
                    ...values,
                    status: "draft",
                },
                "preview"
            );
        }
    };

    const saveAsDraft = () => {
        confirm({
            onConfirm: () =>
                handleFormSubmit(
                    {
                        ...values,
                        status: "draft",
                    },
                    "drafts"
                ),
            title: "Save listing as draft",
            text: "Click confirm to proceed.",
        });
    };

    return (
        <div className="pt-8">
            <div className="flex flex-col-reverse md:flex-row items-center">
                <Button
                    text="Cancel"
                    transparent
                    url="/seller_center/my_listings"
                    fontBold
                    customClass="mt-8 md:mt-0"
                />

                <div className="md:ml-auto flex flex-col-reverse md:flex-row space-y-4 md:space-y-0 md:space-x-4 md:items-center">
                    <Button
                        type="button"
                        text="Save as draft"
                        transparent
                        disabled={loading}
                        onClick={() => saveAsDraft()}
                        customClass="mt-4 md:mt-0"
                    />
                    <Button
                        type="button"
                        text="Preview"
                        secondary
                        disabled={loading}
                        onClick={() => previewListing()}
                    />
                    <Button
                        type="submit"
                        text={submitText}
                        primary
                        disabled={loading}
                    />
                </div>
            </div>
        </div>
    );
};

export default FormFooter;

import { gql, useMutation } from "@apollo/client";
import { useContext } from "react";
import { PopupContext } from "../popup_context";

const PLACE_ORDER = gql`
    mutation PlaceOrder($input: PlaceOrderInput!) {
        result: placeOrder(input: $input) {
            purchaseId
            newCheckoutSession
            paymentConfirmed
            success
            errors
        }
    }
`;

const UPDATE_ORDER_PAYMENT_STATUS = gql`
    mutation UpdateOrderPaymentStatus($input: UpdateOrderPaymentStatusInput!) {
        result: updateOrderPaymentStatus(input: $input) {
            purchase {
                id
            }
            newCheckoutSession
            success
            errors
        }
    }
`;

const useCheckoutMutation = (callback = () => {}) => {
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);

    const onError = () => {
        setShowPopup(false);
        setShowPopup(true);
        setPopupAction("warn");
        setMessage("Something went wrong.");
    };

    const [placeOrder, { loading: placeOrderLoading }] = useMutation(
        PLACE_ORDER,
        {
            onCompleted: (data) => callback(data),
            onError: onError,
        }
    );

    const [updateOrderPaymentStatus, { loading: updateOrderPaymentStatusLoading }] = useMutation(
        UPDATE_ORDER_PAYMENT_STATUS,
        {
            onCompleted: (data) => callback(data),
            // onError: onError,
        }
    );

    return {
        placeOrder,
        placeOrderLoading,
        updateOrderPaymentStatus,
        updateOrderPaymentStatusLoading
    };
};

export default useCheckoutMutation;

import { ErrorMessage, Form, Formik } from "formik"
import React, { useContext, useRef } from "react"
import Modal from "../../modal"
import ModalPortal from "../../modal/modal_portal"
import { PopupContext } from "../../popup_context"
import Button from "../../seller_center/shared_components/button"
import StarRating from "../../star_rating"
import * as yup from 'yup'
import PurchaseSellerRatingMutation from "../../mutations/purchase_seller_rating_mutation"

const RateSellerModal = ({
    sellerUsername,
    purchaseSellerId,
    mountModal,
    setMountModal,
    callback = () => {},
}) => {
    const closeButton = useRef(null)
    const { setShowPopup, setPopupAction, setMessage } = useContext(PopupContext);
    const createReviewCallback = (data) => {
        const result = data.result
        if (result.success) {
            setShowPopup(true)
            setPopupAction('success')
            setMessage("Your review has been submitted. Thank you!")
        } else {
            setShowPopup(true)
            setPopupAction('warn')
            setMessage(result.errors.join(", "))
        }
        closeButton.current.click()
        setTimeout(() => {
            callback()
        }, 1000);
    }
    const { createPurchaseSellerReview, createReviewLoading } = PurchaseSellerRatingMutation(createReviewCallback)

    const handleFormSubmit = ({...values}) => {
        createPurchaseSellerReview({
            variables: {
                input: {
                    review: values
                }
            }
        })
    }

    const schema = yup.object().shape({
        purchaseSellerId: yup.string().required(),
        rating: yup.number().min(1, 'Rate your experiece from 1 to 5 stars')
                   .max(5, "Rate your experiece from 1 to 5 stars")
                   .required("Rate your experiece from 1 to 5 stars"),
        title: yup.string().max(50, "Title is too long (max 50 characters)").nullable(),
        content: yup.string().max(1000, "Content is too long (max 1000 characters)").nullable()
    })

    const initialValues = {
        purchaseSellerId: purchaseSellerId,
        rating: 0,
        title: null,
        content: null
    }

    return (
        <ModalPortal>
            {mountModal && (
                <Modal
                    title="Rate Seller"
                    preventESC
                    size="xs"
                    closeModal={() => setMountModal(false)}
                    className="p-10 text-black-100"
                    closeIcon='x'
                    titleClass="text-gray-650 text-md font-semibold"
                    showTitleBorder={false}
                    modalCustomClass="rounded"
                >
                    {({ hideModal }) => (
                        <Formik
                            onSubmit={(values) => handleFormSubmit(values)}
                            validationSchema={schema}
                            initialValues={initialValues}
                        >
                            {(form) => (
                                <Form>
                                    <div className='p-6 text-gray-600'>
                                        <div className="py-4 space-y-8 border-t-2 border-gray-400 border-opacity-40">
                                            <div className='flex space-x-2 items-center'>
                                                <span className='text-xl'>What is your rating for</span>
                                                <span className='text-xl font-semibold'>{sellerUsername}?</span>
                                            </div>
                                            <div className='p-1'>
                                                <StarRating
                                                    iconSize={32}
                                                    onValueChange={(rating) => form.setFieldValue('rating', rating)}
                                                    customClass='text-gray-300'
                                                    withText
                                                    textClass="text-gray-400 uppercase font-semibold text-xl"
                                                />
                                                <ErrorMessage name="rating">
                                                    { msg => <div className='text-red-400 text-xs'>{msg}</div> }
                                                </ErrorMessage>
                                            </div>
                                            <div className='grid space-y-8'>
                                                <div className="grid w-full">
                                                    <span className="text-md font-body mb-2 w-9/12 text-gray-800 text-opacity-90">
                                                        Title [optional]
                                                    </span>
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        className="text-xs w-full p-2 rounded text-black text-sm border"
                                                        onChange={(e) => form.setFieldValue('title', e.target.value)}
                                                    />
                                                    <ErrorMessage name="title">
                                                        { msg => <div className='text-red-400 text-xs'>{msg}</div> }
                                                    </ErrorMessage>
                                                </div>
                                                <div className="grid w-full">
                                                    <span className="text-md font-body mb-2 w-full text-gray-800 text-opacity-90">
                                                        Tell others your experience with this seller [optional]
                                                    </span>
                                                    <textarea
                                                        name="content"
                                                        rows="4"
                                                        cols="50"
                                                        className="text-xs w-full p-2 rounded text-black text-sm border"
                                                        onChange={(e) => form.setFieldValue('content', e.target.value)}
                                                    />
                                                    <ErrorMessage name="content">
                                                        { msg => <div className='text-red-400 text-xs'>{msg}</div> }
                                                    </ErrorMessage>
                                                </div>
                                                <div className='flex justify-end'>
                                                    <div className="flex items-center space-x-6">
                                                        <Button
                                                            text="Cancel"
                                                            type="button"
                                                            secondary
                                                            onClick={() => hideModal()}
                                                            buttonRef={closeButton}
                                                        />
                                                        <Button
                                                            text="Submit"
                                                            type="submit"
                                                            primary
                                                            disabled={createReviewLoading}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Modal>
            )}
        </ModalPortal>
    )
}

export default RateSellerModal
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ArrowLeft, Heart, Plus, Trash } from "react-feather";
import { Redirect } from "react-router";
import Modal from "../modal";
import ModalPortal from "../modal/modal_portal";
import {
    ADD_CARDS_TO_HAVE_LIST,
    ADD_CARDS_TO_WANT_LIST,
    UNSTASH_CARD,
    updateRemoveFromStash,
    updateUnstash,
} from "../mutations";
import { POPUP_TYPES, PopupContext } from "../popup_context";
import { GET_STASHED_CARDS_WITH_LISTS } from "../queries";
import SelectList from "../trophy_room/select_list";
import UserIdContext from "../trophy_room/user_id_context";
import StashedCard from "./stashed_card";
import BGLightsDarker from "bg-lights-darker.png";

const ADD_TO_WANT_LIST_MODAL = "ADD_TO_WANT_LIST_MODAL";
const ADD_TO_HAVE_LIST_MODAL = "ADD_TO_HAVE_LIST_MODAL";
const REMOVE_MODAL = "REMOVE_MODAL";

const FixedBottomButtons = ({ showModal }) => {
    const docEl = useRef();
    const footer = useRef();
    const [bottomPosition, setBottomPosition] = useState(0);

    function onScroll() {
        const el = docEl?.current || {};
        const footerHeight = footer?.current?.offsetHeight;
        if (el) {
            const { clientHeight, scrollHeight, scrollTop } = el;
            if (scrollHeight - scrollTop <= clientHeight + footerHeight) {
                if (el && el.offsetWidth <= 769) {
                    setBottomPosition(0);
                } else {
                    setBottomPosition("95px");
                }
            } else {
                setBottomPosition(0);
            }
        }
    }

    useEffect(() => {
        docEl.current = window.document.documentElement;
        footer.current = docEl?.current?.querySelector("footer");
        document.addEventListener("scroll", onScroll);
        document.addEventListener("resize", onScroll);

        return () => {
            document.removeEventListener("scroll", onScroll);
            document.removeEventListener("resize", onScroll);
        };
    }, []);
    return (
        <div
            className="absolute md:fixed lg:fixed rounded-tr-xl rounded-tl-xl py-3 px-2 lg:py-6 lg:px-4
                bg-black-300 bg-opacity-50 mx-auto left-0 right-0 w-max flex justify-center divide-x divide-primary"
            style={{ bottom: bottomPosition }}>
            <button
                type="button"
                className="flex items-center space-x-2 text-xxs sm:text-xs lg:text-sm px-2 lg:px-4 py-1 text-primary-100 text-center"
                onClick={showModal.bind(null, ADD_TO_WANT_LIST_MODAL)}>
                <Heart size={16} />

                <span className="font-semibold">Add To Want List</span>
            </button>
            <button
                type="button"
                className="flex items-center space-x-2 text-xxs sm:text-xs lg:text-sm px-2 lg:px-4 py-1 text-primary-100 text-center"
                onClick={showModal.bind(null, ADD_TO_HAVE_LIST_MODAL)}>
                <Plus size={16} />
                <span className="font-semibold">Add To Have List</span>
            </button>
            <button
                type="button"
                className="flex items-center space-x-2 text-xxs sm:text-xs lg:text-sm px-2 lg:px-4 py-1 text-primary-100 text-center"
                onClick={showModal.bind(null, REMOVE_MODAL)}>
                <Trash size={16} />
                <span className="font-semibold">Remove</span>
            </button>
        </div>
    );
};

const OrganizeStash = ({ history }) => {
    const userId = useContext(UserIdContext);
    const [selectedCards, setSelectedCards] = useState([]);
    const [mountModal, setMountModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [getStashedCardsAndList, { data }] = useLazyQuery(
        GET_STASHED_CARDS_WITH_LISTS
    );
    // const cards = data?.stashedCards;
    const [cards, setCards] = useState([]);
    const allCardIds = cards?.map((c) => c.id) || [];
    const isAllSelected = selectedCards.length === allCardIds.length;

    const haveListOptions = data?.haveLists?.map(({ id, name }) => ({
        id,
        name,
    }));
    const wantListOptions = data?.wantLists?.map(({ id, name }) => ({
        id,
        name,
    }));

    const selectedListOptions =
        modalType === ADD_TO_WANT_LIST_MODAL
            ? wantListOptions
            : haveListOptions;

    useEffect(() => {
        getStashedCardsAndList({
            variables: {
                userId,
            },
        });
        setCards(data?.stashedCards);
    }, [userId, data]);

    function addToList(id) {
        setSelectedCards(selectedCards.concat(id));
    }
    function removeFromList(id) {
        setSelectedCards(selectedCards.filter((c) => c !== id));
    }

    function toggleSelectAll() {
        if (isAllSelected) {
            setSelectedCards([]);
        } else {
            setSelectedCards(cards.map((c) => c.id));
        }
    }

    function closeModal() {
        setMountModal(false);
        setModalType("");
        setSelectedCards([]);
    }

    function showModal(type) {
        setMountModal(true);
        setModalType(type);
    }

    function goBack() {
        history.goBack();
    }

    if (!userId) {
        return <Redirect to="/" />;
    }

    const updateList = (card_ids) => {
        // console.log(cards);
        let list = cards.filter((c) => {
            return !card_ids.includes(c.id);
        });
        setCards(list);
    };

    return (
        <div
            className="min-h-screen p-8 animate__animated animate__fadeIn relative bg-fixed img-cover-basic"
            style={{ backgroundImage: `url(${BGLightsDarker})` }}>
            <div className="container mx-auto text-white mb-12 relative flex items-center">
                <button
                    onClick={goBack}
                    className="items-center flex-shrink-0 space-x-2 hidden lg:flex">
                    <ArrowLeft />
                    <span>Go Back</span>
                </button>
                <div className="text-center mx-auto">
                    <h1 className="text-2xl font-body font-semibold mb-2">
                        Organize your Stash
                    </h1>
                    <p>
                        Quickly add multiple cards to your have and want lists.
                    </p>
                </div>
                <button
                    type="button"
                    onClick={toggleSelectAll}
                    className="px-2 py-1 w-32 border border-white rounded text-white
                        hover:text-primary hover:border-primary self-center hidden lg:block">
                    {isAllSelected ? "Deselect all" : "Select all"}
                </button>
            </div>
            <div className="flex items-center justify-between">
                <button
                    onClick={goBack}
                    className="flex lg:hidden items-center text-white flex-shrink-0 space-x-2">
                    <ArrowLeft />
                    <span>Go Back</span>
                </button>
                <button
                    type="button"
                    onClick={toggleSelectAll}
                    className="px-2 py-1 w-32 border border-white rounded text-white
                        hover:text-primary hover:border-primary self-center block lg:hidden">
                    {isAllSelected ? "Deselect all" : "Select all"}
                </button>
            </div>
            <div className="container mx-auto flex flex-col my-4 lg:my-0 lg:grid grid-cols-9 gap-4">
                {cards?.map((card, i) => (
                    <StashedCard
                        card={card}
                        key={i}
                        selected={selectedCards.includes(card?.id)}
                        addToList={addToList}
                        removeFromList={removeFromList}
                        width="col-span-3"
                        updateList={updateList}
                    />
                ))}
            </div>
            <FixedBottomButtons showModal={showModal} />
            <ModalPortal id="modalPortalStash">
                {mountModal && (
                    <Modal
                        title={
                            modalType === REMOVE_MODAL
                                ? "Remove cards from stash"
                                : "Add cards to list"
                        }
                        closeModal={closeModal}>
                        {({ hideModal }) => (
                            <ModalBody
                                modalType={modalType}
                                selectedCards={selectedCards}
                                selectedListOptions={selectedListOptions}
                                hideModal={hideModal}
                                updateList={updateList}
                            />
                        )}
                    </Modal>
                )}
            </ModalPortal>
        </div>
    );
};

const ModalBody = ({
    hideModal,
    selectedCards,
    selectedListOptions,
    modalType,
    updateList = () => {},
}) => {
    const [selectedListId, setSelectedListId] = useState("");
    const ref = useRef(null);
    const isHaveList = modalType === ADD_TO_HAVE_LIST_MODAL;
    const isWantList = modalType === ADD_TO_WANT_LIST_MODAL;
    const isRemove = modalType === REMOVE_MODAL;
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);

    const [addToHaveList, haveRes] = useMutation(ADD_CARDS_TO_HAVE_LIST, {
        variables: {
            input: {
                cardIds: selectedCards,
                haveListId: selectedListId,
                removeFromStash: true,
            },
        },
        onCompleted: resetInput,
        update: (a, b) => updateRemoveFromStash(a, b, selectedCards),
    });
    const [addToWantList, wantRes] = useMutation(ADD_CARDS_TO_WANT_LIST, {
        variables: {
            input: {
                cardIds: selectedCards,
                wantListId: selectedListId,
                removeFromStash: true,
            },
        },
        onCompleted: resetInput,
        update: (a, b) => updateRemoveFromStash(a, b, selectedCards),
    });

    const [unstashCards, unstashRes] = useMutation(UNSTASH_CARD, {
        variables: {
            input: {
                cardIds: selectedCards,
            },
        },
        onCompleted: () => {
            setSelectedListId("");
            hideModal();
            updateList(selectedCards);
        },
        update: (a, b) => updateUnstash(a, b, selectedCards),
    });

    const handleSave = () => {
        if (isHaveList) {
            addToHaveList();
            updateList(selectedCards);
        } else if (isWantList) {
            addToWantList();
        } else {
            unstashCards();
        }
    };

    function resetInput({ addCardToList }) {
        const { success, errors } = addCardToList;
        setShowPopup(true);
        if (success) {
            setPopupAction(POPUP_TYPES.SUCCESS);
            setMessage("Card was added to list.");
            updateList(selectedCards);
        } else {
            setPopupAction(POPUP_TYPES.DANGER);
            setMessage(errors.join(", "));
        }
        // setMessage(payPopupMessage);
        setSelectedListId("");
        hideModal();
    }

    useEffect(() => {
        ref?.current?.focus();
    }, []);

    if (isRemove) {
        return (
            <div
                className="p-4 flex flex-col focus:outline-none"
                tabIndex={-1}
                ref={ref}>
                <p className="mb-3">
                    Are you sure you want to remove{" "}
                    <span className="font-semibold">
                        {selectedCards?.length} card
                        {selectedCards?.length !== 1 ? "s " : " "}
                    </span>
                    from your stash?
                </p>
                <button
                    disabled={unstashRes?.loading}
                    onClick={handleSave}
                    type="button"
                    className="rounded bg-red-500 hover:bg-red-600 text-white text-center w-full p-2">
                    Remove
                </button>
            </div>
        );
    }

    return (
        <div className="p-4 flex flex-col">
            <CardSelectionTextInfo
                len={selectedCards?.length}
                type={modalType}
            />

            <SelectList
                ref={ref}
                onChange={(e) => setSelectedListId(e.target.value)}
                value={selectedListId}
                options={selectedListOptions}
            />
            <button
                disabled={wantRes?.loading || haveRes?.loading}
                type="button"
                onClick={handleSave}
                className="rounded bg-primary hover:bg-primary-500 text-black font-semibold text-center w-full p-2">
                Save
            </button>
        </div>
    );
};

const CardSelectionTextInfo = ({ len, type }) => {
    const modalTypeText =
        type === ADD_TO_WANT_LIST_MODAL ? "want list" : "have list";

    return (
        <p className="mb-3">
            You are adding{" "}
            <span className="font-bold">
                {len} card{len !== 1 ? "s" : ""} to your {modalTypeText}.
            </span>{" "}
            Select list name to continue.
        </p>
    );
};

export default OrganizeStash;

import React, { createContext, useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { AlertCircle, CheckCircle, XCircle } from "react-feather";

export const POPUP_TYPES = {
    WARN: "warn",
    DANGER: "danger",
    SUCCESS: "success",
};

const PopupPortal = ({ children, id = "popupPortal" }) => {
    let ref = useRef(null);
    useEffect(() => {
        const portal = document.getElementById(id);
        if (!portal) {
            ref.current = document.createElement("div");
            ref.current.id = id;
            document.body.append(ref.current);
        } else {
            ref.current = portal;
        }

        return () => {
            if (ref.current && ref.current.parent === document.body)
                document.body.removeChild(ref.current);
        };
    }, []);

    if (!ref.current) {
        return null;
    }

    return createPortal(children, ref.current);
};

const Popup = ({ hidePopup, type, children }) => {
    const timeout = useRef(null);
    useEffect(() => {
        timeout.current = setTimeout(hidePopup, 7000);

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, []);

    function handleClick() {
        timeout?.current && clearTimeout(timeout.current);
        hidePopup();
    }

    if (!Object.values(POPUP_TYPES).includes(type)) {
        return null;
    }

    const cx = [
        "fixed bg-opacity-90 shadow-md",
        "flex items-center bottom-12 left-8 p-2",
        "rounded w-2/3 md:w-1/3 max-w-fit min-w-min text-sm animate__faster animate__animated animate__fadeInUp",
        "bg-white text-black",
    ].join(" ");

    return (
        <button onClick={handleClick} className={cx} style={{ zIndex: 1000 }}>
            {type === POPUP_TYPES.WARN && (
                <span className="text-yellow-600">
                    <AlertCircle size={16} />
                </span>
            )}
            {type === POPUP_TYPES.SUCCESS && (
                <span className="text-green-500">
                    <CheckCircle size={16} />
                </span>
            )}
            {type === POPUP_TYPES.DANGER && (
                <span className="text-red-500">
                    <XCircle size={16} />
                </span>
            )}
            <span className="ml-3">{children}</span>
        </button>
    );
};

const PopupContext = createContext({
    showPopup: false,
    popupAction: null,
    message: "",
    setShowPopup: () => {},
    setPopupAction: () => {},
    setMessage: () => {},
});

const PopupContextProvider = ({ children }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupAction, setPopupAction] = useState(null);
    const [message, setMessage] = useState("");

    const value = {
        showPopup,
        setShowPopup,
        popupAction,
        setPopupAction,
        message,
        setMessage,
    };

    function hidePopup() {
        setShowPopup(false);
        setMessage("");
        setPopupAction(null);
    }

    return (
        <PopupContext.Provider value={value}>
            {children}
            <PopupPortal>
                {showPopup && (
                    <Popup hidePopup={hidePopup} type={popupAction}>
                        {message}
                    </Popup>
                )}
            </PopupPortal>
        </PopupContext.Provider>
    );
};

export { PopupContextProvider, PopupContext };

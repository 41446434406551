import Pikaday from "pikaday";

document.addEventListener('DOMContentLoaded', function () {
    var startDate,
    endDate,
    updateStartDate = function() {
        startPicker.setStartRange(startDate);
        endPicker.setStartRange(startDate);
        endPicker.setMinDate(startDate);
    },
    updateEndDate = function() {
        startPicker.setEndRange(endDate);
        startPicker.setMaxDate(endDate);
        endPicker.setEndRange(endDate);
    },
    startPicker = new Pikaday({
        field: document.getElementById('filter_start_date'),
        minDate: new Date(),
        maxDate: new Date(2020, 12, 31),
        onSelect: function() {
            startDate = this.getDate();
            updateStartDate();
        }
    }),
    endPicker = new Pikaday({
        field: document.getElementById('filter_end_date'),
        minDate: new Date(),
        maxDate: new Date(2020, 12, 31),
        onSelect: function() {
            endDate = this.getDate();
            updateEndDate();
        }
    }),
    _startDate = startPicker.getDate(),
    _endDate = endPicker.getDate();

    if (_startDate) {
        startDate = _startDate;
        updateStartDate();
    }

    if (_endDate) {
        endDate = _endDate;
        updateEndDate();
    }

    function filterDate(){
        var startDate = dateToString(startPicker.getDate()),
            endDate = dateToString(endPicker.getDate()),
            url = new URL(window.location);

        url = addUrlParam(url, "start_date", startDate);
        url = addUrlParam(url, "end_date", endDate);

        window.location = url;
    }

    function addUrlParam(url, key, value){
        if(url.searchParams.has(key)){
            url.searchParams.set(key, value);
        }
        else {
            url.searchParams.append(key, value);
        }

        return url;
    }

    function dateToString(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const filterBtn = document.getElementById('js--dateFilterBtn');
    if(filterBtn) filterBtn.addEventListener('click', filterDate);
});
import { useQuery, gql } from "@apollo/client";
import React, { Fragment, useContext, useEffect, useState } from "react";
import BgLights from "bg-lights-darker.png";
import { SEARCH_LISTINGS } from "../../queries";
import YellowArrowRight from "icon-golden-arrow-forward.svg";
import MarketCardList from "../browse_listings/card_market_list";
import { useParams, NavLink, useLocation, Redirect } from "react-router-dom";
import Reviews from "./profile_reviews";
import MainBreadcrumbs from "../main_breadcrumbs";
import StarRating from "../../star_rating";
import useFilter from "../../filter/use_filter";
import { MARKETPLACE_SORT_OPTIONS } from "../../sort_options";
import useFilterOptions from "../../hooks/useFilterOptions";
import FilterOverlay from "../../filter/filter_overlay";
import NavigationTabs from "../../seller_center/shared_components/navigation_tabs";
import FilterButton from "../../filter_button";
import HelpText from "../../utils/help_text";
import PageInfo from "../../seller_center/shared_components/page_info";
// import { SUPPORT_MAIL } from "../../utils/constants";
// import mailTo from "../../utils/mail_to";
import SpinnerLoader from "../../spinner_loader";
import PageVariablesContext from "../../page_variables_context";

const SidePanel = ({
    id,
    username,
    score,
    rank,
    totalSales,
    reviewCount,
    averageRating,
    positiveFeedback,
    sellerCountryName,
}) => {
    return (
        <div className="text-white-400 text-gray-1000 rounded-md bg-gray-800 shadow w-100 p-6">
            <div className="flex flex-col grid border-b-2 pb-6 border-gray-200 border-opacity-50">
                <p className="text-2xl font-semibold break-all">{username}</p>
                {sellerCountryName ? (
                    <HelpText
                        sideText={sellerCountryName}
                        sideTextClass="text-xs"
                        iconColor="text-inherit"
                        icon="location"
                        size={12}
                        showTextOnHover={false}
                    />
                ) : null}
                <div className="flex items-center justify-between mt-4">
                    <span className="text-xs lg:text-xs uppercase bg-gray-575 px-2 lg:px-2 py-1 rounded">
                        Card Score {score}
                    </span>
                    {rank && (
                        <span className="text-xs text-white-400">
                            Rank #{rank}
                        </span>
                    )}
                </div>
            </div>
            <div className="py-2">
                <div className="grid grid-cols-2 divide divide-x">
                    <div className="p-4 pl-0 flex flex-col justify-center items-center">
                        <div className="flex space-x-2 items-center mb-2">
                            <StarRating
                                value={averageRating}
                                customClass="text-primary"
                                iconSize={10}
                                readOnly
                            />
                            <div className="text-xs text-primary mx-auto text-center">
                                [{reviewCount}]
                            </div>
                        </div>
                        {Boolean(reviewCount) ? (
                            <p className="text-white-400 text-center text-xs">
                                {positiveFeedback}% positive feedback
                            </p>
                        ) : null}
                    </div>
                    <div className="p-4 pr-0 grid space-y-2 text-center">
                        <div className="text-2xl font-semibold text-gray-1000">
                            {totalSales}
                        </div>
                        <div className="text-xs text-white-400">
                            Total Sales
                        </div>
                    </div>
                </div>
            </div>
            <a href={`/trophy_room/${id}`}>
                <div className="pt-4 px-1 border-t-2 border-gray-200 border-opacity-50 flex space-x-2 items-center cursor-pointer">
                    <span className="text-sm">View trophy room</span>
                    <img
                        src={YellowArrowRight}
                        alt="Arrow right icon"
                        className="w-4 h-4"
                    />
                </div>
            </a>
        </div>
    );
};

const Listings = ({ sellerId }) => {
    const { userId } = useContext(PageVariablesContext);

    const { _search, initialPage } = useFilterOptions();
    const [searchString] = _search;
    const DEFAULT_VARIABLES = {
        searchString: searchString || "*",
        userId: sellerId,
        options: {
            page: Number(initialPage),
            status: "active",
            sortCategory: "added",
            sortOrder: "desc",
            itemsPerPage: 30,
        },
    };

    const { SearchField, ResultInfo, SelectedFilters, Filters, Pagination, results, loading } =
        useFilter(SEARCH_LISTINGS, DEFAULT_VARIABLES, MARKETPLACE_SORT_OPTIONS);

    const listings = results?.cards || [];
    const hasListings = Boolean(listings?.length);
    const [displayFilter, setDisplayFilter] = useState(false);

    return (
        <Fragment>
            <FilterOverlay displayFilter={displayFilter}>
                <Filters
                    visible={true}
                    setVisibility={setDisplayFilter}
                    overlay
                />
            </FilterOverlay>
            {!loading && hasListings ? (
                <>
                    <div className="flex flex-col space-y-3 md:flex-row items-center justify-between py-4">
                        <div className="flex flex-col text-white-400 font-body text-sm">
                            {searchString && searchString !== "*" && (
                                <ResultInfo />
                            )}
                            <SelectedFilters />
                        </div>
                        <div className="flex items-center space-x-3 w-full md:w-1/3">
                            <SearchField isFull shortPlaceHolder darkMode />
                            <FilterButton onClick={() => setDisplayFilter(!displayFilter)}
                                        lgHidden={false}/>
                        </div>
                    </div>
                    <MarketCardList
                        listings={listings}
                        numTruncatedCardName={30}
                        showCartIcon
                        isMarketPlace
                        sellerProfileList={userId === sellerId}
                        loading={loading}
                        withTeamNames
                    />
                    <Pagination />
                </>
            ) : null}
            {loading ? (
                <SpinnerLoader />
            ) : null}
            {!loading && !hasListings ? (
                <PageInfo type="noCardsListed" />
            ) : null}
        </Fragment>
    );
};

const GET_SELLER_BY_USERNAME = gql`
    query getUserByUsername($username: String!) {
        seller: getSellerByUsername(username: $username) {
            id
            username
            score
            rank
            totalSales
            sellerCountryName
            isSellingAllowed
            purchaseSellerReviews {
                rating
                title
                content
                dateReviewed
                user {
                    username
                }
            }
        }
    }
`;

const getPositiveFeedback = (reviews) => {
    const positiveReviews = reviews?.filter((i) => i.rating >= 3).length;
    return (positiveReviews / reviews?.length) * 100;
};

const SellerProfile = () => {
    const { username } = useParams();

    const { data, loading } = useQuery(GET_SELLER_BY_USERNAME, {
        fetchPolicy: "cache-first",
        variables: {
            username: username,
        },
    });
    const seller = data?.seller;
    const sellerId = seller?.id;
    const isSellingAllowed = seller?.isSellingAllowed;
    const reviews = seller?.purchaseSellerReviews;

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const tab = queryParams.get("tab");

    const reviewCount = reviews?.length;
    const averageRating = Math.round(
        reviews?.reduce((ave, item) => ave + item.rating, 0) / reviewCount
    );
    const positiveFeedback = getPositiveFeedback(reviews);
    const navItems = ["Listings", "Reviews"];
    const [selectedTab, setSelectedTab] = useState(tab || "listings")
    useEffect(() => {
        <Redirect to={`/marketplace/s/${username}?tab=${selectedTab.toLowerCase()}`} />
    }, [navItems])
    return (
        <div
            className="animate__animated animate__fadeIn bg-cover bg-center bg-fixed bg-no-repeat min-h-screen"
            style={{ backgroundImage: `url(${BgLights})` }}>
                {sellerId && !loading && isSellingAllowed ? (
                    <>
                        <MainBreadcrumbs>
                            {sellerId && (
                                <NavLink
                                    to="#"
                                    activeClassName="font-semibold"
                                    className="text-sm text-primary-900">
                                    {seller.username}
                                </NavLink>
                            )}
                        </MainBreadcrumbs>
                        <div className="px-4 py-10 merqary-container mx-auto grid grid-cols-12 gap-6">
                            <div className="col-span-12 md:col-span-3">
                                <SidePanel
                                    {...seller}
                                    reviewCount={reviewCount}
                                    averageRating={averageRating}
                                    positiveFeedback={positiveFeedback}
                                />
                            </div>
                            <div className="col-span-full md:col-span-9">
                                <NavigationTabs
                                    tabs={navItems}
                                    selectedTab={selectedTab}
                                    setSelectedTab={setSelectedTab}
                                    customClass="pt-0"
                                >
                                    {/* <HelpText
                                        sideText="Contact support"
                                        sideTextClass="text-white font-semibold"
                                        icon="message"
                                        size="w-4 h-4"
                                        showTextOnHover={false}
                                        onClick={() => mailTo(SUPPORT_MAIL)}
                                    /> */}
                                </NavigationTabs>
                                {reviews && selectedTab === "reviews" && (
                                    <Reviews
                                        items={reviews}
                                        reviewCount={reviewCount}
                                        averageRating={averageRating}
                                        positiveFeedback={positiveFeedback}
                                    />
                                )}
                                {selectedTab === "listings" && <Listings sellerId={sellerId} />}
                            </div>
                        </div>
                    </>
                ) : null}
                {(!sellerId || !isSellingAllowed) && !loading ? (
                    <PageInfo type="sellerNotFound" />
                ) : null}
        </div>
    );
};

export default SellerProfile;

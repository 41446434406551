import React, { Fragment, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import BgLightsDarker from "bg-lights-darker.png";
import PageTitle from "../../seller_center/create_listing/page_title";
import CardImageContainer from "../../seller_center/create_listing/card_image_container";
import CardDetails from "../../seller_center/create_listing/card_details";
import ListingInformation from "../../marketplace/listing_preview/listing_information";
import ListingPreviewBody from "../../marketplace/listing_preview/listing_preview_body";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { PopupContext } from "../../popup_context";
import OtherListings from "../../marketplace/listing_preview/other_listings";
import Button from "../shared_components/button";
import { Form, Formik } from "formik";
import * as yup from "yup";
import PageInfo from "../shared_components/page_info";
import PageVariablesContext from "../../page_variables_context";

const GET_MARKET_LISTING = gql`
    query GetMarketListing($id: ID!, $userId: ID) {
        listing: getMarketListing(id: $id, userId: $userId) {
            id
            marketListingId: id
            price
            currency
            dealFormat
            alterable
            condition
            status
            userId
            details
            locationId
            location {
                id
                name
            }
            card {
                id
                cardId: id
                cardName
                userWantCount
                playersJson
                printRun
                sportName
                cardNumber
                startYear
                endYear
                manufacturerName: nameManufacturer
                productName: nameProduct
                cardSetName: nameCardSet
                isBase
                hasJersey
                hasPatch
                hasAutograph
                isRookie
                isVerified
                hasInsert
                hasLogoman
            }
            user {
                id
                username
                fulfillmentOptions {
                    id
                    fulfillmentType
                    description
                    isActive
                }
            }
            ownedCard {
                id
                ownedCardId: id
                currentOwnerId
                currentOwnerUsername
                printRunOrdinality
                graderName
                grade
                description
                countryName
                userWantCount
                frontImage
                backImage
            }
        }
    }
`;

const UPDATE_LISTING = gql`
    mutation UpdateMarketListing($input: UpdateMarketListingInput!) {
        updateMarketListing(input: $input) {
            result
            marketListing {
                id
            }
            errors
        }
    }
`;

const DraftPreview = () => {
    const { id } = useParams();
    const {
        userId,
        isSellingAllowed,
    } = useContext(PageVariablesContext);
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);
    const history = useHistory();
    const [getListing, { data, loading, called }] = useLazyQuery(
        GET_MARKET_LISTING,
        {
            variables: { id, userId },
            fetchPolicy: "cache-and-network",
        }
    );

    const [updateListing] = useMutation(UPDATE_LISTING, {
        onCompleted: (data) => handleUpdateListingSuccess(data),
        onError: () => {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage("Something went wrong.");
        },
    });

    const handleUpdateListingSuccess = (data) => {
        const result = data.updateMarketListing;
        if (result.result) {
            setShowPopup(true);
            setPopupAction("success");
            setMessage("Market listing updated");
            history.push(`/seller_center/my_listings/${id}`);
        } else {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage(result.errors.join(", "));
        }
    };

    const pageClass = [
        "px-8 py-8 md:px-18 md:py-12 lg:px-20 xl:px-32 2xl:px-60",
        "animate__animated animate__fadeIn",
        "bg-fixed bg-cover bg-center bg-no-repeat",
        "min-h-screen",
        "text-gray-1000",
    ].join(" ");

    useEffect(() => {
        if (!called) {
            getListing();
        }
    }, []);

    const listing = data?.listing;
    const card = listing?.card;
    const ownedCard = listing?.ownedCard;
    const fulfillmentOptions = listing?.user?.fulfillmentOptions;
    const ownedByCurrentUser = listing?.userId === userId;
    const archived = ["sold", "completed"].includes(listing?.status);

    const submitText =
        listing?.status === "draft" ? "Publish Listing" : "Update Listing";

    const listingSchema = yup.object().shape({
        ownedCardId: yup.string().required(),
        condition: yup.string().required("Select card condition"),
        details: yup.string().nullable(),
        price: yup
            .number()
            .min(1, "Input listing price")
            .max(99999999, `Price max is at ${99999999}`)
            .required("Input listing price"),
        dealFormat: yup.string().required("Select a deal format"),
        currency: yup.string().required("select a currency"),
        userId: yup.string().required(),
        location: yup.string().required("Input location"),
        status: yup.string(),
    });

    const initialValues = {
        id: listing?.marketListingId,
        ownedCardId: listing?.ownedCard.ownedCardId,
        condition: listing?.condition,
        price: listing?.price,
        dealFormat: listing?.dealFormat,
        currency: listing?.currency,
        location: listing?.locationId,
        status: listing?.status,
        userId: listing?.userId,
        details: listing?.details,
    };

    const handleFormSubmit = ({ ...values }) => {
        const variables = {
            id: values.id,
            listing: {
                ...(({ id, ...v }) => v)(values),
                price: Number(values.price),
            },
        };

        if (!listing.alterable) {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage("Listing with transaction cannot be updated!");
            return;
        }

        updateListing({
            variables: {
                input: variables,
            },
        });
    };

    return (
        <div
            className={pageClass}
            style={{ backgroundImage: `url(${BgLightsDarker})` }}>
                {isSellingAllowed ? (
                    <Fragment>
                        {card && listing && !loading && (
                            <div className="merqary-container-sm px-4 md:px-12 bg-gray-800 rounded mx-auto">
                                <PageTitle
                                    title="Listing Preview"
                                    contentText={
                                        archived
                                            ? ""
                                            : `Review the details of your listing and click "Publish listing" when you are ready.`
                                    }>
                                    {!archived && (
                                        <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row md:space-x-3 items-center">
                                            <Button
                                                text="Edit Listing"
                                                url={`/seller_center/my_listings/${id}`}
                                                secondary
                                                type="button"
                                            />
                                            <Formik
                                                onSubmit={
                                                    (values) => handleFormSubmit({
                                                                    ...values,
                                                                    status: "active",
                                                                })
                                                }
                                                // onError={(e) => console.log(e)}
                                                validationSchema={listingSchema}
                                                initialValues={initialValues}>
                                                {(form, errors) => (
                                                    <Form>
                                                        <Button
                                                            text={submitText}
                                                            primary
                                                            onClick={
                                                                () => form.validateForm().then(
                                                                    (e) => {
                                                                        if (Object.keys(e).length === 0)
                                                                            form.submitForm();
                                                                        else {
                                                                            setShowPopup(true);
                                                                            setPopupAction("warn");
                                                                            setMessage("Please update required fields on your listing");
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    )}
                                </PageTitle>
                                <div className="grid grid-cols-10 gap-0 py-8 space-y-6 lg:space-y-0">
                                    <div className="col-span-full lg:col-span-3 flex justify-center lg:justify-start">
                                        <CardImageContainer
                                            {...ownedCard}
                                            showZoom
                                            removeAutoMargin
                                            width="w-2/3 lg:w-3/4"
                                        />
                                    </div>
                                    <div className="space-y-8 px-4 col-span-full lg:col-span-7">
                                        <CardDetails
                                            isPreview
                                            {...card}
                                            {...listing}
                                            ownedByCurrentUser={ownedByCurrentUser}
                                            draftPreview
                                        />
                                        <ListingInformation
                                            draftPreview
                                            {...card}
                                            {...listing}
                                            {...(ownedCard && ownedCard)}
                                            shippingOptions={fulfillmentOptions}
                                            ownedByCurrentUser={ownedByCurrentUser}
                                        />
                                        <ListingPreviewBody
                                            card={card}
                                            ownedCard={ownedCard}
                                            listing={listing}
                                            draftPreview={true}
                                        />
                                        <OtherListings />
                                    </div>
                                </div>
                            </div>
                        )}
                        {!card && !loading && called && (
                            <div className="flex justify-center w-full">
                                <div className="text-4xl text-white">Card not found</div>
                            </div>
                        )}
                    </Fragment>
                ) : (
                    <PageInfo type="sellingNotAllowed" />
                )}
        </div>
    );
};

export default DraftPreview;

import React, { Fragment, useContext, useRef, useState } from "react";
import {
    Check,
    Heart,
    Loader,
    MoreVertical,
    Plus,
    ChevronDown,
} from "react-feather";
import { useMutation } from "@apollo/client";
import UserIdContext from "./user_id_context";
import {
    DELETE_CARD_FROM_HAVE_LIST,
    DELETE_CARD_FROM_WANT_LIST,
    STASH_CARD,
    UNSTASH_CARD,
    updateRemoveCardFromList,
    updateStash,
    updateUnstash,
} from "../mutations";
import CardImage from "../card_image";
import Badge from "../badge";
import { VariablesContext } from "../variables_context";
import IconVaulted from "icon-card-V.svg";
import IconMarketPlace from "icon-card-M.svg";
import IconHaveList from "icon-card-H.svg";
import IconGoldenEdit from "icon-golden-edit.svg";
import IconGoldenView from "icon-golden-view.svg";
import { PopupContext, POPUP_TYPES } from "../popup_context";
import { ConfirmationContext } from "../confirmation_context"
import IsMarketplaceBetaUserContext from "../is_marketplace_beta_user_context";
import formatCardPlayers from "../utils/format_card_players";

const StashCheckbox = ({ name = "", id, loading, handleChange, isStashed }) => {
    const labelClassname = [
        "block h-4 w-4 text-black",
        "rounded border-px flex items-center justify-center border-gray-400",
        "hover:bg-primary",
        isStashed ? "bg-primary text-black" : "bg-gray-200 text-transparent",
    ].join(" ");
    return (
        <div className="flex justify-center absolute lg:top-2 lg:right-2 pt-2 pr-2">
            <input
                id={`${name}-stash-${id}`}
                className="hidden"
                type="checkbox"
                disabled={loading}
                onChange={handleChange}
                checked={isStashed}
            />
            {loading && (
                <span className="flex text-primary items-center justify-center block h-6 w-6 animate__animated animate__infinited animate__flash">
                    <Loader size={24} />
                </span>
            )}

            {!loading && (
                <label
                    htmlFor={`${name}-stash-${id}`}
                    className="StashCheckbox flex items-center cursor-pointer text-sm lg:text-xs"
                    style={{ transition: "all 0.3s ease-in-out" }}>
                    <span className={labelClassname}>
                        <Check className="w-2 h-2 lg:w-3 lg:h-3" />
                    </span>
                    <span className="ml-2 text-white text-fs10 lg:text-xs">
                        Add to stash
                    </span>
                </label>
            )}
        </div>
    );
};

const PlayerCard = ({
    cardName,
    frontImage,
    backImage,
    canStash,
    isStashed,
    isVaulted,
    id: cardId,
    isRow,
    isFeatured,
    addToHaveList,
    addToWantList,
    editCardModal,
    cardId: parentCardId,
    canEdit,
    printRun,
    printRunOrdinality,
    isPublished,
    playersJson,
    listId = null,
    isHaveList,
    isWantList,
    canAddToList,
    isForSale,
    isSold,
    isMarketListable,
    userHaveCardId,
    refreshList,
}) => {
    const confirm = useContext(ConfirmationContext);
    const userId = useContext(UserIdContext);
    const isMarketplaceBetaUser = useContext(IsMarketplaceBetaUserContext);
    const r = useContext(VariablesContext);
    const { variables, isTrophyRoom } = r;
    const [showOtherMenu, setShowOtherMenu] = useState(false);
    const [flipped, setFlipped] = useState(false);
    const ref = useRef(null);
    const [stashCard, { loading: stashLoading }] = useMutation(STASH_CARD, {
        update: updateStash,
    });
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);

    const [unstashCard, { loading: unstashLoading }] = useMutation(
        UNSTASH_CARD,
        {
            update: (a, b) => updateUnstash(a, b, cardId),
        }
    );

    const [removeCardFromHave] = useMutation(DELETE_CARD_FROM_HAVE_LIST, {
        onCompleted: ({ removeCardFromList }) => {
            if (removeCardFromList?.result) {
                setShowPopup(false);
                setShowPopup(true);
                setPopupAction(POPUP_TYPES.SUCCESS);
                setMessage("Card was deleted.");
                setShowOtherMenu(false);
                refreshList();
            } else {
                setShowPopup(false);
                setShowPopup(true);
                setPopupAction(POPUP_TYPES.WARN);
                setMessage(removeCardFromList?.errors.join(", "));
            }
        },
        onError: (res) => console.log(res),
        update: (c, res) =>
            updateRemoveCardFromList(c, res, {
                ...variables,
                haveCardIds: [userHaveCardId],
                isHaveList,
            }),
    });

    const [removeCardFromWant] = useMutation(DELETE_CARD_FROM_WANT_LIST, {
        onCompleted: ({ removeCardFromList }) => {
            if (removeCardFromList?.result) {
                setShowOtherMenu(false);
            }
        },
        onError: (res) => console.log(res),
        update: (c, res) =>
            updateRemoveCardFromList(c, res, {
                ...variables,
                cardIds: [cardId],
                isHaveList,
            }),
    });

    const cardClassName = [
        "focus:outline-none",
        "trophyRoom-card",
        "relative",
        "mb-4 flex-shrink-0 flex-grow-0",
        isHaveList || isTrophyRoom ? "mt-0 lg:pt-130" : "mt-9",
        userId ? "" : "!mt-0",
        "md:mt-12 lg:mt-0",
        "border-8 border-white rounded-md",
        flipped ? "flip" : "",
        isRow
            ? "w-32 h-44 lg:w-36 lg:h-48 mr-0 lg:mr-8"
            : "mx-auto w-4/5 lg:w-full pt-100",
    ].join(" ");

    const cardContainerClassName = [
        "PlayerCard--container text-white",
        "py-4 px-0 lg:p-4 rounded-md bg-gray-800 hover:bg-gray-600",
        "shadow hover:shadow-md border flex-shrink-0",
        userId ? "" : "items-center lg:items-start",
        isHaveList ? "" : "flex flex-col lg:flex-row",
        isTrophyRoom ? "flex lg:flex-col" : "",
        isStashed ? "border-primary" : "border-gray-700",
        isRow
            ? "col-span-full flex items-center"
            : "col-span-full sm:col-span-6 lg:col-span-4 xl:col-span-3 flex flex-col items-stretch",
        isFeatured ? "md:col-span-6" : "",
        canAddToList ? "" : "items-start",
        "animate__animated animate__fadeIn",
    ].join(" ");

    const handleChange = (e) => {
        if (!isStashed) {
            stashCard({
                variables: {
                    input: {
                        userId,
                        cardId,
                    },
                },
            });
        } else {
            unstashCard({
                variables: {
                    input: {
                        cardIds: [cardId],
                    },
                },
            });
        }
    };

    const displayCardPath = parentCardId
        ? `/browse_cards/preview/${parentCardId}/vaulted/${cardId}`
        : `/browse_cards/preview/${cardId}`;

    function deleteCardFromList(e) {
        e.preventDefault();

        if (isHaveList) {
            removeCardFromHave({
                variables: {
                    input: {
                        haveCardIds: [userHaveCardId],
                        haveListId: listId,
                    },
                },
            });
        } else {
            removeCardFromWant({
                variables: {
                    input: {
                        cardIds: [cardId],
                        wantListId: listId,
                    },
                },
            });
        }
    }

    function handleClickOther(e) {
        function handleOutsideClick(ev) {
            if (!ref?.current?.contains(ev.target)) {
                setShowOtherMenu(false);
                removeOutsideClick();
            }
        }

        function removeOutsideClick() {
            document.removeEventListener("click", handleOutsideClick);
        }
        document.addEventListener("click", handleOutsideClick);
        setShowOtherMenu(true);
    }

    return (
        <div className={cardContainerClassName}>
            <a className={cardClassName}
                href={displayCardPath}>
                <CardImage
                    isFront
                    image={frontImage}
                    hideMoreText={isRow}
                />
                <CardImage image={backImage} hideMoreText={isRow} />
            </a>
            {canStash && (
                <StashCheckbox
                    id={cardId}
                    loading={stashLoading || unstashLoading}
                    handleChange={handleChange}
                    isStashed={isStashed}
                />
            )}
            {!isRow && (
                <div className="w-full flex items-center mb-4 px-4 lg:px-0">
                    <div className="flex items-center">
                        {canEdit && (
                            <Fragment>
                                {!isPublished && (
                                    <div className="flex items-center text-white bg-gray-575 px-1 py-1 uppercase text-xxs lg:text-xs font-semibold">
                                        <span>Drafts</span>
                                        <span className="ml-1">-</span>
                                    </div>
                                )}
                                <ListingLabel isForSale={isForSale}
                                              isSold={isSold}
                                              isPublished={isPublished} />
                                <OwnedCardLabel isVaulted={isVaulted}
                                                isPublished={isPublished}
                                                isForSale={isForSale}
                                                isSold={isSold} />
                                {isPublished && !isForSale && !isSold ? (
                                    <CardIcons cardId={cardId}
                                               isVaulted={isVaulted}
                                               isPublished={isPublished}
                                               isForSale={isForSale}/>
                                ) : null}
                            </Fragment>
                        )}
                        {/* must be showed on browse_cards but not on trophy room
                        !! re add condition on browse cards query if card is currently on want list
                        !! replace with image asset (current is broken when in safari) */}
                        {!isHaveList && !isWantList ?  (
                            <Badge outline long text="Want List">
                                <Heart className="" height={12} />
                                <span>Want List</span>
                            </Badge>
                        ) : null}
                    </div>

                    <button
                        type="button"
                        className="text-white text-xxs lg:text-xs uppercase pl-2 ml-auto outline-none focus:outline-none"
                        onClick={() => setFlipped(!flipped)}>
                        Flip
                    </button>
                </div>
            )}

            <div className={`flex flex-col w-full 2xl:w-5/6 ${isRow ? "" : "h-full"}`}>
                <p
                    className={`flex w-full flex-col font-body space-y-2 mb-2 items-start px-4 lg:px-0 pb-2 ${
                        userId ? "" : "!pb-0"
                    } ${isRow ? "pt-0 lg:pt-6" : ""}`}>
                    <span
                        href={displayCardPath}
                        className="font-semibold text-xs lg:text-sm pr-2 merqary-cutoff">
                        {cardName}
                    </span>
                    <span className="flex flex-col md:flex-row flex-wrap mt-2 w-full lg:mt-0">
                        <span className="w-6/7 lg:w-5/6 text-fs10 md:truncate lg:text-sm merqary-truncate two-lines">
                            {formatCardPlayers(playersJson, ["names"])}
                        </span>
                        <span className="w-6/7 lg:w-5/6 text-fs10 md:truncate md:ml-2 lg:ml-0 lg:text-sm merqary-truncate two-lines">
                            {formatCardPlayers(playersJson, ["teams"], false)}
                        </span>
                    </span>
                    {printRun && (
                        <span className="w-full truncate text-fs10 lg:text-sm">
                            #{printRunOrdinality}/{printRun}
                        </span>
                    )}
                </p>


                {/* !!! Browse cards section */}
                {canAddToList ? (
                    isRow ? (
                        <Fragment>
                            <div className="py-4 px-4 md:px-0">
                                <ListingLabel isForSale={isForSale}
                                            isSold={isSold}
                                            isPublished={isPublished} />
                                <CardIcons cardId={cardId}
                                            isVaulted={isVaulted}
                                            isPublished={isPublished}
                                            isForSale={isForSale}/>
                            </div>
                            <div
                                className="flex justify-center lg:justify-start items-center w-full pb-2
                                lg:pb-8 pt-4 divide-x text-xs border-t border-gray-400 overflow-hidden">
                                <button
                                    className="pr-4 flex items-center shrink-0 text-sm lg:text-xs"
                                    onClick={addToHaveList}>
                                    <Plus className="h-1.5 lg:h-2.5" />
                                    <span className="text-fs10 w-max lg:text-sm">
                                        Have List
                                    </span>
                                </button>

                                <button
                                    className="pl-2 pr-4 flex items-center shrink-0 text-sm lg:text-xs"
                                    onClick={addToWantList}>
                                    <Heart className="h-1.5 lg:h-2.5" />
                                    <span className="text-fs10 w-max lg:text-sm">
                                        Want List
                                    </span>
                                </button>
                                <div className="relative hidden">
                                    <button
                                        type="button"
                                        ref={ref}
                                        onClick={handleClickOther}
                                        className="px-4 text-white hover:text-primary focus:ring-0 ring-0 flex items-center text-sm">
                                        <span className="ml-1 text-xs">
                                            More
                                        </span>
                                    </button>
                                    {showOtherMenu && (
                                        <div
                                            className="p-3 absolute bottom-6 right-0 text-black rounded bg-white text-sm"
                                            style={{ width: "160px" }}>
                                            <button>
                                                <span className="text-sm">
                                                    View on Marketplace
                                                </span>
                                            </button>
                                            <button className="flex items-center">
                                                {/* <Share2 height={12} /> */}
                                                <span className="text-sm">
                                                    Share
                                                </span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Fragment>
                    ) : null
                ) : null}
            </div>
            {!isRow && (
                <div className="mt-0 lg:mt-2 px-4 lg:px-0 pt-3 border-t w-full flex items-center relative">
                    <a
                        href={displayCardPath}
                        title="View Card"
                        className="text-white hover:text-primary flex items-center text-sm mr-3">
                        <img src={IconGoldenView} className="w-3 h-3" />
                        <span className="ml-1 text-xxs lg:text-xs">View</span>
                    </a>
                    {canEdit && (
                        <>
                            <button
                                type="button"
                                className="hidden lg:flex text-white hover:text-primary items-center text-sm mr-3"
                                onClick={editCardModal}>
                                <img src={IconGoldenEdit} className="w-3 h-3" />
                                <span className="ml-1 text-xxs lg:text-xs">
                                    Edit
                                </span>
                            </button>

                            <button
                                type="button"
                                ref={ref}
                                onClick={handleClickOther}
                                className="text-white hover:text-primary flex items-center text-sm ml-auto">
                                <span className="mr-1 text-xxs lg:text-xs">
                                    More
                                </span>
                                <ChevronDown
                                    size={14}
                                    className="text-primary block lg:hidden"
                                />
                                <MoreVertical
                                    size={14}
                                    className="text-primary hidden lg:block"
                                />
                            </button>
                            {showOtherMenu && (
                                <div className="absolute bottom-6 right-0 p-2 text-black rounded bg-white text-sm">
                                    <button
                                        onClick={(e) => {
                                            if (isHaveList) {
                                                confirm({
                                                    title: "Delete Card",
                                                    text: `You are about to delete ${cardName}.
                                                    Deleting this card would also remove all
                                                    market listing if any. Proceed?`,
                                                    confirmText: "Yes, I understand",
                                                    onConfirm: () => deleteCardFromList(e)
                                                })
                                            }
                                            if (!isHaveList) deleteCardFromList(e);
                                        }}
                                        className="text-xxs lg:text-xs">
                                        Remove from List
                                    </button>
                                    <button
                                        onClick={editCardModal}
                                        className="block text-xxs lg:hidden">
                                        Edit
                                    </button>
                                    {isMarketplaceBetaUser && isMarketListable ? (
                                        <a
                                            href={`/seller_center/create_listing/${cardId}`}
                                            className="block text-xxs lg:text-xs">
                                            Create Listing
                                        </a>
                                    ) : null}
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

const ListingLabel = ({
    isForSale,
    isSold,
    isPublished,
}) => {
    const isMarketplaceBetaUser = useContext(IsMarketplaceBetaUserContext);
    if (!isMarketplaceBetaUser) return null;
    if ((!isForSale && !isSold) || !isPublished) return null;

    let label = isForSale ? "For Sale" : "";
    if (isSold) label = "Sold";

    return (
        <span className="rounded bg-primary text-white font-semibold p-1 text-xs uppercase">
            {label}
        </span>
    );
};

const OwnedCardLabel = ({
    isVaulted,
    isPublished,
    isForSale,
    isSold,
}) => {
    if (!isForSale || !isSold) return null;
    if (!isVaulted && !isPublished) return null;

    return (
        <Fragment>
            <div className="flex items-center mr-3">
                <img
                    src={IconVaulted}
                    className="w-4 h-4"
                />
                <span className="text-primary text-xs ml-1">
                    -
                </span>
            </div>
            <div className="flex items-center mr-3">
                <img
                    src={IconHaveList}
                    className="w-4 h-4"
                />
                <span className="text-primary text-xs ml-1">
                    -
                </span>
            </div>
            <div className="flex items-center mr-3">
                <img
                    src={IconMarketPlace}
                    className="w-4 h-4"
                />
                <span className="text-primary text-xs ml-1">
                    -
                </span>
            </div>
        </Fragment>
    );
};

const CardIcons = ({
    isVaulted,
    isPublished,
    isForSale,
    cardId,
}) => {
    if (!isVaulted && !isPublished && !isForSale) return null;

    let icons = [];
    if (isVaulted) icons.push(IconVaulted);
    if (isPublished) icons.push(IconHaveList);
    if (isForSale) icons.push(IconMarketPlace);

    return (
        <div className="flex items-center space-x-2">
            {icons.map((icon, i) => (
                <div key={`icon-${cardId}-${i}`} className="flex flex-row space-x-2">
                    <img src={icon}
                            className="w-4 h-4"/>
                            {i < (icons.length - 1) ? (
                            <span className="text-primary text-xs">-</span>
                            ) : null}
                </div>
            ))}
        </div>
    );
};

export default PlayerCard;

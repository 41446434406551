import React, { useEffect, useState } from "react";
import Button from "../seller_center/shared_components/button";
import KobeGiveawayImage from "kobe-giveaway-card-img.png";
import BackgroundBeeHive from "background-beehive.jpg";

const PromotionalBanner = ({ bgColor = null }) => {
    const bannerDetails = {
        title: "Kobe Bryant Autograph Giveaway",
        details:
            "Become a member today for your chance to win this $10,000 card!",
        actionText: "Entry details",
        url: "/promotions",
        image: KobeGiveawayImage,
    };
    const [onLargeDisplay, setOnLargeDisplay] = useState(
        window.innerWidth > 1024
    );
    useEffect(() => {
        window.addEventListener("resize", () =>
            setOnLargeDisplay(window.innerWidth > 1024)
        );
    }, []);

    const containerClass = [
        "w-full h-48 lg:h-36 px-2 py-4 md:py-0 2xl:px-16",
        "flex items-center justify-center",
        "space-x-0 lg:space-x-6",
        onLargeDisplay
            ? bgColor ||
              "bg-gradient-to-r from-primary-300 via-primary-330 to-primary-350"
            : "",
    ].join(" ");

    const containerStyle = {
        ...(!onLargeDisplay && {
            backgroundImage: `url(${BackgroundBeeHive})`,
        }),
        ...(!onLargeDisplay && { boxShadow: "inset 80px 0 100px" }),
    };

    return (
        <>
            {bannerDetails?.url && !bannerDetails?.actionText ? (
                <a
                    href={bannerDetails?.url}
                    className={containerClass}
                    style={containerStyle}>
                    <BannerContent
                        {...bannerDetails}
                        onLargeDisplay={onLargeDisplay}
                    />
                </a>
            ) : (
                <div className={containerClass} style={containerStyle}>
                    <BannerContent
                        {...bannerDetails}
                        onLargeDisplay={onLargeDisplay}
                    />
                </div>
            )}
        </>
    );
};

const BannerContent = ({
    title,
    details,
    actionText,
    url,
    image,
    onLargeDisplay,
}) => {
    const imageClass = [
        "h-full lg:h-56 xl:h-72 2xl:h-80 w-auto",
        "transform -translate-y-2 lg:-translate-y-6 xl:-translate-y-16 2xl:-translate-y-20",
    ].join(" ");

    const textContainerClass = [
        "flex flex-col my-2 lg:space-y-3",
        "text-black-500 font-semibold",
    ].join(" ");

    return (
        <>
            {image ? (
                <div className="h-full overflow-hidden">
                    <img
                        src={image}
                        className={imageClass}
                        style={{ maxWidth: "100%" }}
                        alt="Promotional banner image"
                    />
                </div>
            ) : null}
            <div className="flex flex-col w-1/2 lg:w-2/3 lg:flex-row ml-16 lg:ml-0 lg:space-x-4 xl:space-x-16 lg:items-center">
                <div className={textContainerClass}>
                    <span className="text-orange-30 lg:text-black-100 uppercase font-semibold font-display text-xl md:text-fs32 lg:text-fs35 xl:text-fs40">
                        {title}
                    </span>
                    <span className="hidden lg:block text-fs18 font-medium lg:font-semibold">
                        {details}
                    </span>
                </div>
                {actionText ? (
                    <Button
                        primary={!onLargeDisplay}
                        secondary={onLargeDisplay}
                        text={onLargeDisplay ? actionText : "Learn\u00A0more"}
                        url={url}
                        customClass="w-full md:w-2/3 lg:w-40"
                        paddings="px-6 py-3"
                        borderColor="border-gray-575"
                        fontBold
                    />
                ) : null}
            </div>
        </>
    );
};

BannerContent.defaultProps = {
    title: "",
    details: "",
    actionText: null,
    url: null,
    image: null,
};

export default PromotionalBanner;

import { useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import Button from "../../seller_center/shared_components/button";
import formatPrice from "../../utils/format_price";
import CardContainer from "./card_container";

const OrderDetails = ({ checkoutSellers, disabled }) => {
    const items = checkoutSellers.flatMap(seller => seller.items);
    const collatedFulfillmentOptions = checkoutSellers?.flatMap(
        summary => summary.seller.shippingMethods
    );

    const subTotals = checkoutSellers.map((summary) => ({
        items: summary.items,
        seller: summary.seller.username,
        currency: summary.currency,
        subTotal: summary.items
            .reduce((subTotal, item) => subTotal + Number(item.price), 0)
            .toFixed(2),
    }));
    const grossTotal = subTotals?.reduce(
        (total, item) => total + Number(item.subTotal),
        0
    ).toFixed(2);
    const showSubTotalEachSeller = false;

    const { values } = useFormikContext();
    const totals = useRef(
        {
            grossTotal: parseFloat(grossTotal),
            shippingFee: 0,
            netTotal: 0
        }
    );

    useEffect(() => {
        const selectedFulfillmentIds = values.purchaseDetails.map(
            d => d.fulfillmentOptionId
        );
        const selectedFulfillments = collatedFulfillmentOptions.filter(
            f => selectedFulfillmentIds.includes(f.id)
        );
        const shippingTotal = selectedFulfillments.reduce(
            (total, f) => total + f.shippingFee,
            0
        ).toFixed(2);
        totals.current.shippingFee = parseFloat(shippingTotal);
        totals.current.netTotal = totals.current.grossTotal + totals.current.shippingFee;
    }, [values.purchaseDetails])
    return (
        <div className="sticky" style={{ top: "8.5rem" }}>
            <CardContainer
                subText="Please review and confirm all details are correct before placing your order."
                titleBorder>
                <div className="space-y-4 text-gray-30">
                    <div className="space-y-6">
                        {/* TODO :: show subtotal for each seller? */}
                        {showSubTotalEachSeller && (
                            <>
                                {subTotals.map((summary) => (
                                    <div
                                        key={`subtotal-${summary.seller}`}
                                        className="flex justify-between">
                                        <span>
                                            {summary.seller} [
                                            {summary.items.length} items]
                                        </span>
                                        <span>
                                            {formatPrice(summary.subTotal)}
                                        </span>
                                    </div>
                                ))}
                            </>
                        )}
                        {!showSubTotalEachSeller && (
                            <div className="flex justify-between">
                                <span>Subtotal [{items.length} items]</span>
                                <span>{formatPrice(totals.current.grossTotal)}</span>
                            </div>
                        )}
                        <div className="flex justify-between">
                                <span>Shipping Fee</span>
                                <span>{formatPrice(totals.current.shippingFee)}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-xl font-semibold text-gray-1000">
                                Total
                            </span>
                            <span className="text-xl font-semibold text-gray-1000">
                                {formatPrice(totals.current.netTotal, "V C")}
                            </span>
                        </div>
                    </div>
                    <Button
                        text="Place order"
                        type="submit"
                        primary
                        customClass="w-full"
                        disabled={disabled}
                    />
                    <div className="text-center text-sm leading-8">
                        Your official receipt and transaction confirmation will
                        be sent to your registered email.
                    </div>
                </div>
            </CardContainer>
        </div>
    );
};

export default OrderDetails;

import { PaymentElement } from '@stripe/react-stripe-js'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { CreditCard } from 'react-feather'
import CardContainer from './card_container'

const PaymentDetails = ({
    userPaymentMethod,
    checkPaymentValidity,
    setPaymentErrors,
}) => {
    const [useDifferentCard, setUseDifferentCard] = useState(!userPaymentMethod);
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (useDifferentCard) {
            setFieldValue("paymentMethodId", null);
        } else {
            if (userPaymentMethod) setFieldValue("paymentMethodId", userPaymentMethod.id);
            setPaymentErrors(null);
        }
    }, [useDifferentCard]);

    const actionText = userPaymentMethod ? useDifferentCard ? "Use existing payment method" : "Use a different card" : null;

    const handlePaymentElementChange = (data) => {
        if (!data.complete || data.empty) {
            setPaymentErrors("Please input valid payment details.");
        } else {
            setPaymentErrors(null);
        }
    };

    return (
        <CardContainer
            title="Payment Details"
            titleBorder
            fullWidthHeader
            actionText={actionText}
            actionIcon={actionText ? "edit" : null}
            onActionClick={() => {
                checkPaymentValidity(() => {
                    setUseDifferentCard(!useDifferentCard);
                })
            }}
        >
            <div className="p-6 text-gray-30 space-x-6 flex items-center">
                {!useDifferentCard && (
                    <>
                        {/* TODO :: replace with correct icon */}
                        <CreditCard size={24} />
                        <div className="flex flex-col">
                            <span>**** **** **** {userPaymentMethod.lastDigits}</span>
                            <span>Expiry: {userPaymentMethod.expirationMonth}/{String(userPaymentMethod.expirationYear).slice(-2)}</span>
                        </div>
                    </>
                )}
                {useDifferentCard && (
                    <div className="w-full space-y-3 text-gray-300">
                        <PaymentElement onChange={handlePaymentElementChange} />
                        <div className="flex space-x-2 text-xs">
                            <span>
                                By providing your card information, you allow MERQARY to charge your card for future payments in accordance with the
                            </span>
                            <a href="/terms_and_conditions" className="cursor-pointer underline">
                                Terms and Conditions.
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </CardContainer>
    )
}

export default PaymentDetails

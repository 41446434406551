import React, { useRef, useState } from 'react'
import { Calendar, X } from 'react-feather'

const DateRangePicker = ({ value, onChange, triggerChangeOnClear, showClearInputs }) => {

    const startDate = useRef(null)
    const endDate = useRef(null)
    const [endDateMin, setEndDateMin] = useState(null)
    const [startDateMax, setStartDateMax] = useState(null)

    const handleChange = () => {
        setEndDateMin(startDate.current.value)
        setStartDateMax(endDate.current.value)
        onChange({
            startDate: startDate.current.value,
            endDate: endDate.current.value
        })
    }

    const handleClear = () => {
        startDate.current.value = null
        endDate.current.value = null
        if (triggerChangeOnClear) handleChange()
    }

    return (
        <div className='date-picker-container'>
            <span>
                <Calendar size={18}/>
            </span>
            <input className="date-picker" type="date" ref={startDate} max={startDateMax} defaultValue={value?.startDate} onChange={(e) => handleChange()}/>
            <span>-</span>
            <input className="date-picker" type="date" ref={endDate} min={endDateMin} defaultValue={value?.endDate} onChange={(e) => handleChange()}/>
            {showClearInputs && (
                <button
                    className='pr-2 focus:border-none focus:outline-none'
                    onClick={() => handleClear()}
                >
                    <X size={16} />
                </button>
            )}
        </div>
    )
}

DateRangePicker.defaultProps = {
    value: {
        startDate: null,
        endDate: null
    },
    showClearInputs: false,
    triggerChangeOnClear: false,
    onChange: () => {},
}

export default DateRangePicker

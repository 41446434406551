import React, { useContext } from 'react';
import { Copy } from 'react-feather';
import { PopupContext, POPUP_TYPES } from '../popup_context';

const CopyToClipboard = ({ text, size, color, actionText }) => {

    const { setShowPopup, setPopupAction, setMessage } = useContext(PopupContext);
    const textToCopy = Array.isArray(text) ? text.join(" ") : text;
    const handleClick = () => {
        navigator.clipboard.writeText(textToCopy);
        setShowPopup(true);
        setPopupAction(POPUP_TYPES.SUCCESS);
        setMessage(`"${textToCopy}" was copied to clipboard`);
    };

    return (
        <button
            className='focus:border-none focus:ring-0 focus:outline-none'
            type='button'
            onClick={() => handleClick()}
        >
            {actionText
                ? actionText
                : <Copy size={size} className={`${color}`} />}
        </button>
    );
};

CopyToClipboard.defaultProps = {
    text: null,
    size: 16,
    color: 'text-primary',
    actionText: null,
};

export default CopyToClipboard;

import React, { Fragment, useEffect, useState } from "react";
import { render } from "react-dom";
import Modal from "../modal";
import ModalPortal from "../modal/modal_portal";

const WithdrawalModal = ({ balance, currency }) => {
    const [mountModal, setMountModal] = useState(false);
    const [amount, setAmount] = useState(0);
    const [stripeFee, setStripeFee] = useState(0);
    const [netAmount, setNetAmount] = useState(0);
    const [bankName, setBankName] = useState(0);
    const [accountNumber, setAccountNumber] = useState(0);

    function closeModal() {
        setMountModal(false);
    }

    useEffect(() => {
        const $amountInput = document.querySelector(
            "#withdrawal-amount-container"
        );
        const $stripeFeeSpan = document.querySelector("#stripe-fee-container");
        const $netAmountSpan = document.querySelector(
            "#payout-amount-container"
        );
        const $bankNameSpan = document.querySelector("#bank-name-container");
        const $accountNumberSpan = document.querySelector(
            "#account-number-container"
        );
        const amount = $amountInput.value;
        const stripeFee = $stripeFeeSpan.innerHTML;
        const netAmount = $netAmountSpan.innerHTML;
        const bankName = $bankNameSpan.innerHTML;
        const accountNumber = $accountNumberSpan.innerHTML;

        if (mountModal) {
            setStripeFee(stripeFee);
            setNetAmount(netAmount);
            setAmount(amount);
            setBankName(bankName);
            setAccountNumber(accountNumber);
        }
    }, [mountModal]);

    return (
        <Fragment>
            <button
                type="button"
                className="w-48 font-semibold bg-primary text-gray-70 rounded text-center ring-1 ring-yellow-500 hover-primary-btn focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 p-2"
                onClick={() => {
                    setMountModal(true);
                }}>
                Withdraw Funds
            </button>
            <ModalPortal id="withdraw_modal">
                {mountModal && (
                    <Modal
                        title="Withdrawal Confirmation"
                        closeModal={closeModal}
                        size="lg"
                        preventESC
                        closeIcon="x">
                        {({ hideModal }) => (
                            <div className="p-6 flex flex-col justify-between w-full font-body text-gray-70">
                                <div className="flex flex-col bg-white-400 rounded mb-4 p-4 space-y-2">
                                    <span className="font-semibold">
                                        Transfer funds to:
                                    </span>
                                    <span>{bankName}</span>
                                    <span>{accountNumber}</span>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <span>Withdrawal Amount:</span>
                                    <span>{currency}{amount}</span>
                                </div>
                                {/* TODO Connect Stripe fees */}
                                <div className="flex justify-between mb-2">
                                    <span>Stripe Fees:</span>
                                    <span>{currency}{stripeFee}</span>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <span>You will receive:</span>
                                    <span>{currency}{netAmount}</span>
                                </div>
                                <div className="ml-auto flex text-sm mt-8">
                                    <button
                                        className="font-semibold hover:bg-gray-1000 border py-2 px-8 border-gray-500 rounded text-gray-70 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        onClick={closeModal}>
                                        Cancel
                                    </button>
                                    <button className="font-semibold ml-4 px-4 py-2 rounded bg-primary text-gray-70 ring-1 ring-yellow-500 hover-primary-btn focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                        Confirm Withdrawal
                                    </button>
                                </div>
                            </div>
                        )}
                    </Modal>
                )}
            </ModalPortal>
        </Fragment>
    );
};

export default () => {
    document.addEventListener("DOMContentLoaded", () => {
        const container = document.getElementById("withdraw_modal");
        if (container) {
          const { balance, currency } = container.dataset
            render(<WithdrawalModal balance={balance} currency={currency} />, container);
        }
    });
};

import React, { useContext, useState } from "react";
import ModalPortal from "../../modal/modal_portal";
import Modal from "../../modal";
import ImageDropzone from "./image_dropzone";
import Select from "react-select";
import { gql, useQuery } from "@apollo/client";
import SpinnerLoader from "../../spinner_loader";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { PopupContext, POPUP_TYPES } from "../../popup_context";
import OwnedCardMutation from "../../mutations/owned_card_mutation";
import Button from "../../seller_center/shared_components/button";
import PageVariablesContext from "../../page_variables_context";
import CardInfo from "../../browse_cards/card_info";
import formatCardPlayers from "../../utils/format_card_players";

const GET_OWNED_CARD_CONSTANTS = gql`
    query GetOwnedCardDetailConstants($userId: ID) {
        constants: getOwnedCardDetailConstants(userId: $userId) {
            haveLists {
                id
                name
            }
            countries {
                id
                name
            }
            companies {
                id
                name
            }
            grading
        }
    }
`;

const OwnedCardSchema = yup.object().shape({
    printRunOrdinality: yup.number("must be a number"),
    grade: yup.string(),
    gradedBy: yup.string(),
    countryId: yup.string(),
    description: yup.string(),
    isPublic: yup.boolean(),
    haveListIds: yup.array().of(yup.string()).min(1, "card must be in a list"),
});

const OwnedCardDetailModal = ({
    mountModal = false,
    setMountModal,
    ownedCardDetails,
    onImageUpdate,
    reloadOnUpdate,
    activeMarketListing,
    draftMarketListing,
    playersJson,
}) => {
    const { userId } = useContext(PageVariablesContext)
    const { data, loading } = useQuery(GET_OWNED_CARD_CONSTANTS, {
        fetchPolicy: "cache-first",
        variables: { userId: userId },
    });
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);

    const updateCardCallback = (data) => {
        const result = data?.result;
        const errors = result.errors.join(", ");
        setShowPopup(true);
        setPopupAction(errors ? POPUP_TYPES.DANGER : POPUP_TYPES.SUCCESS);
        setMessage(errors || "Owned Card Updated.");
        reloadOnUpdate();
    };
    const { confirmUpdate, updateLoading } =
        OwnedCardMutation(updateCardCallback);

    const constants = data?.constants;
    const countryOptions = constants?.countries?.map((c) => ({
        label: c.name,
        value: c.id,
    }));
    const companyOptions = constants?.companies?.map((c) => ({
        label: c.name,
        value: c.id,
    }));
    const haveListOptions = constants?.haveLists?.map((h) => ({
        label: h.name,
        value: h.id
    }));
    const gradingOptions = constants?.grading?.map((g) => ({
        label: g,
        value: g,
    }));
    const [images, setImages] = useState({
        front: ownedCardDetails?.frontImage,
        back: ownedCardDetails?.backImage,
    });

    const updateImage = (img, side) => {
        if (side === "front") setImages({ ...images, front: img });
        if (side === "back") setImages({ ...images, back: img });
        onImageUpdate(img, side);
    };

    return (
        <ModalPortal
            id="estimatedEarningsModal"
            className="fixed z-10 inset-0 overflow-y-auto hidden">
            {mountModal && (
                <Modal
                    title="Edit card details"
                    preventESC
                    size="2xl"
                    showTitleBorder={false}
                    closeModal={() => setMountModal(false)}
                    className="p-6 text-black-100"
                    closeIcon="x"
                    modalCustomClass="py-4 md:py-8 px-2 md:px-6 rounded"
                    titleClass="text-gray-650 text-md font-semibold">
                    {({ hideModal }) => (
                        <div className="p-4 overflow-y-hidden">
                            {loading && <SpinnerLoader />}
                            {!loading && constants && images && (
                                <Formik
                                    onSubmit={({ ...values }) => {
                                        confirmUpdate(
                                            values,
                                            activeMarketListing,
                                            draftMarketListing
                                        );
                                        hideModal();
                                    }}
                                    validationSchema={OwnedCardSchema}
                                    initialValues={ownedCardDetails}>
                                    {(form) => (
                                        <Form>
                                            <div className="pt-6 space-y-8 border-t-2 border-gray-400 border-opacity-40 text-gray-650">
                                                <div className="grid grid-cols-2 gap-4 pb-6 space-y-12 md:space-y-0">
                                                    <div className="space-y-10 col-span-full md:col-span-1">
                                                        <div className="font-semibold text-black-100">
                                                            <p>{ ownedCardDetails?.cardName }</p>
                                                        </div>

                                                        <div className="space-y-8">
                                                            <div className="space-y-4">
                                                                <span>
                                                                    Click to upload
                                                                    an image of your
                                                                    card.
                                                                </span>
                                                                <div className="grid grid-cols-2 gap-2">
                                                                    <ImageDropzone
                                                                        side="front"
                                                                        cardId={
                                                                            ownedCardDetails?.id
                                                                        }
                                                                        imagePreview={
                                                                            images.front
                                                                        }
                                                                        onImageChange={
                                                                            updateImage
                                                                        }
                                                                        addOverlayImage
                                                                    />
                                                                    <ImageDropzone
                                                                        side="back"
                                                                        cardId={
                                                                            ownedCardDetails?.id
                                                                        }
                                                                        imagePreview={
                                                                            images.back
                                                                        }
                                                                        onImageChange={
                                                                            updateImage
                                                                        }
                                                                        addOverlayImage
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* TODO Remove images function. will comment this for now */}
                                                            {/* <div className="underline cursor-pointer mt-4">
                                                                Remove images
                                                            </div> */}
                                                            <div className="flex">
                                                                <CardInfo title="Player">
                                                                    {formatCardPlayers(playersJson, ["names"], false, true)}
                                                                </CardInfo>
                                                                <CardInfo title="Team">
                                                                    {formatCardPlayers(playersJson, ["teams"], false, true)}
                                                                </CardInfo>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="md:space-y-4 col-span-full md:col-span-1">
                                                        <div className="flex md:justify-end space-x-3">
                                                            <label
                                                                htmlFor="isPublicTrue"
                                                                className="flex items-center space-x-3 cursor-pointer">
                                                                <input
                                                                    id="isPublicTrue"
                                                                    className="text-primary focus:ring-primary"
                                                                    type="radio"
                                                                    name="isPublic"
                                                                    value={true}
                                                                    checked={ownedCardDetails?.isPublic}
                                                                    onChange={() => {
                                                                        form.setFieldValue("isPublic", true);
                                                                        ownedCardDetails.isPublic = true;
                                                                        // NOTE: ownedCardDetails prop update is used to saved user's unsaved changes
                                                                        //       prop update on normal circumstance is not advisable.
                                                                    }}
                                                                />
                                                                <span>
                                                                    Vaulted
                                                                </span>
                                                            </label>
                                                            <label
                                                                htmlFor="isPublicFalse"
                                                                className="flex items-center space-x-3 cursor-pointer">
                                                                <input
                                                                    id="isPublicFalse"
                                                                    className="text-primary focus:ring-primary"
                                                                    type="radio"
                                                                    name="isPublic"
                                                                    value={false}
                                                                    checked={!ownedCardDetails?.isPublic}
                                                                    onChange={() => {
                                                                        form.setFieldValue("isPublic", false);
                                                                        ownedCardDetails.isPublic = false;
                                                                    }}
                                                                />
                                                                <span>
                                                                    Vaulted-hidden
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="pt-6 space-y-4">
                                                            <div className="space-y-2">
                                                                <span className="text-sm font-body">
                                                                    List name
                                                                </span>
                                                                <Select
                                                                    classNamePrefix="ReactSelect"
                                                                    className="w-full border-0 outline-none focus:outline-none focus:ring-0 rounded text-gray-700 text-sm"
                                                                    onChange={
                                                                        (v) => {
                                                                        const selected = v.map(h => h.value);
                                                                        form.setFieldValue("haveListIds", selected);
                                                                        ownedCardDetails.haveListIds = selected;
                                                                    }}
                                                                    value={haveListOptions.filter(
                                                                        (o) => ownedCardDetails.haveListIds.includes(o.value)
                                                                    )}
                                                                    options={haveListOptions}
                                                                    isOptionDisabled={(option) => option.disabled}
                                                                    isMulti
                                                                />
                                                                <ErrorMessage name="haveListIds">
                                                                    {(msg) => (
                                                                        <div className="text-red-400 text-xs">
                                                                            {msg}
                                                                        </div>
                                                                    )}
                                                                </ErrorMessage>
                                                            </div>
                                                            <div className="grid grid-cols-2 gap-4">
                                                                <div className="space-y-2">
                                                                    <span className="text-sm font-body">
                                                                        Grading
                                                                        type
                                                                    </span>
                                                                    <Select
                                                                        classNamePrefix="ReactSelect"
                                                                        className="w-full border-0 outline-none focus:outline-none focus:ring-0 rounded text-gray-700 text-sm"
                                                                        onChange={
                                                                            (v) => {
                                                                            form.setFieldValue("gradedBy", v.value);
                                                                            ownedCardDetails.gradedBy = v.value;
                                                                        }}
                                                                        defaultValue={companyOptions.filter(
                                                                            (o) => o.value === ownedCardDetails.gradedBy
                                                                        )}
                                                                        options={companyOptions}
                                                                    />
                                                                </div>
                                                                <div className="space-y-2">
                                                                    <span className="text-sm font-body">
                                                                        Grade
                                                                    </span>
                                                                    <Select
                                                                        classNamePrefix="ReactSelect"
                                                                        className="w-full border-0 outline-none focus:outline-none focus:ring-0 rounded text-gray-700 text-sm"
                                                                        onChange={
                                                                            (v) => {
                                                                            form.setFieldValue("grade", v.value);
                                                                            ownedCardDetails.grade = v.value;
                                                                        }}
                                                                        defaultValue={gradingOptions.filter(
                                                                            (o) => o.value === ownedCardDetails.grade
                                                                        )}
                                                                        options={gradingOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="grid grid-cols-2 gap-4">
                                                                <div
                                                                    className={`space-y-2 ${
                                                                        !ownedCardDetails.printRun
                                                                            ? "col-span-2"
                                                                            : ""
                                                                    }`}>
                                                                    <span className="text-sm font-body">
                                                                        Location
                                                                    </span>
                                                                    <div className="space-y-2">
                                                                        <div className="flex items-center rounded bg-white relative">
                                                                            {/* <span className="text-md font-semibold  px-6">
                                                                                <MapPin className='w-4 h-4 text-gray-300'/>
                                                                            </span> */}
                                                                            <Select
                                                                                classNamePrefix="ReactSelect"
                                                                                className="w-full border-0 outline-none focus:outline-none focus:ring-0 rounded text-gray-700 text-sm"
                                                                                onChange={
                                                                                    (v) => {
                                                                                    form.setFieldValue("countryId", v.value);
                                                                                    ownedCardDetails.country_id = v.value;
                                                                                }}
                                                                                options={countryOptions}
                                                                                defaultValue={
                                                                                    ownedCardDetails
                                                                                        ? countryOptions.filter(o => o.value === ownedCardDetails.countryId)
                                                                                        : []
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {ownedCardDetails.printRun && (
                                                                    <div className="space-y-2">
                                                                        <label
                                                                            htmlFor="printRunOrdinality"
                                                                            className="text-sm font-body">
                                                                            Print
                                                                            Run
                                                                        </label>
                                                                        <div className="border-gray-50 border-2 text-black rounded-md w-full flex items-stretch">
                                                                            <input
                                                                                id="printRunOrdinality"
                                                                                className="p-2 w-full border-0 focus:ring-primary focus:outline-none focus:border-primary"
                                                                                type="number"
                                                                                min="1"
                                                                                max={
                                                                                    ownedCardDetails.printRun
                                                                                }
                                                                                step="1"
                                                                                name="printRunOrdinality"
                                                                                onChange={
                                                                                    (v) => {
                                                                                    form.setFieldValue("printRunOrdinality", v.target.value);
                                                                                    ownedCardDetails.printRunOrdinality =v.target.value;
                                                                                }}
                                                                                defaultValue={ownedCardDetails.printRunOrdinality}
                                                                                onWheel={(e) => e.target.blur()}
                                                                            />
                                                                            <span className="ml-auto p-2 bg-gray-25 h-100">
                                                                                {ownedCardDetails.printRun}
                                                                            </span>
                                                                        </div>
                                                                        <ErrorMessage name="printRunOrdinality">
                                                                            {(msg) => (
                                                                                <div className="text-red-400 text-xs">
                                                                                    {msg}
                                                                                </div>
                                                                            )}
                                                                        </ErrorMessage>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="space-y-2">
                                                                <label
                                                                    htmlFor="description"
                                                                    className="text-sm font-body">
                                                                    Description
                                                                    [optional]
                                                                </label>
                                                                <textarea
                                                                    id="description"
                                                                    name="description"
                                                                    rows={4}
                                                                    placeholder="Describe your card in detail"
                                                                    className="w-full text-sm border-gray-30 border-2 border-opacity-40 focus:ring-primary focus:outline-none focus:border-primary text-black rounded-md"
                                                                    onChange={
                                                                        (v) => {
                                                                        form.setFieldValue("description", v.target.value);
                                                                        ownedCardDetails.description = v.target.value;
                                                                    }}
                                                                    defaultValue={ownedCardDetails.description}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between md:justify-end items-center space-x-4">
                                                    <Button
                                                        transparent
                                                        type="button"
                                                        text="Cancel"
                                                        onClick={() =>
                                                            hideModal()
                                                        }
                                                    />
                                                    <Button
                                                        primary
                                                        type="submit"
                                                        disabled={updateLoading}
                                                        text="Save changes"
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </div>
                    )}
                </Modal>
            )}
        </ModalPortal>
    );
};

export default OwnedCardDetailModal;

import React from 'react'
import ModalPortal from '../../modal/modal_portal'
import Modal from '../../modal'

const EstimatedEarnings = ({
    mountModal = false,
    setMountModal,
    feePercentage,
}) => {
    return (
        <ModalPortal id="estimatedEarningsModal" className="fixed z-10 inset-0 overflow-y-auto hidden">
            {mountModal && (
                <Modal
                    title="MERQARY Sales and Commissions"
                    preventESC
                    size='md'
                    showTitleBorder={false}
                    closeModal={() => setMountModal(false)}
                    className="p-10 text-black-100"
                    closeIcon='x'
                    modalCustomClass="py-8 px-6 rounded-xl"
                    titleClass="text-gray-650 text-md font-semibold"
                >
                    {({ hideModal }) => (
                        <div className='p-4'>
                            <div className="pt-6 space-y-8 border-t-2 border-gray-400 border-opacity-40 text-gray-650">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                                <div className='grid space-y-1'>
                                    <span className='text-md'>Your total sales:</span>
                                    <span className='text-lg font-semibold'>USD 100</span>
                                </div>
                                <div className='grid space-y-1'>
                                    <span className='text-md'>Fee percentage:</span>
                                    <span className='text-lg font-semibold'>{feePercentage}%</span>
                                </div>
                                <div className='grid space-y-1'>
                                    <span className='text-md'>Do you still have questions and want to learn more?</span>
                                    <a href='/pricing' className='text-lg font-semibold'>Visit the subscriptions page</a>
                                </div>
                                <div className='flex justify-end'>
                                    <button
                                        className="py-2 px-6 rounded-md bg-primary text-md font-semibold"
                                        onClick={() => hideModal()}
                                    >
                                        Close
                                    </button>
                                </div>

                            </div>
                        </div>
                    )}
                </Modal>
            )}
        </ModalPortal>
    )
}

export default EstimatedEarnings

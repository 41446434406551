import { ErrorMessage, Form, Formik } from "formik";
import { gql, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import Select from "react-select";
import * as yup from "yup";
import { PopupContext, POPUP_TYPES } from "../popup_context";
import ImageDropzone from "./owned_card_detail/image_dropzone";
import SpinnerLoader from "../spinner_loader";
import OwnedCardMutation from "../mutations/owned_card_mutation";
import Button from "../seller_center/shared_components/button";

const GET_CONSTANTS = gql`
    query GetOwnedCardDetailConstants {
        constants: getOwnedCardDetailConstants(userId: null) {
            countries {
                id
                name
            }
            companies {
                id
                name
            }
            grading
        }
    }
`;

const OwnedCardSchema = yup.object().shape({
    printRunOrdinality: yup.number(),
    grade: yup.string(),
    gradedBy: yup.string(),
    countryId: yup.string(),
    description: yup.string(),
    isPublic: yup.boolean(),
    haveListIds: yup.array()
                    .of(yup.string())
                    .min(1, "card must be in a list"),
});

const UpdateOwnedCard = ({ hideModal, refreshList = () => {}, formValues, haveLists }) => {
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);
    const { data, loading: optionsLoading } = useQuery(GET_CONSTANTS, {
        fetchPolicy: "cache-first",
    });

    const updateCardCallback = (data) => {
        const errors = data?.result.errors.join(", ");
        setShowPopup(true);
        setPopupAction(errors ? POPUP_TYPES.DANGER : POPUP_TYPES.SUCCESS);
        setMessage(errors || "Owned Card Updated.");
        refreshList();
        hideModal();
    };

    const { confirmUpdate, updateLoading } = OwnedCardMutation(updateCardCallback)

    function updateImage(img, side) {
        if (side === "front") formValues.frontImage = img;
        if (side === "back") formValues.backImage = img;
    }

    const ownedCardConstants = data?.constants;
    const countryOptions = ownedCardConstants?.countries?.map((c) => ({
        label: c.name,
        value: c.id,
    }));
    const gradingTypeOptions = ownedCardConstants?.companies?.map((c) => ({
        label: c.name,
        value: c.id,
    }));
    const gradingOptions = ownedCardConstants?.grading?.map((c) => ({
        label: c,
        value: c,
    }));

    const initialFormValues = {
        printRunOrdinality: formValues?.printRunOrdinality || "",
        grade: formValues?.grade || "",
        gradedBy: formValues?.gradedBy || "",
        countryId: formValues?.countryId || "",
        description: formValues?.description || "",
        isPublic: formValues?.isPublic || false,
        haveListIds: formValues?.haveListIds || []
    };

    const haveListOptions = haveLists?.map(
        (h) => ({ label: h.name, value: h.id }));

    return (
        <div className="p-4 flex flex-col">
            {optionsLoading && <SpinnerLoader />}
            {!optionsLoading && (
                <div className="grid grid-cols-2 gap-0">
                    <div className="col-span-full md:col-span-1 flex flex-col items-center space-y-4">
                        <ImageDropzone
                            side="front"
                            cardId={formValues?.cardId}
                            imagePreview={formValues?.frontImage}
                            onImageChange={updateImage}
                        />
                        <ImageDropzone
                            side="back"
                            cardId={formValues?.cardId}
                            imagePreview={formValues?.backImage}
                            onImageChange={updateImage}
                        />
                    </div>
                    <Formik
                        onSubmit={({...values}) => confirmUpdate(
                                                    {cardId: formValues.cardId, ...values },
                                                    formValues.activeMarketListing,
                                                    formValues.draftMarketListing
                                                    )}
                        validationSchema={OwnedCardSchema}
                        initialValues={initialFormValues}>
                        {(form) => (
                            <Form className="px-2 w-full col-span-full md:col-span-1 ">
                                <h1 className="text-xl font-normal font-body mb-4">
                                    <span className="font-semibold">
                                        {formValues?.cardName}{" "}
                                    </span>
                                    {formValues?.players
                                        ?.map((p) => p.name)
                                        .join(", ")}
                                </h1>
                                <label>List name</label>
                                <Select
                                    classNamePrefix="ReactSelect"
                                    onChange={(v) => {
                                        const selected = v.map(h => h.value);
                                        form.setFieldValue("haveListIds", selected);
                                        formValues.haveListIds = selected;
                                        // NOTE: ownedCardDetails prop update is used to saved user's unsaved changes
                                        //       prop update on normal circumstance is not advisable.
                                    }}
                                    value={haveListOptions?.filter(h => formValues?.haveListIds.includes(h.value))}
                                    options={haveListOptions}
                                    isOptionDisabled={(option) => option.disabled}
                                    isMulti
                                />
                                <ErrorMessage name="haveListIds">
                                    {(msg) => (
                                        <div className="text-red-400 text-xs">
                                            {msg}
                                        </div>
                                    )}
                                </ErrorMessage>
                                <hr className="my-4 border-gray-25" />
                                <div className="space-y-2">
                                    <div>
                                        <label>Card Location</label>
                                        <Select
                                            classNamePrefix="ReactSelect"
                                            placeholder="Select location"
                                            onChange={(v) => {
                                                form.setFieldValue(
                                                    "countryId",
                                                    v.value
                                                );
                                                formValues.countryId = v.value;
                                            }}
                                            value={countryOptions.filter(
                                                (o) =>
                                                    o.value ===
                                                    formValues.countryId
                                            )}
                                            options={countryOptions}
                                        />
                                        <ErrorMessage name="countryId">
                                            {(msg) => (
                                                <div className="text-red inline-block p-2">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                    <div>
                                        <label>Grading type</label>
                                        <Select
                                            classNamePrefix="ReactSelect"
                                            onChange={(v) => {
                                                form.setFieldValue(
                                                    "gradedBy",
                                                    v.value
                                                );
                                                formValues.gradedBy = v.value;
                                            }}
                                            value={gradingTypeOptions.filter(
                                                (o) =>
                                                    o.value ===
                                                    formValues.gradedBy
                                            )}
                                            options={gradingTypeOptions}
                                        />
                                        <ErrorMessage name="countryId">
                                            {(msg) => (
                                                <div className="text-red inline-block p-2">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                    <div>
                                        <label htmlFor="grade">Grade</label>
                                        <Select
                                            classNamePrefix="ReactSelect"
                                            onChange={(v) => {
                                                form.setFieldValue(
                                                    "grade",
                                                    v.value
                                                );
                                                formValues.grade = v.value;
                                            }}
                                            defaultValue={gradingOptions.filter(
                                                (o) =>
                                                    o.value === formValues.grade
                                            )}
                                            options={gradingOptions}
                                        />
                                        <ErrorMessage name="grade">
                                            {(msg) => (
                                                <div className="text-red inline-block p-2">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                    {formValues?.printRun && (
                                        <div>
                                            <label htmlFor="printRunOrdinality">
                                                Print Run
                                            </label>
                                            <div className="border-gray-50 border-2 text-black rounded-md w-full flex items-stretch">
                                                <input
                                                    id="printRunOrdinality"
                                                    className="p-2 w-full border-0 focus:ring-primary focus:outline-none focus:border-primary"
                                                    type="number"
                                                    min="1"
                                                    max={formValues?.printRun}
                                                    step="1"
                                                    name="printRunOrdinality"
                                                    onChange={(v) => {
                                                        form.setFieldValue(
                                                            "printRunOrdinality",
                                                            v.target.value
                                                        );
                                                        formValues.printRunOrdinality =
                                                            v.target.value;
                                                    }}
                                                    defaultValue={
                                                        formValues.printRunOrdinality
                                                    }
                                                    onWheel={(e) =>
                                                        e.target.blur()
                                                    }
                                                />
                                                <span className="ml-auto p-2 bg-gray-25 h-100">
                                                    {formValues?.printRun}
                                                </span>
                                            </div>
                                            <ErrorMessage name="printRunOrdinality">
                                                {(msg) => (
                                                    <div className="text-red inline-block p-2">
                                                        {msg}
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </div>
                                    )}
                                    <div className="space-y-2">
                                        <label>Status</label>
                                        <div className="flex items-center space-x-1">
                                            <input
                                                name="isPublic"
                                                onChange={() => {
                                                    form.setFieldValue(
                                                        "isPublic",
                                                        true
                                                    );
                                                    formValues.isPublic = true;
                                                }}
                                                type="radio"
                                                value="true"
                                                checked={
                                                    formValues.isPublic === true
                                                }
                                                id="statusTrue"
                                            />
                                            <label htmlFor="statusTrue">
                                                Vaulted
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            <input
                                                name="isPublic"
                                                onChange={() => {
                                                    form.setFieldValue(
                                                        "isPublic",
                                                        false
                                                    );
                                                    formValues.isPublic = false;
                                                }}
                                                type="radio"
                                                value="false"
                                                checked={
                                                    formValues.isPublic ===
                                                    false
                                                }
                                                id="statusFalse"
                                            />
                                            <label htmlFor="statusFalse">
                                                Vaulted Hidden
                                            </label>
                                        </div>
                                        <ErrorMessage name="isPublic">
                                            {(msg) => (
                                                <div className="text-red inline-block p-2">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                    <div>
                                        <label htmlFor="description">
                                            Description
                                        </label>
                                        <textarea
                                            id="description"
                                            name="description"
                                            className="p-2 mb-3 border-gray-50 border-2 focus:ring-primary focus:outline-none focus:border-primary text-black rounded-md w-full"
                                            onChange={(v) => {
                                                form.setFieldValue(
                                                    "description",
                                                    v.target.value
                                                );
                                                formValues.description =
                                                    v.target.value;
                                            }}
                                            defaultValue={
                                                formValues.description
                                            }
                                        />
                                        <ErrorMessage name="description">
                                            {(msg) => (
                                                <div className="text-red inline-block p-2">
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                    <Button
                                        primary
                                        disabled={updateLoading}
                                        type="submit"
                                        text="Save"
                                        customClass="w-full"
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </div>
    );
};

export default UpdateOwnedCard;

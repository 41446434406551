import React from "react";
import CardImageContainer from "../../seller_center/create_listing/card_image_container";
import formatPrice from "../../utils/format_price";

const OtherListingItem = ({
    frontImage,
    price,
    currency,
    dealFormat,
    ownedCardGrade,
    ownedCardGraderName,
    draftPreview,
    id,
}) => {
    const containerClass = [
        "bg-gray-70 p-4 col-span-1 rounded",
        !draftPreview ? "bg-gray-800 rounded py-2" : ''
    ].join(" ");
    return (
        <div className={containerClass}>
            <a href={`/marketplace/listings/${id}`}>
                <div className="flex items-center">
                    <div className="rounded flex items-center bg-white">
                        <CardImageContainer
                            showFlip={false}
                            height="h-26"
                            frontImage={frontImage}
                        />
                    </div>
                    <div className="flex flex-col p-2 space-y-2 ml-4">
                        <span className="text-primary text-sm font-semibold">
                            {formatPrice(price, "V C", currency)}
                        </span>
                        <div className="flex space-x-2 text-sm text-gray-30">
                            {ownedCardGrade && (
                                <>
                                    <span className="uppercase">
                                        {ownedCardGraderName}
                                    </span>
                                    <span className="uppercase">{`Grade ${ownedCardGrade}`}</span>
                                </>
                            )}
                            {!ownedCardGrade && <span>No Grading</span>}
                        </div>
                        <div className="flex flex-wrap space-y-2">
                            <div className="text-xs bg-gray-575 px-2 py-1 rounded">
                                {dealFormat}
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default OtherListingItem;

import React, { useContext, useState } from "react";
import Button from "../../seller_center/shared_components/button";
import PurchaseSellerMutation from "../../mutations/purchase_seller_mutation";
import ViewRatingModal from "./view_rating_modal";
import RateSellerModal from "./rate_seller_modal";
import CopyToClipboard from "../../utils/copy_to_clipboard";
import { PopupContext, POPUP_TYPES } from "../../popup_context";
import { ConfirmationContext } from "../../confirmation_context";

const statusActions = {
    toShip: {
        text: null,
        nextStatus: null,
        title: null,
        button: {
            text: "Order received",
            type: "primary",
            disabled: true,
        },
    },
    toReceive: {
        text: "Once your package arrives, click on Order Received to review the seller.",
        nextStatus: "completed",
        title: "Mark order as received",
        button: {
            text: "Order received",
            type: "primary",
            disabled: false,
        },
    },
    toReview: {
        text: "Let others know about your buying experience.",
        nextStatus: null,
        title: null,
        button: {
            text: "Rate seller",
            type: "secondary",
            disabled: false,
            nextStatus: null,
        },
    },
    completed: {
        text: null,
        nextStatus: null,
        title: null,
        button: {
            text: "View Rating",
            type: "secondary",
            disabled: false,
            nextStatus: null,
        },
    },
};

const getActionKey = (status, review) => {
    if (status === "Received by Seller") return "toShip";
    if (status === "Shipped") return "toReceive";
    if (status === "Completed" && !review) return "toReview";
    if (status === "Completed" && review) return "completed";
};

const PurchaseSellerAction = ({
    id,
    status,
    review,
    callback,
    sellerUsername,
    showShippingDetails,
    orderStatus,
    trackingInformation,
}) => {
    const statusAction = statusActions[getActionKey(status, review)];
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);

    const updatePurchaseSellerCallback = (data) => {
        const result = data?.result;
        if (result.success) {
            callback();
        }
        if (!result.success) {
            setShowPopup(false);
            setShowPopup(true);
            setPopupAction(POPUP_TYPES.DANGER);
            setMessage(result.errors.join(", "));
        }
    };

    const { updatePurchaseSellerStatus } = PurchaseSellerMutation(
        updatePurchaseSellerCallback
    );
    const confirm = useContext(ConfirmationContext);
    const [mountRatingModal, setMountRatingModal] = useState(false);

    const handleAction = (action) => {
        if (action.nextStatus) {
            confirm({
                onConfirm: () => updatePurchaseSellerStatus({
                                    variables: {
                                        input: {
                                            id: id,
                                            status: action.nextStatus,
                                        },
                                    },
                                }),
                title: action.title
            });
        }
        if (!action.nextStatus && action.button.type === "secondary") {
            setMountRatingModal(true);
        }
    };
    return (
        <div className="flex justify-center md:justify-between items-center">
            {review ? (
                <ViewRatingModal
                    mountModal={mountRatingModal}
                    setMountModal={setMountRatingModal}
                    {...review}
                />
            ) : (
                <RateSellerModal
                    mountModal={mountRatingModal}
                    setMountModal={setMountRatingModal}
                    purchaseSellerId={id}
                    sellerUsername={sellerUsername}
                    callback={callback}
                />
            )}
            {!showShippingDetails && <span>{statusAction.text}</span>}
            {showShippingDetails && (
                <>
                    <div>Status</div>
                    <div className="flex items-end text-md">
                        <div className="flex divide-x-2 items-center divide-gray-300 divide-opaicty-40">
                            <div className="px-3 uppercase font-semibold">
                                {orderStatus}
                            </div>
                            {trackingInformation && (
                                <div className="px-3 flex text-gray-30 space-x-2 items-center">
                                    <a
                                        rel="noreferrer"
                                        href={
                                            trackingInformation.trackingUrl ||
                                            "#"
                                        }
                                        target={
                                            trackingInformation.trackingUrl
                                                ? "_blank"
                                                : ""
                                        }>
                                        <span>
                                            {
                                                trackingInformation.logisticsProvider
                                            }{" "}
                                            {trackingInformation.trackingNumber}
                                        </span>
                                    </a>
                                    <CopyToClipboard
                                        text={
                                            trackingInformation.trackingNumber
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
            <div className="flex items-center space-x-4">
                <Button
                    text={statusAction.button.text}
                    primary={statusAction.button.type === "primary"}
                    secondary={statusAction.button.type === "secondary"}
                    disabled={statusAction.button.disabled}
                    customClass={statusAction.button.disabled ? "opacity-60" : ""}
                    type="button"
                    onClick={() => handleAction(statusAction)}
                />
            </div>
        </div>
    );
};

export default PurchaseSellerAction;

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import { PopupContext, POPUP_TYPES } from "../../popup_context";
import { GET_USER_FULFILLMENT_OPTIONS } from "../../queries";
import SpinnerLoader from "../../spinner_loader";
import ShippingOption from "./shipping_option";
import useSelection from "../../hooks/useSelection";
import Button from "../../seller_center/shared_components/button";
import { ConfirmationContext } from "../../confirmation_context";
import PageVariablesContext from "../../page_variables_context";

const GET_LISTING_CONSTANTS = gql`
    query GetListingConstants {
        constants: getListingConstants {
            fulfillmentOptions {
                fulfillmentType
                description
            }
        }
    }
`;

const UPDATE_USER_FULFILLMENT_OPTIONS = gql`
    mutation UpdateUserFulfillmentOptions(
        $input: UpdateUserFulfillmentOptionsInput!
    ) {
        updateUserFulfillmentOptions(input: $input) {
            result
            errors
            fulfillmentOptions {
                id
                userId
                fulfillmentType
                description
                isActive
            }
        }
    }
`;

const ShippingOptions = () => {
    const { userId } = useContext(PageVariablesContext);
    const confirm = useContext(ConfirmationContext);

    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);

    const [getShippingMethods, { data, called }] = useLazyQuery(
        GET_USER_FULFILLMENT_OPTIONS,
        {
            variables: { userId: userId },
        }
    );
    const { data: constantsData, loading: constantsLoading } = useQuery(
        GET_LISTING_CONSTANTS,
        {
            fetchPolicy: "cache-first",
        }
    );
    const [updateUserFulfillmentOptions, { loading: updateLoading }] = useMutation(
        UPDATE_USER_FULFILLMENT_OPTIONS,
        {
            onCompleted: (data) => {
                const result = data?.updateUserFulfillmentOptions;
                if (result.result) {
                    setShowPopup(false);
                    setShowPopup(true);
                    setPopupAction(POPUP_TYPES.SUCCESS);
                    setMessage("Shipping methods updated.");
                    window.location.reload();
                } else {
                    setShowPopup(false);
                    setShowPopup(true);
                    setPopupAction(POPUP_TYPES.DANGER);
                    setMessage(result.errors.join(", "));
                }
            },
            onError: () => {
                setShowPopup(true);
                setPopupAction("error");
                setMessage("Something went wrong.");
            },
        }
    );

    useEffect(() => {
        if (!called) {
            getShippingMethods();
        }
    }, []);

    const shippingMethods = constantsData?.constants?.fulfillmentOptions;
    const userShippingMethods = data?.fulfillmentOptions;

    const { selected, select, setSelected } = useSelection();

    useEffect(() => {
        if (userShippingMethods) {
            select(
                userShippingMethods?.map((s) => ({
                    id: s.id,
                    fulfillmentType: s.fulfillmentType,
                    description: s.description,
                    isActive: s.isActive,
                    shippingFee: s.shippingFee
                }))
            );
        }
    }, [userShippingMethods]);

    const handleShippingMethodChange = (shippingData, checked) => {
        const existingData = selected.filter(
            (s) => s.fulfillmentType === shippingData.fulfillmentType
        );
        const otherData = selected.filter(
            (s) => s.fulfillmentType !== shippingData.fulfillmentType
        );

        if (checked && !shippingData.id) {
            Boolean(existingData.length)
                ? setSelected([...otherData, shippingData])
                : select(shippingData);
        }

        if ((checked && shippingData.id) || (!checked && shippingData.id)) {
            setSelected([...otherData, shippingData]);
        }

        if (!checked && !shippingData.id) {
            setSelected(otherData);
        }
    };

    const updateFulfillmentOptions = () => {
        if (updateLoading) return;

        updateUserFulfillmentOptions({
            variables: {
                input: {
                    userId: userId,
                    options: selected,
                },
            },
        });
    }

    const handleSaveChanges = () => {
        const hasDomestic = selected.some((s) => s.isActive && s.fulfillmentType.includes("domestic"));
        const hasIternational = selected.some((s) => s.isActive && s.fulfillmentType.includes("international"));
        if (!hasDomestic || !hasIternational) {
            setShowPopup(true);
            setPopupAction("warn");
            setMessage("Please select atleast one Domestic and International shipping to proceed");
            return;
        }

        const hasSelectedWithNoShippingFee = selected.some(
            (s) => (s.isActive && parseFloat(s.shippingFee || 0) <= 0)
        );

        if (hasSelectedWithNoShippingFee) {
            confirm({
                title: "Shipping fee",
                text: "You have an active shipping option that has no shipping fee, proceed?",
                onConfirm: () => updateFulfillmentOptions(),
            })
            return;
        }

        updateFulfillmentOptions();
    };

    return (
        <div className="py-10 lg:px-8">
            {!shippingMethods && !constantsLoading && <SpinnerLoader />}
            {shippingMethods && !constantsLoading && userShippingMethods && (
                <div className="bg-gray-800 rounded-lg p-16 space-y-16">
                    <div className="space-y-8">
                        <div className="grid space-y-1">
                            <span className="font-semibold">Shipping Methods</span>
                            <span className="text-gray-30">
                                Select shipping methods that you prefer
                            </span>
                        </div>
                        <div className="space-y-12">
                            {shippingMethods.map((shippingMethod) => (
                                <ShippingOption
                                    key={shippingMethod.fulfillmentType}
                                    {...shippingMethod}
                                    userShippingMethod={userShippingMethods.filter(
                                        (s) =>
                                            s.fulfillmentType ===
                                            shippingMethod.fulfillmentType
                                    )}
                                    onChange={handleShippingMethodChange}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-center md:justify-between space-y-4 md:space-y-0">
                        <Button
                            type="button"
                            text="Save Changes"
                            primary
                            onClick={() => handleSaveChanges()}
                            disabled={updateLoading}
                        />
                        <a href="/seller_center/my_listings" className="underline text-center">
                            See my listings
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShippingOptions;

import animateCSS from '../javascript/utils/animateCSS';

document.addEventListener('DOMContentLoaded', function () {
    const $alerts = document.querySelectorAll('.js-alert');
    window.animateCSS = animateCSS;
    [...$alerts].forEach((el) => {
        const { timeout } = el.dataset;
        const isDismissable = el.classList.contains('js-dismissable');
        let timeoutId;

        function removeEl() {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            animateCSS(el, 'fadeOut').then(() => el.remove());
        }

        if (timeout > 0) {
            timeoutId = setTimeout(removeEl, timeout);
        }

        if (isDismissable) {
            const $closeBtn = el.querySelector('.js-close');
            $closeBtn.addEventListener('click', removeEl);
        }
    });
});

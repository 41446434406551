import { useLazyQuery, useQuery } from "@apollo/client";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
    GET_ALL_STASHED_CARDS,
    GET_CARD_STATUSES_COUNT,
    GLOBAL_SEARCH_CARDS,
} from "../queries";
import CardList from "../trophy_room/card_list";
import UserIdContext from "../trophy_room/user_id_context";
import Stash from "./stash";
import StashPortal from "./stash_portal";
import SpinnerLoader from "../spinner_loader";
import FeaturedCards from "./featured_cards";
import BGLightsDarker from "bg-lights-darker.png";
import useFilter from "../filter/use_filter";
import FilterButton from "../filter_button";
// import PromotionalBanner from "../banners/promotional_banner";
import IsMarketplaceBetaUserContext from "../is_marketplace_beta_user_context";
import PromotionalBanner from "../banners/promotional_banner";

const CardStats = ({ vaulted = 0, listed = 0, forSale = 0 }) => {
    const isMarketplaceBetaUser = useContext(IsMarketplaceBetaUserContext);

    return (
        <div className="flex flex-row items-center justify-between w-auto">
            <div className="flex items-center mr-4">
                <span className="text-sm text-primary">{listed || "-"}</span>
                <span className="text-sm ml-0.5 text-gray-1000">
                    &nbsp;cards listed
                </span>
            </div>
            <div className="flex items-center mr-4">
                <span className="text-sm text-primary">{vaulted || "-"}</span>
                <span className="text-sm ml-0.5 text-gray-1000 ">
                    &nbsp;cards vaulted
                </span>
            </div>
            {isMarketplaceBetaUser ? (
                <div className="flex items-center">
                    <span className="text-sm text-primary">{forSale || "-"}</span>
                    <span className="text-sm ml-0.5 text-gray-1000">
                        &nbsp;for sale
                    </span>
                </div>
            ) : null}
        </div>
    );
}

const SearchResults = () => {
    const userId = useContext(UserIdContext);

    const [getStashedCards, { data: stashedData }] = useLazyQuery(
        GET_ALL_STASHED_CARDS
    );

    const { data: cardStatusCountData, loading: statusCountLoading } = useQuery(
        GET_CARD_STATUSES_COUNT
    );

    const {
        Filters,
        SearchField,
        SortField,
        Pagination,
        ResultInfo,
        results,
        loading,
        called,
    } = useFilter(GLOBAL_SEARCH_CARDS);

    const cardNodes = results?.cards || [];
    const hasCards = Boolean(cardNodes.length);
    const searchString = results?.filters?.searchString;
    const cardStatusCount = cardStatusCountData?.cardStatusCount;
    const [isDisplayFilter, setDisplayFilter] = useState(false);

    useEffect(() => {
        if (userId) {
            getStashedCards();
        }
    }, [userId]);

    useEffect(() => {
        if (document.documentElement.clientWidth <= 1024) {
            document.body.style.overflow = isDisplayFilter ? "hidden" : "auto";
        }
    }, [isDisplayFilter]);

    useEffect(() => {
        if (document.documentElement.clientWidth > 1024) {
            setDisplayFilter(true);
        }
    }, [isDisplayFilter]);

    return (
        <Fragment>
            <div
                className="bg-gray-500 bg-gray-500 bg-fixed py-4 px-8 img-cover-basic relative"
                style={{ backgroundImage: `url(${BGLightsDarker})` }}>
                    {!statusCountLoading ? (
                        <>
                            <div className="container mx-auto items-center justify-between space-x-6 pb-4 hidden lg:flex">
                                <p className="font-body font-semibold text-xl text-white mb-2 text-gray-1000">
                                    Search for Cards
                                </p>
                                <CardStats {...cardStatusCount}/>
                            </div>

                            <div className="container mx-auto">
                                <SearchField />
                            </div>
                            {
                                /* Hidding link until list all cards feature is available */
                                // <div className="container mx-auto flex justify-center items-center py-4">
                                //     <span className="font-body font-regular text-sm text-gray-1000 mr-px hidden lg:block">
                                //         Not sure what you're looking for?&nbsp;
                                //     </span>
                                //     <a href="#"
                                //         className="font-semibold font-body text-sm text-gray-1000 mr-px">
                                //         <u>Browse the entire collection</u>
                                //     </a>
                                // </div>
                            }
                        </>
                    ) : (
                        <div className="container mx-auto">
                            <SpinnerLoader />
                        </div>
                    )}
            </div>
            <div className="p-4 lg:p-8 pt-0 bg-black-300 animate__animated animate__fadeIn">
                {/* will hide this for now. return when dynamic promotions or other promotions are active */}
                {/* <div className="container mx-auto">
                    <PromotionalBanner/>
                </div> */}
                {called && searchString ? (
                    <Fragment>
                        <div className="container mx-auto border-b mb-4">
                            <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between py-4 lg:p-4 w-full">
                                <ResultInfo />
                                <div className="flex items-center justify-between">
                                    <div className="lg:ml-auto flex items-center">
                                        <SortField />
                                    </div>
                                    <FilterButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDisplayFilter(!isDisplayFilter);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container mx-auto flex flex-row items-start">
                            <Filters
                                visible={isDisplayFilter}
                                setVisibility={setDisplayFilter}
                                showAsDefault
                            />
                            {loading && (
                                <div className="px-8">
                                    <SpinnerLoader />
                                </div>
                            )}
                            {!loading && (
                                <div className="ml-0 lg:ml-8 flex flex-col w-full">
                                    {hasCards ? (
                                        <CardList
                                            cards={cardNodes}
                                            canStash={Boolean(userId)}
                                            stashedCardIds={stashedData?.stashedCards?.map(
                                                (c) => c.id
                                            )}
                                            ownedCardIds={stashedData?.haveCards?.map(
                                                (c) => c.cardId
                                            )}
                                            rowView
                                            showForSale
                                        />
                                    ) : (
                                        <div>
                                            <div className="text-4xl py-4 text-white w-full text-center">
                                                No cards found.
                                            </div>
                                            <FeaturedCards
                                                stashedData={stashedData}
                                            />
                                        </div>
                                    )}
                                    <Pagination />
                                </div>
                            )}
                        </div>
                    </Fragment>
                ) : (
                    <>
                        {loading && (
                            <div className="px-8">
                                <SpinnerLoader />
                            </div>
                        )}
                        <FeaturedCards stashedData={stashedData} featuredView />
                    </>
                )}

                <StashPortal>
                    {stashedData?.stashedCards?.length > 0 ? (
                        <Stash
                            cards={stashedData?.stashedCards}
                            userId={userId}
                        />
                    ) : null}
                </StashPortal>
            </div>
            <div className="container pt-4 mx-auto border-t flex items-center justify-center space-x-6 pb-4 block lg:hidden">
                <CardStats {...cardStatusCount} />
            </div>
        </Fragment>
    );
};

export default SearchResults;

import React, { createContext, useContext, useRef, useState } from 'react'
import Modal from './modal';
import ModalPortal from './modal/modal_portal';
import { PopupContext } from './popup_context';
import Button from './seller_center/shared_components/button';

export const ConfirmationContext = createContext();

export const ConfirmationContextProvider = ({ children }) => {
    const confirmProps = useRef({
        title: "Confirm",
        text: "Are you sure you want to proceed?",
        subText: null,
        confirmText: "Confirm",
        onConfirm: () => {},
        cancelText: "Cancel",
        onCancel: () => {}
    });
    const [showModal, setShowModal] = useState(false);
    const { setShowPopup } = useContext(PopupContext);

    const confirm = (props = confirmProps.current) => {
        confirmProps.current = {
            title: props.title || confirmProps.current.title,
            text: props.text || confirmProps.current.text,
            subText: props.subText || confirmProps.current.subText,
            confirmText: props.confirmText || confirmProps.current.confirmText,
            onConfirm: props.onConfirm || confirmProps.current.onConfirm,
            cancelText: props.cancelText || confirmProps.current.cancelText,
            onCancel: props.onCancel || confirmProps.current.onCancel
        }
        setShowPopup(false)
        setShowModal(true)
    }

    return (
        <ConfirmationContext.Provider value={confirm}>
            {children}
            <ModalPortal id="confirmationModal">
                {showModal && (
                    <Modal
                        title={confirmProps.current.title}
                        preventESC
                        size="xs"
                        showTitleBorder={false}
                        closeModal={() => setShowModal(false)}
                        className="p-10 text-black-100"
                        closeIcon='x'
                        modalCustomClass="rounded"
                        titleClass="text-gray-650 text-md font-semibold"
                        zIndex={99999}
                        isConfirmation
                    >
                        {({ hideModal }) => (
                            <div className="p-4">
                                <div className="pt-6 space-y-8 border-t-2 border-gray-400 border-opacity-40 text-gray-650">
                                    <div className="space-y-4">
                                        <p className="text-md font-medium text-center">
                                            {confirmProps.current.text}
                                        </p>
                                        <p className="text-md text-center">
                                            {confirmProps.current.subText}
                                        </p>
                                    </div>
                                    <div className="flex justify-end space-x-4">
                                        <Button
                                            secondary
                                            onClick={() => {
                                                confirmProps.current.onCancel();
                                                hideModal();
                                            }}
                                            text={confirmProps.current.cancelText}
                                        />
                                        <Button
                                            primary
                                            onClick={() => {
                                                confirmProps.current.onConfirm()
                                                setShowModal(false)
                                            }}
                                            text={confirmProps.current.confirmText}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal>
                )}
            </ModalPortal>
        </ConfirmationContext.Provider>
    )
}

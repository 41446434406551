import { useQuery } from "@apollo/client";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ChevronDown } from "react-feather";

import { GET_HAVE_LISTS, SEARCH_USER_HAVE_CARDS } from "../queries";
import ListLink from "./list_link";
import useFilterOptions from "../hooks/useFilterOptions";

import OtherListContainer from "./other_list_container";
import useFilter from "../filter/use_filter";
import FilterButton from "../filter_button";

const ListLinksContainer = ({ currentListId, lists, userId }) => {
    return (
        <ul className="p-4 space-y-4 text-sm w-full" style={{ margin: "0" }}>
            {lists.map((list, i) => {
                const isActive = currentListId
                    ? list.id === currentListId
                    : i === 0;
                return (
                    <ListLink
                        key={i}
                        isActive={isActive}
                        id={list.id}
                        name={list.name}
                        userId={userId}
                        isHaveList
                        isViewOnly
                    />
                );
            })}
        </ul>
    );
};

const MobileListLinksContainer = ({ currentListId, lists, userId }) => {
    const [isDisplayOpts, setDisplayOpts] = useState(false);
    const activeListName = currentListId
        ? lists.find((l) => l.id === currentListId)?.name
        : "Select List";

    return (
        <div className="relative">
            <button
                className="flex items-center block p-2 text-xs text-black-100 bg-white rounded-md"
                onClick={() => setDisplayOpts(!isDisplayOpts)}>
                <span className="mr-4">{activeListName}</span>
                <ChevronDown />
            </button>
            {isDisplayOpts && (
                <div
                    className="absolute bg-gray-700 left-0 py-2 mt-2 z-10"
                    style={{ width: "50vw" }}>
                    <ListLinksContainer
                        currentListId={currentListId}
                        lists={lists}
                        userId={userId}
                    />
                </div>
            )}
        </div>
    );
};

const OthersHaveList = () => {
    const { userId } = useParams();
    const location = useLocation();

    const listRes = useQuery(GET_HAVE_LISTS, {
        variables: { userId },
        fetchPolicy: "cache-and-network",
    });

    const q = new URLSearchParams(location.search);
    const currentListId = q.get("list");
    const lists = listRes?.data?.getHaveLists || [];
    const currentList = lists.find((l, i) =>
        /* return first list if currentListId is undefined or null */
        currentListId ? l.id === currentListId : i === 0
    );

    const listId = currentList?.id;
    // useScrollToTop([listId, currentPage]);

    const { _search, initialPage } = useFilterOptions();
    const [searchString] = _search;
    const [isDisplayFilter, setDisplayFilter] = useState(false);

    const DEFAULT_VARIABLES = {
        searchString: searchString || "*",
        userId: userId,
        haveListId: listId,
        options: {
            page: initialPage,
            publishStatus: "published",
            vaultStatus: "vaulted",
            sortCategory: "added",
            sortOrder: "desc",
        },
    };

    useEffect(() => {
        if (listId) {
            setVariables({
                ...DEFAULT_VARIABLES,
                haveListId: listId,
            });
        }
    }, [listId]);

    const {
        Filters,
        SearchField,
        SortField,
        Pagination,
        setVariables,
        called,
        results,
        loading,
    } = useFilter(SEARCH_USER_HAVE_CARDS, DEFAULT_VARIABLES, null, false);

    const nodes = results?.cards || [];
    const hasCards = Boolean(nodes.length);

    return (
        <Fragment>
            <div className="flex flex-col justify-between items-start">
                {!loading && called ? (
                    <div className="w-full pb-8 hidden lg:flex space-between items-center">
                        <SearchField />
                        <div className="ml-auto pl-4">
                            <SortField />
                        </div>
                    </div>
                ) : null}
                <hr className="pb-8 w-full divide-gray-600 hidden lg:block" />
                <div className="flex-col lg:hidden w-full">
                    {!loading && called ? (
                        <div className="pb-6 mb-4 space-x-3 justify-between items-center flex border-b border-gray-575">
                            <SearchField />
                            <FilterButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDisplayFilter(!isDisplayFilter);
                                }}
                            />
                        </div>
                    ) : null}
                    <div className="mb-4 justify-between items-center flex">
                        <MobileListLinksContainer
                            currentListId={currentListId || listId}
                            lists={lists}
                            userId={userId}
                        />
                        {!loading && called ? (
                            <SortField />
                        ) : null}
                    </div>
                </div>
                <div className="w-full flex flex-col lg:flex-row">
                    <div
                        className="w-72 flex-shrink-0 md:sticky"
                        style={{ top: "90px" }}>
                        <div
                            className="w-full divide-y overflow-y-auto divide-gray-600 space-y-6 bg-gray-700 rounded-sm text-white p-2 hidden lg:block"
                            style={{ maxHeight: "50%" }}>
                            {" "}
                            <p className="p-4 text-xl font-semibold">
                                Have List
                            </p>
                            <ListLinksContainer
                                currentListId={currentListId}
                                lists={lists}
                                userId={userId}
                            />
                        </div>
                        <div
                            style={{ maxHeight: "50%" }}
                            className="lg:mt-4 w-72 flex-shrink-0 divide-y divide-gray-600 space-y-6 overflow-y-auto bg-gray-700 rounded-sm">
                            <Filters
                                showAsDefault
                                visible={isDisplayFilter}
                                setVisibility={setDisplayFilter}
                            />
                        </div>
                    </div>
                    <OtherListContainer
                        nodes={nodes}
                        listId={listId}
                        loading={loading}
                        hasCards={hasCards}
                        name={currentList?.name || "-"}
                        pagination={<Pagination />}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default OthersHaveList;

import React, { Fragment, useCallback, useContext } from 'react'
import { gql, useMutation } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import { Image } from 'react-feather';
import GrayAddCircle from 'icon-gray-add-circle.svg';
import BlackAddCircle from 'icon-black-add-circle.svg';
import { PopupContext, POPUP_TYPES } from "../../popup_context";


const UPLOAD_CARD_IMAGE = gql`
    mutation uploadCardImage(
        $ownedCardId: ID!
        $image: String!
        $side: String!
        $isFront: Boolean!
        $isBack: Boolean!
    ) {
        result: uploadCardImage(
            input: { ownedCardId: $ownedCardId, image: $image, side: $side }
        ) {
            success
            errors
            ownedCard {
                id
                frontImage @include(if: $isFront)
                backImage @include(if: $isBack)
            }
        }
    }
`

const ImageDropzone = ({
    side = 'front',
    cardId,
    imagePreview = null,
    onImageChange,
    addOverlayImage,
}) => {
    const { setShowPopup, setPopupAction, setMessage } = useContext(PopupContext);
    const [uploadImage, { loading }] = useMutation(UPLOAD_CARD_IMAGE);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                const base64image = reader.result;
                uploadImage({
                    variables: {
                        ownedCardId: cardId,
                        side,
                        image: base64image,
                        isFront: side === 'front',
                        isBack: side !== 'front',
                    },
                }).then((res) => {
                    const result = res.data.result;
                    if (result.success) {
                        onImageChange(base64image, side);
                    } else {
                        setShowPopup(false);
                        setMessage(result.errors.join(", "));
                        setPopupAction(POPUP_TYPES.WARN);
                        setShowPopup(true);
                    }
                });
            };
            reader.readAsDataURL(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const innerImageClassname = [
        'rounded flex items-center',
        'justify-center flex-col space-y-1',
        'w-full h-full p-2',
        'bg-contain bg-center bg-no-repeat',
        !imagePreview && 'border border-dashed border-gray-50',
        loading ? 'opacity-50' : '',
    ].join(' ');
    return (
        <div {...getRootProps()}
            className={`${addOverlayImage ? "bg-black w-48 relative" : "" }`}>
            <div className={`${addOverlayImage ? "" : "hidden"}
                cursor-pointer flex flex-col justify-center items-center absolute
                inset-0 m-auto z-50 text-white-400 font-semibold`}>
                { addOverlayImage && <input {...getInputProps()} accept="image/*" /> }
                <img src={`${ imagePreview ? GrayAddCircle : BlackAddCircle }`}
                    className="w-12 h-12" />
                <span className={`capitalize mt-2 ${imagePreview ? "" : "text-gray-20"}`}>Card {side}</span>
            </div>
            <div
                className={`p-2 h-64 bg-gray-25 rounded bg-contain bg-center bg-no-repeat ${ addOverlayImage ? "w-full opacity-50" : "w-48" }`}
            >
                <div
                    className={innerImageClassname}
                    style={
                        imagePreview
                            ? {
                                backgroundImage: `url(${imagePreview})`,
                            }
                            : {}
                    }
                >
                    {!addOverlayImage && <input {...getInputProps()} accept="image/*" /> }
                    {!imagePreview && (
                        <Fragment>
                            <Image size={24} />
                            <p className="text-center text-sm font-semibold">
                                Card {side}
                            </p>
                            <p className="text-center text-sm">
                                <span className="underline">Upload a file</span> or
                                drag and drop
                            </p>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ImageDropzone

import React, { useEffect } from "react";

const FilterOverlay = ({ displayFilter, children }) => {
    useEffect(() => {
        document.body.style.overflow = displayFilter ? "hidden" : "auto";
    }, [displayFilter]);

    if (!displayFilter) return null;

    return (
        <div
            className="w-full h-full fixed block left-0 bg-black bg-opacity-60 z-50"
            style={{ top: "5rem" }}>
            <div className="flex justify-end top-1/8 block relative w-full">
                {children}
            </div>
        </div>
    );
};

export default FilterOverlay;

import { useLazyQuery, gql } from "@apollo/client";
import React, { Fragment, useContext, useEffect, useState } from "react";
import BgLights from "bg-lights-darker.png";
import { GET_USER_ORDERS } from "../../queries";
import SpinnerLoader from "../../spinner_loader";
import { Redirect } from "react-router-dom";
import PurchaseSellers from "./purchase_sellers";
import NavigationTabs from "../../seller_center/shared_components/navigation_tabs";
import useFilterOptions from "../../hooks/useFilterOptions";
import useFilter from "../../filter/use_filter";
import PageTitle from "../../seller_center/create_listing/page_title";
import PageVariablesContext from "../../page_variables_context";

const GET_BUYER_ORDERS_PAGE_DATA = gql`
    query GetOrdersAndShippingPageDetails($userId: ID!, $userType: String!) {
        result: getOrdersAndShippingPageData(userId: $userId, userType: $userType) {
            firstTransactionDate
            navigationTabs {
                text
                count
                disabled
                priority
            }
        }
    }
`;

const pageClass = [
    "px-8 py-8 md:px-18 md:py-12 lg:px-20 xl:px-32 2xl:px-60",
    "animate__animated animate__fadeIn",
    "bg-fixed bg-cover bg-center bg-no-repeat",
    "min-h-screen",
    "text-white",
].join(" ");

const MyOrders = () => {
    const { userId } = useContext(PageVariablesContext);
    const { _search, initialPage } = useFilterOptions();
    const [searchString] = _search;
    const [DEFAULT_VARIABLES, setDefaultVariables] = useState({
        userId: userId,
        status: "To Receive",
        searchString: searchString,
        userType: "buyer",
        options: {
            page: Number(initialPage),
            itemsPerPage: 6,
            sortCategory: "added",
            sortOrder: "desc",
        },
    });

    const [getOrderData, { data: pageData }] = useLazyQuery(
        GET_BUYER_ORDERS_PAGE_DATA,
        {
            variables: { userId, userType: "buyer" },
            fetchPolicy: "cache-first",
        }
    );

    const { setVariables, variables, results, loading, Pagination } = useFilter(
        GET_USER_ORDERS,
        DEFAULT_VARIABLES
    );

    const [orders, setOrders] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("To Receive");
    const [navItems, setNavItems] = useState([]);

    useEffect(() => {
        if (pageData) {
            setNavItems([...pageData?.result?.navigationTabs]);
        }
    }, [pageData]);

    const fetchData = () => {
        setVariables({
            ...DEFAULT_VARIABLES,
            status: selectedStatus,
        });
        getOrderData();
    };

    useEffect(() => {
        fetchData();
    }, [selectedStatus]);

    useEffect(() => {
        setOrders(results?.orders);
        setDefaultVariables({
            ...variables,
            searchString: results?.filters?.searchString,
            status: selectedStatus,
        });
    }, [results]);

    if (!userId) {
        return <Redirect to="/marketplace" />;
    }

    return (
        <Fragment>
            <div
                className={pageClass}
                style={{ backgroundImage: `url(${BgLights})` }}>
                <PageTitle title="My Orders" showBorder={false} />
                <NavigationTabs
                    tabs={navItems}
                    selectedTab={selectedStatus}
                    setSelectedTab={setSelectedStatus}
                    customClass="pt-6"
                />
                <div className="py-4 md:p-12">
                    {loading ? <SpinnerLoader /> : null}
                    {!loading ? (
                        <div className="flex flex-col space-y-6">
                            <PurchaseSellers items={orders} callback={fetchData} />
                            <Pagination />
                        </div>
                    ) : null}
                </div>
            </div>
        </Fragment>
    );
};

export default MyOrders;

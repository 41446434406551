import React from "react"
import SpinnerLoader from "../../spinner_loader"
import PageInfo from "../shared_components/page_info"

const ListNav = ({
    lists,
    activeList,
    loading,
    hasList,
    setActiveList = () => {},
}) => {
    const activeClass = "text-sm font-semibold font-body border-l-2 border-primary px-6"
    const defaultClass = "text-sm font-medium text-gray-30 px-6"

    const handleListChange = (listId) => {
        if (listId === activeList) return false
        setActiveList(listId)
    }

    return (
        <div className="flex flex-col space-y-4">
            {loading ? (
                <SpinnerLoader />
            ) : null}
            {hasList ? (
                <>
                    {lists.map(list => (
                        <div key={list.id} className={list.id === activeList ? activeClass : defaultClass}>
                            <button
                                onClick={() => handleListChange(list.id)}
                            >
                                {list.name} [{list?.listableOwnedCardsCount}]
                            </button>
                        </div>
                    ))}
                </>
            ) : (
                <PageInfo type="No lists found." />
            )}
        </div>
    )
}

export default ListNav
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

function useFilterOptions() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search.substring(1));
    const q = Object.fromEntries(searchParams);
    const validParams = [
        "search",
        "page",
        "list",
        "isBase",
        "hasJersey",
        "hasPatch",
        "hasAutograph",
        "isRookie",
        "isVerified",
        "hasInsert",
        "hasLogoman"
    ]
    let urlParams = Object.keys(q)
                          .filter(key => validParams.includes(key))
                          .reduce((obj, key) => {
                              obj[key] = q[key];
                              return obj;
                           }, {});
    const { search: initialSearch, page = 1, list, ...initialOptions } = urlParams;

    let coercedOptions = {};
    Object.keys(initialOptions).forEach((k) => {
        coercedOptions[k] = JSON.parse(initialOptions[k]);
    });

    const _options = useState(coercedOptions);
    const _search = useState(initialSearch || null);

    return {
        _options,
        _search,
        initialPage: page || 1,
    };
}

export default useFilterOptions;

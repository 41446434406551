import React, { useContext, useEffect, useState } from "react";
import SpinnerLoader from "../../spinner_loader";
import { gql, useQuery } from "@apollo/client";
import { GET_USER_ORDERS } from "../../queries";
import NavigationTabs from "../shared_components/navigation_tabs";
import PurchaseSellers from "../../marketplace/my_orders/purchase_sellers";
import Checkbox from "../../checkbox";
import { Printer, Truck } from "react-feather";
import useFilterOptions from "../../hooks/useFilterOptions";
import useFilter from "../../filter/use_filter";
import DateRangePicker from "../shared_components/date_range_picker";
import useSelection from "../../hooks/useSelection";
import SellerCenterContainer from "../seller_center_container";
import { ORDERS_SORT_OPTIONS } from "../../sort_options";
import PageVariablesContext from "../../page_variables_context";

const GET_PAGE_DATA = gql`
    query GetOrdersAndShippingPageDetails($userId: ID!, $userType: String!) {
        result: getOrdersAndShippingPageData(userId: $userId, userType: $userType) {
            firstTransactionDate
            navigationTabs {
                text
                count
                disabled
                priority
            }
            ordersSummary {
                activeOrders
                fulfilledOrders
                cardsSold
            }
        }
    }
`;

const OrdersAndShipping = () => {
    const {
        userId,
        hasNegativeBalance,
    } = useContext(PageVariablesContext);

    const { _search, initialPage } = useFilterOptions();
    const [searchString] = _search;
    const [DEFAULT_VARIABLES, setDefaultVariables] = useState({
        userId: userId,
        status: "Active Orders",
        searchString: searchString,
        userType: "seller",
        options: {
            page: Number(initialPage),
            itemsPerPage: 6,
            sortCategory: "added",
            sortOrder: "desc",
        },
    });

    const {
        SearchField,
        SortField,
        Pagination,
        setVariables,
        variables,
        results,
        loading,
    } = useFilter(GET_USER_ORDERS, DEFAULT_VARIABLES, ORDERS_SORT_OPTIONS);

    const { data: pageData } = useQuery(GET_PAGE_DATA, {
        fetchPolicy: "cache-first",
        variables: {
            userId,
            userType: "seller"
        },
    });

    const [transactionItems, setTransactionItems] = useState([]);
    const [navItems, setNavItems] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("Active Orders");
    const [dateFilterCategory, setDateFilterCategory] = useState("order");

    const summary = pageData?.result?.ordersSummary;
    const endDate = new Date().toISOString().substring(0, 10);
    const startDate = pageData?.result?.firstTransactionDate || endDate;

    useEffect(() => {
        if (pageData) {
            setNavItems([...pageData?.result?.navigationTabs]);
        }
    }, [pageData]);

    useEffect(() => {
        fetchData();
    }, [selectedStatus]);

    const fetchData = () => {
        setVariables({
            ...DEFAULT_VARIABLES,
            status: selectedStatus,
        });
    };

    useEffect(() => {
        setTransactionItems(results?.orders);
        setDefaultVariables({
            ...variables,
            searchString: results?.filters?.searchString,
            status: selectedStatus,
        });
    }, [results]);

    const handleDateFilterChange = (dateValues) => {
        // if (!dateValues.startDate && !dateValues.endDate) return

        setVariables({
            ...DEFAULT_VARIABLES,
            options: {
                ...DEFAULT_VARIABLES.options,
                ...(dateValues && {
                    dateFilter: {
                        category: dateFilterCategory,
                        from: dateValues.startDate,
                        to: dateValues.endDate,
                    },
                }),
            },
        });
    };

    const { selected, select, deSelect } = useSelection([]);

    if (hasNegativeBalance)  {
        window.location.replace("/seller_center/top_up/required");
        return true;
    }

    return (
        <SellerCenterContainer>
            <div className="flex flex-col w-full">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <p className="font-body font-semibold text-xl text-gray-1000">
                        You have {summary?.activeOrders} active orders
                    </p>
                    <div className="flex divide divide-x-2 divide-gray-300 divide-opacity-40 text-md">
                        <div className="flex space-x-2 px-3">
                            <span className="font-semibold text-gray-1000">
                                {summary?.fulfilledOrders}
                            </span>
                            <span className="text-gray-30">
                                Total fulfilled orders
                            </span>
                        </div>
                        <div className="flex space-x-2 px-3">
                            <span className="font-semibold text-gray-1000">
                                {summary?.cardsSold}
                            </span>
                            <span className="text-gray-30">
                                Cards sold
                            </span>
                        </div>
                    </div>
                </div>
                <div className="space-y-10">
                    <NavigationTabs
                        tabs={navItems}
                        selectedTab={selectedStatus}
                        setSelectedTab={setSelectedStatus}
                        customClass="pt-6"
                    />
                    {!loading ? (
                        <>
                            <div className="flex flex-col md:flex-row md:items-center text-gray-1000">
                                <div className="bg-gray-800 p-0 flex items-center rounded md:mr-auto">
                                    <SearchField darkMode />
                                </div>
                                <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
                                    <div className="p-0 flex flex-col md:flex-row items-stretch md:items-center border-2 border-gray-1000 border-opacity-30 divide-y-2 md:divide-y-0 md:divide-x-2 divide-gray-1000 divide-opacity-30">
                                        <select
                                            className="bg-transparent border-0 w-full md:w-auto focus:border-0 focus:ring-0"
                                            defaultValue={dateFilterCategory}
                                            onChange={(e) =>
                                                setDateFilterCategory(
                                                    e.target.value
                                                )
                                            }>
                                            <option
                                                className="text-black-100"
                                                value="order">
                                                Order date
                                            </option>
                                            <option
                                                className="text-black-100"
                                                value="shipment">
                                                Shipment date
                                            </option>
                                        </select>
                                        <DateRangePicker
                                            value={{ startDate, endDate }}
                                            onChange={(dates) =>
                                                handleDateFilterChange(dates)
                                            }
                                            showClearInputs
                                            triggerChangeOnClear
                                        />
                                    </div>
                                    <div className=" flex items-center py-0 px-2 border-2 border-gray-200 border-opacity-30">
                                        <span>Sort By:</span>
                                        <SortField
                                            selectBGColor="bg-transparent"
                                            selectClassName="border-0 ring-0 focus:ring-0"
                                            selectTextColor="text-gray-1000"
                                            optionClass="text-black-100"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-6 bg-gray-800 rounded-md flex flex-col md:flex-row items-center justify-between">
                                <Checkbox
                                    id="selectAllTransaction"
                                    name="selectAllTransaction"
                                    onChange={(e) =>
                                        e.target.checked
                                            ? select(
                                                transactionItems?.map(
                                                    (t) => t.id
                                                )
                                            )
                                            : deSelect(
                                                transactionItems?.map(
                                                    (t) => t.id
                                                )
                                            )
                                    }
                                    label="Select all items"
                                    checked={
                                        selected.length ===
                                            transactionItems?.length &&
                                        Boolean(transactionItems.length)
                                    }
                                    inputDimension={5}
                                    labelClass="cursor-pointer text-gray-30 text-lg space-x-3"
                                    inputBGColor="bg-gray-650"
                                />
                                <div className="flex space-x-6 items-center text-gray-1000">
                                    <div className="flex items-center space-x-2 opacity-40">
                                        <Truck
                                            size={18}
                                            fillOpacity={40}
                                            fill="currentColor"
                                        />
                                        <span className="font-semibold">
                                            Mass shipment
                                        </span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Printer
                                            size={18}
                                            className="text-primary"
                                        />
                                        <span className="font-semibold">
                                            Print invoice
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                    {loading && <SpinnerLoader />}
                    {!loading && Boolean(transactionItems?.length) && (
                        <>
                            <PurchaseSellers
                                items={transactionItems}
                                selected={selected}
                                select={select}
                                deSelect={deSelect}
                                callback={fetchData}
                                seller
                            />
                            <Pagination placement="end" />
                        </>
                    )}
                    {!loading && !Boolean(transactionItems?.length) && (
                        <div className="flex justify-center w-full">
                            <div className="text-4xl text-white">
                                No orders found.
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </SellerCenterContainer>
    );
};

export default OrdersAndShipping;

import { useLazyQuery } from "@apollo/client";
import React, { Fragment, useEffect, useState } from "react";
import { render } from "react-dom";
import ApolloProviderClient from "./apollo_provider_client";
import Modal from "./modal";
import ModalPortal from "./modal/modal_portal";
import { GET_OWNED_CARD } from "./queries";
import UpdateOwnedCard from "./trophy_room/update_owned_card";

const StandAloneUpdateOwnedCard = ({ cardId }) => {
    const [mountModal, setMountModal] = useState(false);
    const [getOwnedCard, { data }] = useLazyQuery(GET_OWNED_CARD, {
        variables: {
            id: cardId,
        },
    });

    useEffect(() => {
        console.log(mountModal);
        if (mountModal) {
            getOwnedCard();
        }
    }, [mountModal]);

    function closeModal() {
        setMountModal(false);
    }

    /* Need to overwrite cardId with the ownerCardId to make use 
       of the modal.
    */
    const newCard = {
        ...data?.card,
        cardId: cardId,
    };

    return (
        <Fragment>
            <button
                type="button"
                className="mt-4 px-2 py-1 text-center w-full border border-white rounded"
                onClick={() => {
                    setMountModal(true);
                }}>
                Edit card details
            </button>
            <ModalPortal id="modalCardList">
                {mountModal && (
                    <Modal
                        title="Update Owned Card"
                        closeModal={closeModal}
                        preventESC>
                        {({ hideModal }) => (
                            <UpdateOwnedCard
                                hideModal={hideModal}
                                card={newCard || {}}
                            />
                        )}
                    </Modal>
                )}
            </ModalPortal>
        </Fragment>
    );
};

const App = (props) => {
    return (
        <ApolloProviderClient>
            <StandAloneUpdateOwnedCard {...props} />
        </ApolloProviderClient>
    );
};

export default () => {
    document.addEventListener("DOMContentLoaded", () => {
        const container = document.getElementById("updateOwnedCardComponent");
        if (container) {
            const { cardId, userId } = container.dataset;

            render(<App cardId={cardId} userId={userId} />, container);
        }
    });
};

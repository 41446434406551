import React, { Fragment, useContext, useEffect, useState } from "react";
import BgLightsDarker from "bg-lights-darker.png";
import PageTitle from "./page_title";
import CardImageContainer from "./card_image_container";
import { useLazyQuery } from "@apollo/client";
import { GET_OWNED_CARD, GET_USER_FULFILLMENT_OPTIONS } from "../../queries";
import { useHistory, useParams   } from "react-router-dom";
import { X } from "react-feather";
import ListingForm from "./listing_form";
import CardDetails from "./card_details";
import PageInfo from "../shared_components/page_info";
import Button from "../shared_components/button";
import PageVariablesContext from "../../page_variables_context";

const CreateListing = () => {
    const { id } = useParams();
    const history = useHistory();
    const [images, setImages] = useState({});

    const {
        userId,
        isSellingAllowed,
        hasNegativeBalance,
    } = useContext(PageVariablesContext);

    const [getOwnedCard, { data, loading, called }] = useLazyQuery(
        GET_OWNED_CARD,
        {
            fetchPolicy: "cache-first",
            variables: { id, userId },
        }
    );

    const [getShippingMethods, { data: shippingData, called: shippingCalled }] =
        useLazyQuery(GET_USER_FULFILLMENT_OPTIONS, {
            variables: { userId: userId },
        });

    const pageClass = [
        "px-8 py-8 md:px-18 md:py-12 lg:px-24 xl:px-32 2xl:px-60",
        "animate__animated animate__fadeIn",
        "bg-fixed bg-cover bg-center bg-no-repeat",
        "min-h-screen",
        "text-gray-1000",
    ].join(" ");

    useEffect(() => {
        if (!shippingCalled) getShippingMethods();
        if (!called) getOwnedCard();
    }, []);

    const card = data?.card;

    useEffect(() => {
        if (card) {
            const listingId = card?.activeMarketListing?.id
                            || card?.draftMarketListing?.id
                            || card?.soldMarketListing?.id;
            if (listingId) {
                window.location.replace(`/seller_center/my_listings/${listingId}/preview`);
                return;
            }

            setImages({
                front: card?.frontImage,
                back: card?.backImage,
            });
        }
    }, [card]);

    const onImageUpdate = (img, side) => {
        if (side === "front") setImages({ ...images, front: img });
        if (side === "back") setImages({ ...images, back: img });
    };

    if (hasNegativeBalance)  {
        window.location.replace("/seller_center/top_up/required");
        return true;
    }

    return (
        <div
            className={pageClass}
            style={{ backgroundImage: `url(${BgLightsDarker})` }}>
                {isSellingAllowed ? (
                    <Fragment>
                        {card &&
                            !loading &&
                            !card.activeMarketListing &&
                            !card.draftMarketListing && (
                                <div className="merqary-container-sm px-12 bg-gray-800 rounded mx-auto">
                                    <PageTitle title="Add new listing">
                                        <Button
                                            transparent
                                            type="button"
                                            onClick={() => history.goBack()}
                                            icon={<X className="w-4 h-4" />}
                                            text="Cancel"
                                            customClass="text-sm"
                                        />
                                    </PageTitle>
                                    <div className="grid grid-cols-10 gap-0 py-8 space-y-6 lg:space-y-0">
                                        <div className="col-span-full lg:col-span-3 flex justify-center lg:justify-start">
                                            <CardImageContainer
                                                {...card}
                                                frontImage={images.front}
                                                backImage={images.back}
                                                removeAutoMargin
                                                width="w-2/3 lg:w-3/4"
                                            />
                                        </div>
                                        <div
                                            className="divide divide-y-2 divide-opacity-40 space-y-12 col-span-full lg:col-span-7">
                                            <CardDetails
                                                {...card}
                                                onImageUpdate={onImageUpdate}
                                                longerHeader
                                                reloadOnUpdate={getOwnedCard}
                                            />
                                            <ListingForm
                                                {...card}
                                                ownedCardId={card.id}
                                                fulfillmentOptions={
                                                    shippingData?.fulfillmentOptions
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        {!card && !loading && called && (
                            <div className="flex justify-center w-full mt-24">
                                <div className="text-4xl text-white">Card not found</div>
                            </div>
                        )}
                        {card && !loading && card.activeMarketListing && (
                            <div className="flex justify-center w-full mt-24">
                                <div className="grid text-center">
                                    <span className="text-4xl text-white">
                                        Card is already for sale
                                    </span>
                                    <a
                                        className="underline text-gray-30"
                                        href={`/seller_center/my_listings/${card.activeMarketListing.id}`}>
                                        View listing
                                    </a>
                                </div>
                            </div>
                        )}
                        {card && !loading && card.draftMarketListing && (
                            <div className="flex justify-center w-full mt-24">
                                <div className="grid text-center">
                                    <span className="text-4xl text-white">
                                        Card is already in your drafts
                                    </span>
                                    <a
                                        className="underline text-gray-30"
                                        href={`/seller_center/my_listings/${card.draftMarketListing.id}`}>
                                        View listing
                                    </a>
                                </div>
                            </div>
                        )}
                    </Fragment>
                ) : (
                    <PageInfo
                        type="sellingNotAllowed"
                    />
                )}
        </div>
    );
};

export default CreateListing;

import React, {
    Fragment,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import PlayerCard from "./player_card";
import ModalPortal from "../modal/modal_portal";
import Modal from "../modal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_CARDS_TO_HAVE_LIST, ADD_CARDS_TO_WANT_LIST } from "../mutations";
import UserIdContext from "./user_id_context";
import { GET_ALL_STASHED_CARDS, GET_USER_LISTS } from "../queries";
import SelectList from "./select_list";
import UpdateOwnedCard from "./update_owned_card";
import SpinnerLoader from "../spinner_loader";
import { PopupContext, POPUP_TYPES } from "../popup_context";

const ADD_TO_WANT_LIST_MODAL = "ADD_TO_WANT_LIST_MODAL";
const ADD_TO_HAVE_LIST_MODAL = "ADD_TO_HAVE_LIST_MODAL";
const EDIT_OWNED_CARD_MODAL = "EDIT_OWNED_CARD_MODAL";

const CardSelectionTextInfo = ({ len, type }) => {
    const modalTypeText =
        type === ADD_TO_WANT_LIST_MODAL ? "want list" : "have list";

    return (
        <p className="mb-3">
            You are adding{" "}
            <span className="font-bold">
                {len} card{len !== 1 ? "s" : ""} to your {modalTypeText}.
            </span>{" "}
            Select list name to continue.
        </p>
    );
};

export const ModalBody = ({
    hideModal,
    selectedCards,
    selectedListOptions,
    modalType,
}) => {
    const { setMessage, setPopupAction, setShowPopup } =
        useContext(PopupContext);
    const [selectedListId, setSelectedListId] = useState("");
    const ref = useRef(null);
    const isHaveList = modalType === ADD_TO_HAVE_LIST_MODAL;
    const isWantList = modalType === ADD_TO_WANT_LIST_MODAL;

    const [addToHaveList, haveRes] = useMutation(ADD_CARDS_TO_HAVE_LIST, {
        variables: {
            input: {
                cardIds: selectedCards,
                haveListId: selectedListId,
                removeFromStash: true,
            },
        },
        onCompleted: resetInput,
        update: (cache, { data }) => {
            const existingStashedCards = cache.readQuery({
                query: GET_ALL_STASHED_CARDS,
            });

            const haveCards = [
                ...existingStashedCards?.haveCards,
                ...selectedCards,
            ];

            if (!data?.errors?.length) {
                cache.writeQuery({
                    query: GET_ALL_STASHED_CARDS,
                    data: {
                        haveCards,
                    },
                });
            }
        },
    });

    const [addToWantList, wantRes] = useMutation(ADD_CARDS_TO_WANT_LIST, {
        variables: {
            input: {
                cardIds: selectedCards,
                wantListId: selectedListId,
                removeFromStash: true,
            },
        },
        onCompleted: resetInput,
    });

    function resetInput({ addCardToList }) {
        const { payPopupAction, payPopupMessage, errors } = addCardToList;
        setShowPopup(true);
        setSelectedListId("");
        hideModal();
        if (errors.length) {
            setPopupAction(POPUP_TYPES.DANGER);
            setMessage(errors.join(", "));
        } else {
            setPopupAction(payPopupAction);
            setMessage(payPopupMessage);
        }
    }

    const isLoading = wantRes?.loading || haveRes?.loading;

    const handleSave = () => {
        if (isHaveList) {
            addToHaveList();
        } else if (isWantList) {
            addToWantList();
        }
    };

    useEffect(() => {
        ref?.current?.focus();
    }, []);

    return (
        <div className="p-4 flex flex-col">
            <CardSelectionTextInfo
                len={selectedCards?.length}
                type={modalType}
            />
            <SelectList
                ref={ref}
                onChange={(e) => setSelectedListId(e.target.value)}
                value={selectedListId}
                options={selectedListOptions}
            />

            <button
                disabled={isLoading}
                type="button"
                onClick={handleSave}
                className="rounded bg-primary hover:bg-primary-500 text-black font-semibold text-center w-full p-2">
                Save
            </button>
        </div>
    );
};

const CardList = ({
    cards,
    canStash = false,
    stashedCardIds = [],
    ownedCardIds = [],
    rowView = false,
    isHaveList,
    isWantList,
    isViewOnly = false,
    loading,
    featuredView = false,
    listId,
    refreshList = () => {},
    haveLists,
}) => {
    const userId = useContext(UserIdContext);
    const [modalType, setModalType] = useState(ADD_TO_HAVE_LIST_MODAL);
    const [mountModal, setMountModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [getUserLists, { data }] = useLazyQuery(GET_USER_LISTS, {
        variables: {
            userId,
        },
    });
    const haveListOptions = data?.haveLists?.map(({ id, name }) => ({
        id,
        name,
    }));
    const wantListOptions = data?.wantLists?.map(({ id, name }) => ({
        id,
        name,
    }));

    const selectedListOptions =
        modalType === ADD_TO_WANT_LIST_MODAL
            ? wantListOptions
            : haveListOptions;

    useEffect(() => {
        if (userId) {
            getUserLists();
        }
    }, [userId]);

    function addToHaveList({ cardId }) {
        setSelectedCard({ cardId });
        setModalType(ADD_TO_HAVE_LIST_MODAL);
        setMountModal(true);
    }

    function addToWantList({ cardId }) {
        setSelectedCard({ cardId });
        setModalType(ADD_TO_WANT_LIST_MODAL);
        setMountModal(true);
    }

    function closeModal() {
        setSelectedCard(null);
        setMountModal(false);
    }

    function editCardModal(card) {
        setModalType(EDIT_OWNED_CARD_MODAL);
        setMountModal(true);
        editOwnedCardformValues.current = {
            ...card,
            haveListIds: card?.haveLists.map(h => h.id)
        };
    }

    const editOwnedCardformValues = useRef({});

    const isEditModal = modalType === EDIT_OWNED_CARD_MODAL;
    return (
        <Fragment>
            <div className="grid grid-cols-12 gap-6 w-5/6 mx-auto sm:w-full mb-8">
                {loading ? (
                    <SpinnerLoader />
                ) : (
                    cards.map(({ id, ...c }, i) => (
                        <PlayerCard
                            key={i}
                            id={id}
                            {...c}
                            {...(isHaveList && c.ownedCard)}
                            canStash={canStash}
                            canEdit={!isViewOnly && isHaveList}
                            isStashed={stashedCardIds.includes(id)}
                            isVaulted={ownedCardIds.includes(id) || isHaveList}
                            isRow={rowView}
                            addToHaveList={addToHaveList.bind(null, {
                                cardId: id,
                            })}
                            addToWantList={addToWantList.bind(null, {
                                cardId: id,
                            })}
                            editCardModal={
                                isHaveList &&
                                editCardModal.bind(null, {
                                    ...c.ownedCard,
                                    cardId: c.ownedCard.id,
                                })
                            }
                            isFeatured={featuredView}
                            listId={listId}
                            isHaveList={isHaveList}
                            isWantList={isWantList}
                            canAddToList={userId}
                            forSale={c.isForSale}
                            refreshList={refreshList}
                        />
                    ))
                )}
            </div>
            <ModalPortal id="modalCardList">
                {mountModal && (
                    <Modal
                        title={
                            isEditModal
                                ? "Update owned card"
                                : "Add card to list"
                        }
                        size="sm"
                        closeModal={closeModal}
                        preventESC={isEditModal}>
                        {({ hideModal }) =>
                            isEditModal ? (
                                <UpdateOwnedCard
                                    hideModal={hideModal}
                                    options={haveListOptions}
                                    refreshList={refreshList}
                                    formValues={editOwnedCardformValues.current}
                                    haveLists={haveLists}
                                />
                            ) : (
                                <ModalBody
                                    modalType={modalType}
                                    selectedCards={[selectedCard?.cardId]}
                                    selectedListOptions={selectedListOptions}
                                    hideModal={hideModal}
                                />
                            )
                        }
                    </Modal>
                )}
            </ModalPortal>
        </Fragment>
    );
};
export default CardList;

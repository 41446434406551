import React from "react";

const PAGE_INFO_TYPES = {
    sellingNotAllowed: {
        title: "This feature of your seller center is currently disabled.",
        subText: "Please upgrade your subscription plan to fully use your seller center.",
        url: "/subscriptions",
        urlText: "Learn more."
    },
    noListsFound: {
        subText: "No lists found."
    },
    sellerNotFound: {
        title: "Seller not found",
        url: "/marketplace",
        urlText: "Back to Marketplace"
    },
    noCardsListed: {
        title: "No cards listed"
    },
};

const PageInfo = ({ type, titleFontSize, subTextFontSize, titleColor, subTextColor }) => {
    const infoType = PAGE_INFO_TYPES[type];
    if (!infoType) return null;

    const { title, subText, url, urlText } = infoType;

    const titleClass = [titleFontSize, titleColor].join(" ");
    const subTextClass = ["space-x-2", subTextFontSize, subTextColor].join(" ");

    return (
        <div className="flex flex-col text-center w-full pt-16 text-gray-30 space-y-2">
            <span className={titleClass}>{title}</span>
            <div className={subTextClass}>
                <span>{subText}</span>
                {url ? (
                    <a href={url} className="underline">{urlText}</a>
                ) : null}
            </div>
        </div>
    );
}

PageInfo.defaultProps = {
    type: null,
    titleFontSize: "text-3xl",
    subTextFontSize: "text-medium",
    titleColor: "text-gray-30",
    subTextColor: "text-gray-30"
}

export default PageInfo;

import { gql } from "@apollo/client";
import {
    GET_ALL_STASHED_CARDS,
    SEARCH_USER_HAVE_CARDS,
    SEARCH_WANT_CARDS,
} from "../queries";

const STASH_CARD = gql`
    mutation StashCard($input: StashCardInput!) {
        stashCard(input: $input) {
            card {
                id
                hasPatch
                hasInsert
                hasJersey
                hasLogoman
                hasAutograph
                frontImage
                backImage
                sport {
                    name
                }
                cardName
                cardNumber
                printRun
                hasMarketListings
                playersJson
            }
            errors
        }
    }
`;

function updateStash(cache, { data }) {
    const newStashedCard = data?.stashCard?.card;
    const existingStashedCards = cache.readQuery({
        query: GET_ALL_STASHED_CARDS,
    });
    const lists = [...existingStashedCards?.stashedCards, newStashedCard];

    if (newStashedCard) {
        cache.writeQuery({
            query: GET_ALL_STASHED_CARDS,
            data: {
                stashedCards: lists,
                haveCards: existingStashedCards?.haveCards
            },
        });
    }
}

function updateUnstash(cache, { data }, cardIds) {
    const existingStashedCards = cache.readQuery({
        query: GET_ALL_STASHED_CARDS,
    });

    const isArray = Array.isArray(cardIds);

    const lists = existingStashedCards?.stashedCards?.filter((c) =>
        isArray ? !cardIds.includes(c.id) : c.id !== cardIds
    );

    if (data?.deleteStashedCard?.result && existingStashedCards) {
        cache.writeQuery({
            query: GET_ALL_STASHED_CARDS,
            data: {
                stashedCards: lists,
                haveCards: existingStashedCards?.haveCards
            },
        });
    }
}

function updateRemoveFromStash(cache, { data }, cardIds) {
    const existingStashedCards = cache.readQuery({
        query: GET_ALL_STASHED_CARDS,
    });

    const lists = existingStashedCards?.stashedCards?.filter(
        (c) => !cardIds.includes(c.id)
    );

    if (data?.addCardToList?.success) {
        cache.writeQuery({
            query: GET_ALL_STASHED_CARDS,
            data: {
                stashedCards: lists,
            },
        });
    }
}

const UNSTASH_CARD = gql`
    mutation UnstashStashCard($input: DeleteStashedCardInput!) {
        deleteStashedCard(input: $input) {
            result
            errors
        }
    }
`;

const ADD_CARDS_TO_HAVE_LIST = gql`
    mutation AddCardsToHaveList($input: AddCardToHaveListInput!) {
        addCardToList: addCardToHaveList(input: $input) {
            cards {
                id
            }
            errors
            success
            payPopupAction
            payPopupMessage
        }
    }
`;

/**
 * {
 *   variables {
 *     cardIds: [],
 *     wantListId: ''
 *   }
 * }
 *
 */

const ADD_CARDS_TO_WANT_LIST = gql`
    mutation AddCardsToWantList($input: AddCardToWantListInput!) {
        addCardToList: addCardToWantList(input: $input) {
            cards {
                id
            }
            errors
            success
            payPopupAction
            payPopupMessage
        }
    }
`;

/**
 * {
 *  variables: {
 *      haveListId: ''
 *      cardIds: []
 *  }
 * }
 */

const DELETE_CARD_FROM_HAVE_LIST = gql`
    mutation RemoveCardsFromHaveList($input: DeleteCardsFromHaveListInput!) {
        removeCardFromList: deleteCardsFromHaveList(input: $input) {
            result
            errors
        }
    }
`;

const DELETE_CARD_FROM_WANT_LIST = gql`
    mutation RemoveCardsFromWantList($input: DeleteCardsFromWantListInput!) {
        removeCardFromList: deleteCardsFromWantList(input: $input) {
            result
            errors
        }
    }
`;

function updateRemoveCardFromList(
    cache,
    { data },
    { haveCardIds, isHaveList, ...variables }
) {
    const cs = cache.readQuery({
        query: isHaveList ? SEARCH_USER_HAVE_CARDS : SEARCH_WANT_CARDS,
        variables: {
            ...variables,
        },
    });

    const cards = cs?.results?.cards?.filter(
        (c) => !haveCardIds.includes(c.id)
    );
    if (data?.removeCardFromList?.result) {
        cache.writeQuery({
            query: isHaveList ? SEARCH_USER_HAVE_CARDS : SEARCH_WANT_CARDS,
            variables: {
                ...variables,
            },
            data: {
                results: {
                    ...cs.results,
                    cards,
                },
            },
        });
    }
}

export {
    STASH_CARD,
    UNSTASH_CARD,
    updateStash,
    updateUnstash,
    ADD_CARDS_TO_HAVE_LIST,
    ADD_CARDS_TO_WANT_LIST,
    updateRemoveFromStash,
    DELETE_CARD_FROM_HAVE_LIST,
    DELETE_CARD_FROM_WANT_LIST,
    updateRemoveCardFromList,
};

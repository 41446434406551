import React from "react";
import { Link } from "react-router-dom";
import SellerTransaction from "../../seller_center/orders_and_shipping/seller_transaction";
import PurchaseSeller from "./purchase_seller";

const PurchaseSellers = ({
    items,
    seller = false,
    selected,
    select,
    deSelect,
    callback = () => {},
}) => {
    return (
        <div className="space-y-6 overflow-hidden">
            {items?.map((item, i) => (
                <div key={item.id}>
                    {/* TODO: refactor once component and use for both buyer and seller */}
                    {seller ? (
                        <SellerTransaction
                            {...item}
                            callback={callback}
                            selected={selected}
                            select={select}
                            deSelect={deSelect}
                        />
                    ) : (
                        <PurchaseSeller {...item} callback={callback} />
                    )}
                </div>
            ))}
            {items?.length < 1 && (
                <div className="grid items-center justify-center">
                    <div className="text-3xl font-semibold text-white">
                        No orders found.
                    </div>
                    {!seller && (
                        <Link
                            to={`/marketplace`}
                            className="underline text-white text-center">
                            Browse the Marketplace
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
};

export default PurchaseSellers;

import React, { Fragment } from "react";
import SpinnerLoader from "../../spinner_loader";
import MarketCard from "./market_card";

const MarketCardList = ({
    withCheckbox = false,
    withTeamNames = false,
    showFlip,
    showWantIcon,
    showCartIcon,
    listings,
    rowView = false,
    loading,
    isMarketPlace = false,
    callback = () => {},
    numTruncatedCardName,
    smallerList,
    unlistedList = false,
    newListingList = false,
    sellerProfileList = false,
}) => {
    const containerClassName = [
        newListingList
            ? ""
            : "grid grid-cols-4 gap-6 w-full mb-8",
        isMarketPlace ? "px-4 md:ml-8 pb-4" : "py-4"
    ].join(" ");

    return (
        <Fragment>
            <div
                className={containerClassName}>
                {loading ? (
                    <div className="p-4">
                        <SpinnerLoader />
                    </div>
                ) : (
                    listings.map((listing, i) => (
                        <MarketCard
                            key={i}
                            isRow={rowView}
                            {...listing}
                            {...listing.card}
                            {...listing.ownedCard}
                            {...listing.purchaseSeller}
                            showFlip={showFlip}
                            showWantIcon={showWantIcon}
                            showCartIcon={showCartIcon}
                            withCheckbox={withCheckbox}
                            isMarketPlace={isMarketPlace}
                            callback={callback}
                            withTeamNames={withTeamNames}
                            numTruncatedCardName={numTruncatedCardName}
                            smallerList={smallerList}
                            unlistedList={unlistedList}
                            newListingList={newListingList}
                            sellerProfileList={sellerProfileList}
                        />
                    ))
                )}
            </div>
        </Fragment>
    );
};
export default MarketCardList;

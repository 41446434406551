import React from "react";
import { Edit2, Trash } from "react-feather";
import Button from "../../seller_center/shared_components/button";

const icons = {
    edit: <Edit2 size={16} className="text-primary" fill="currentColor" />,
    delete: <Trash size={16} className="text-gray-200" />,
};

const CardContainer = ({
    title,
    subText,
    subTextAlignment,
    fullWidthHeader,
    actionText,
    actionIcon,
    onActionClick,
    titleBorder,
    children,
}) => {
    const titleClass =
        subTextAlignment === "col"
            ? "flex flex-col space-y-1"
            : "flex space-x-2 items-center";
    const containerClass = [
        "py-6",
        !fullWidthHeader ? "p-6" : "",
        "rounded shadow",
        "bg-gray-800",
    ].join(" ");
    const titleContainerClass = [
        "pb-4",
        "flex justify-between items-center flex-wrap",
        fullWidthHeader ? "px-6" : "",
        titleBorder ? "border-b-2 border-gray-200 border-opacity-40" : "",
    ].join(" ");

    return (
        <div className={containerClass}>
            <div className={titleContainerClass}>
                <div className={titleClass}>
                    {title && (
                        <span className="text-lg font-semibold text-gray-30">
                            {title}
                        </span>
                    )}
                    {subText && (
                        <span className="text-md text-gray-30">{subText}</span>
                    )}
                </div>

                {actionText && (
                    <Button
                        icon={actionIcon && icons[actionIcon]}
                        text={actionText}
                        type="button"
                        transparent
                        customClass="font-semibold text-gray-30 ml-auto"
                        onClick={() => onActionClick()}
                    />
                )}
            </div>
            <div className="py-4">{children}</div>
        </div>
    );
};

CardContainer.defaultProps = {
    title: null,
    subText: null,
    subTextAlignment: "col",
    fullWidthHeader: false,
    actionText: null,
    actionIcon: null,
    onActionClick: () => {},
    titleBorder: false,
};

export default CardContainer;

import React from "react";

export const DEFAULT_SORT = {
    sortCategory: "",
    sortOrder: "",
};

const NEWEST = {
    sortCategory: "added",
    sortOrder: "desc",
};

const OLDEST = {
    ...DEFAULT_SORT,
    sortOrder: "asc",
};

const SCORE_DESC = {
    sortCategory: "score",
    sortOrder: "desc",
};

const SCORE_ASC = {
    ...SCORE_DESC,
    sortOrder: "asc",
};

const YEAR_ASC = {
    sortCategory: "year",
    sortOrder: "asc",
};

const YEAR_DESC = {
    ...YEAR_ASC,
    sortOrder: "desc",
};

const OPTIONS = [
    // { label: 'Relevance', value: JSON.stringify(DEFAULT_SORT) },
    { label: "Recently Added", value: JSON.stringify(NEWEST) },
    { label: "Oldest", value: JSON.stringify(OLDEST) },
    { label: "Highest Score", value: JSON.stringify(SCORE_DESC) },
    { label: "Lowest Score", value: JSON.stringify(SCORE_ASC) },
    { label: "New Release", value: JSON.stringify(YEAR_DESC) },
    { label: "Oldest Release", value: JSON.stringify(YEAR_ASC) },
];

const stringify = (obj) => {
    return JSON.stringify(obj);
};

const MARKETPLACE_SORT_OPTIONS = [
    // { label: 'Relevance', value: JSON.stringify(DEFAULT_SORT) },
    {
        label: "Recently Added",
        value: stringify({
            sortCategory: "added",
            sortOrder: "desc",
        }),
    },
    {
        label: "Oldest",
        value: stringify({
            sortCategory: "added",
            sortOrder: "asc",
        }),
    },
    {
        label: "Highest Price",
        value: stringify({
            sortCategory: "price",
            sortOrder: "desc",
        }),
    },
    {
        label: "Lowest Price",
        value: stringify({
            sortCategory: "price",
            sortOrder: "asc",
        }),
    },
    {
        label: "A-Z",
        value: stringify({
            sortCategory: "name",
            sortOrder: "asc",
        }),
    },
    {
        label: "Z-A",
        value: stringify({
            sortCategory: "name",
            sortOrder: "desc",
        }),
    },
];

const SortField = ({ onChange, value, type = "browseCards" }) => {
    let options = [];
    switch (type) {
        case "browseCards":
            options = OPTIONS;
            break;
        case "marketListings":
            options = MARKETPLACE_SORT_OPTIONS;
            break;

        default:
            options = OPTIONS;
            break;
    }
    return (
        <div className="flex items-center">
            <label className="mr-2 text-white text-fs15 hidden">Sort by</label>
            <select
                className="merqary-select text-sm"
                onChange={onChange}
                value={value}>
                {options.map((opt, i) => (
                    <option key={i} value={opt.value}>
                        {opt.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SortField;

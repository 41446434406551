import React from 'react'
import CardContainer from './card_container'
import SellerShippingMethods from './seller_shipping_methods'

const ShippingMethod = ({ seller, buyerLocationId, cardLocationIds }) => {
    return (
        <CardContainer
            title="Shipping Method"
            subText="How do you want your package shipped to you?"
        >
            <div className='text-gray-30'>
                <SellerShippingMethods
                    sellerId={seller?.id}
                    shippingMethods={seller?.shippingMethods}
                    buyerLocationId={buyerLocationId}
                    cardLocationIds={cardLocationIds}
                />
            </div>
        </CardContainer>
    )
}

export default ShippingMethod

import React from 'react'
import PurchaseSellerItem from './purchase_seller_item'

const PurchaseSellerItems = ({ items, purchaseSellerStatus, purchaseSellerId }) => {
    const purchaseSellerItems = [...items]
    const previewedItems = purchaseSellerItems.slice(0, 2)
    const otherItemsCount = purchaseSellerItems.length - previewedItems.length
    return (
        <div className='text-gray-30 border-b-2 border-gray-300 border-opacity-40 pb-12'>
            <div className='space-y-8'>
                {previewedItems.map(listing => (
                    <PurchaseSellerItem
                        key={listing.id}
                        {...listing}
                        purchaseSellerStatus={purchaseSellerStatus}
                    />
                ))}
            </div>
            {Boolean(otherItemsCount) ? (
                <div className='px-20'>
                    <a
                        href={`/marketplace/my_orders/${purchaseSellerId}`}
                        className='underline'>
                            + {otherItemsCount} more items
                    </a>
                </div>
            ) : null}
        </div>
    )
}

export default PurchaseSellerItems

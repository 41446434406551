import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from "./constants"

const formatPrice = (price = 0, format = "V", currency = DEFAULT_CURRENCY, locale = DEFAULT_LOCALE) => {
    // V - value
    // VC - value currency
    format = format.toUpperCase()
    currency = currency.toUpperCase()

    const value = (Number(price)).toLocaleString(locale, {
      style: 'currency',
      currency: currency.toUpperCase(),
      currencyDisplay: 'symbol'
    })

    format = format.replace("V", String(value))
    format = format.replace("C", currency)

    return format
}

export default formatPrice
import React, { Fragment, useContext } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { VariablesContextProvider } from "../variables_context";
import { PopupContextProvider } from "../popup_context";
import ApolloProviderClient from "../apollo_provider_client";
import { GAComponent } from "../hooks/useReactGA";
import MyListings from "./my_listings/my_listings";
import SettingsIcon from "icon-black-settings.svg";
import OrdersAndShipping from "./orders_and_shipping/orders_and_shipping";
import UpdateListing from "../seller_center/update_listing/update_listing";
import NewListing from "./new_listing/new_listing";
import useBreadCrumbs from "./use_breadcrumbs";
import CreateListing from "./create_listing/create_listing";
import ShopSettings from "./shop_settings/shop_settings";
import ManageOrder from "./orders_and_shipping/manage_order";
import DraftPreview from "./draft_preview/draft_preview";
import { ConfirmationContextProvider } from "../confirmation_context";
import PageVariablesContext from "../page_variables_context";

const NavBar = () => {
    const { hasCrumbs, BreadCrumbs } = useBreadCrumbs();
    const { username } = useContext(PageVariablesContext);
    return (
        <>
            {hasCrumbs ? (
                <BreadCrumbs />
            ) : (
                <div className="flex items-center merqary-banner py-2 px-4 md:px-14 justify-between w-full">
                    <span className="text-lg uppercase font-display font-semibold">
                        Seller Center
                    </span>
                    <div className="flex items-center">
                        <Link
                            className="flex mr-4"
                            to={"/seller_center/shop_settings"}>
                            <img src={SettingsIcon} className="h-5 w-5 mr-2" />
                            <span className="text-sm">Shop settings</span>
                        </Link>
                        <a
                            className="text-sm"
                            href={`/marketplace/s/${username}`}
                        >
                            View shop
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

const App = ({ username }) => {
    return (
        <Fragment>
            <Router>
                <Route path="*" component={NavBar} />
                <Switch>
                    <Route
                        path="/seller_center/my_listings"
                        exact
                        component={(props) => <MyListings {...props} />}
                    />
                    <Route
                        path="/seller_center/orders_and_shipping"
                        component={OrdersAndShipping}
                        exact
                    />
                    <Route
                        path="/seller_center/orders_and_shipping/:id/manage_order"
                        component={ManageOrder}
                        exact
                    />
                    <Route
                        path="/seller_center/new_listing"
                        component={NewListing}
                        exact
                    />
                    <Route
                        path="/seller_center/shop_settings"
                        component={ShopSettings}
                        exact
                    />
                    <Route
                        path="/seller_center/my_listings/:id"
                        component={UpdateListing}
                        exact
                    />
                    <Route
                        path="/seller_center/create_listing/:id"
                        component={CreateListing}
                        exact
                    />
                    <Route
                        path="/seller_center/my_listings/:id/preview"
                        component={DraftPreview}
                        exact
                    />
                </Switch>
                <Route path="*" component={GAComponent} />
            </Router>
        </Fragment>
    );
};

export default () => {
    document.addEventListener("DOMContentLoaded", function () {
        const container = document.getElementById("seller_center");

        if (container) {
            const isSellingAllowed = JSON.parse(container?.dataset?.sellPermission || "false");
            const hasNegativeBalance = JSON.parse(container?.dataset?.hasNegativeBalance || "false");
            const userId = container?.dataset?.userId;
            const username = container?.dataset?.username;

            if (!userId) {
                window.location.replace("/users/sign_in");
                return;
            }
            const pageVars = {
                isSellingAllowed,
                hasNegativeBalance,
                username,
                userId
            };

            render(
                <ApolloProviderClient>
                    <PopupContextProvider>
                        <VariablesContextProvider>
                            <PageVariablesContext.Provider value={pageVars}>
                                <ConfirmationContextProvider>
                                    <App />
                                </ConfirmationContextProvider>
                            </PageVariablesContext.Provider>
                        </VariablesContextProvider>
                    </PopupContextProvider>
                </ApolloProviderClient>,
                container
            );
        }
    });
};

import React, { Fragment, useContext } from "react";
import { useQuery } from "@apollo/client";
import { GET_FEATURED_CARDS } from "../queries";
import CardList from "../trophy_room/card_list";
import UserIdContext from "../trophy_room/user_id_context";

const FeaturedCards = ({ stashedData, featuredView = false }) => {
    const { data } = useQuery(GET_FEATURED_CARDS, {
        fetchPolicy: "cache-first",
    });

    // const { nodes, pageInfo } = getConnectionDetails(data?.featuredCards);
    const cards = data?.featuredCards;
    const hasCards = Boolean(data?.featuredCards.length);
    const userId = useContext(UserIdContext);
    return (
        <Fragment>
            {hasCards ? (
                <div className="container bg-black-300 mx-auto py-5 px-6">
                    <div className="my-8">
                        <p className="font-body font-semibold text-2xl text-white uppercase mb-2 text-gray-1000 ">
                            Featured Cards
                        </p>
                        <div className="flex justify-between font-body text-sm text-white mb-2 text-gray-1000 ">
                            {/* <h6 className="text-gray-200">Most searched cards or most number in vaults.</h6> */}
                            {/* <h6 className="text-white">View More</h6> */}
                        </div>
                    </div>
                    <CardList
                        cards={cards}
                        canStash={Boolean(userId)}
                        stashedCardIds={stashedData?.stashedCards?.map(
                            (c) => c.id
                        )}
                        ownedCardIds={stashedData?.haveCards?.map(
                            (c) => c.cardId
                        )}
                        rowView
                        featuredView={featuredView}
                    />
                </div>
            ) : null}
        </Fragment>
    );
};

export default FeaturedCards;

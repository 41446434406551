import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import OtherListingItem from "./other_listing_item";
import SpinnerLoader from "../../spinner_loader";

const GET_OTHER_LISTING = gql`
    query GetUserOtherListings($userId: ID!, $listingId: ID, $limit: Int) {
        listings: getUserOtherListings(
            userId: $userId
            listingId: $listingId
            limit: $limit
        ) {
            id
            frontImage
            price
            currency
            dealFormat
            ownedCardGrade
            ownedCardGraderName
        }
    }
`;

const OtherListings = ({ userId, listingId, limit, draftPreview }) => {
    const [getOtherListings, { data, loading, called }] = useLazyQuery(
        GET_OTHER_LISTING,
        {
            variables: {
                userId,
                listingId,
                limit,
            },
            fetchPolicy: "cache-and-network",
        }
    );

    useEffect(() => {
        if (!called && userId) {
            getOtherListings();
        }
    }, []);

    const listings = data?.listings || [];
    const hasListings = Boolean(listings?.length);

    return (
        <>
            {loading && <SpinnerLoader />}
            {hasListings && !loading && (
                <div className="flex flex-col py-4">
                    <span className="text-gray-1000 text-xl font-semibold mb-4">
                        Other listings by this seller
                    </span>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {listings.map((listing) => (
                            <OtherListingItem
                                key={listing.id}
                                {...listing}
                                draftPreview={draftPreview}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default OtherListings;

import React from 'react';
import { ChevronDown } from 'react-feather';

const FilterHeader = ({
     title,
     hasSelected = false,
     handleClear,
     showSelection,
     setShowSelection = () => {},
}) => (
    <button
        className="capitalize text-white-400 font-semibold bg-none cursor-pointer text-sm w-full"
        onClick={() => setShowSelection(!showSelection)}>
            <div className='flex justify-between'>
                <span>{title}</span>
                <div className={`flex flex-row space-x-4 ${showSelection ? "transform rotate-90" : ''}`}>
                    <ChevronDown size={20} className="text-white-1000" />
                </div>
            </div>
    </button>
);

export default FilterHeader;

import React from "react";
import CardImage from "../../card_image";
import formatCardPlayers from "../../utils/format_card_players";
import formatPrice from "../../utils/format_price";

const cardClassName = [
    "focus:outline-none",
    "trophyRoom-card",
    "relative",
    "flex-shrink-0",
    "border-2 border-white",
    "w-full",
    "w-16 h-20",
].join(" ");

const PurchaseSellerItem = ({
    id, // listing id
    cardId,
    ownedCardId,
    price,
    currency,
    frontImage,
    cardTitle,
    playersJson,
    printRun,
    printRunOrdinaltiy,
    purchaseSellerStatus,
}) => {
    const previewURL =
        purchaseSellerStatus === "Completed"
            ? `/marketplace/listings/${id}`
            : `/browse_cards/preview/${cardId}/vaulted/${ownedCardId}`;
    return (
        <div className="flex justify-between items-center w-full">
            <a
                href={previewURL}
                className="cursor-pointer flex flex-col md:flex-row items-start md:items-center md:space-x-3">
                <div className={`${cardClassName} mb-2 md:mb-0`}>
                    <CardImage isFront image={frontImage} hideMoreText />
                </div>
                <div className="flex md:space-x-2 flex-col md:flex-row text-sm md:text-md font-medium">
                    <span>{cardTitle || ""}</span>
                    <span className="merqary-truncate two-lines">
                        {formatCardPlayers(playersJson, ["names", "teams"])}
                    </span>
                    {printRun && printRunOrdinaltiy && (
                        <span>
                            #{printRun}/{printRunOrdinaltiy}
                        </span>
                    )}
                </div>
            </a>
            <div className="ml-auto text-md font-medium pl-2">
                {formatPrice(price, "V C", currency)}
            </div>
        </div>
    );
};

export default PurchaseSellerItem;

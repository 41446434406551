import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const errorLink = onError((res) => {
    const { graphQLErrors, networkError, forward, operation } = res;
    if (graphQLErrors)
        for (let err of graphQLErrors) {
            switch (err?.extensions?.code) {
                // Apollo Server sets code to UNAUTHENTICATED
                // when an AuthenticationError is thrown in a resolver
                case 'AUTHENTICATION_ERROR':
                    // Modify the operation context with a new token
                    window.location.href = '/unauthorized';
                    break;
                default:
                    forward(operation);
                    break;
            }
        }

    if (networkError) {
    }
});

const httpLink = new HttpLink({
    uri: '/graphql',
});

const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    getAllStashedCards: {
                        merge(_ = [], incoming) {
                            return [...incoming];
                        },
                    },
                },
            },
        },
    }),
});

export default client;

import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import useFilterOptions from "../hooks/useFilterOptions";
import Filter from "./filter";
import { Search, X } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "react-feather";
import PageNumberButton from "../pagination/page_number_button";
import useScrollToTop from "../hooks/useScrollToTop";
import { DEFAULT_SORT_OPTIONS } from "../sort_options";
import camelize from "../utils/camelizeString";

const useFilter = (query, defaultVariables = null, sortOptions = null, executeUponInitialize = true) => {
    const [executeQuery, { data, loading, called, error }] = useLazyQuery(
        query,
        { fetchPolicy: "cache-and-network" }
    );
    const defaultPageValue = {
        page: null,
        pages: null,
    };
    const { _search, initialPage } = useFilterOptions();
    const [searchString, setSearchString] = _search;
    const [variables, setVariables] = useState(null);
    const [results, setResults] = useState(null);
    const [defaultSortValue, setDefaultSortValue] = useState(null);
    const [pageInfo, setPageInfo] = useState(defaultPageValue);
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search.substring(1));
    const q = Object.fromEntries(searchParams);
    const listId = q.list || null;

    const baseVariables = {
        searchString: searchString,
        options: {
            page: Number(initialPage),
            sortOrder: null,
        },
        ...(defaultVariables && defaultVariables),
    };

    useScrollToTop([pageInfo?.page]);

    useEffect(() => {
        if (!called && executeUponInitialize) {
            setVariables(baseVariables);
        }
    }, []);
    useEffect(() => {
        if (data) {
            setResults(data?.results);
            setDefaultSortValue(data?.results?.filters?.sortOptions?.sortKey);
        }
        if (data?.results?.filters?.pagination) {
            setPageInfo(data?.results?.filters?.pagination);
        }
    }, [data]);

    useEffect(() => {
        if (variables && _search) {
            executeQuery({
                variables: variables,
            });
        }
    }, [variables]);

    const handleFilterClear = () => {
        setVariables(baseVariables);
    };

    const handleSearchClear = () => {
        let searchParams = q;
        delete searchParams.search;

        history.push({
            pathname: location.pathname,
            search: `?${new URLSearchParams(searchParams).toString()}`,
        });

        setSearchString("");
        if (location.pathname.includes("browse_cards")) {
            setResults(null);
        } else {
            setVariables({
                ...variables,
                searchString: "*",
            });
        }
    };

    const handleSortChange = (e) => {
        const sort = e.target;
        const value = JSON.parse(
            sort.options[sort.selectedIndex].getAttribute("data-value")
        );
        setVariables({
            ...variables,
            options: {
                ...variables.options,
                ...value,
            },
        });
    };

    const handlePageChange = (page) => {
        q.page = page;
        if (searchString) q.search = searchString;
        history.push({
            pathname: location.pathname,
            search: `?${new URLSearchParams(q).toString()}`,
        });
        setVariables({
            ...variables,
            options: {
                ...variables.options,
                page: page,
            },
        });
    };

    const handleFilterRemove = (title, value) => {
        title = camelize(title);
        const filterValues = variables.options[title];
        setVariables({
            ...variables,
            options: {
                ...variables.options,
                [title]: filterValues.filter((f) => f !== value),
            },
        });
    };

    const handleReload = () => {
        setVariables({
            ...variables,
        });
    };

    const Filters = ({
        visible = false,
        setVisibility = () => {},
        showAsDefault = false,
        isFloating,
        overlay = false,
    }) => {
        return (
            <Filter
                isOverlay={overlay}
                showAsDefault={showAsDefault}
                visible={visible}
                setVisibility={setVisibility}
                filterItems={results?.filters?.filterOptions}
                loading={loading}
                clearSearch={handleFilterClear}
                variables={variables}
                setVariables={setVariables}
                isFloating={isFloating}
                darkMode
            />
        );
    };

    const SearchField = ({
        darkMode,
        shortPlaceHolder,
        containerClassName = `flex flex-row-reverse lg:flex-row items-center overflow-hidden relative py-2 h-10 w-full pr-2 ${
            darkMode ? "bg-gray-70 rounded-sm" : "bg-white rounded-full lg:pr-4"
        }`,
        inputClassname = `border-0 outline-none focus:outline-none focus:ring-0 w-full text-xs lg:text-sm ${
            darkMode ? " bg-gray-70 text-white-500" : "bg-white text-gray-70"
        }`,
        placeholder = `${
            darkMode
                ? "Search player or team..."
                : shortPlaceHolder
                ? "Type here..."
                : "Enter player or team name..."
        }`,
        customParams = null,
        showSubmit = true,
    }) => {
        const defaultValue = searchString;

        return (
            <form autoComplete="off" className={containerClassName}>
                {listId && <input type="hidden" value={listId} name="list" />}
                <label
                    className={`text-fs ${
                        darkMode ? "text-white-400" : "text-gray-500"
                    } pr-0 pl-2.5 py-2 border-l lg:border-none`}
                    htmlFor="searchField">
                    <Search size={18} />
                </label>
                <div className="flex items-center relative w-full text-black-100">
                    <input
                        id="searchField"
                        name="search"
                        className={inputClassname}
                        type="text"
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                    />
                    {customParams && (
                        <input
                            id={customParams.name}
                            name={customParams.name}
                            type="hidden"
                            defaultValue={customParams.value}
                        />
                    )}
                    {defaultValue && (
                        <button
                            type="button"
                            className="flex items-center px-4 pr-1 lg:pr-14 text-fs-15 text-black hover:text-primary focus:outline-none focus:ring-0"
                            onClick={() => handleSearchClear()}>
                            <span
                                className="lg:underline lg:border-r border-gray-500 ml-1 lg:pr-4 lg:mr-0 text-xs text-gray-500"
                                style={{ paddingRight: "0.8rem" }}>
                                    {darkMode ? <X size={16} className="text-white-400"/> : "Clear"}
                            </span>
                        </button>
                    )}
                </div>
                {(darkMode || showSubmit) ? (
                    <button
                        type="submit"
                        className="px-4 hidden lg:block text-xs text-gray-500 hover:bg-primary hover:text-black font-semibold absolute top-0 bottom-0 right-0">
                            Search
                    </button>
                ) : null}
            </form>
        );
    };

    const SortField = ({
        label = "Sort by",
        lableClassName = "mr-2 text-white text-fs15 hidden",
        selectClassName = "merqary-select pl-4 lg:rounded-md text-sm focus:outline-none focus:ring-0 focus:border focus:border-gray-200 space-y-4",
        selectBGColor = "bg-white",
        selectTextColor = "text-black-100",
        border = "0",
        optionClass = "text-black-100",
    }) => {
        const options =
            sortOptions != null ? sortOptions : DEFAULT_SORT_OPTIONS;
        const defaultValue = defaultSortValue
            ? defaultSortValue
            : createSortKey(results?.filters?.sortOptions);
        return (
            <div className="flex items-center">
                <label className={lableClassName}>{label}</label>
                <select
                    className={`${selectClassName} ${selectBGColor} ${selectTextColor} border${border}`}
                    onChange={(e) => handleSortChange(e)}
                    defaultValue={defaultValue}>
                    {options.map((opt, i) => (
                        <option
                            key={i}
                            className={optionClass}
                            value={createSortKey(opt.value)}
                            data-value={JSON.stringify(opt.value)}>
                            {opt.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    const Pagination = ({
        buttonClass = "rounded px-4 py-2 text-center hover:bg-black focus:outline-none bg-gray-700",
        placement = "center",
    }) => {
        const { page, pages } = pageInfo;
        const isFirst = page === 1;
        const isLast = page === pages;
        const beforeCurrentPage = (page - 1) > 0
                                    ? [2,1].map(i => page - i).filter(i => i > 1 && i !== pages)
                                    : [];
        const afterCurrentPage = (pages - page) > 0
                                    ? [1,2].map(i => page + i).filter(i => i < pages && i !== 1)
                                    : [];
        const elipsisBefore = (page - 1) >= 5
                                ? "..."
                                : (page - 4) === 1 ? 2 : null;
        const elipsisAfter = (pages - page) >= 5
                                ? "..."
                                : (pages - (page + 3)) === 1 ? pages -1 : null;
        return (
            <>
                {results?.filters?.pagination && (
                    <div
                        className={`flex items-center justify-${placement} w-100 space-x-1`}>
                        {!isFirst && (
                            <PageNumberButton
                                buttonClass={buttonClass}
                                onClick={() =>
                                    handlePageChange(Math.max(page - 1))
                                }>
                                <ChevronLeft />
                            </PageNumberButton>
                        )}
                        {!isFirst && (
                            <PageNumberButton
                                buttonClass={buttonClass}
                                onClick={() => handlePageChange(1)}>
                                1
                            </PageNumberButton>
                        )}
                        {elipsisBefore ? (
                            <PageNumberButton
                                buttonClass={buttonClass}
                                {...(typeof elipsisBefore == 'number' && { onClick: () => handlePageChange(elipsisBefore) })}>
                                {elipsisBefore}
                            </PageNumberButton>
                        ) : null}
                        {beforeCurrentPage.map(page => (
                            <PageNumberButton
                                key={`page-${page}`}
                                buttonClass={buttonClass}
                                onClick={() => handlePageChange(page)}>
                                {page}
                            </PageNumberButton>
                        ))}
                        <PageNumberButton buttonClass={buttonClass} currentPage>
                            {page}
                        </PageNumberButton>
                        {afterCurrentPage.map(page => (
                            <PageNumberButton
                                key={`page-${page}`}
                                buttonClass={buttonClass}
                                onClick={() => handlePageChange(page)}>
                                {page}
                            </PageNumberButton>
                        ))}
                        {elipsisAfter ? (
                            <PageNumberButton
                                buttonClass={buttonClass}
                                {...(typeof elipsisAfter == 'number' && { onClick: () => handlePageChange(elipsisAfter) })}>
                                {elipsisAfter}
                            </PageNumberButton>
                        ) : null}
                        {!isLast && (
                            <PageNumberButton
                                buttonClass={buttonClass}
                                onClick={() => handlePageChange(pages)}>
                                {pages}
                            </PageNumberButton>
                        )}
                        {!isLast && (
                            <PageNumberButton
                                buttonClass={buttonClass}
                                onClick={() =>
                                    handlePageChange(Math.max(page + 1))
                                }>
                                <ChevronRight />
                            </PageNumberButton>
                        )}
                    </div>
                )}
            </>
        );
    };

    const ResultInfo = ({
        hideSearchString = false,
        textClass = "text-white-400 py-4 text-sm mr-4",
    }) => {
        if (!searchString || searchString == "*" ) return null;
        return (
            <div className="flex flex-col">
                {!loading && (
                    <div className={textClass}>
                        <div className="flex">
                            {pageInfo?.count ? (
                                <>
                                    {pageInfo?.from}-{pageInfo?.to} of{" "}
                                    {pageInfo?.count}
                                </>
                            ) : (
                                0
                            )}{" "}
                            <p className="pl-1">Results for "{searchString}"</p>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const SelectedFilters = () => {
        const filters = results?.filters?.filterOptions
            ?.filter((f) => Boolean(f.selected.length))
            .map((f) => ({ filterKey: f.title, value: f.selected }));
        let selectedFilters = [];
        filters?.forEach((filter) => {
            filter.value.forEach((value) => {
                selectedFilters.push({
                    title: filter.filterKey,
                    value: value,
                });
            });
        });

        return (
            <>
                {Boolean(selectedFilters?.length) && (
                    <div className="flex flex-wrap space-x-2">
                        <div className="flex flex-wrap items-center">
                            {selectedFilters?.map((filter) => (
                                <div
                                    key={`${filter.title}_${filter.value
                                        .toLowerCase()
                                        .replace(" ", "_")}`}
                                    className="pr-2 pb-2">
                                    <div className="flex bg-gray-500 text-xs py-1 px-2 rounded items-center text-white-400">
                                        <X
                                            className="w-3 h-3 mr-1 cursor-pointer"
                                            onClick={() =>
                                                handleFilterRemove(
                                                    filter.title,
                                                    filter.value
                                                )
                                            }
                                        />
                                        <span className="capitalize">
                                            {filter.title === "attributes"
                                                ? filter.value
                                                      .replace("is", "")
                                                      .replace("has", "")
                                                      .replace("_", "")
                                                : filter.value}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="">
                            <button
                                className="border-0 p-2 flex items-center focus:outline-none text-white-400 hover:text-primary cursor-pointer"
                                onClick={() => handleFilterClear()}>
                                <span className="leading-none ml-1 text-xs font-semibold underline">
                                    Clear all
                                </span>
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    };

    return {
        Filters,
        SearchField,
        SortField,
        Pagination,
        ResultInfo,
        SelectedFilters,
        setVariables,
        handleReload,
        variables,
        results,
        loading,
        called,
        error,
    };
};

const createSortKey = (value) => {
    if (!value) return null;
    return Object.values(value).join("_");
};

export default useFilter;

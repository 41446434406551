import React, { useContext, useEffect } from "react";
import BgLights from "bg-lights-darker.png";
import PurchaseSellerAction from "./purchase_seller_action";
import OrderSummary from "../checkout/order_summary";
import CardContainer from "../checkout/card_container";
import { useParams } from "react-router-dom";
import SpinnerLoader from "../../spinner_loader";
import { gql, useLazyQuery } from "@apollo/client";
import PaymentSummary from "../checkout/payment_summary";
import PageVariablesContext from "../../page_variables_context";
import useAddressFormatter from "../../hooks/useAddressFormatter";

const GET_PURCHASE_SELLER = gql`
    query GetPurchaseSeller($id: ID!) {
        purchaseSeller: getPurchaseSeller(id: $id) {
            id
            purchaseUserId
            transactionNumber
            currency
            totalAmount
            orderNumber
            status
            sellerUsername
            notifiedEmail
            buyerDisplayedStatus
            sellingFee
            shippingFee
            stripeFee
            shippingAddress {
                fullName
                phoneNumber
                unitNumber
                town
                postalCode
                state
                countryName
                building
                street
            }
            orderSummary {
                seller {
                    id
                    username
                }
                items {
                    id
                    price
                    currency
                    status
                    ownedCardId
                    cardId
                    frontImage
                    cardTitle
                    playersJson
                }
            }
            review {
                rating
                title
                content
                dateReviewed
                username
                sellerUsername
            }
            paymentMethodDetails {
                brand
                last4
                currency
            }
            trackingInformation: deliveryTracking {
                id
                logisticsProvider
                trackingNumber
                trackingUrl
                expectedDateOfArrival
                shipmentDate
            }
        }
    }
`;

const pageClass = [
    "px-8 py-8 md:px-18 md:py-12 lg:px-20 xl:px-32 2xl:px-60",
    "animate__animated animate__fadeIn",
    "bg-fixed bg-cover bg-center bg-no-repeat",
    "min-h-screen",
    "text-gray-30",
    "space-y-8",
].join(" ");

const OrderDetails = () => {
    const { id } = useParams();
    const { userId } = useContext(PageVariablesContext);

    const [fetchData, { data, loading, called }] = useLazyQuery(
        GET_PURCHASE_SELLER,
        {
            variables: { id: id },
            fetchPolicy: "cache-and-network",
        }
    );

    const purchaseSeller = data?.purchaseSeller;
    const review = purchaseSeller?.review;
    const orderSummary = purchaseSeller?.orderSummary;
    const paymentMethodDetails = purchaseSeller?.paymentMethodDetails;
    const trackingInformation = purchaseSeller?.trackingInformation;
    const contactInformation = purchaseSeller?.shippingAddress;

    const ownedByCurrentUser = userId === purchaseSeller?.purchaseUserId;
    const { addressLineOne, addressLineTwo } = useAddressFormatter(contactInformation);

    useEffect(() => {
        if (!called) {
            fetchData();
        }
    }, []);

    return (
        <div
            className={pageClass}
            style={{ backgroundImage: `url(${BgLights})` }}>
            {(purchaseSeller && !loading && ownedByCurrentUser) ? (
                <>
                    <div className="mx-auto w-4/5 xl:w-3/5">
                        <a href="/marketplace/my_orders" className="underline">
                            Back to My Orders
                        </a>
                    </div>
                    <div className="mx-auto relative w-4/5 xl:w-3/5 rounded-lg bg-gray-800 shadow">
                        <PaymentSummary
                            paymentMethodDetails={paymentMethodDetails}
                            shippingFeeTotal={purchaseSeller?.shippingFee}
                            currency={paymentMethodDetails?.currency}
                            amount={purchaseSeller?.totalAmount}
                            notifiedEmail={purchaseSeller?.notifiedEmail}
                            orderNumber={purchaseSeller?.transactionNumber}
                        />
                        {purchaseSeller.buyerDisplayedStatus !==
                            "Unshipped" && (
                            <div className="py-4 px-6 rounded-b border-t-2 border-gray-300 border-opacity-40">
                                <PurchaseSellerAction
                                    id={id}
                                    status={purchaseSeller.status}
                                    review={review}
                                    sellerUsername={
                                        purchaseSeller.sellerUsername
                                    }
                                    callback={fetchData}
                                    orderStatus={
                                        purchaseSeller.buyerDisplayedStatus
                                    }
                                    trackingInformation={trackingInformation}
                                    showShippingDetails
                                />
                            </div>
                        )}
                    </div>
                    <div className="mx-auto relative w-4/5 xl:w-3/5 rounded-lg bg-gray-800 shadow">
                        <CardContainer
                            title="Shipping"
                            titleBorder
                            fullWidthHeader>
                            <div className="px-6 space-y-4">
                                <div className="grid grid-cols-4">
                                    <div>Recipient</div>
                                    <div className="col-span-3">
                                        <div className="grid">
                                            <span>
                                                {contactInformation?.fullName}
                                            </span>
                                            <span>
                                                {contactInformation?.phoneNumber}
                                            </span>
                                            <div className="flex flex-col">
                                                <span>{addressLineOne},</span>
                                                <span>{addressLineTwo}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {trackingInformation && (
                                    <>
                                        <div className="grid grid-cols-4">
                                            <div>Ship Date</div>
                                            <div className="col-span-3">
                                                <span>
                                                    {
                                                        trackingInformation.shipmentDate
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-4">
                                            <div>Logistics Provider</div>
                                            <div className="col-span-3">
                                                <span>
                                                    {
                                                        trackingInformation.logisticsProvider
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-4">
                                            <div>Tracking Number</div>
                                            <div className="col-span-3">
                                                <span>
                                                    {
                                                        trackingInformation.trackingNumber
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        {trackingInformation.trackingUrl && (
                                            <div className="grid grid-cols-4">
                                                <div>Tracking URL</div>
                                                <div className="col-span-3">
                                                    <span>
                                                        {
                                                            trackingInformation.trackingUrl
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        {trackingInformation.expectedDateOfArrival && (
                                            <div className="grid grid-cols-4">
                                                <div>ETA</div>
                                                <div className="col-span-3">
                                                    <span>
                                                        {
                                                            trackingInformation.expectedDateOfArrival
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </CardContainer>
                    </div>
                    <div className="mx-auto relative w-4/5 xl:w-3/5 rounded-lg bg-gray-800 shadow">
                        <OrderSummary checkoutSellers={[orderSummary]} purchaseSellerStatus={purchaseSeller.status} />
                    </div>
                </>
            ) : null}
            {!loading && (!purchaseSeller || !ownedByCurrentUser) ? (
                <div className="flex justify-center w-full">
                    <div className="text-4xl text-white">Order not found</div>
                </div>
            ) : null}
            {loading && <SpinnerLoader />}
        </div>
    );
};

export default OrderDetails;

import React from 'react';
import GoldCheck from 'icon-gold-check.svg';
import GrayCross from 'icon-gray-cross.svg';

const Checkbox = ({
    name,
    id,
    label,
    onChange,
    checked,
    value,
    radio,
    labelClass = "flex cursor-pointer capitalize items-center text-white-500",
    inputDimension = '4',
    disabled = false,
    inputBGColor = '',
    hideCheckbox = false,
    customClass = "mb-2",
    defaultChecked,
}) => {
    const containerClass = [
        "flex items-center",
        !hideCheckbox ? customClass : ""
    ].join(" ");

    return (
        <div className={containerClass}>
            <label htmlFor={id} className={labelClass}>
                <input
                    id={id}
                    type={radio ? 'radio' : 'checkbox'}
                    name={name}
                    onChange={onChange}
                    checked={checked}
                    value={value}
                    className={`${hideCheckbox ? "hidden" : ""} cursor-pointer merqary-checkbox text-primary rounded focus:outline-none focus:ring-0 h-${inputDimension} w-${inputDimension} ${inputBGColor} ${disabled && 'opacity-40'}`}
                    disabled={disabled}
                    defaultChecked={defaultChecked}
                />
                <img src={checked ? GoldCheck : GrayCross}
                    className={`${hideCheckbox ? "" : "hidden"} w-5 h-5 ml-0`}/>
                <span className={`text-sm font-medium ${label ? "pl-2" : "" }`}>
                    {label}
                </span>
            </label>
        </div>
    );
}

export default Checkbox;

import React from "react";

const BentoMenu = ({ backColor }) => {
    return (
        <div className="bento-menu cursor-pointer font-white ml-2">
            {Array.from({ length: 9 }, (_, i) => (
                <div key={i} className={`bento-dot ${backColor}`}></div>
            ))}
        </div>
    );
};

export default BentoMenu;

import React from "react";
import IconFilter from "icon-filter-gold.svg";

function FilterButton({ onClick, lgHidden, textColor }) {
    const buttonClass = [
        "flex items-center cursor-pointer font-semibold",
        lgHidden ? "lg:hidden" : "",
        textColor
    ].join(" ");

    return (
        <button
            className={buttonClass}
            id="filter-btn"
            onClick={onClick}>
            <img src={IconFilter} className="w-5 h-5 mr-2" alt="filter icon" />
            Filters
        </button>
    );
}

FilterButton.defaultProps = {
    onClick: () => {},
    lgHidden: true,
    textColor: "text-primary"
}

export default FilterButton;

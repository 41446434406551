import React from "react";
import CopyToClipboard from "../../utils/copy_to_clipboard";
import HelpText from "../../utils/help_text";

const CardDetailItem = ({
    title,
    value,
    col,
    capitalize,
    copyToClipboard,
    link,
    textAlignment,
    boldTitle,
    boldValue,
    titleColor,
    valueColor,
    helpText,
}) => {
    const containerClass = [
        !col
        ? `grid grid-cols-3 md:grid-cols-2 xl:grid-cols-3 col-span-full gap-1`
        : "",
        `text-${textAlignment}`,
        titleColor
    ].join(" ");

    const titleClass = [
        !col ? "col-span-1 " : "",
        textAlignment === "right" ? "col-start-2 row-start-1" : "",
        boldTitle ? "font-semibold" : "",
        helpText ? "flex justify-end" : "",
    ].join(" ");

    const valueClass = [
        "break-all",
        !col ? "col-span-2" : "",
        textAlignment === "right" ? "col-start-3" : "",
        boldValue ? "font-semibold" : "",
        capitalize ? "capitalize" : "",
        copyToClipboard ? "flex items-center space-x-2" : "",
        valueColor
    ].join(" ");

    return (
        <div className={containerClass}>
            <div className={titleClass}>
                {!helpText ? (
                    <span>{title}</span>
                ) : (
                    <HelpText
                        sideText={title}
                        text={helpText}
                    />
                )}
            </div>
            <div
                className={valueClass}>
                <TextValue value={value} link={link} />
                {copyToClipboard && <CopyToClipboard text={value} />}
            </div>
        </div>
    );
};

const TextValue = ({ value, link }) => {
    const multiLine = Array.isArray(value);

    if (link) return (
        <a href={value} className="underline" target="_blank">
            {value}
        </a>
    );

    if (!multiLine) return ( <span>{value || "N/A"}</span> );

    return (
        <div className="flex flex-col">
            {value.map(v => (
                <span key={v}>{v}</span>
            ))}
        </div>
    );
};

CardDetailItem.defaultProps = {
    title: null,
    value: null,
    col: false,
    capitalize: false,
    copyToClipboard: false,
    fullWidth: false,
    link: false,
    textAlignment: "left",
    boldTitle: true,
    boldValue: false,
    titleColor: "text-gray-1000",
    valueColor: "text-white-400",
    helpText: null,
};

export default CardDetailItem;

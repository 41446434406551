import { gql, useMutation, useQuery } from "@apollo/client";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext } from "react";
import Modal from "../../modal";
import ModalPortal from "../../modal/modal_portal";
import * as yup from "yup";
import Select from "react-select";
import HelpText from "../../utils/help_text";
import Button from "../../seller_center/shared_components/button";
import { PopupContext, POPUP_TYPES } from "../../popup_context";
import PageVariablesContext from "../../page_variables_context";

const GET_COUNTRIES = gql`
    query GetCountries {
        countries {
            id
            name
        }
    }
`;
const CREATE_CONTACT_INFORMATION = gql`
    mutation CreateContactInformation($input: CreateContactInformationInput!) {
        result: createContactInformation(input: $input) {
            shippingAddress: contactInformation {
                id
                userId
                fullName
                phoneNumber
                unitNumber
                building
                street
                town
                state
                postalCode
                countryId
            }
            success
            errors
        }
    }
`;

const UPDATE_CONTACT_INFORMATION = gql`
    mutation UpdateContactInformation($input: UpdateContactInformationInput!) {
        result: updateContactInformation(input: $input) {
            shippingAddress: contactInformation {
                id
                userId
                fullName
                phoneNumber
                unitNumber
                building
                street
                town
                state
                postalCode
                countryId
            }
            success
            errors
        }
    }
`;

const schema = yup.object().shape({
    id: yup.string().nullable(),
    fullName: yup.string().required("Enter Full Name"),
    phoneNumber: yup.string().required("Enter Phone Number"),
    unitNumber: yup.string().required("Enter Unit Number and Floor/Level Number, or Postal Box Number"),
    building: yup.string(),
    street: yup.string(),
    town: yup.string().required("Enter Town or Suburb"),
    state: yup.string().required("Enter Street/Road Name or Number"),
    countryId: yup.string().required("Select Country"),
    postalCode: yup.string().required("Enter Post Code"),
    userId: yup.string().required(),
});

const ShippingAddressModal = ({
    mountModal,
    setMountModal,
    shippingAddress,
    buyer,
}) => {
    const { userId } = useContext(PageVariablesContext);
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);
    const { data } = useQuery(GET_COUNTRIES, {
        fetchPolicy: "cache-and-network",
    });
    const countryOptions = data?.countries?.map((country) => ({
        label: country.name,
        value: country.id,
    }));
    const inputClassName =
        "text-xs w-full p-2 rounded text-black text-sm border";
    const onError = () => {
        setShowPopup(true);
        setPopupAction("warn");
        setMessage("Something went wrong.");
    };

    const mutation = shippingAddress
        ? UPDATE_CONTACT_INFORMATION
        : CREATE_CONTACT_INFORMATION;

    const [saveShippingAddress, { loading }] = useMutation(mutation, {
        onCompleted: (data) => {
            setShowPopup(true);
            if (data?.result?.success) {
                setPopupAction(POPUP_TYPES.SUCCESS);
                setMessage("Shipping address saved");
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            } else {
                setPopupAction(POPUP_TYPES.DANGER);
                setMessage(data?.result?.errors?.join(", "));
            }
        },
        onError: onError,
    });

    const initialValues = {
        id: shippingAddress?.id,
        fullName: shippingAddress?.fullName || buyer?.fullName || "",
        phoneNumber: shippingAddress?.phoneNumber || buyer?.fullName || "",
        unitNumber: shippingAddress?.unitNumber || "",
        building: shippingAddress?.building,
        street: shippingAddress?.street,
        town: shippingAddress?.town || "",
        state: shippingAddress?.state || "",
        countryId: shippingAddress?.countryId || "",
        postalCode: shippingAddress?.postalCode || "",
        userId: userId,
    };

    const handleFormSubmit = ({ ...values }) => {
        saveShippingAddress({
            variables: {
                input: {
                    contactInfo: { ...values },
                },
            },
        });
    };

    return (
        <ModalPortal id="buyerShippingAddressModal">
            {mountModal && (
                <Modal
                    title="Add shipping details"
                    preventESC
                    size="md"
                    closeModal={() => setMountModal(false)}
                    className="p-10 text-black-100"
                    closeIcon="x"
                    modalCustomClass="rounded-lg"
                    titleClass="text-gray-650 text-md font-semibold">
                    {({ hideModal }) => (
                        <div className="p-6 space-y-4">
                            <span className="text-black-100">
                                Add your shipping and contact details
                            </span>
                            <Formik
                                onSubmit={(values) => handleFormSubmit(values)}
                                validationSchema={schema}
                                initialValues={initialValues}>
                                {(form) => (
                                    <Form className="space-y-6">
                                        <div className="grid space-y-4 mt-4">
                                            <div className="flex items-center">
                                                <div className="flex flex-col space-y-2 w-1/2 mr-4">
                                                    <span className="text-xs font-body mb-2 w-9/12">
                                                        Full Name
                                                        <span className="text-red-500">
                                                            *
                                                        </span>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        name="fullName"
                                                        defaultValue={
                                                            shippingAddress?.fullName ||
                                                            buyer?.fullName
                                                        }
                                                        className={
                                                            inputClassName
                                                        }
                                                        onChange={(e) =>
                                                            form.setFieldValue(
                                                                "fullName",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <ErrorMessage name="fullName">
                                                        {(msg) => (
                                                            <div className="text-red-400 text-xs">
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                                <div className="flex flex-col space-y-2 w-1/2">
                                                    <span className="text-xs font-body mb-2 w-9/12">
                                                        Phone Number
                                                        <span className="text-red-500">
                                                            *
                                                        </span>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        name="phoneNumber"
                                                        defaultValue={
                                                            shippingAddress?.phoneNumber ||
                                                            buyer?.mobile
                                                        }
                                                        className={
                                                            inputClassName
                                                        }
                                                        onChange={(e) =>
                                                            form.setFieldValue(
                                                                "phoneNumber",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <ErrorMessage name="phoneNumber">
                                                        {(msg) => (
                                                            <div className="text-red-400 text-xs">
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="flex flex-col space-y-2 w-full">
                                                    <span className="text-xs font-body mb-2 w-9/12">
                                                        Unit Number and
                                                        Floor/Level Number, or
                                                        Postal Box Number
                                                        <span className="text-red-500">
                                                            *
                                                        </span>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        name="unitNumber"
                                                        defaultValue={
                                                            shippingAddress?.unitNumber
                                                        }
                                                        className={
                                                            inputClassName
                                                        }
                                                        onChange={(e) =>
                                                            form.setFieldValue(
                                                                "unitNumber",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <ErrorMessage name="unitNumber">
                                                        {(msg) => (
                                                            <div className="text-red-400 text-xs">
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="flex flex-col space-y-2 w-full">
                                                    <span className="text-xs font-body mb-2 w-9/12">
                                                        Building Name or Number
                                                    </span>
                                                    <input
                                                        type="text"
                                                        name="building"
                                                        defaultValue={
                                                            shippingAddress?.building
                                                        }
                                                        className={
                                                            inputClassName
                                                        }
                                                        onChange={(e) =>
                                                            form.setFieldValue(
                                                                "building",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <ErrorMessage name="building">
                                                        {(msg) => (
                                                            <div className="text-red-400 text-xs">
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="flex flex-col space-y-2 w-full">
                                                    <span className="text-xs font-body mb-2 w-9/12">
                                                        Street/Road Name or
                                                        Number
                                                    </span>
                                                    <input
                                                        type="text"
                                                        name="street"
                                                        defaultValue={
                                                            shippingAddress?.street
                                                        }
                                                        className={
                                                            inputClassName
                                                        }
                                                        onChange={(e) =>
                                                            form.setFieldValue(
                                                                "street",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <ErrorMessage name="street">
                                                        {(msg) => (
                                                            <div className="text-red-400 text-xs">
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="flex flex-col space-y-2 w-full">
                                                    <div className="flex space-x-2">
                                                        <span className="text-xs font-body">
                                                            Town or Suburb
                                                            <span className="text-red-500">
                                                                *
                                                            </span>
                                                        </span>
                                                        <HelpText text="Residential area, Neighbourhood or Postal Division, Locality." />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="town"
                                                        defaultValue={
                                                            shippingAddress?.town
                                                        }
                                                        className={
                                                            inputClassName
                                                        }
                                                        onChange={(e) =>
                                                            form.setFieldValue(
                                                                "town",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <ErrorMessage name="town">
                                                        {(msg) => (
                                                            <div className="text-red-400 text-xs">
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="flex flex-col space-y-2 w-full">
                                                    <div className="flex space-x-2">
                                                        <span className="text-xs font-body">
                                                            State
                                                            <span className="text-red-500">
                                                                *
                                                            </span>
                                                        </span>
                                                        <HelpText text="E.g. State, Territory, Province, Prefecture, or Region." />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="state"
                                                        defaultValue={
                                                            shippingAddress?.state
                                                        }
                                                        className={
                                                            inputClassName
                                                        }
                                                        onChange={(e) =>
                                                            form.setFieldValue(
                                                                "state",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <ErrorMessage name="state">
                                                        {(msg) => (
                                                            <div className="text-red-400 text-xs">
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-3">
                                                <div className="grid space-y-2 w-1/2">
                                                    <span className="text-xs font-body">
                                                        Country
                                                        <span className="text-red-500">
                                                            *
                                                        </span>
                                                    </span>
                                                    {countryOptions && (
                                                        <Select
                                                            className="rounded text-black border-1 border-red"
                                                            onChange={(v) =>
                                                                form.setFieldValue(
                                                                    "countryId",
                                                                    v.value
                                                                )
                                                            }
                                                            options={
                                                                countryOptions
                                                            }
                                                            defaultValue={countryOptions?.filter(
                                                                (o) =>
                                                                    o.value ===
                                                                    shippingAddress?.countryId
                                                            )}
                                                        />
                                                    )}
                                                    <ErrorMessage name="countryId">
                                                        {(msg) => (
                                                            <div className="text-red-400 text-xs">
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                                <div className="grid space-y-2 w-1/2">
                                                    <div className="flex space-x-2">
                                                        <span className="text-xs font-body">
                                                            Postcode
                                                            <span className="text-red-500">
                                                                *
                                                            </span>
                                                        </span>
                                                        <HelpText text="Postcode, ZIP code, etc." />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="postalCode"
                                                        defaultValue={
                                                            shippingAddress?.postalCode
                                                        }
                                                        className={
                                                            inputClassName
                                                        }
                                                        onChange={(e) =>
                                                            form.setFieldValue(
                                                                "postalCode",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <ErrorMessage name="postalCode">
                                                        {(msg) => (
                                                            <div className="text-red-400 text-xs">
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-end space-x-6">
                                            <Button
                                                text="Cancel"
                                                type="button"
                                                secondary
                                                onClick={() => hideModal()}
                                            />
                                            <Button
                                                text="Save details"
                                                type="submit"
                                                primary
                                                disabled={loading}
                                            />
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    )}
                </Modal>
            )}
        </ModalPortal>
    );
};

export default ShippingAddressModal;

import React, { Fragment, useContext, useEffect, useState } from "react";
import BgLights from "bg-lights-darker.png";
import ListNav from "./list_nav";
import { gql, useLazyQuery } from "@apollo/client";
import { SEARCH_HAVE_CARDS } from "../../queries";
import useFilter from "../../filter/use_filter";
import { useHistory, useLocation } from "react-router-dom";
import FilterOverlay from "../../filter/filter_overlay";
import PageInfo from "../shared_components/page_info";
import MarketCardList from "../../marketplace/browse_listings/card_market_list";
import EmptyList from "../../trophy_room/empty_list";
import FilterButton from "../../filter_button";
import PageVariablesContext from "../../page_variables_context";

const GET_HAVE_LISTS = gql`
    query GetHaveLists($userId: ID!) {
        haveLists: getHaveLists(userId: $userId) {
            id
            name
            ordinality
            listableOwnedCardsCount
        }
    }
`;

const NewListing = () => {
    const {
        userId,
        isSellingAllowed,
        hasNegativeBalance,
    } = useContext(PageVariablesContext);

    const [getHaveLists, { data, called, loading: haveListLoading }] = useLazyQuery(GET_HAVE_LISTS, {
        fetchPolicy: "cache-first",
        variables: { userId: userId },
    });

    const pageClass = [
        "px-6 lg:px-24 animate__animated animate__fadeIn",
        "bg-cover bg-center bg-fixed bg-no-repeat",
        "min-h-screen",
        "space-y-16 text-gray-1000",
    ].join(" ");

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search.substring(1));
    const q = Object.fromEntries(searchParams);
    const [activeList, setActiveList] = useState(q.list || null);
    const haveLists = data?.haveLists;
    const hasList = Boolean(haveLists?.length);
    const searchString = q.search || "*";
    const initialPage = q.page || 1;
    const [isDisplayFilter, setDisplayFilter] = useState(false);
    // const [showPublished, setShowPublished] = useState(false);
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);
    const [DEFAULT_VARIABLES, setDefaultVariables] = useState({
        searchString: searchString || "*",
        userId: userId,
        haveListId: activeList,
        options: {
            page: Number(initialPage),
            itemsPerPage: 10,
            listingStatus: "unlisted",
            sortCategory: "added",
            sortOrder: "desc",
        },
    });

    const {
        Filters,
        SearchField,
        SortField,
        Pagination,
        ResultInfo,
        setVariables,
        SelectedFilters,
        variables,
        results,
        loading,
    } = useFilter(SEARCH_HAVE_CARDS, DEFAULT_VARIABLES);

    const nodes = results?.cards || [];

    useEffect(() => {
        if (!called) {
            getHaveLists();
        }
    }, [])

    useEffect(() => {
        if (
            (!activeList && haveLists) ||
            (activeList === "null" && haveLists)
        ) {
            setActiveList(haveLists[0].id);
        }
    }, [haveLists]);

    useEffect(() => {
        setVariables({
            ...DEFAULT_VARIABLES,
            searchString: searchString || "*",
            haveListId: activeList,
        });
        q.list = activeList;
        history.push({
            pathname: location.pathname,
            search: `?${new URLSearchParams(q).toString()}`,
        });
    }, [activeList]);

    useEffect(() => {
        setDefaultVariables({
            ...DEFAULT_VARIABLES,
            searchString: results?.filters?.searchString,
            haveListId: activeList,
            options: {
                ...DEFAULT_VARIABLES.options,
                // listingStatus: !showPublished ? "unlisted" : "with_listed",
            },
        });
        const filters = results?.filters?.filterOptions
            ?.filter((f) => Boolean(f.selected.length))
            .map((f) => ({ filterKey: f.title, value: f.selected }));
        setHasSelectedFilters(Boolean(filters?.length));
    }, [results]);


    {/* Hiding this and other related variables for now. New listing page will now default to unlisted cards only */}
    // const handleShowPublishedChange = (e) => {
    //     const checked = e.target.checked;
    //     setVariables({
    //         ...DEFAULT_VARIABLES,
    //         options: {
    //             ...DEFAULT_VARIABLES.options,
    //             listingStatus: !checked ? "unlisted" : "with_listed",
    //         },
    //     });
    //     setShowPublished(!showPublished);
    // };


    if (hasNegativeBalance)  {
        window.location.replace("/seller_center/top_up/required");
        return true;
    }

    return (
        <div
            className={pageClass}
            style={{ backgroundImage: `url(${BgLights})` }}
        >
            <FilterOverlay displayFilter={isDisplayFilter}>
                <Filters
                    visible={true}
                    setVisibility={setDisplayFilter}
                    overlay
                />
            </FilterOverlay>
            {isSellingAllowed ? (
                <Fragment>
                    <div className="w-full max-h-36">
                        <div className="py-6 lg:py-14 space-y-16 lg:space-y-0 flex flex-col lg:flex-row justify-between border-b-2 border-opacity-30 border-gray-300">
                            <span className="font-body font-semibold text-xl ">
                                Select a card to list
                            </span>
                            {!loading ? (
                                <div className="flex space-x-4 justify-center ">
                                    <div>
                                        <SearchField
                                            inputClassname="border-0 outline-none focus:outline-none focus:ring-0 bg-gray-800 w-full text-gray-30 text-xs lg:text-sm"
                                            containerClassName="flex flex-row-reverse lg:flex-row items-center bg-gray-800 overflow-hidden relative py-2 pr-2 lg:pr-4 lg:pr-16 h-10 w-full"
                                            clearButtonClass="flex items-center text-fs-15 text-gray-1000 hover:text-primary cursor-pointer w-4 h-4 text-primary"
                                            iconColor="text-gray-1000"
                                            customParams={{
                                                name: "list",
                                                value: activeList,
                                            }}
                                            darkMode
                                        />
                                    </div>
                                    <FilterButton
                                        lgHidden={false}
                                        textColor="text-white"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDisplayFilter(!isDisplayFilter);
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="grid grid-cols-5 gap-2 m-0 space-y-12 lg:space-y-0">
                        <div className="col-span-full lg:col-span-1">
                            <ListNav
                                lists={haveLists}
                                activeList={activeList}
                                setActiveList={setActiveList}
                                loading={haveListLoading}
                                hasList={hasList}
                            />
                        </div>
                        <div className="col-span-full lg:col-span-4">
                            {((searchString &&
                                variables?.searchString !== "*") ||
                                hasSelectedFilters) && (
                                <div className="flex flex-col text-white-400 pr-4 py-4 font-body text-sm w-5/12">
                                    <ResultInfo textClass="text-gray-30 text-md" />
                                    <SelectedFilters />
                                </div>
                            )}
                            <div
                                className={`rounded-md bg-gray-800 shadow w-100 p-4 lg:p-10 ${
                                    hasSelectedFilters ? "my-2" : "mb-2"
                                } grid space-y-2`}>
                                <div className="flex justify-between">
                                    <SortField
                                        selectBGColor="bg-transparent"
                                        selectTextColor="text-gray-30"
                                        optionClass="text-black-100 p-2"
                                        border="2"
                                    />
                                    {/* Hiding this and other related variables for now. New listing page will now default to unlisted cards only */}
                                    {/* <Checkbox
                                        label="Show published listings"
                                        labelClass="cursor-pointer"
                                        checked={showPublished}
                                        inputDimension="6"
                                        onChange={(e) =>
                                            handleShowPublishedChange(e)
                                        }
                                    /> */}
                                </div>
                                <div>
                                    <MarketCardList
                                        rowView
                                        withTeamNames
                                        unlistedList
                                        newListingList
                                        listings={nodes}
                                        loading={loading}
                                    />
                                    {!Boolean(nodes.length) && !loading ? (
                                        <EmptyList
                                            hasListSelected
                                            bgColor="bg-transparent"
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex justify-between w-full py-3">
                                <div>
                                    <ResultInfo
                                        textClass="text-gray-30 text-md"
                                        hideSearchString
                                    />
                                </div>
                                <div>
                                    <Pagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <PageInfo type="sellingNotAllowed" />
            )}
        </div>
    );
};

export default NewListing;

import React, { Fragment, useContext, useEffect, useState } from "react";
import { render } from "react-dom";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
} from "react-router-dom";
import { MoreVertical, ChevronDown } from "react-feather";
import HaveList from "./have_list";
import OthersHaveList from "./have_list_others";
import WantList from "./want_list";
import UserIdContext from "./user_id_context";
import OrganizeStash from "../browse_cards/organize_stash";
import {
    VariablesContext,
    VariablesContextProvider,
} from "../variables_context";
import { PopupContextProvider } from "../popup_context";
import ApolloProviderClient from "../apollo_provider_client";
import IconAccount from "icon-account.svg";
import IconTrophyGradient from "icon-trophy-gradient.svg";
import { GAComponent } from "../hooks/useReactGA";
import { ConfirmationContextProvider } from "../confirmation_context";
import IsMarketplaceBetaUserContext from "../is_marketplace_beta_user_context";

const TopBarLink = ({ children, to, exact }) => {
    return (
        <NavLink
            to={to}
            className="TopBarLink font-body lg:ml-8 text-sm lg:text-lg"
            exact={exact}
            activeClassName="isActive">
            {children}
        </NavLink>
    );
};

const TopBar = (props) => {
    const userId = useContext(UserIdContext);
    const { setDisplayMenu, isDisplayMenu } = props;

    const { setIsTrophyRoom } = useContext(VariablesContext);
    useEffect(() => {
        setIsTrophyRoom(true);
    }, []);

    return (
        <Fragment>
            <div className="hidden lg:block bg-black-300 border-b-2 border-gray-300">
                <div className="container mx-auto flex items-center space-x-6 px-10">
                    <img src={IconTrophyGradient} className="w-6 h-6" />
                    <h1
                        className="font-bodytext-3xl text-white"
                        style={{ marginLeft: "14px" }}>
                        Trophy Room
                    </h1>
                    <div className="flex items-center ml-8 text-xl -mb-px">
                        <TopBarLink to="/my_trophy_room" exact>
                            Have List
                        </TopBarLink>
                        <TopBarLink to="/my_trophy_room/want_list">
                            Want List
                        </TopBarLink>
                        <TopBarLink to="/my_trophy_room/stash">
                            Stash
                        </TopBarLink>
                    </div>
                    <a
                        href={`/trophy_room/${userId}`}
                        className="flex items-center flex-1 justify-end text-white">
                        <img src={IconAccount} className="w-6 h-6" />
                        <span className="ml-2 text-gray-1000">
                            View as other user
                        </span>
                    </a>
                </div>
            </div>
            <div className="block lg:hidden bg-black-300 border-b border-gray-575">
                <div className="flex items-center text-xl justify-between px-4">
                    <div className="flex items-center">
                        <TopBarLink to="/my_trophy_room" exact>
                            <span className="flex items-center">Have List</span>
                        </TopBarLink>
                        <button
                            className="border-b-0"
                            onClick={() => setDisplayMenu(!isDisplayMenu)}>
                            <ChevronDown
                                size={16}
                                className="text-primary ml-2 cursor-pointer"
                                id="have-list-mobile-menu"
                            />
                        </button>
                    </div>
                    <div className="flex items-center">
                        <TopBarLink to="/my_trophy_room/want_list">
                            <span className="flex items-center">Want List</span>
                        </TopBarLink>
                        <button
                            className="border-b-0"
                            onClick={() => setDisplayMenu(!isDisplayMenu)}>
                            <ChevronDown
                                size={16}
                                className="text-primary ml-2 cursor-pointer"
                                id="want-list-mobile-menu"
                            />
                        </button>
                    </div>
                    <TopBarLink to="/my_trophy_room/stash">Stash</TopBarLink>
                    <a
                        href={`/trophy_room/${userId}`}
                        className="flex items-center justify-end text-gray-100">
                        <span className="ml-2 text-gray-100 text-sm">More</span>
                        <MoreVertical size={14} className="ml-1" />
                    </a>
                </div>
            </div>
        </Fragment>
    );
};

const App = () => {
    const [isDisplayMenu, setDisplayMenu] = useState(false);

    return (
        <Router>
            <Switch>
                <Route
                    path="/my_trophy_room/stash"
                    exact
                    component={OrganizeStash}
                />
                <Route
                    path="/my_trophy_room/want_list"
                    exact
                    component={(props) => (
                        <Fragment>
                            <TopBar
                                isDisplayMenu={isDisplayMenu}
                                setDisplayMenu={setDisplayMenu}
                            />
                            <WantList
                                isDisplayMenu={isDisplayMenu}
                                setDisplayMenu={setDisplayMenu}
                                {...props}
                            />
                        </Fragment>
                    )}
                />
                <Route
                    path="/my_trophy_room"
                    exact
                    component={(props) => (
                        <Fragment>
                            <TopBar
                                isDisplayMenu={isDisplayMenu}
                                setDisplayMenu={setDisplayMenu}
                            />
                            <HaveList
                                isDisplayMenu={isDisplayMenu}
                                setDisplayMenu={setDisplayMenu}
                                {...props}
                            />
                        </Fragment>
                    )}
                />
                <Route path="/trophy_room/:userId" component={OthersHaveList} />
            </Switch>
            <Route path="*" component={GAComponent} />
        </Router>
    );
};

export default () => {
    document.addEventListener("DOMContentLoaded", function () {
        const container = document.getElementById("trophyRoom");
        if (container) {
            const userId = container?.dataset?.userId;
            const isMarketplaceBetaUser = JSON.parse(container?.dataset?.marketplaceBetaUser || "false");
            render(
                <ApolloProviderClient>
                    <PopupContextProvider>
                        <VariablesContextProvider>
                            <UserIdContext.Provider value={userId}>
                                <ConfirmationContextProvider>
                                    <IsMarketplaceBetaUserContext.Provider value={isMarketplaceBetaUser}>
                                        <App />
                                    </IsMarketplaceBetaUserContext.Provider>
                                </ConfirmationContextProvider>
                            </UserIdContext.Provider>
                        </VariablesContextProvider>
                    </PopupContextProvider>
                </ApolloProviderClient>,
                container
            );
        }
    });
};

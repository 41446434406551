const useAddressFormatter = (contactInformation, includeBuilding = false) => {
    let address = {
        addressLineOne: null,
        addressLineTwo: null,
        fullAddress: null
    };
    if (!contactInformation) return address;

    const {
        unitNumber,
        building,
        street,
        town,
        state,
        countryName,
        postalCode
    } = contactInformation;

    address.addressLineOne = `${unitNumber} ${(building && includeBuilding) ? `${building}` : ''} ${street ? `${street}` : ''}`;
    address.addressLineTwo = `${town} ${state} ${countryName} ${postalCode}`;
    address.fullAddress = `${address.addressLineOne} ${address.addressLineTwo}`;

    return address;
}

export default useAddressFormatter;

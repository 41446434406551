import React, { Fragment, useContext, useEffect, useState } from "react";
import EmptyList from "./empty_list";
import CardList from "./card_list";
import { SEARCH_USER_HAVE_CARDS, SEARCH_WANT_CARDS } from "../queries";
import useFilterOptions from "../hooks/useFilterOptions";
import UserIdContext from "./user_id_context";
import SpinnerLoader from "../spinner_loader";
import { VariablesContext } from "../variables_context";
import IconFilter from "icon-filter-gold.svg";
import useFilter from "../filter/use_filter";
import FilterOverlay from "../filter/filter_overlay";
import FilterButton from "../filter_button";

const ListContainer = ({ listId, isHaveList, isWantList, name, isViewOnly = false, haveLists }) => {
    const userId = useContext(UserIdContext);
    const { setVariables: setCacheVariables } = useContext(VariablesContext);
    const { _search, initialPage } = useFilterOptions();
    const [searchString] = _search;
    const [isDisplayFilter, setDisplayFilter] = useState(false);

    const QUERY = isHaveList ? SEARCH_USER_HAVE_CARDS : SEARCH_WANT_CARDS;
    const _listId = isHaveList
        ? { haveListId: listId }
        : { wantListId: listId };

    const DEFAULT_VARIABLES = {
        searchString: searchString || "*",
        userId: userId,
        ..._listId,
        options: {
            page: Number(initialPage),
            sortCategory: "added",
            sortOrder: "desc",
        },
    };

    useEffect(() => {
        setVariables({
            ...DEFAULT_VARIABLES,
            ..._listId,
        });
    }, [listId]);

    const {
        Filters,
        SearchField,
        SortField,
        Pagination,
        setVariables,
        handleReload,
        variables,
        results,
        loading,
    } = useFilter(QUERY, DEFAULT_VARIABLES);

    useEffect(() => {
        setCacheVariables(variables);
    }, [results]);

    const nodes = results?.cards || [];
    const filters = results?.filters;
    const vaultStatus = filters?.vaultStatus;
    const publishStatus = filters?.publishStatus;

    function handleChangePublic(e, value) {
        const checked = e.target.checked;
        const vaultStatus = {
            vaultStatus: checked ? value : null,
        };
        setVariables({
            ...variables,
            options: {
                ...variables.options,
                ...vaultStatus,
            },
        });
        if (!checked) e.target.checked = false;
    }

    function handleChangePublished(e, value) {
        const checked = e.target.checked;

        const publishStatus = {
            publishStatus: checked ? value : null,
        };
        setVariables({
            ...variables,
            options: {
                ...variables.options,
                ...publishStatus,
            },
        });
        if (!checked) e.target.checked = false;
    }

    return (
        <Fragment>
            <div className="md:ml-8 flex flex-col w-full  animate__animated animate__fadeIn">
                {!isViewOnly && !loading ? (
                    <Fragment>
                        <FilterOverlay displayFilter={isDisplayFilter}>
                            <Filters
                                visible={true}
                                setVisibility={setDisplayFilter}
                                overlay
                                showHideButton
                            />
                        </FilterOverlay>
                        {isHaveList && (
                            <div className="mb-4 space-between items-center hidden lg:flex">
                                <SearchField placeholder="Search My Trophy Room" />
                                {/* <SearchField
                                    handleSubmit={search}
                                    clearSearch={clearSearch}
                                    defaultValue={searchString}
                                /> */}
                                <div className="ml-auto pl-4">
                                    <SortField />
                                    {/* <SortField
                                        onChange={handleSort}
                                        value={currentSort}
                                    /> */}
                                </div>
                                <button
                                    className="flex items-center cursor-pointer text-primary font-semibold ml-4"
                                    id="filter-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDisplayFilter(!isDisplayFilter);
                                    }}>
                                    <img
                                        src={IconFilter}
                                        className="w-5 h-5 mr-2"
                                    />
                                    Filters
                                </button>
                            </div>
                        )}
                        {/* Mobile view for search field and filters*/}
                        <div className="flex-col lg:hidden">
                            <div className="mb-4 flex items-center">
                                <h1 className="text-white font-body text-xl mr-auto">
                                    {name || "-"}
                                </h1>
                            </div>
                            <div
                                className="pb-6 mb-4 space-between justify-between items-center
                                flex border-b border-gray-575">
                                { isHaveList && <SearchField placeholder="Search My Trophy Room" /> }
                                <FilterButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDisplayFilter(!isDisplayFilter);
                                    }}
                                />
                            </div>
                            <div className="mb-4 justify-between items-center flex">
                                <div className="">
                                    <SortField />
                                </div>
                                {/* Feature not working yet. Will hide for now */}
                                {/* <div className="flex items-center cursor-pointer text-sm font-body">
                                    <input
                                        type="checkbox"
                                        className="rounded text-primary mr-2"
                                    />
                                    <span className="text-gray-1000">
                                        Listed on Marketplace
                                    </span>
                                </div> */}
                            </div>
                        </div>
                        {isHaveList && (
                            <div className="py-4 hidden lg:flex items-center space-x-2">
                                <input
                                    className="hidden published"
                                    type="checkbox"
                                    id="vaulted"
                                    name="isPublic"
                                    value="vaulted"
                                    onChange={(e) =>
                                        handleChangePublic(e, "vaulted")
                                    }
                                    checked={vaultStatus === "vaulted"}
                                />
                                <label
                                    htmlFor="vaulted"
                                    className="p-2 rounded border"
                                    data-value="vaulted">
                                    Vaulted
                                </label>
                                <input
                                    className="hidden published"
                                    type="checkbox"
                                    name="isPublic"
                                    value="vaulted-hidden"
                                    id="vaulted-hidden"
                                    onChange={(e) =>
                                        handleChangePublic(e, "vaulted-hidden")
                                    }
                                    checked={vaultStatus === "vaulted-hidden"}
                                />

                                <label
                                    htmlFor="vaulted-hidden"
                                    className="p-2 rounded border "
                                    data-value="vaulted-hidden">
                                    Vaulted Hidden
                                </label>
                                <input
                                    className="hidden published"
                                    type="checkbox"
                                    id="published"
                                    name="isPublished"
                                    value="published"
                                    onChange={(e) =>
                                        handleChangePublished(e, "published")
                                    }
                                    checked={publishStatus === "published"}
                                />
                                <label
                                    htmlFor="published"
                                    className="p-2 rounded border"
                                    data-value="published">
                                    Published
                                </label>
                                <input
                                    className="hidden published"
                                    type="checkbox"
                                    name="isPublished"
                                    value="draft"
                                    id="draft"
                                    onChange={(e) =>
                                        handleChangePublished(e, "draft")
                                    }
                                    checked={publishStatus === "draft"}
                                />

                                <label
                                    htmlFor="draft"
                                    className="p-2 rounded border "
                                    data-value="draft">
                                    Draft
                                </label>
                            </div>
                        )}
                    </Fragment>
                ) : null}

                <div className="mb-6 hidden lg:flex items-center">
                    <h1 className="text-white font-body text-xl mr-auto">
                        {name || "-"}
                    </h1>
                </div>

                {nodes?.length > 0 ? (
                    <CardList
                        cards={nodes}
                        loading={loading}
                        isHaveList={isHaveList}
                        isWantList={isWantList}
                        isViewOnly={isViewOnly}
                        listId={listId}
                        refreshList={handleReload}
                        haveLists={haveLists}
                    />
                ) : loading ? (
                    <SpinnerLoader />
                ) : (
                    <EmptyList
                        isViewOnly={isViewOnly}
                        hasListSelected={Boolean(listId)}
                    />
                )}
                <Pagination />
            </div>
        </Fragment>
    );
};

export default ListContainer;

import { useFormikContext } from "formik";
import React, { useContext } from "react";
import { Edit2 } from "react-feather";
import { useHistory } from "react-router-dom";
import { ConfirmationContext } from "../../confirmation_context";
import ShippingOption from "../shop_settings/shipping_option";

const ShippingMethod = ({
    shippingMethods,
    handleFormSubmit,
    userShippingMethods,
    forUpdate = false,
}) => {
    const hasShippingMethod = Boolean(shippingMethods.length);
    const hasActiveShippingMethod = userShippingMethods?.some(s => s.isActive);
    const history = useHistory();
    const confirm = useContext(ConfirmationContext);
    const { values } = useFormikContext();
    let comfirmProps = {
        title: "Leave listing creation",
        text: "As this is your first time selling an item, you need to set up the shipping options you provide, so a member can select from these when they purchase from you. You only need to do this once.",
        subText: "When you select 'Confirm' you will leave this page to complete your shipping options. Your listing will be saved as a draft in your seller center under drafts.",
    };

    if (hasActiveShippingMethod) {
        comfirmProps.text = comfirmProps.subText;
        comfirmProps.subText = null;
    }

    const handleEditShippingOptions = () => {
        if (!forUpdate) {
            confirm({
                ...comfirmProps,
                onConfirm: () =>
                    handleFormSubmit(
                        {
                            ...values,
                            status: "draft",
                        },
                        "/seller_center/shop_settings"
                    ),
            });
        }
        if (forUpdate) {
            history.push({
                pathname: "/seller_center/shop_settings",
            });
        }
    };

    return (
        <>
            {hasShippingMethod && (
                <div className="space-y-6">
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <div className="grid text-gray-30">
                            <span className="font-semibold text-xl">
                                Shipping method
                            </span>
                            <span>Verify your chosen shipping options.</span>
                        </div>
                        <div className="p-2">
                            <button
                                className="flex space-x-2 items-center cursor-pointer"
                                type="button"
                                onClick={() => handleEditShippingOptions()}>
                                <Edit2
                                    className="text-primary w-4 h-4"
                                    fill="currentColor"
                                />
                                <span className="font-semmibold text-md">
                                    Edit shipping options
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="space-y-6">
                        {shippingMethods.map((shippingMethod) => (
                            <ShippingOption
                                key={shippingMethod.fulfillmentType}
                                userShippingMethod={userShippingMethods.filter(
                                    (s) =>
                                        s.fulfillmentType ===
                                        shippingMethod.fulfillmentType
                                )}
                                {...shippingMethod}
                                disabled
                                hideCheckbox
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default ShippingMethod;

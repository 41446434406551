import React from "react";
import BgLights from "bg-lights-darker.png";
import SideBar from "./shared_components/sidebar";

const SellerCenterContainer = ({ children }) => {
    const pageClass = [
        "bg-fixed bg-center bg-cover bg-gray-900 text-white",
        "animate__animated animate__fadeIn",
        "bg-no-repeat",
    ].join(" ");

    return (
        <div
            className={pageClass}
            style={{ backgroundImage: `url(${BgLights})` }}>
            <div className="min-h-screen px-4 md:px-8 lg:px-12 py-8">
                <div className="grid grid-cols-9 xl:grid-cols-7 gap-0 w-full space-y-6 md:space-y-0 overflow-hidden">
                    <div className="col-span-1 lg:col-span-2 xl:col-span-1">
                        <SideBar />
                    </div>
                    <div className="col-span-full lg:col-span-7 xl:col-span-6">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SellerCenterContainer;

import React, { useRef, useState } from "react";
import { Edit2, Tag } from "react-feather";
import formatCardPlayers from "../../utils/format_card_players";
import CardDetailItem from "./card_detail_item";
import OwnedCardDetailModal from "../../trophy_room/owned_card_detail/owned_card_detail_modal";
// import HelpText from "../../utils/help_text";
// import mailTo from "../../utils/mail_to";
// import { SUPPORT_MAIL } from "../../utils/constants";

const CardDetails = ({
    countryName,
    printRun,
    printRunOrdinality,
    graderName,
    grade,
    description,
    cardName,
    playersJson,
    isPreview, // marketplace listing preview
    ownedByCurrentUser,
    draftPreview,
    id,
    ownedCardId,
    gradedBy,
    countryId,
    isPublic,
    frontImage,
    backImage,
    haveLists,
    onImageUpdate,
    reloadOnUpdate,
    longerHeader,
    status,
    activeMarketListing,
    draftMarketListing,
}) => {
    const noInfo = "No Information";
    // const showMessage =
    //     (isPreview && !ownedByCurrentUser) || (isPreview && draftPreview);
    const [showEditModal, setShowEditModal] = useState(false);
    const ownedCardData = useRef({
        frontImage: frontImage,
        backImage: backImage,
    });

    const handleShowEditModal = () => {
        ownedCardData.current = {
            id: ownedCardId || id,
            cardName: cardName || "No Card Name",
            printRunOrdinality: printRunOrdinality || "",
            grade: grade || "",
            gradedBy: gradedBy || "",
            countryId: countryId || "",
            description: description || "",
            isPublic: isPublic || false,
            frontImage: frontImage,
            backImage: backImage,
            printRun: printRun,
            haveListIds: haveLists.map((h) => h.id),
        };
        setShowEditModal(true);
    };
    return (
        <div className="space-y-2 md:space-y-6 mt-4 md:mt-0">
            <div className="flex flex-col md:flex-row md:justify-between space-y-6 md:space-y-0">
                <div className="grid font-semibold text-xl text-gray-1000 space-y-1">
                    {isPreview && status === "active" && (
                        <div className="py-1 px-2 w-24 flex mb-2 justify-center space-x-2 items-center bg-gray-300 bg-opacity-40 rounded uppercase">
                            <Tag
                                className="w-3 h-3"
                                fill="currentColor"
                                fillRule=""
                            />
                            <span className="text-xs">For Sale</span>
                        </div>
                    )}
                    <div className="flex flex-col">
                        <span
                            className={`${
                                longerHeader ? "md:w-5/6" : ""
                            } break-words cursor-pointer`}>
                            <abbr
                                title={cardName}
                                className="!no-underline border-0">
                                {cardName}
                            </abbr>
                        </span>
                        <span
                            className={`${
                                longerHeader ? "w-5/6" : ""
                            } merqary-truncate cursor-pointer`}>
                            <abbr
                                title={formatCardPlayers(playersJson, "names")}
                                className="!no-underline border-0">
                                {formatCardPlayers(playersJson, "names")}
                            </abbr>
                        </span>
                        <span
                            className={`${
                                longerHeader ? "w-5/6" : ""
                            } merqary-truncate cursor-pointer`}>
                            <abbr
                                title={formatCardPlayers(playersJson, "teams")}
                                className="!no-underline border-0">
                                {formatCardPlayers(playersJson, "teams")}
                            </abbr>
                        </span>
                    </div>
                </div>
                <div className="p-2">
                    {!isPreview && (
                        <>
                            <button
                                className="flex space-x-2 items-center cursor-pointer"
                                onClick={() => handleShowEditModal()}>
                                <Edit2
                                    className="text-primary w-4 h-4"
                                    fill="currentColor"
                                />
                                <span className="font-semibold text-md">
                                    Edit card details
                                </span>
                            </button>
                            <OwnedCardDetailModal
                                mountModal={showEditModal}
                                setMountModal={setShowEditModal}
                                ownedCardDetails={ownedCardData.current}
                                onImageUpdate={onImageUpdate}
                                reloadOnUpdate={reloadOnUpdate}
                                activeMarketListing={activeMarketListing}
                                draftMarketListing={draftMarketListing}
                                playersJson={playersJson}
                            />
                        </>
                    )}
                    {/* {showMessage ? (
                        <HelpText
                            sideText="Contact support"
                            sideTextClass="text-white font-semibold"
                            icon="message"
                            size="w-4 h-4"
                            showTextOnHover={false}
                            onClick={() => mailTo(SUPPORT_MAIL)}
                        />
                    ) : null} */}
                    {/* TODO waiting for design subheader */}
                    {
                        <div className="hidden text-sm">
                            <CardDetailItem
                                title="Player/Team"
                                value={formatCardPlayers(playersJson)}
                                col
                            />
                        </div>
                    }
                </div>
            </div>
            {!isPreview && (
                <div className="space-y-3">
                    <CardDetailItem title="Location" value={countryName} />
                    <CardDetailItem
                        title="Print Run"
                        value={
                            printRun &&
                            `#${printRunOrdinality || ""}/${printRun}`
                        }
                    />
                    <CardDetailItem
                        title="Grading"
                        value={
                            !graderName && !grade
                                ? noInfo
                                : `${graderName.toUpperCase()} ${grade}`
                        }
                    />
                    <CardDetailItem title="Details" value={description} />
                </div>
            )}
        </div>
    );
};

export default CardDetails;

import { useFormikContext } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import useAddressFormatter from '../../hooks/useAddressFormatter'
import { PopupContext } from '../../popup_context'
import CardContainer from './card_container'
import ShippingAddressModal from './shipping_address_modal'

const ShippingAddress = ({
    shippingAddress,
    checkPaymentValidity,
    buyer,
}) => {
    const { setShowPopup } = useContext(PopupContext);
    const [buyerShippingAddress, setShippingAddress] = useState(shippingAddress);
    const [showEditShippingAddressModal, setShowEditShippingAddressModal] = useState(!shippingAddress);

    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (buyerShippingAddress) {
            setFieldValue('contactInformationId', buyerShippingAddress.id);
        }
    }, [buyerShippingAddress]);

    const { addressLineOne, addressLineTwo } = useAddressFormatter(buyerShippingAddress);
    return (
        <CardContainer
            title="Shipping Address"
            titleBorder
            fullWidthHeader
            actionText={buyerShippingAddress ? "Change" : "Add shipping address"}
            actionIcon="edit"
            onActionClick={() => {
                checkPaymentValidity(() => {
                    setShowPopup(false);
                    setShowEditShippingAddressModal(true);
                })
            }}
        >
            {buyerShippingAddress && (
                <div className='p-6 text-gray-30 flex space-x-12'>
                    <div className='grid'>
                        <span>{buyerShippingAddress.fullName}</span>
                        <span>{buyerShippingAddress.phoneNumber}</span>
                    </div>
                    <div className="flex flex-col">
                        <span>{addressLineOne},</span>
                        <span>{addressLineTwo}</span>
                    </div>
                </div>
            )}
            {!buyerShippingAddress && (
                <div className='flex justify-center'>
                    <span>Please add your shipping address to proceed to checkout</span>
                </div>
            )}
            <ShippingAddressModal
                buyer={buyer}
                mountModal={showEditShippingAddressModal}
                setMountModal={setShowEditShippingAddressModal}
                shippingAddress={buyerShippingAddress}
                setShippingAddress={setShippingAddress}
            />
        </CardContainer>
    );
};

export default ShippingAddress;

import React, { useState } from "react";
import SortField, { DEFAULT_SORT } from "../../sort_field";
import StarRating from "../../star_rating";
import pluralizeString from "../../utils/pluralize_string";

const Reviews = ({
    items = [],
    reviewCount,
    averageRating,
    positiveFeedback,
}) => {
    const [currentSort] = useState(JSON.stringify(DEFAULT_SORT));
    return (
        <div className="text-white text-gray-1000 rounded-md bg-gray-800 shadow w-100 p-0 my-12">
            <div className="grid space-y-3">
                <div className="flex justify-between py-4 px-6">
                    <div className="grid space-y-4">
                        <span className="text-xl font-semibold">
                            Member Reviews
                        </span>
                        <div className="grid space-y-2">
                            <div className="flex space-x-4 items-center">
                                <StarRating
                                    value={averageRating}
                                    customClass="text-primary"
                                    iconSize={18}
                                    readOnly
                                />
                            </div>
                            <div className="flex space-x-2">
                                <div className="flex space-x-2 text-sm ">
                                    <span className="font-semibold">
                                        {pluralizeString(reviewCount, "Rating")}
                                    </span>
                                    {Boolean(reviewCount.length) && (
                                        <span className="text-gray-30">
                                            {positiveFeedback}% positive
                                            feedback
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden font-semibold">
                        <SortField onChange={() => {}} value={currentSort} />
                    </div>
                </div>
                {items.map((review, i) => (
                    <ReviewItem key={i} {...review} />
                ))}
                {/* <div className="px-6 py-4">
                    <div className="py-4 border-t-2 border-gray-200 border-opacity-40 flex justify-between">
                        <div className="flex space-x-2">
                            <span className="font-semibold">
                                10 of 100
                            </span>
                            <span>
                                results displayed
                            </span>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

const ReviewItem = ({ user, rating, title, dateReviewed, content }) => {
    return (
        <div className="px-6 py-4">
            <div className="flex flex-col space-y-1">
                <StarRating
                    value={rating}
                    customClass="text-primary"
                    iconSize={16}
                    readOnly
                    withText
                />
                <div className="flex flex-col space-y-3">
                    {title && title.trim() !== "" ? (
                        <div className="text-sm font-semibold">{`"${title}"`}</div>
                    ) : null}
                    {content && content.trim() !== "" ? (
                        <div className="text-sm text-white-400 text-wrap leading-5">
                            {content}
                        </div>
                    ) : null}
                </div>
                <div className="grid text-sm">
                    <span className="font-semibold">
                        {user.fullName || user.username}
                    </span>
                    <span className="text-white-400">{dateReviewed}</span>
                </div>
            </div>
        </div>
    );
};

export default Reviews;

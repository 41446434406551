import React, { useContext, useEffect, useState } from "react";
import Button from "../../seller_center/shared_components/button";
import CartSellerItem from "./cart_seller_item";
import formatPrice from "../../utils/format_price";
import useSelection from "../../hooks/useSelection";
import cartItemsMutation from "../../mutations/cart_items_mutation";
import { PopupContext, POPUP_TYPES } from "../../popup_context";
import { ConfirmationContext } from "../../confirmation_context";
import pluralizeString from "../../utils/pluralize_string";

const containerClass = ["py-6", "rounded shadow", "bg-gray-800"].join(" ");

const CartSeller = ({
    seller,
    cartItems,
    subTotal,
    fetchCartItems,
}) => {
    const confirm = useContext(ConfirmationContext);
    const cartItemIds = cartItems
        .filter(item => item.marketListing.availableForCheckout)
        .map(item => item.cartItemId);
    const unavailableCartItemIds = cartItems
        .filter(item => !cartItemIds.includes(item.cartItemId))
        .map(item => item.cartItemId);
    const hasUnavailableCartItems = Boolean(unavailableCartItemIds?.length);
    const { selected, select, deSelect } = useSelection(cartItemIds);
    const [selectedSubTotal, setSelectedSubtotal] = useState(subTotal);
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);

    useEffect(() => {
        setSelectedSubtotal(
            cartItems
                .filter((item) => selected.includes(item.cartItemId) && item.marketListing.availableForCheckout)
                .reduce((total, item) => total + item.marketListing.price, 0)
                .toFixed(2)
        );
    }, [selected, fetchCartItems]);

    const deleteCartItemsCallback = (data) => {
        const result = data.deleteCartItems;
        if (result.success) {
            window.location.reload();
        } else {
            setShowPopup(true);
            setPopupAction(POPUP_TYPES.WARN);
            setMessage(result.errors.join(", "));
        }
    };

    // when clicking delete all
    const deleteSelected = (selected, sellerUsername) => {
        let cartItemsToDelete = [...selected, ...unavailableCartItemIds];
        let message = null;

        if (Boolean(cartItemsToDelete.length)) {
            message = Boolean(selected.length)
                            ? `Delete selected items from ${sellerUsername}?`
                            : "";
            if (hasUnavailableCartItems) {
                message += " All unavailable items will be deleted";
                message += Boolean(selected.length)
                            ? " as well."
                            : ".";
            }
        }

        handleDeleteCartItems(
            cartItemsToDelete,
            `Delete cart items`,
            message
        );
    };

    const handleDeleteCartItems = (cartItemIds, title, message) => {
        if (!Boolean(cartItemIds.length)) {
            setShowPopup(true);
            setPopupAction(POPUP_TYPES.WARN);
            setMessage("No cart item selected");
            return;
        }
        confirm({
            onConfirm: () => deleteCartItems({
                                variables: {
                                    input: { cartItemIds: cartItemIds },
                                },
                            }),
            title,
            text: message
        });
    };

    const handleCheckoutCartItems = (cartItemIds) => {
        if (cartItemIds.length < 1) {
            setShowPopup(true);
            setPopupAction(POPUP_TYPES.WARN);
            setMessage("No cart item selected");
            return;
        }
        checkoutCartItems({
            variables: {
                input: { cartItemIds: cartItemIds },
            },
        });
    };

    const { deleteCartItems } = cartItemsMutation(deleteCartItemsCallback);
    const { checkoutCartItems, checkoutCartItemsLoading } = cartItemsMutation();

    return (
        <div className={containerClass}>
            {checkoutCartItemsLoading && (
                <div className="w-full h-full fixed block top-0 left-0 bg-black opacity-80 z-50">
                    <div className="grid top-1/2 my-0 mx-auto block relative h-0 text-center text-white w-full">
                        {/* <img src={}  /> */}
                        <span className="text-3xl font-semibold">
                            Processing...
                        </span>
                        <span className="text-xl font-semibold">
                            You will automatically be redirected to a new page
                        </span>
                    </div>
                </div>
            )}
            <label htmlFor={`${seller?.username}`} className="cursor-pointer">
                <div className="px-2 md:px-12 pb-4 grid grid-cols-12 gap-0 text-gray-30 ">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id={`${seller?.username}`}
                            className="text-primary bg-gray-30 rounded w-5 h-5 focus:ring-0"
                            checked={Boolean(selected.length) && selected.length === cartItemIds.length}
                            onChange={(e) =>
                                e.target.checked
                                    ? select(cartItemIds.filter(i => !selected.includes(i)))
                                    : deSelect(selected)
                            }
                        />
                    </div>
                    <div className="col-span-11 flex justify-between items-center">
                        <div className="flex space-x-2">
                            <span>Fulfilled by:</span>
                            <a
                                href={`/marketplace/s/${seller?.username}`}
                                className="underline">
                                {seller?.username}
                            </a>
                        </div>
                        <Button
                            text="Delete all"
                            type="button"
                            customClass="underline hidden md:block"
                            transparent
                            onClick={() => deleteSelected(selected, seller?.username)}
                        />
                    </div>
                </div>
            </label>
            <div className="border-t-2 border-gray-300 border-opacity-40">
                <div className="grid py-6 space-y-6">
                    {cartItems.map((item) => (
                        <CartSellerItem
                            key={item?.cartItemId}
                            {...item.marketListing}
                            selected={selected.includes(item.cartItemId)}
                            select={() => select(item?.cartItemId)}
                            deSelect={() => deSelect(item?.cartItemId)}
                            deleteCartItem={() =>
                                handleDeleteCartItems(
                                    [item?.cartItemId],
                                    "Delete cart item",
                                    `${item?.marketListing?.ownedCard?.cardName} will be deleted, continue?`
                                )
                            }
                        />
                    ))}
                </div>
            </div>
            <label htmlFor={`${seller?.username}`} className="cursor-pointer">
                <div className="px-12 flex flex-col md:flex-row md:space-x-6 md:items-center md:justify-end space-y-4 md:space-y-0">
                    <div className="flex md:space-x-4 justify-between">
                        <span>Subtotal [{pluralizeString(selected.length, "item")}]</span>
                        <span className="font-semibold">
                            {formatPrice(selectedSubTotal)}
                        </span>
                    </div>
                    <div className="flex flex-col justify-center space-y-4">
                        <Button
                            text="Checkout from this seller"
                            type="button"
                            primary
                            onClick={() => handleCheckoutCartItems(selected)}
                        />
                        <Button
                            text="Delete all"
                            type="button"
                            customClass="underline block md:hidden text-gray-30"
                            transparent
                            onClick={() => deleteSelected(selected, seller?.username)}
                        />
                    </div>
                </div>
            </label>
        </div>
    );
};

CartSeller.defaultProps = {
    seller: null,
    cartItems: [],
    subTotal: null,
};

export default CartSeller;

import React, { Fragment } from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import CopyToClipboard from "../../utils/copy_to_clipboard";
import PurchaseSellerItems from "./purchase_seller_items";
import formatPrice from "../../utils/format_price";
import PurchaseSellerAction from "./purchase_seller_action";
// import HelpText from "../../utils/help_text";
// import mailTo from "../../utils/mail_to";
// import { SUPPORT_MAIL } from "../../utils/constants";

const PurchaseSeller = ({
    id,
    sellerUsername,
    status,
    purchaseSellerItems,
    transactionNumber,
    buyerDisplayedStatus,
    trackingInfo,
    review,
    dateOrdered,
    cancelledAt,
    refunds,
    shippingFee,
    totalOrderAmount,
    payment,
    callback = () => {},
}) => {
    return (
        <div className="bg-gray-800 rounded">
            <div className="border-b-4 border-gray-300 border-opacity-40">
                <div className="py-7 px-4 md:px-12 flex flex-col md:flex-row justify-between">
                    <div className="space-y-3">
                        <a href={`/marketplace/my_orders/${id}`}>
                            <div className="flex flex-col md:flex-row md:space-x-2 md:items-center font-semibold">
                                <span>Order No.</span>
                                <span>{transactionNumber}</span>
                            </div>
                        </a>
                        <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 md:items-center">
                            <div className="flex space-x-2 items-center">
                                {/* TODO :: Replace with store icon */}
                                <Home size={14} className="text-gray-30" />
                                <Link
                                    className="text-gray-30 underline"
                                    to={`/marketplace/s/${sellerUsername}`}>
                                    {sellerUsername}
                                </Link>
                            </div>
                            {/* <HelpText
                                sideText="Contact support"
                                sideTextClass="text-white font-semibold"
                                icon="message"
                                size="w-4 h-4"
                                showTextOnHover={false}
                                onClick={() => mailTo(SUPPORT_MAIL)}
                            /> */}
                        </div>
                    </div>

                    <div className="flex items-center divide-x-2 divide-gray-300 divide-opacity-40 mt-6 md:mt-0">
                        <div className="pl-0 md:pl-3 pr-3 uppercase font-semibold">
                            {buyerDisplayedStatus}
                        </div>
                        {trackingInfo && (
                            <div className="pr-0 md:pr-3 pl-3 flex text-gray-30 space-x-2 items-center">
                                <a
                                    href={trackingInfo.trackingUrl || "#"}
                                    target={
                                        trackingInfo.trackingUrl ? "_blank" : ""
                                    }>
                                    <span>
                                        {trackingInfo.logisticsProvider}{" "}
                                        {trackingInfo.trackingNumber}
                                    </span>
                                </a>
                                <CopyToClipboard
                                    text={trackingInfo.trackingNumber}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* NOTE: item currency, and payment details currency will have mismatch until deprecation of User.currency */}
            <div className="pt-4 px-4 md:pt-8 md:px-12">
                <PurchaseSellerItems
                    items={purchaseSellerItems}
                    purchaseSellerStatus={status}
                    purchaseSellerId={id}
                />
                <div className="flex flex-col md:flex-row md:justify-between space-y-4 md:space-y-0 py-6">
                    <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 text-gray-30 md:items-center">
                        <span>Order Placed</span>
                        <span>{dateOrdered}</span>
                    </div>
                    {cancelledAt && (
                        <Fragment>
                            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 text-gray-30 md:items-center">
                                <span>Order Cancelled</span>
                                <span>{cancelledAt}</span>
                            </div>
                            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:items-center">
                                <span className="text-gray-30">
                                    Refund Amount
                                </span>
                                <span className="md:ml-4 font-semibold">
                                    {formatPrice(refunds[0]?.amount, "V C", refunds[0]?.currency)}
                                </span>
                            </div>
                        </Fragment>
                    )}
                    <div className="flex flex-col space-y-2">
                        <div className="flex flex-col md:justify-end md:flex-row space-y-2 md:space-x-2 md:space-y-0 md:items-center">
                            <span className="text-gray-30">Shipping Fee</span>
                            <span className="text-gray-30">
                                {formatPrice(
                                    shippingFee,
                                    "V C",
                                    payment?.currency
                                )}
                            </span>
                        </div>
                        <div className="flex flex-col md:justify-end md:flex-row space-y-2 md:space-y-0 md:items-center">
                            <span className="text-gray-30">
                                Total [{purchaseSellerItems.length} items]
                            </span>
                            <span className="md:ml-4 font-semibold ">
                                {formatPrice(
                                    totalOrderAmount,
                                    "V C",
                                    payment?.currency
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {!cancelledAt && (
                <div className="py-8 px-12 rounded-b bg-gray-600 text-gray-30">
                    <PurchaseSellerAction
                        id={id}
                        status={status}
                        review={review}
                        sellerUsername={sellerUsername}
                        callback={callback}
                    />
                </div>
            )}
        </div>
    );
};

export default PurchaseSeller;

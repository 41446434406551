import React from "react";

const PageTitle = ({ title, contentText, showBorder, children }) => {
    const containerClass = [
        "flex items-center",
        "w-full py-6",
        showBorder && "border-opacity-30  border-gray-300 border-b-2",
    ].join(" ");

    return (
        <div className={containerClass}>
            <div className="flex flex-col text-gray-1000 text-lg mr-auto pr-2">
                <span className="font-semibold mb-1">{title}</span>
                <span className="text-sm text-gray-30">{contentText}</span>
            </div>
            {children}
        </div>
    );
};

PageTitle.defaultProps = {
    title: null,
    contentText: null,
    showBorder: true,
};

export default PageTitle;

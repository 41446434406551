import React from "react";
import BgComingSoon from "bg-coming-soon.jpg";

const MainBreadcrumbs = (props) => {
    const { isMainMarketPlace, children } = props;

    return (
        <div
            className="py-2 md:py-10 px-4 g-fixed bg-center bg-cover"
            style={{ backgroundImage: `url(${BgComingSoon})` }}>
            <div className="merqary-container mx-auto flex flex-col md:flex-row md:items-center py-4">
                {isMainMarketPlace && (
                    <h1 className="text-xl text-primary-900 uppercase mr-4 text-xl text-primary-900 uppercase merqary-main-market-header">
                        Explore the Marketplace
                    </h1>
                )}
                {!isMainMarketPlace && (
                    <h1 className="text-xl text-primary-900 uppercase mr-4">
                        Marketplace
                    </h1>
                )}
                {!isMainMarketPlace ? (
                    <div className="md:border-l md:pl-4 border-primary flex items-center space-x-2 md:space-x-4">
                        {children}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default MainBreadcrumbs;

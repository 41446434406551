import React from "react";
import { Image as ImageIcon } from "react-feather";

const CardImage = ({ isFront = false, image, hideMoreText = false, zoom }) => {
    const className = [
        isFront ? "front" : "back",
        "flex items-center justify-center",
        "bg-center bg-contain bg-no-repeat absolute inset-0",
        image ? "bg-white" : "bg-gray-50",
    ].join(" ");

    return (
        <div
            className={className}
            style={image ? { backgroundImage: `url(${image})` } : {}}>
            {!image && (
                <div className="flex flex-col items-center text-gray-700">
                    <ImageIcon size={24} />
                    <div className="px-4 text-center space-y-1">
                        <span className="font-bold text-xs lg:text-xs">
                            {isFront ? "Front " : "Back "} image unavailable
                        </span>
                        {!hideMoreText && (
                            <p className="text-xs lg:text-sm">
                                Edit this card to upload an image.
                            </p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CardImage;

import React from 'react'
import { MoreVertical } from 'react-feather';

const PopupMenu = ({ text, onClick, visible, elementRef, placement, children }) => {

    const textAndIconContainerClass = [
        "flex items-center space-x-2 text-xs",
    ].join(" ");

    const textClass = [
        "relative z-10 p-4 shadow-lg rounded",
        "bg-white flex flex-col space-y-4",
        "text-sm text-black leading-none whitespace-no-wrap"
    ].join(" ");

    const textContainerClass = [
        "absolute transform items-center",
        visible ? "flex" : "hidden",
        placement === 'top' ? "-translate-x-1/4 -translate-y-full": "",
        placement === 'bottom' ? "-translate-x-1/4 translate-y-1/4": "",
        placement === 'left' ? "-translate-x-full -translate-y-1/2": "",
        placement === 'right' ? "translate-x-1/2 -translate-y-1/2": "",
    ].join(" ");


    return (
        <div className="group">
            <div className={textContainerClass}>
                <div className={textClass}>
                    {children}
                </div>
            </div>
            <button ref={elementRef} className={textAndIconContainerClass} onClick={onClick}>
                <MoreVertical
                    size={14}
                    className="text-primary"
                />
                {text}
            </button>
        </div>
    );
};

PopupMenu.defaultProps = {
    text: "Menu",
    elementRef: null,
    visible: false,
    onClick: () => {},
    placement: "top",
};

export default PopupMenu;

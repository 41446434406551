import React, { useState, useEffect, useContext, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { ArrowLeft, ChevronRight } from "react-feather";
import { useParams } from "react-router-dom";

import BgLights2 from "bg-lights-2.jpg";
import IconUserYellow from "icon-user-yellow.svg";

import UpdateOwnedCard from "./update_owned_card";
import UserIdContext from "./user_id_context";

import Modal from "../modal";
import ModalPortal from "../modal/modal_portal";

import IconGrayFlip from "icon-gray-flip.svg";
import IconGrayZoom from "icon-gray-zoom.svg";

import { GET_OWNED_CARD } from "../queries";
import CardImage from "../card_image";
import ZoomContainer from "../marketplace/zoom_image";
import useZoomImage from "../hooks/useZoomImage";
import IsMarketplaceBetaUserContext from "../is_marketplace_beta_user_context";

const CallToActions = ({ history }) => {
    return (
        <div className="flex text-gray-1000 pt-8">
            <div className="flex mr-auto">
                <button
                    onClick={() => history.goBack()}
                    className="flex space-x-2">
                    <ArrowLeft />
                    <span className="">Back</span>
                </button>
            </div>
        </div>
    );
};

const CardDetails = ({
    cardName,
    currentOwnerId,
    currentOwnerUsername,
    currentOwnerScore,
    description,
    score,
    grade,
    hasJersey,
    hasPatch,
    hasAutograph,
    isRookie,
    hasInsert,
    hasLogoman,
    isBase,
    playersJson,
    printRun,
    printRunOrdinality,
    id,
    isMarketListable,
    deleted,
}) => {
    const isMarketplaceBetaUser = useContext(IsMarketplaceBetaUserContext);
    const players =
        playersJson && JSON.parse(playersJson)
            ? JSON.parse(playersJson).map(({ player_name, team_name }) => ({
                  name: player_name,
                  teamName: team_name,
              }))
            : [];

    return (
        <>
            <div>
                <div className="bg-gray-575 rounded hidden lg:flex justify-between items-center mb-8 px-8 py-4">
                    <div className="flex items-center">
                        <img src={IconUserYellow} className="flex-1" />
                        <div className="flex flex-col">
                            <span className="ml-2 uppercase">
                                {currentOwnerUsername}
                            </span>
                            <span className="ml-2">
                                Score: {currentOwnerScore || "-"}
                            </span>
                        </div>
                    </div>
                    <a
                        href={`/trophy_room/${currentOwnerId}`}
                        className="underline">
                        Visit Trophy Room
                    </a>
                </div>
                <div className="flex justify-between">
                    <div>
                        <p className="hidden lg:block text-lg">
                            {cardName || "No card name."}
                        </p>
                        <p className="hidden lg:block text-sm">
                            {players
                                .map((p) => `${p.name}(${p.teamName || "-"})`)
                                .join(", ") || "-"}
                        </p>
                    </div>
                    <div>
                        {isMarketplaceBetaUser &&
                        isMarketListable &&
                        !deleted ? (
                            <a
                                href={`/seller_center/create_listing/${id}`}
                                className="p-3 rounded-md border-2 border-gray-300">
                                List on marketplace
                            </a>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="pt-8">
                <div className="flex mb-8">
                    <div className="w-1/4 font-bold">Print Run</div>
                    {`${printRunOrdinality || "-"} / ${printRun || "-"}`}
                </div>
                <div className="flex mb-8">
                    <div className="w-1/4 font-bold">Card Score</div>
                    {score || "-"}
                </div>
                <div className="flex mb-8">
                    <div className="w-1/4 font-bold">Grading</div>
                    {grade || "-"}
                </div>
                <div className="flex mb-8">
                    <div className="w-1/4 font-bold">Details</div>
                    {description || "-"}
                </div>
                <div className="flex mb-8">
                    <div className="w-1/4 font-bold">Attributes</div>
                    <div className="flex flex-wrap">
                        {isBase && (
                            <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                base
                            </span>
                        )}
                        {hasJersey && (
                            <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                jersey
                            </span>
                        )}
                        {hasPatch && (
                            <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                patch
                            </span>
                        )}
                        {hasAutograph && (
                            <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                autograph
                            </span>
                        )}
                        {isRookie && (
                            <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                rookie
                            </span>
                        )}
                        {hasInsert && (
                            <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                insert
                            </span>
                        )}
                        {hasLogoman && (
                            <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                logoman
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const EditOwnedCardModal = ({ initialCard, closeModal, haveLists }) => {
    const editOwnedCardformValues = useRef({
        ...initialCard,
        cardId: initialCard?.id,
        haveListIds: initialCard?.haveLists?.map((h) => h.id),
    });

    return (
        <Modal title="Update Owned Card" closeModal={closeModal} preventESC>
            {({ hideModal }) => (
                <UpdateOwnedCard
                    hideModal={hideModal}
                    formValues={editOwnedCardformValues.current}
                    haveLists={haveLists}
                />
            )}
        </Modal>
    );
};

const OwnedCardPreview = ({ history }) => {
    const { id } = useParams();
    const [getCard, { data }] = useLazyQuery(GET_OWNED_CARD, {
        variables: { id, isDeleted: true },
    });
    const [flipped, setFlipped] = useState(false);
    const [mountModal, setMountModal] = useState(false);
    const userId = useContext(UserIdContext);

    useEffect(() => {
        getCard();
    }, [id, mountModal]);

    const card = data?.card;
    const haveLists = card?.haveLists;

    const { zoom, zoomDisabled, showZoom, closeZoom } = useZoomImage(
        card,
        flipped
    );

    const cardClassName = [
        "focus:outline-none",
        "trophyRoom-card",
        "relative",
        "mb-4 flex-shrink-0",
        "border-8 border-white rounded-md",
        "w-52",
        "h-72",
        flipped ? "flip" : "",
    ].join(" ");

    return (
        <>
            <div
                className="min-h-screen bg-gray-900 px-8 md:px-8 lg:px-12 pb-8 text-white"
                style={{ backgroundImage: `url(<%= asset_path ${BgLights2})` }}>
                <div className="lg:divide-y space-y-8">
                    <CallToActions history={history} {...card} />
                    <div className="text-white mt-0 bg-gray-800 flex flex-col items-center lg:flex-row lg:space-x-12 py-8 px-8 lgpx-24 my-8 rounded">
                        {card && (
                            <div className="bg-gray-575 rounded flex lg:hidden justify-between items-center mb-8 px-4 lg:px-8 py-4 w-full">
                                <div className="flex items-center">
                                    <img
                                        src={IconUserYellow}
                                        className="flex-1"
                                    />
                                    <div className="flex flex-col">
                                        <span className="ml-2 uppercase">
                                            {card.currentOwnerUsername}
                                        </span>
                                        <span className="ml-2">
                                            Score:{" "}
                                            {card.currentOwnerScore || "-"}
                                        </span>
                                    </div>
                                </div>
                                <a href={`/trophy_room/${card.currentOwnerId}`}>
                                    <ChevronRight size={28} />
                                </a>
                            </div>
                        )}
                        <div className="flex flex-col mb-8 w-9/12 lg:w-1/2 lg:mb-0 justify-center items-center">
                            <div className={cardClassName}>
                                <CardImage isFront image={card?.frontImage} />
                                <CardImage image={card?.backImage} />
                            </div>
                            <div className="flex justify-center mt-4">
                                <button
                                    disabled={zoomDisabled}
                                    className="text-sm underline flex items-center cursor-pointer disabled:opacity-50"
                                    onClick={showZoom}>
                                    <img
                                        src={IconGrayZoom}
                                        className="w-5 h-5"
                                    />
                                    <span className="ml-2">Zoom In</span>
                                </button>
                                <button
                                    className="text-sm underline flex items-center ml-4 cursor-pointer"
                                    onClick={() => setFlipped(!flipped)}>
                                    <img
                                        src={IconGrayFlip}
                                        className="w-5 h-5"
                                    />
                                    <span className="ml-2">Flip</span>
                                </button>
                            </div>
                        </div>
                        <div className="w-full lg:pl-12 lg:divide-y lg:space-y-4 lg:pb-4">
                            <CardDetails {...card} history={history} />
                            {userId === card?.currentOwnerId &&
                            !card?.deleted ? (
                                <div>
                                    <button
                                        type="button"
                                        className="mt-4 px-2 py-1 text-center w-full border border-white rounded"
                                        onClick={() => setMountModal(true)}>
                                        Edit card details
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <ModalPortal id="modalCardList">
                {mountModal && (
                    <EditOwnedCardModal
                        initialCard={card}
                        closeModal={() => setMountModal(false)}
                        haveLists={haveLists}
                    />
                )}
            </ModalPortal>
            <ZoomContainer show={zoom} handleClose={closeZoom}>
                <img
                    src={flipped ? card?.backImage : card?.frontImage}
                    alt="zoomed card"
                    style={{ maxWidth: "none", maxHeight: "none" }}
                />
            </ZoomContainer>
        </>
    );
};

export default OwnedCardPreview;

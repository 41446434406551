import { gql, useMutation } from "@apollo/client"
import { useContext } from "react"
import { PopupContext } from "../popup_context"

const CREATE_PURCHASE_SELLER_REVIEW = gql`
    mutation CreatePurchaseSellerReview($input: CreatePurchaseSellerReviewInput!){
        result: createPurchaseSellerReview(input: $input) {
            review {
                id
            }
            success
            errors
        }
    }
`

const PurchaseSellerRatingMutation = (callback = () => {}) => {
    const { setShowPopup, setPopupAction, setMessage } = useContext(PopupContext);

    const onError = () => {
        setShowPopup(false)
        setShowPopup(true)
        setPopupAction('warn')
        setMessage("Something went wrong.")
    }

    const [createPurchaseSellerReview, { loading: createReviewLoading }] = useMutation(CREATE_PURCHASE_SELLER_REVIEW, {
        onCompleted: (data) => callback(data),
        onError: onError
    })

    return {
        createPurchaseSellerReview,
        createReviewLoading
    }
}

export default PurchaseSellerRatingMutation
import React, { useEffect, useRef } from "react";
import FilterItem from "./filter_item";
import SpinnerLoader from "../spinner_loader";
import IconFilter from "icon-filter.svg";

const Filter = ({
    darkMode = false,
    isFloating,
    isOverlay,
    showAsDefault = false,
    visible = false,
    filterItems = [],
    loading,
    variables,
    showHideButton = false,
    setVariables = () => {},
    setVisibility = () => {},
    clearSearch = () => {},
}) => {
    const ref = useRef(null);
    const hasSelected = Boolean(
        filterItems.filter((i) => i.selected.length > 0).length > 0
    );

    let sorted = [...filterItems].sort((a, b) => a.ordinality - b.ordinality);

    function hideFilter(e) {
        if (!ref?.current?.contains(e.target)) {
            if (isOverlay) setVisibility(false);
            document.body.style.overflow = "auto";
        }
    }

    useEffect(() => {
        document.addEventListener("click", hideFilter);
        return () => document.removeEventListener("click", hideFilter);
    }, []);

    const containerClassName = [
        "w-72 flex-shrink-0 divide-y divide-gray-600 overflow-y-auto p-6",
        "merqary-bg-filter rounded-sm sticky",
        !visible ? "hidden" : "",
        showAsDefault ? "lg:block" : "",
        isOverlay ? "py-10" : "",
        isFloating ? "lg:mt-[-100px]" : ""
    ].join(" ");

    return (
        <div
            ref={ref}
            className={containerClassName}
            id="filter-mobile-menu"
            style={
                isOverlay
                    ? { height: `90vh` }
                    : { maxHeight: "calc(100vh - 150px)" }
            }>
            <div className="flex justify-between items-center pb-8 text-white-400">
                <div className="flex items-center">
                    <img src={IconFilter} className="w-5 h-5 mr-2" />
                    <span className="text-lg font-semibold">Filters</span>
                </div>
                <div className="grid space-y-2">
                    {hasSelected && !isOverlay && (
                        <button
                            className="text-sm font-semibold cursor-pointer"
                            onClick={() => clearSearch()}>
                            X Clear
                        </button>
                    )}
                    <button
                        type="button"
                        className={`
                                ${
                                    showHideButton
                                        ? ""
                                        : isOverlay
                                        ? ""
                                        : "lg:hidden"
                                }
                                text-white-400 text-sm hover:text-primary cursor-pointer
                            `}
                        onClick={() => setVisibility(false)}>
                        Hide
                    </button>
                </div>
            </div>
            {loading ? (
                <div className="flex justify-center my-6">
                    <SpinnerLoader />
                </div>
            ) : (
                <>
                    {sorted.map((item, i) => (
                        <FilterItem
                            key={i}
                            {...item}
                            variables={variables}
                            setVariables={setVariables}
                            expanded={i < 5}
                            darkMode={darkMode}
                        />
                    ))}
                </>
            )}
        </div>
    );
};

export default Filter;

import React from "react";

const Badge = ({ count, priority, className = "" }) => {
    const badgeClass = [
        "flex items-center justify-center mx-auto h-5 rounded-full font-semibold text-xs",
        priority
            ? "bg-primary text-black-100"
            : "bg-gray-200 bg-opacity-60 text-gray-30",
        count > 99 ? "w-7" : "w-5",
        className,
    ].join(" ");
    return (
        <div className={badgeClass}>
            <span>{count}</span>
        </div>
    );
};

const Tab = ({ text = "tab", active = false, onClick, badge, disabled }) => {
    const containerClass = [
        "flex items-center py-6 border-b-2",
        active
            ? "font-semibold font-body border-primary"
            : "font-medium border-transparent",
    ].join(" ");
    const buttonClass = [
        "focus:outline-none text-xs md:text-sm",
        disabled && "opacity-40",
    ].join(" ");

    const handleOnclick = () => {
        if (disabled) return;
        onClick();
    };

    return (
        <div className={containerClass}>
            <button
                type="button"
                className={buttonClass}
                onClick={() => handleOnclick()}>
                {text}
            </button>
            {badge.count > 0 && !disabled && (
                <Badge {...badge} className="ml-2 flex-shrink-0" />
            )}
        </div>
    );
};

Tab.defaultProps = {
    text: "tab",
    active: false,
    onClick: () => {},
    badge: {
        count: 0,
    },
    disabled: false,
};

Badge.defaultProps = {
    count: 0,
    primary: true,
};

export default Tab;

import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_TRACKING_CODE, {
    debug: process.env.NODE_ENV === "development",
    titleCase: false,
});

const useReactGA = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname);
    }, [location.pathname]);

    return null;
};

export const GAComponent = () => {
    useReactGA();

    return null;
};

export default useReactGA;

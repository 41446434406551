import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ArrowLeft, Heart, MapPin, Plus } from "react-feather";
import IconYellowShop from "icon-yellow-shop.svg";
import IconHeartGold from "icon-heart-gold.svg";
import IconCollectionGold from "icon-collection-gold.svg";
import IconTrophyGold from "icon-trophy-gold.svg";
import IconGrayFlip from "icon-gray-flip.svg";
import IconGrayZoom from "icon-gray-zoom.svg";
import WhiteArrowRight from "icon-slider-arrow-right-white.svg";
import { X } from "react-feather";
import Badge from "../badge";
import Modal from "../modal";
import ModalPortal from "../modal/modal_portal";
import { ModalBody } from "../trophy_room/card_list";
import UserIdContext from "../trophy_room/user_id_context";
import { GET_ALL_STASHED_CARDS, GET_CARD, GET_USER_LISTS } from "../queries";
import IconUserYellow from "icon-user-yellow.svg";
import BGLightsDarker from "bg-lights-darker.png";
// import IconShare from "icon-gray-share.svg";
import CardInfo from "./card_info";
import CardImage from "../card_image";
import formatPrice from "../utils/format_price";
import ZoomContainer from "../marketplace/zoom_image";
import useZoomImage from "../hooks/useZoomImage";
import IsMarketplaceBetaUserContext from "../is_marketplace_beta_user_context";
import formatCardPlayers from "../utils/format_card_players";

const ADD_TO_WANT_LIST_MODAL = "ADD_TO_WANT_LIST_MODAL";
const ADD_TO_HAVE_LIST_MODAL = "ADD_TO_HAVE_LIST_MODAL";

const CallToActions = ({
    addToHaveList,
    addToWantList,
    goBack,
    isUserPresent,
}) => (
    <div className="flex text-white px-4 lg:px-0 py-8 relative">
        <div className="flex mr-auto cursor-pointer">
            <button onClick={goBack} className="flex space-x-2 items-center">
                <ArrowLeft size={20} />
                <span>Browse cards</span>
            </button>
        </div>
        {isUserPresent && (
            <div className="flex">
                <button
                    type="button"
                    className="hidden lg:flex items-center mr-4 px-2 py-1 hover:bg-gray-575"
                    onClick={addToHaveList}>
                    <Plus size={12} className="mr-2" />
                    <span>Have List</span>
                </button>
                <button
                    type="button"
                    className="hidden lg:flex items-center mr-4 px-2 py-1 hover:bg-gray-575"
                    onClick={addToWantList}>
                    <Heart size={12} className="mr-2" />
                    <span>Want List</span>
                </button>
                {/* <a className="flex items-center mr-4 px-2 py-1 hover:bg-gray-575" onClick={addToWantList}>
                    <img src={IconShop} className="w-4 h-4 mr-2" />
                    <span>Marketplace</span>
                </a> */}
                {/* <a
                    className="flex items-center px-2 py-1 hover:bg-gray-575"
                    href="/my_trophy_room">
                    <img src={IconShare} className="w-5 h-5" />
                    <span className="ml-2">Share</span>
                </a> */}
            </div>
        )}
    </div>
);

const Listing = ({
    id,
    frontImage,
    price,
    currency,
    dealFormat,
    ownedCardGraderName,
    ownedCardGrade,
}) => {
    return (
        <div className="px-3 md:px-6 py-6 border-t-2 border-gray-300 border-opacity-60">
            <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row">
                <div className="flex space-x-6 md:w-2/3 md:pr-6">
                    <div className="flex justify-center w-1/4">
                        <a href={`/marketplace/listings/${id}`}
                           className="flex flex-col text-center">
                            <div className="h-28 w-20 border-2 border-white rounded flex items-center bg-white">
                                <img src={frontImage} className="mx-auto my-auto max-h-full max-w-full"/>
                            </div>
                        </a>
                    </div>
                    <div className="flex flex-col md:flex-row space-y-2 justify-around md:space-y-0 w-3/4">
                        <div className="flex justify-start md:justify-center items-center text-md font-semibold">
                            {ownedCardGraderName || ""} {ownedCardGrade || ""}
                        </div>
                        <div className="flex justify-start md:justify-center items-center">
                            <div className="rounded-sm bg-gray-500 bg-opacity-80 p-1 text-sm font-medium uppercase">
                                {dealFormat}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center md:w-1/3 md:px-14 md:px-4">
                    <div>
                        <div className="flex justify-start md:justify-center items-center text-md font-semibold text-primary">
                            {formatPrice(price, "V C", currency)}
                        </div>
                    </div>
                    <a href={`/marketplace/listings/${id}`}
                    className="underline text-md">
                        View listing
                    </a>
                </div>
            </div>
        </div>
    );
};

const Marketplace = ({ marketListings, cardName }) => {
    const previewedListings = marketListings.slice(0, 4);
    return (
        <>
            <div className="lg:bg-gray-800 text-white p-4 rounded py-6 px-2 lg:px-12 2xl:px-28">
                <div className="flex justify-between w-full mb-2">
                    <span className="text-md md:text-lg font-semibold">
                        Listed on the marketplace
                    </span>
                    <a
                        href={`/marketplace?page=1&search=${encodeURI(
                            cardName
                        )}`}>
                        <div className="flex space-x-2 items-center">
                            <span className="text-md md:text-lg underline text-right">
                                See all{" "}
                                {marketListings?.length > 1
                                    ? `${marketListings.length} `
                                    : ""}
                                listings
                            </span>
                            <img src={WhiteArrowRight} className="w-3 h-3" />
                        </div>
                    </a>
                </div>
                <div className="divide divide-y-2 divide-gray-300">
                    {previewedListings?.map((listing, i) => (
                        <Listing {...listing} key={i} />
                    ))}
                </div>
                <div className="border-t-2 border-gray-300 border-opacity-80"></div>
            </div>
        </>
    );
};

const CardDetails = ({
    cardSetName,
    endYear,
    hasAutograph,
    hasInsert,
    hasJersey,
    hasLogoman,
    hasPatch,
    isBase,
    isRookie,
    isVaulted,
    manufacturerName,
    playersJson,
    printRun,
    productName,
    score,
    sport,
    startYear,
    vaultedCount,
    userWantCount,
    cardNumber,
}) => {
    const yearRun =
        `${startYear}` +
        `${endYear ? ` - ${endYear?.toString()?.slice(-2)}` : ""}`;
    return (
        <div className="flex-1 w-full lg:w-auto pl-0 lg:pl-12 pt-6 lg:pt-0 border-t !border-l-0 !ml-0 lg:border-t-0 lg:divide-y lg:space-y-4">
            <div className="flex flex-col lg:flex-row justify-between mb-6">
                <span className="text-lg mb-4 lg:mb-0">Card Information</span>
                <div className="flex items-center">
                    <span className="text-xs uppercase bg-gray-575 px-2 py-1 lg:px-4 lg:py-3 rounded-md">
                        Card score {score || "-"}
                    </span>
                    {isVaulted && (
                        <Badge className="ml-4 !text-sm !pr-0 !font-semibold">
                            Vaulted
                        </Badge>
                    )}
                </div>
            </div>

            {
                /* Fields to displayed in the future. */

                <div className="flex flex-col lg:flex-row pt-2 lg:pt-8 pb-4">
                    {vaultedCount > 0 && (
                        <span className="mb-4 lg:mb-0 mr-8 flex items-center">
                            <img src={IconCollectionGold} className="w-5 h-5" />
                            <span className="ml-2">
                                {vaultedCount || "-"} vaulted
                            </span>
                        </span>
                    )}
                    {/* <span className="mb-4 lg:mb-0 mr-8 flex items-center">
                            <img src={IconPriceTagGold} className="w-5 h-5" />
                            <span className="ml-2">- on the market</span>
                        </span> */}
                    {userWantCount > 0 && (
                        <span className="mb-4 lg:mb-0 mr-8 flex items-center">
                            <img src={IconHeartGold} className="w-5 h-5" />
                            <span className="ml-2">
                                {userWantCount || "-"}{" "}
                                {userWantCount > 1 ? "people" : "person"} want
                                this
                            </span>
                        </span>
                    )}
                </div>
            }
            <div className="hidden lg:block">
                <div className="flex pt-4 space-x-12">
                    <CardInfo title="Sport">{sport?.name || "-"}</CardInfo>
                    <CardInfo title="Card Number">{cardNumber || "-"}</CardInfo>
                    <CardInfo title="Print Run">{printRun || "-"}</CardInfo>
                </div>
                <div className="flex pt-4 space-x-12">
                    <CardInfo title="Manufacturer">
                        {manufacturerName || "-"}
                    </CardInfo>
                    <CardInfo title="Product">{productName || "-"}</CardInfo>
                    <CardInfo title="Card Set">{cardSetName || "-"}</CardInfo>
                </div>
                <div className="flex pt-4 space-x-12">
                    <CardInfo title="Year">{yearRun ? yearRun : "-"}</CardInfo>

                    <CardInfo title="Player">
                        {formatCardPlayers(playersJson, ["names"], false, true)}
                    </CardInfo>
                    <CardInfo title="Team">
                        {formatCardPlayers(playersJson, ["teams"], false, true)}
                    </CardInfo>
                </div>
                <div className="flex pt-4 space-x-12">
                    <CardInfo title="Card Attribute">
                        <div className="flex flex-wrap">
                            {isBase && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    base
                                </span>
                            )}
                            {hasJersey && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    jersey
                                </span>
                            )}
                            {hasPatch && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    patch
                                </span>
                            )}
                            {hasAutograph && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    autograph
                                </span>
                            )}
                            {isRookie && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    rookie
                                </span>
                            )}
                            {hasInsert && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    insert
                                </span>
                            )}
                            {hasLogoman && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    logoman
                                </span>
                            )}
                        </div>
                    </CardInfo>
                </div>
            </div>
            <div className="block lg:hidden">
                <div className="flex pt-4 space-x-12">
                    <CardInfo title="Sport">{sport?.name || "-"}</CardInfo>
                    <CardInfo title="Card Number">{cardNumber || "-"}</CardInfo>
                </div>
                <div className="flex pt-4 space-x-12">
                    <CardInfo title="Print Run">{printRun || "-"}</CardInfo>
                    <CardInfo title="Manufacturer">
                        {manufacturerName || "-"}
                    </CardInfo>
                </div>
                <div className="flex pt-4 space-x-12">
                    <CardInfo title="Product">{productName || "-"}</CardInfo>
                    <CardInfo title="Card Set">{cardSetName || "-"}</CardInfo>
                </div>
                <div className="flex pt-4 space-x-12">
                    <CardInfo title="Player">
                        {formatCardPlayers(playersJson, ["names"], false, true)}
                    </CardInfo>
                    <CardInfo title="Team">
                        {formatCardPlayers(playersJson, ["teams"], false, true)}
                    </CardInfo>
                </div>
                <div className="flex pt-4 space-x-12">
                    <CardInfo title="Year">{yearRun ? yearRun : "-"}</CardInfo>
                    <CardInfo title="Card Attribute">
                        <div className="flex flex-wrap">
                            {isBase && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    base
                                </span>
                            )}
                            {hasJersey && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    jersey
                                </span>
                            )}
                            {hasPatch && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    patch
                                </span>
                            )}
                            {hasAutograph && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    autograph
                                </span>
                            )}
                            {isRookie && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    rookie
                                </span>
                            )}
                            {hasInsert && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    insert
                                </span>
                            )}
                            {hasLogoman && (
                                <span className="text-xs uppercase bg-gray-575 p-1 mr-4">
                                    logoman
                                </span>
                            )}
                        </div>
                    </CardInfo>
                </div>
            </div>
        </div>
    );
};

const OwnedCard = ({
    id,
    cardId,
    frontImage,
    currentOwnerUsername,
    ownedCard,
    mountModal = () => {},
    setData = () => {},
}) => {
    const handleShowOwnedCardDetails = () => {
        setData(ownedCard);
        mountModal(true);
    };
    const cardClassName = [
        "focus:outline-none",
        "trophyRoom-card",
        "relative",
        "flex-shrink-0",
        "w-32 mx-auto",
        "h-40",
    ].join(" ");

    return (
        <div className="bg-gray-775 col-span-3 lg:col-span-1 p-2">
            <div className="grid text-center cursor-pointer">
                <button
                    onClick={() => handleShowOwnedCardDetails()}>
                    <div className={cardClassName}>
                        <CardImage
                            isFront
                            image={frontImage}
                        />
                    </div>
                    <div className="flex hover:underline mt-4 items-center justify-center">
                        <img src={IconTrophyGold} className="h-4" />
                        <span className="ml-2 uppercase">
                            {currentOwnerUsername}
                        </span>
                    </div>
                </button>
            </div>
        </div>
    );
};

const OwnedCards = ({
    ownedCards,
    cardId,
    card,
    mountModal = () => {},
    setData = () => {},
}) => {
    const [previewedCards, setPreviewedCards] = useState([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        const cardsToPreview = card?.printRun
            ? ownedCards.filter((oc) => oc.printRunOrdinality)
            : ownedCards;
        showAll
            ? setPreviewedCards(cardsToPreview)
            : setPreviewedCards(cardsToPreview?.slice(0, 5));
    }, [showAll]);
    return (
        <div className="grid space-y-4 py-10 px-2 md:px-6 lg:px-12">
            <div className="w-full grid space-y-2">
                <span className="text-lg font-semibold">
                    Vaulted Cards ({ownedCards.length})
                </span>
                <p className="text-md">
                    Click on any card to view owner and listing details.
                </p>
            </div>
            <div className="grid grid-cols-6 lg:grid-cols-5 gap-4 w-full">
                {previewedCards?.map((c, i) => (
                    <OwnedCard
                        key={c.id}
                        {...c}
                        cardId={cardId}
                        mountModal={mountModal}
                        setData={setData}
                        ownedCard={c}
                    />
                ))}
            </div>
            {ownedCards.length > 5 && (
                <div className="flex justify-center">
                    <button
                        className="flex space-x-2 items-center cursor-pointer"
                        onClick={() => setShowAll(!showAll)}>
                        <span className="underline">
                            {showAll ? "Show less" : "Show all"}
                        </span>
                        <img
                            src={WhiteArrowRight}
                            className={`transform ${
                                showAll ? "-rotate-90" : "rotate-90"
                            } w-3 h-3`}
                        />
                    </button>
                </div>
            )}
        </div>
    );
};

const OwnedCardModal = ({
    currentOwnerUsername,
    frontImage,
    cardName,
    currentOwnerScore,
    currentOwnerId,
    countryName,
    printRun,
    printRunOrdinality,
    grade,
    graderName,
    description,
    playersJson,
    price,
    currency,
    dealFormat,
    listingId,
    isMarketplaceBetaUser,
    isPrivate, // listing
    closeModal = () => {},
}) => {
    const cardClassName = [
        "focus:outline-none",
        "trophyRoom-card",
        "relative",
        "mb-4 flex-shrink-0",
        "border-8 border-white rounded-md",
        "w-52",
        "h-72 mx-auto",
        "col-span-3 lg:col-span-1",
    ].join(" ");
    return (
        <div className="p-4 md:p-12 grid space-y-4 text-white">
            <div className="flex justify-start">
                <button className="flex space-x-2 items-center"
                        onClick={() => closeModal()}>
                    <X size={24} />
                    <span className="text-lg font-semibold">Close</span>
                </button>
            </div>
            <div className="grid grid-cols-3 gap-4">
                <div className={cardClassName}>
                    <div>
                        <CardImage isFront image={frontImage} hideMoreText />
                    </div>
                </div>
                <div className="col-span-3 lg:col-span-2">
                    <div className="flex flex-col space-y-4 w-full">
                        <div className="bg-gray-575 rounded flex items-center space-x-4 px-4 md:px-8 py-4 h-16 ">
                            <img src={IconUserYellow} className="w-8"/>
                            <div className="flex flex-col">
                                <span className="uppercase">
                                    {currentOwnerUsername}
                                </span>
                                <span>
                                    Score: {currentOwnerScore || "-"}
                                </span>
                            </div>
                        </div>
                        <div className="flex space-x-2 items-center">
                            <a href={`/trophy_room/${currentOwnerId}`}
                               className="underline">
                                Visit Trophy Room
                            </a>
                            <img src={WhiteArrowRight} className="w-3 h-3"/>
                        </div>
                        <div className="divide divide-y">
                            <div className="grid space-y-2 w-full py-4">
                                <div className="flex flex-col">
                                    <span className="text-xl font-medium merqary-truncate two-lines">
                                        {cardName}
                                    </span>
                                    <span className="text-md font-medium merqary-truncate two-lines">
                                        {formatCardPlayers(playersJson, ["names"], false, true)}
                                    </span>
                                </div>
                                <div className="flex space-x-2 items-center">
                                    <MapPin size={16} />
                                    <span>{countryName || ""}</span>
                                </div>
                            </div>
                            <div className="grid space-y-4 py-4">
                                <div className="flex space-x-8">
                                    <div className="font-semibold w-24">
                                        Print Run
                                    </div>
                                    <div className="text-opacity-60">
                                        {printRunOrdinality || ""}/
                                        {printRun || ""}
                                    </div>
                                </div>
                                <div className="flex space-x-8">
                                    <div className="font-semibold w-24">
                                        Grading
                                    </div>
                                    <div className="text-opacity-60">
                                        {graderName || ""} {grade || ""}
                                    </div>
                                </div>
                                <div className="flex space-x-8">
                                    <div className="font-semibold w-24">
                                        Details
                                    </div>
                                    <div className="text-opacity-60">
                                        {description || ""}
                                    </div>
                                </div>
                            </div>
                            {isMarketplaceBetaUser && listingId && !isPrivate ? (
                                <div className="flex justify-between items-center py-4">
                                    <div className="grid space-y-2">
                                        <span className="font-semibold">
                                            Sale Price:{" "}
                                            {formatPrice(
                                                price,
                                                "V C",
                                                currency
                                            )}
                                        </span>
                                        <div className="rounded-sm bg-gray-500 bg-opacity-80 p-1 text-sm font-medium uppercase text-center">
                                            {dealFormat}
                                        </div>
                                    </div>
                                    <a
                                        href={`/marketplace/listings/${listingId}`}
                                        className="w-32 h-10 p-1 rounded bg-transparent border-2 border-gray-375 text-center">
                                        View this listing
                                    </a>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CardPreview = ({ history }) => {
    const { id } = useParams();
    const userId = useContext(UserIdContext);
    const isMarketplaceBetaUser = useContext(IsMarketplaceBetaUserContext);
    const [getCard, { loading, data: cardData }] = useLazyQuery(GET_CARD, {
        variables: { id },
    });
    const [getUserLists, { data }] = useLazyQuery(GET_USER_LISTS, {
        variables: {
            userId,
        },
    });
    const [getStashedCards, { data: stashedData }] = useLazyQuery(
        GET_ALL_STASHED_CARDS
    );
    const [flipped, setFlipped] = useState(false);
    const [mountModal, setMountModal] = useState(false);
    const [mountModalOwnedCardDetails, setMountModalOwnedCardDetails] =
        useState(false);
    const [modalType, setModalType] = useState(ADD_TO_HAVE_LIST_MODAL);
    const [ownedCardModalData, setOwnedCardModalData] = useState({});

    const haveListOptions = data?.haveLists?.map(({ id, name }) => ({
        id,
        name,
    }));

    const wantListOptions = data?.wantLists?.map(({ id, name }) => ({
        id,
        name,
    }));

    const selectedListOptions =
        modalType === ADD_TO_WANT_LIST_MODAL
            ? wantListOptions
            : haveListOptions;

    useEffect(() => {
        if (userId) {
            getUserLists();
            getStashedCards();
        }
    }, [userId]);

    useEffect(() => {
        if (id) {
            getCard();
        }
    }, [id]);

    const card = cardData?.card || {};

    const { zoom, zoomDisabled, showZoom, closeZoom } = useZoomImage(
        card,
        flipped
    );
    const ownedCards = cardData?.ownedCards || [];
    const marketListings = card?.marketListings?.filter(m => !m.isPrivate) || [];
    const isVaulted = stashedData?.haveCards?.map((c) => c.cardId).includes(id);

    const goBack = () => {
        history.goBack();
    };

    const addToHaveList = () => {
        setModalType(ADD_TO_HAVE_LIST_MODAL);
        setMountModal(true);
    };

    const addToWantList = () => {
        setModalType(ADD_TO_WANT_LIST_MODAL);
        setMountModal(true);
    };

    if (loading) return <p>Loading ...</p>;

    const cardClassName = [
        "focus:outline-none",
        "trophyRoom-card",
        "relative",
        "mb-4 flex-shrink-0",
        "border-8 border-white rounded-md",
        "w-52",
        "h-72",
        flipped ? "flip" : "",
    ].join(" ");

    return (
        <>
            <div
                className="fixed py-3 px-2 lg:py-6 lg:px-4
                    bg-black-300 bg-opacity-50 mx-auto left-0 right-0 w-full flex lg:hidden justify-center divide-x divide-primary"
                style={{ bottom: 0 }}
                // style={{ bottom: bottomPosition }}
            >
                <button
                    type="button"
                    className="flex items-center space-x-2 text-sm md:text-lg px-2 lg:px-4 py-1 text-primary-100 text-center"
                    onClick={addToHaveList}>
                    <Plus size={16} />
                    <span className="font-semibold">Have List</span>
                </button>
                <button
                    type="button"
                    className="flex items-center space-x-2 text-sm md:text-lg px-2 lg:px-4 py-1 text-primary-100 text-center"
                    onClick={addToWantList}>
                    <Heart size={16} />

                    <span className="font-semibold">Want List</span>
                </button>
                <button
                    type="button"
                    className="flex items-center space-x-2 text-sm md:text-lg px-2 lg:px-4 py-1 text-primary-100 text-center">
                    <img src={IconYellowShop} className="w-4 h-4" />
                    <span className="font-semibold">Marketplace</span>
                </button>
            </div>
            <div
                className="bg-fixed bg-gray-900 bg-cover px-0 lg:px-4 md:px-8 lg:px-12 pb-8 text-white"
                style={{
                    backgroundImage: `url(<%= asset_path ${BGLightsDarker})`,
                }}>
                <CallToActions
                    goBack={goBack}
                    addToHaveList={addToHaveList}
                    addToWantList={addToWantList}
                    isUserPresent={!!userId}
                />
                <div className="bg-transparent lg:bg-gray-800 flex text-white space-x-12 p-8 mb-2 lg:mb-8 rounded">
                    <div className="w-full p-0 lg:p-6">
                        <div className="flex flex-col container mx-auto divide-y space-y-12">
                            <div className="flex flex-col items-center lg:flex-row divide-x space-x-12">
                                <div className="flex flex-col text-white p-4 mb-4 w-9/12 lg:w-1/4 text-center">
                                    <span className="text-sm uppercase text-primary mb-2">
                                        {card?.sport?.name}
                                    </span>
                                    <span className="mb-4 font-semibold">
                                        {card?.cardName}
                                    </span>
                                    <div className="flex flex-col mb-8 w-9/12 mx-auto lg:w-full lg:mb-0 justify-center items-center">
                                        <div className={cardClassName}>
                                            {/*
                                            Need this inner div to prevent a bug where the back image does not show
                                            .trophyRoom--card
                                                .inner-div
                                                    .front-image
                                                    .back-image
                                            */}
                                            <CardImage
                                                isFront
                                                image={card?.frontImage}
                                            />
                                            <CardImage
                                                image={card?.backImage}
                                            />
                                        </div>
                                        <div className="flex justify-center mt-4">
                                            <button
                                                disabled={zoomDisabled}
                                                onClick={showZoom}
                                                className="disabled:opacity-50 text-sm underline flex items-center cursor-pointer">
                                                <img
                                                    src={IconGrayZoom}
                                                    className="w-5 h-5"
                                                />
                                                <span className="ml-2">
                                                    Zoom In
                                                </span>
                                            </button>
                                            <button
                                                className="text-sm underline flex items-center ml-4 cursor-pointer"
                                                onClick={() =>
                                                    setFlipped(!flipped)
                                                }>
                                                <img
                                                    src={IconGrayFlip}
                                                    className="w-5 h-5"
                                                />
                                                <span className="ml-2">
                                                    Flip
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <CardDetails {...card} isVaulted={isVaulted} />
                            </div>
                            {ownedCards?.length > 0 && (
                                <OwnedCards
                                    ownedCards={ownedCards}
                                    cardId={id}
                                    mountModal={setMountModalOwnedCardDetails}
                                    setData={setOwnedCardModalData}
                                    card={card}
                                />
                            )}
                        </div>
                        {isMarketplaceBetaUser && marketListings?.length > 0 ? (
                            <Marketplace
                                marketListings={marketListings}
                                cardName={card?.cardName}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            <ModalPortal id="modalCardList">
                {mountModal && (
                    <Modal
                        title="Add card to list"
                        closeModal={() => setMountModal(false)}
                        preventESC={false}>
                        {({ hideModal }) => (
                            <ModalBody
                                modalType={modalType}
                                selectedCards={[id]}
                                selectedListOptions={selectedListOptions}
                                hideModal={hideModal}
                            />
                        )}
                    </Modal>
                )}
            </ModalPortal>
            <ModalPortal id="modalOwnedCardDetails">
                {mountModalOwnedCardDetails && (
                    <Modal
                        showClose={false}
                        size="lg"
                        closeModal={() => setMountModalOwnedCardDetails(false)}
                        preventESC={false}
                        bgColor="gray-900"
                        showTitleBorder={false}>
                        {({ hideModal }) => (
                            <OwnedCardModal
                                {...ownedCardModalData}
                                {...ownedCardModalData?.marketListing}
                                closeModal={() => hideModal()}
                                isMarketplaceBetaUser={isMarketplaceBetaUser}
                            />
                        )}
                    </Modal>
                )}
            </ModalPortal>
            <ZoomContainer show={zoom} handleClose={closeZoom}>
                <img
                    src={flipped ? card?.backImage : card?.frontImage}
                    alt="zoomed card"
                    style={{ maxWidth: "none", maxHeight: "none" }}
                />
            </ZoomContainer>
        </>
    );
};

export default CardPreview;

import React, {
    Fragment,
    useRef,
    useState,
    useEffect,
    useContext,
} from "react";
import { Formik, Field, Form } from "formik";
import { gql, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import IconEdit from "icon-edit.svg";
import IconSave from "icon-check.svg";
import IconDelete from "icon-delete.svg";
import { PopupContext, POPUP_TYPES } from "../popup_context";
import { GET_HAVE_LISTS, GET_WANT_LISTS } from "../queries";
import { MoreVertical } from "react-feather";
import { ConfirmationContext } from "../confirmation_context";

function updateDeleteList(cache, { data }, args) {
    const { id, isHaveList, userId } = args;
    const cs = cache.readQuery({
        query: isHaveList ? GET_HAVE_LISTS : GET_WANT_LISTS,
        variables: {
            userId,
        },
    });

    const lists = isHaveList ? cs.getHaveLists : cs.getWantLists;
    const newLists = lists.filter((l) => l.id !== id);

    if (data?.deleteList?.result) {
        cache.writeQuery({
            query: isHaveList ? GET_HAVE_LISTS : GET_WANT_LISTS,
            variables: {
                userId,
            },
            data: {
                [isHaveList ? "getHaveLists" : "getWantLists"]: newLists,
            },
        });
    }
}

const UPDATE_WANT_LIST = gql`
    mutation UpdateList($input: UpdateWantListInput!) {
        updateWantList(input: $input) {
            errors
            wantList {
                id
                name
            }
        }
    }
`;

const UPDATE_HAVE_LIST = gql`
    mutation UpdateList($input: UpdateHaveListInput!) {
        updateHaveList(input: $input) {
            errors
            haveList {
                id
                name
                ordinality
            }
        }
    }
`;

const DELETE_WANT_LIST = gql`
    mutation DeleteList($id: ID!) {
        deleteList: deleteWantList(input: { id: $id }) {
            errors
            result
        }
    }
`;
const DELETE_HAVE_LIST = gql`
    mutation DeleteList($id: ID!) {
        deleteList: deleteHaveList(input: { id: $id }) {
            errors
            result
        }
    }
`;

const Input = ({ field, form, innerRef, ...props }) => (
    <input ref={innerRef} {...field} {...props} />
);

const ListLink = ({
    id,
    name,
    isActive,
    isHaveList = false,
    isViewOnly = false,
    userId,
    setEmptyOnDelete = () => {},
}) => {
    const confirm = useContext(ConfirmationContext);
    const ref = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);

    const className = [
        "border-l-4",
        "hover:text-primary pl-4",
        isActive ? "border-primary" : "border-transparent",
        "w-9/12",
        "merqary-truncate",
    ].join(" ");

    const [updateList] = useMutation(
        isHaveList ? UPDATE_HAVE_LIST : UPDATE_WANT_LIST
    );

    const [deleteList] = useMutation(
        isHaveList ? DELETE_HAVE_LIST : DELETE_WANT_LIST,
        {
            onCompleted: (data) => {
                let message = "List has been deleted.";
                const success = data.deleteList.result;
                if (!success) {
                    message = data.deleteList.errors.join(", ");
                }
                setIsEditing(false);
                setShowPopup(true);
                setPopupAction(
                    success ? POPUP_TYPES.SUCCESS : POPUP_TYPES.DANGER
                );
                setMessage(message);
            },
            onError: (err) => {
                setShowPopup(true);
                setIsEditing(false);
                setPopupAction(POPUP_TYPES.DANGER);
                setMessage(
                    err?.message ||
                        "Something went wrong. Unable to delete list."
                );
            },
            update: (a, b) =>
                updateDeleteList(a, b, { id, userId, isHaveList }),
        }
    );

    useEffect(() => {
        if (isEditing) {
            ref?.current?.focus();
        }
    }, [isEditing]);

    function delList() {
        deleteList({
            variables: {
                id: id,
            },
        });
        if (isActive) setEmptyOnDelete();
    }

    return (
        <>
            <li className="flex items-center border-b border-gray-575 lg:border-b-0 pb-4 lg:pb-0">
                {!isEditing ? (
                    <Fragment>
                        <Link
                            to={{
                                pathname: isHaveList
                                    ? isViewOnly
                                        ? `/trophy_room/${userId}`
                                        : "/my_trophy_room"
                                    : "/my_trophy_room/want_list",
                                search: `?list=${id}`,
                            }}
                            className={className}>
                            {name}
                        </Link>
                        {!isViewOnly && (
                            <button
                                type="button"
                                className="flex items-center ml-auto hover:text-primary"
                                onClick={() => setIsEditing(true)}>
                                <div className="merqary-tooltip">
                                    <div className="bg-gray-575 p-1 rounded-md ml-2 hidden lg:block">
                                        <img
                                            src={IconEdit}
                                            className="w-4 h-4"
                                        />
                                    </div>
                                    <div className="p-1 border-0 ml-2 block lg:hidden">
                                        <MoreVertical size={14} />
                                    </div>
                                    <span className="merqary-tooltip-image font-body">
                                        Edit list
                                    </span>
                                </div>
                            </button>
                        )}
                    </Fragment>
                ) : (
                    <Formik
                        initialValues={{ name: name }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            updateList({
                                variables: {
                                    input: {
                                        id: id,
                                        name: values.name,
                                    },
                                },
                            })
                                .then(() => {
                                    setIsEditing(false);
                                    setShowPopup(true);
                                    setPopupAction(POPUP_TYPES.SUCCESS);
                                    setMessage("List updated.");
                                }, console.warn)
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}>
                        {({ isSubmitting }) => (
                            <Form
                                className="flex items-center w-10/12 lg:w-full"
                                onKeyUp={(e) => {
                                    if (e.key.toLowerCase() === "escape") {
                                        setIsEditing(false);
                                    }
                                }}>
                                <Field
                                    name="name"
                                    disabled={isSubmitting}
                                    className="ml-2 text-black pl-2 w-4/6"
                                    innerRef={ref}
                                    component={Input}
                                />
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="flex items-center ml-auto hover:text-primary">
                                    <div className="merqary-tooltip">
                                        <div className="bg-gray-575 p-1 rounded-md ml-2">
                                            <img
                                                src={IconSave}
                                                className="w-4 h-4"
                                            />
                                        </div>
                                        <span className="merqary-tooltip-image font-body">
                                            Save list
                                        </span>
                                    </div>
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        confirm({
                                            title: `Delete the list '${name}'?`,
                                            text: `Deleting this list would also remove all
                                            card along with listings associated with it. Proceed?`,
                                            confirmText: "Yes, I understand",
                                            onConfirm: () => delList()
                                        })
                                    }}
                                    disabled={isSubmitting}
                                    className="flex items-center ml-auto hover:text-primary">
                                    <div className="merqary-tooltip">
                                        <div className="bg-gray-575 p-1 rounded-md ml-2">
                                            <img
                                                src={IconDelete}
                                                className="w-4 h-4"
                                            />
                                        </div>
                                        <span className="merqary-tooltip-image font-body">
                                            Delete
                                        </span>
                                    </div>
                                </button>
                            </Form>
                        )}
                    </Formik>
                )}
            </li>
        </>
    );
};

export default ListLink;

import React from 'react'
import Button from '../shared_components/button'

const DealFormat = ({
    dealFormat,
    selectedDeal,
    handleDealChange = () => {},
}) => {
    const activeClass = "bg-primary font-semibold py-1 px-4 rounded-full cursor-pointer text-black-300"
    const defaultClass = "py-1 px-4 border rounded-full border-gray-400 text-gray-300 cursor-pointer"

    return (
        <Button
            text={dealFormat}
            customClass={selectedDeal === dealFormat ? activeClass : defaultClass}
            fontBold={selectedDeal === dealFormat}
            onClick={ () => handleDealChange(dealFormat) }
        />
    )
}

export default DealFormat

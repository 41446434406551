import React, { useState } from 'react'
import BgLights from 'bg-lights-darker.png';
import ShippingOptions from './shipping_options';
import NavigationTabs from '../shared_components/navigation_tabs';

const ShopSettings = () => {
    const pageClass = [
        "py-6 px-4 xl:px-36 2xl:px-56 xl:py-24",
        "animate__animated animate__fadeIn",
        "bg-fixed bg-cover bg-center bg-no-repeat",
        "min-h-screen",
        "text-gray-1000"
    ].join(" ");

    const tabs = [
        "Shipping options"
    ];

    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    return (
        <div className={pageClass}
            style={{ backgroundImage: `url(${BgLights})`}}
        >
            <span className='text-xl font-semibold'>Shop settings</span>
            <NavigationTabs
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                customClass="pt-2"
            />
            {selectedTab === 'Shipping options' && (
                <ShippingOptions />
            )}
        </div>
    )
}

export default ShopSettings

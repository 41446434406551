import React from "react";
import Tab from "./tab";

const NavigationTabs = ({
    tabs,
    selectedTab,
    setSelectedTab,
    customClass,
    children,
}) => {
    const containerClassName = [
        "w-full flex flex-wrap items-center justify-between",
        customClass,
    ].join(" ");

    const tabContainerClassName = [
        "relative flex space-x-6 md:space-x-6",
        "overflow-x-auto overflow-y-hidden md:mr-auto",
        "font-body font-semibold text-gray-1000"
    ].join(" ");
    return (
        <div className={containerClassName} style={{ zIndex: 1 }}>
            <div className="flex items-center flex-col md:flex-row space-y-6 md:space-y-0 mb-6 md:mb-0 w-full">
                <div className={tabContainerClassName} style={{ zIndex: 10 }}>
                    {tabs.map((tab, i) => {
                        const withBadge =
                            typeof tab === "object" && tab !== null;
                        const text = withBadge ? tab.text : tab;
                        const badge = withBadge && {
                            count: tab.count,
                            priority: tab.priority,
                        };
                        selectedTab = selectedTab.toLowerCase();
                        return (
                            <Tab
                                key={withBadge ? tab.text : tab}
                                text={withBadge ? tab.text : tab}
                                active={selectedTab === text.toLowerCase()}
                                onClick={() =>
                                    setSelectedTab(text.toLowerCase())
                                }
                                badge={badge}
                                disabled={withBadge && tab.disabled}
                            />
                        );
                    })}
                </div>
                {children}
            </div>
            <hr
                className="relative w-full border-b-2 border-gray-300 border-opacity-40"
                style={{ marginTop: "-2px", zIndex: 1 }}
            />
        </div>
    );
};

NavigationTabs.defaultProps = {
    tabs: [],
    selectedTab: null,
    setSelectedTab: () => {},
    customClass: "px-1 md:px-6 pt-12",
};

export default NavigationTabs;

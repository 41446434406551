import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SearchResults from './search_results';
import CardPreview from './card_preview';
import UserIdContext from '../trophy_room/user_id_context';
import OwnedCardPreview from '../trophy_room/owned_card_preview';
import OrganizeStash from './organize_stash';
import { VariablesContextProvider } from '../variables_context';
import { PopupContextProvider } from '../popup_context';
import ApolloProviderClient from '../apollo_provider_client';
import { GAComponent } from '../hooks/useReactGA';
import IsMarketplaceBetaUserContext from "../is_marketplace_beta_user_context";

const App = () => (
    <Fragment>
        <Router>
            <Switch>
                <Route path="/browse_cards/stash" component={OrganizeStash} />
                <Route path="/browse_cards" exact component={SearchResults} />
                <Route
                    path="/browse_cards/preview/:id"
                    exact
                    component={CardPreview}
                />
                <Route
                    path="/browse_cards/preview/:cardId/vaulted/:id"
                    exact
                    component={OwnedCardPreview}
                />
            </Switch>
            <Route path="*" component={GAComponent} />
        </Router>
    </Fragment>
);

export default () => {
    document.addEventListener('DOMContentLoaded', function () {
        const container = document.getElementById('browseCards');
        if (container) {
            const userId = container?.dataset?.userId;
            const isMarketplaceBetaUser = JSON.parse(container?.dataset?.marketplaceBetaUser || "false");
            render(
                <ApolloProviderClient>
                    <PopupContextProvider>
                        <VariablesContextProvider>
                            <UserIdContext.Provider value={userId}>
                                <IsMarketplaceBetaUserContext.Provider value={isMarketplaceBetaUser}>
                                    <App />
                                </IsMarketplaceBetaUserContext.Provider>
                            </UserIdContext.Provider>
                        </VariablesContextProvider>
                    </PopupContextProvider>
                </ApolloProviderClient>,
                container
            );
        }
    });
};

import React from "react"
import Modal from "../../modal"
import ModalPortal from "../../modal/modal_portal"
import Button from "../../seller_center/shared_components/button"
import StarRating from "../../star_rating"


const ViewRatingModal = ({
    sellerUsername,
    rating,
    title,
    content,
    dateReviewed,
    username,
    mountModal,
    setMountModal,
}) => {
    return (
        <ModalPortal>
            {mountModal && (
                <Modal
                    title="Your rating"
                    preventESC
                    size="xs"
                    closeModal={() => setMountModal(false)}
                    className="p-10 text-black-100"
                    closeIcon='x'
                    titleClass="text-gray-650 text-md font-semibold"
                    showTitleBorder={false}
                    modalCustomClass="rounded"
                >
                    {({ hideModal }) => (
                        <div className='p-6 text-gray-600'>
                            <div className="py-4 space-y-4 border-t-2 border-gray-400 border-opacity-40">
                                <div className='flex space-x-2 items-center'>
                                    <span className='text-lg'>Your rating for</span>
                                    <span className='text-lg font-semibold'>{sellerUsername}</span>
                                </div>
                                <StarRating
                                    iconSize={26}
                                    customClass='text-gray-300'
                                    withText
                                    readOnly
                                    value={rating || 0}
                                    textClass="text-gray-400 uppercase font-semibold text-xl"
                                />
                                <div className="space-y-3">
                                    {title && (
                                        <div className="text-md font-semibold">
                                            {`"${title}"`}
                                        </div>
                                    )}
                                    <div className='space-y-2'>
                                        {content && (
                                            <div className="break-all">
                                                {content}
                                            </div>
                                        )}
                                        <div className="">
                                            <div>By {username}</div>
                                            <div>{dateReviewed}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-end'>
                                    <Button
                                        type="button"
                                        secondary
                                        text="Close"
                                        onClick={() => hideModal()}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </Modal>
            )}
        </ModalPortal>
    )
}

export default ViewRatingModal
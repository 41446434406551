import React, { useState } from "react";
import CardImage from "../../card_image";
import IconGrayFlip from "icon-gray-flip.svg";
import IconGrayZoom from "icon-gray-zoom.svg";
import ZoomContainer from "../../marketplace/zoom_image";
import useZoomImage from "../../hooks/useZoomImage";

const CardImageContainer = ({
    frontImage,
    backImage,
    showFlip = true,
    showZoom: _showZoom = false,
    height = "h-72",
    width = "w-full",
}) => {
    const [flipped, setFlipped] = useState(false);

    const { zoom, zoomDisabled, showZoom, closeZoom } = useZoomImage(
        {
            frontImage,
            backImage,
        },
        flipped
    );

    const cardClassName = [
        "focus:outline-none",
        "trophyRoom-card",
        "relative",
        "flex-shrink-0",
        "border-4 border-white rounded-md",
        "px-8 h-full",
        flipped ? "flip" : "",
    ].join(" ");

    const hasActions = showFlip || _showZoom;

    return (
        <>
            <div className={`space-y-6 ${width}`}>
                <div className={height}>
                    <div className={cardClassName}>
                        {/* Must have both CardImage component for front and back to make flip work  */}
                        <CardImage isFront image={frontImage} hideMoreText />
                        <CardImage image={backImage} hideMoreText />
                    </div>
                </div>
                {hasActions && (
                    <div className="flex mt-4 text-xs">
                        {showFlip && (
                            <button
                                className="flex items-center justify-center space-x-1 cursor-pointer border border-white rounded py-2 px-4 mr-4 w-5/12"
                                onClick={() => setFlipped(!flipped)}>
                                <img src={IconGrayFlip} className="w-3 h-3" />
                                <span className="ml-2">Flip</span>
                            </button>
                        )}
                        {showZoom && (
                            <button
                                disabled={zoomDisabled}
                                className="flex items-center justify-center space-x-1 cursor-pointer border border-white rounded py-2 px-4 w-7/12"
                                onClick={showZoom}>
                                <img src={IconGrayZoom} className="w-4 h-4" />
                                <span className="ml-2">Zoom In</span>
                            </button>
                        )}
                    </div>
                )}
            </div>
            <ZoomContainer show={zoom} handleClose={closeZoom}>
                <img
                    src={flipped ? backImage : frontImage}
                    alt="zoomed card"
                    style={{ maxWidth: "none", maxHeight: "none" }}
                />
            </ZoomContainer>
        </>
    );
};

CardImageContainer.defaultProps = {
    frontImage: null,
    backImage: null,
    showFlip: true,
    showZoom: false,
    height: "h-72",
    width: null,
};

export default CardImageContainer;

import { gql, useMutation } from "@apollo/client"
import { useContext } from "react"
import { PopupContext } from "../popup_context"

const UPDATE_PURCHASE_SELLER_STATUS = gql`
    mutation UpdatePurchaseSellerStatus($input: UpdatePurchaseSellerStatusInput!) {
        result: updatePurchaseSellerStatus(input: $input) {
            purchaseSeller {
                status
            }
            errors
            success
        }
    }
`

const PurchaseSellerMutation = (callback = () => {}) => {
    const { setShowPopup, setPopupAction, setMessage } = useContext(PopupContext);

    const onError = () => {
        setShowPopup(false)
        setShowPopup(true)
        setPopupAction('warn')
        setMessage("Something went wrong.")
    }

    const [updatePurchaseSellerStatus, { loading: updateStatusLoading }] = useMutation(UPDATE_PURCHASE_SELLER_STATUS, {
        onCompleted: (data) => callback(data),
        onError: onError
    })

    return {
        updatePurchaseSellerStatus,
        updateStatusLoading
    }
}

export default PurchaseSellerMutation
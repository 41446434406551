import React, { Fragment, useState } from "react";
import { render } from "react-dom";
import Modal from ".";
import ModalPortal from "./modal_portal";

const SubscribeModal = ({ subscription, pricing, url }) => {
    const [mountModal, setMountModal] = useState(false);

    function closeModal() {
        setMountModal(false);
    }

    return (
        <Fragment>
            <button
                type="button"
                className="font-semibold text-gray py-2 px-6 rounded border bg-primary-400 hover-primary-btn focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                onClick={() => {
                    setMountModal(true);
                }}>
                Change Membership
            </button>
            <ModalPortal id="subscribe_modal">
                {mountModal && (
                    <Modal
                        title="Confirm Subscription"
                        closeModal={closeModal}
                        size="lg"
                        preventESC
                        closeIcon="x">
                        {() => (
                            <div className="p-6 flex flex-col justify-between w-full font-body text-gray-70">
                                <div className="flex flex-col rounded mb-4 p-4 space-y-2">
                                    <span className="font-semibold">
                                        Update subscription level to:
                                    </span>
                                    <span>{subscription}</span>
                                    <span>{pricing} / per month</span>
                                </div>
                                <div className="ml-auto flex text-sm mt-8">
                                    <button
                                        className="font-semibold hover:bg-gray-1000 border py-2 px-8 border-gray-500 hover:border-gray-1000 rounded text-gray-70 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        onClick={closeModal}>
                                        Cancel
                                    </button>
                                    <a
                                        className="font-semibold ml-4 px-4 py-2 rounded bg-primary-800 hover:bg-primary-900 text-gray-70 focus:outline-none"
                                        rel="nofollow"
                                        data-method="put"
                                        href={url}>
                                        Confirm Subscription
                                    </a>
                                </div>
                            </div>
                        )}
                    </Modal>
                )}
            </ModalPortal>
        </Fragment>
    );
};

export default () => {
    document.addEventListener("DOMContentLoaded", () => {
        // console.log("Loading modal...");
        const container = document.getElementById("subscribe_modal");
        if (container) {
            const { subscription, pricing, url } = container.dataset;
            render(
                <SubscribeModal
                    subscription={subscription}
                    pricing={pricing}
                    url={url}
                />,
                container
            );
        }
    });
};

import React, { useEffect } from "react";
import BgLights from "bg-lights-darker.png";
import PageTitle from "../create_listing/page_title";
import IconUserGold from "icon-user-gold.svg";
import CardDetailItem from "../create_listing/card_detail_item";
import CardImageContainer from "../create_listing/card_image_container";
import { ExternalLink } from "react-feather";
import formatPrice from "../../utils/format_price";
import ManageOrderCard from "./manage_order_card";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import formatCardPlayers from "../../utils/format_card_players";
import ManageOrderForm from "./manage_order_form";
import { GET_PURCHASE_SELLER } from "../../queries";
import useAddressFormatter from "../../hooks/useAddressFormatter";

const ManageOrder = () => {
    const { id } = useParams();

    const [fetchData, { data, loading, called }] = useLazyQuery(
        GET_PURCHASE_SELLER,
        {
            variables: { id: id },
            fetchPolicy: "cache-and-network",
        }
    );

    const pageClass = [
        "p-6 xl:px-48 2xl:px-56 xl:py-16 space-y-12",
        "animate__animated animate__fadeIn",
        "bg-fixed bg-cover bg-center bg-no-repeat",
        "min-h-screen",
        "text-gray-1000",
    ].join(" ");

    const purchaseSeller = data?.purchaseSeller;
    const payment = purchaseSeller?.payment;
    const paymentMethodDetails = purchaseSeller?.paymentMethodDetails;
    const trackingInformation = purchaseSeller?.trackingInformation;
    const buyer = purchaseSeller?.buyer;
    const buyerContactInformation = purchaseSeller?.shippingAddress;
    const purchaseSellerItems = purchaseSeller?.purchaseSellerItems;
    const fulfillmentOption = purchaseSeller?.fulfillmentOption;
    const seller = purchaseSeller?.seller;
    const sellerFulfillmentOptions = seller?.fulfillmentOptions;
    const refund = purchaseSeller?.refunds[0];
    const totalEarnings = purchaseSeller?.shipped ? purchaseSeller?.totalSellerEarnings : purchaseSeller?.totalEstimatedSellerEarnings;
    const hasDiscount = purchaseSeller?.shipped || purchaseSeller?.cancelled ? purchaseSeller?.hasCommissionFeeDiscount : purchaseSeller?.sellerHasCommissionFeeDiscount;
    const { addressLineOne, addressLineTwo } = useAddressFormatter(buyerContactInformation);
    const discountMessage = !purchaseSeller?.shipped
                                ? `You have ${seller?.monthlyCommissionFreeSales} commission free sales this month`
                                : "Commission fee discount applied on this order";

    useEffect(() => {
        if (!called) {
            fetchData();
        }
    }, []);

    return (
        <div
            className={pageClass}
            style={{ backgroundImage: `url(${BgLights})` }}>
            {purchaseSeller && !loading && called && (
                <>
                    <PageTitle title={`Order ID ${purchaseSeller.orderNumber}`}>
                        <a
                            href={`/trophy_room/${buyer.id}`}
                            className="text-sm flex space-x-2 items-center cursor-pointer">
                            <img
                                src={IconUserGold}
                                alt="User icon"
                                className="icon w-5 h-5"
                            />
                            <span className="text-lg underline">
                                {buyer.username}
                            </span>
                        </a>
                    </PageTitle>
                    <div className="grid grid-cols-3 md:grid-cols-5 gap-10">
                        <div className="col-span-3 space-y-8">
                            <ManageOrderCard title="Shipping Details">
                                <CardDetailItem
                                    title="Recipient Name"
                                    value={buyerContactInformation?.fullName}
                                />
                                <CardDetailItem
                                    title="Contact number"
                                    value={buyerContactInformation?.phoneNumber}
                                />
                                <CardDetailItem
                                    title="Recipient Address"
                                    value={[`${addressLineOne},`, addressLineTwo]}
                                    copyToClipboard
                                />
                                <CardDetailItem
                                    title="Shipping Method"
                                    value={`${fulfillmentOption.displayedFulfillmentType} Shipping`}
                                />
                            </ManageOrderCard>
                            <ManageOrderCard title="Transaction Details">
                                <div className="space-y-8 pb-6 border-b-2 border-gray-300 border-opacity-40">
                                    {purchaseSellerItems.map((item) => (
                                        <div
                                            className="grid grid-cols-8 gap-1 items-center"
                                            key={item.id}>
                                            <div className="w-12 col-span-2">
                                                <CardImageContainer
                                                    frontImage={item.frontImage}
                                                    showFlip={false}
                                                    height="h-24"
                                                />
                                            </div>
                                            <div className="col-span-full md:col-span-5 grid space-y-2 text-md">
                                                <div className="flex space-x-2 items-center">
                                                    <span>
                                                        {item.cardTitle}
                                                    </span>
                                                    <a
                                                        href={`/seller_center/my_listings/${item.id}/preview`}>
                                                        <ExternalLink
                                                            size={18}
                                                            className="text-primary"
                                                        />
                                                    </a>
                                                </div>
                                                <span>
                                                    {formatCardPlayers(
                                                        item.playersJson,
                                                        ["names"]
                                                    )}
                                                </span>
                                            </div>
                                            <div className="col-span-full md:col-span-1 flex justify-end">
                                                <span className="text-right">
                                                    {formatPrice(
                                                        item.price,
                                                        "V",
                                                        item.currency
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="space-y-2">
                                    <CardDetailItem
                                        textAlignment="right"
                                        title="Order Subtotal"
                                        boldTitle={false}
                                        value={formatPrice(
                                            purchaseSeller.totalAmount,
                                            "V",
                                            payment?.currency
                                        )}
                                    />
                                    <CardDetailItem
                                        textAlignment="right"
                                        title="Shipping Fee"
                                        boldTitle={false}
                                        value={formatPrice(
                                            purchaseSeller.shippingFee,
                                            "V",
                                            payment?.currency
                                        )}
                                    />
                                    <CardDetailItem
                                        textAlignment="right"
                                        title="Commission Fee"
                                        boldTitle={false}
                                        value={formatPrice(
                                            purchaseSeller.sellingFee * -1,
                                            "V",
                                            payment?.currency
                                        )}
                                    />
                                    <CardDetailItem
                                        textAlignment="right"
                                        title="Charges"
                                        boldTitle={false}
                                        value={formatPrice(
                                            purchaseSeller.stripeFee * -1,
                                            "V",
                                            payment?.currency
                                        )}
                                    />
                                    {hasDiscount ? (
                                        <CardDetailItem
                                            textAlignment="right"
                                            title="Commission Fee Discount"
                                            boldTitle={false}
                                            helpText={discountMessage}
                                            value={formatPrice(
                                                purchaseSeller.sellingFee,
                                                "V",
                                                payment?.currency
                                            )}
                                        />
                                    ) : null}
                                    <CardDetailItem
                                        textAlignment="right"
                                        title="Total Earnings"
                                        boldValue
                                        value={formatPrice(
                                            totalEarnings,
                                            "V C",
                                            payment?.currency
                                        )}
                                        valueColor="text-primary"
                                    />
                                </div>
                            </ManageOrderCard>
                            <ManageOrderCard title="Buyer Payment">
                                <CardDetailItem
                                    title="Payment Date"
                                    value={payment?.paymentDate}
                                />
                                <CardDetailItem
                                    title="Total Amount Paid"
                                    value={formatPrice(
                                        purchaseSeller.totalOrderAmount,
                                        "V C",
                                        payment?.currency
                                    )}
                                />
                                <CardDetailItem
                                    title="Currency"
                                    value={payment?.currency.toUpperCase()}
                                />
                                <CardDetailItem
                                    title="Method"
                                    capitalize
                                    value={paymentMethodDetails?.brand}
                                />
                            </ManageOrderCard>
                        </div>
                        {purchaseSeller.cancelledAt ? (
                            <div className="col-span-3 md:col-span-2">
                                <div className="space-y-3 bg-gray-800 rounded p-8">
                                    <strong className="text-primary">CANCELLED/REFUNDED</strong>
                                    <p>This order has been cancelled and the payment will be returned to the buyer.</p>
                                    <ul className="leading-12 pt-4">
                                        <li className="flex flex-row">
                                            <strong className="w-60">Cancellation Date</strong>
                                            <div>{purchaseSeller.cancelledAt}</div>
                                        </li>
                                        <li className="flex flex-row">
                                            <strong className="w-60">Status</strong>
                                            <div>{purchaseSeller.refundStatus}</div>
                                        </li>
                                        {refund.sellerRemarks && (
                                        <li className="flex flex-row">
                                            <strong className="w-60">Remarks</strong>
                                            <div>{refund.sellerRemarks}</div>
                                        </li>
                                        )}
                                        {refund.userPaymentMethod?.brand && (
                                            <li className="flex flex-row">
                                                <strong className="w-60">Payment Method</strong>
                                                <div>{refund.userPaymentMethod.brand.toUpperCase()} **** {refund.userPaymentMethod.lastDigits}</div>
                                            </li>
                                        )}
                                        <li className="flex flex-row" >
                                            <strong className="w-60">Refund Amount</strong>
                                            <div class="text-right w-40">{formatPrice(refund.amount, "V C", refund.currency)}</div>
                                        </li>
                                        <li className="flex flex-row">
                                            <strong className="w-60">Adjustment/Fees</strong>
                                            <div class="text-right w-40">{formatPrice(refund.adjustmentAmount, "V C", refund.currency)}{refund.amountBreakdown.first}</div>
                                        </li>
                                        {refund.amountBreakdown["Commission fee refund"] ? (
                                            <li className="flex flex-row">
                                                <span className="w-60 pl-8  ">Commission fee refund</span>
                                                <div class="text-right w-40">
                                                    {formatPrice(refund.amountBreakdown["Commission fee refund"], "V C", refund.currency)}
                                                </div>
                                            </li>
                                        ) : ""}
                                        {refund.amountBreakdown["Refund deficit"] ? (
                                            <li className="flex flex-row">
                                                <span className="w-60 pl-8">Cancellation Fee</span>
                                                <div class="text-right w-40">
                                                    {formatPrice(refund.amountBreakdown["Refund deficit"], "V C", refund.currency)}
                                                </div>
                                            </li>
                                        ) : ""}
                                        {refund.amountBreakdown["Stripe card charge fee"] ? (
                                            <li className="flex flex-row">
                                                <span className="w-60 pl-8  ">Stripe card charge fee</span>
                                                <div class="text-right w-40">
                                                    {formatPrice(refund.amountBreakdown["Stripe card charge fee"], "V C", refund.currency)}
                                                </div>
                                            </li>
                                        ) : ""}
                                    </ul>
                                </div>
                            </div>
                          ) : (
                            <div className="col-span-3 md:col-span-2">
                                <ManageOrderForm
                                    purchaseSeller={purchaseSeller}
                                    trackingInformation={trackingInformation}
                                    sellerFulfillmentOptions={
                                        sellerFulfillmentOptions
                                    }
                                    fulfillmentOption={fulfillmentOption}
                                    reloadTransactionData={fetchData}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
            {!purchaseSeller && !loading && called && (
                <div className="flex justify-center w-full">
                    <div className="text-4xl text-white">Order not found</div>
                </div>
            )}
        </div>
    );
};

export default ManageOrder;

import React, { useContext, useEffect } from "react";
import BgLightsDarker from "bg-lights-darker.png";
import { Redirect } from "react-router-dom";
import PageTitle from "../../seller_center/create_listing/page_title";
import CartSellers from "./cart_sellers";
import { GET_USER_CART_ITEMS } from "../../queries";
import { useLazyQuery } from "@apollo/client";
import PageVariablesContext from "../../page_variables_context";

const pageClass = [
    "px-8 py-8 md:px-18 md:py-12 lg:px-20 xl:px-32 2xl:px-60",
    "animate__animated animate__fadeIn",
    "bg-fixed bg-cover bg-center bg-no-repeat",
    "min-h-screen",
    "text-white",
].join(" ");

const Cart = () => {
    const { userId } = useContext(PageVariablesContext);
    const [getCartItems, { data, loading }] = useLazyQuery(
        GET_USER_CART_ITEMS,
        {
            variables: {
                userId,
            },
            cachePolicy: "cache-and-network",
        }
    );

    const cartItemPerSeller = data?.cartItemPerSeller;

    useEffect(() => {
        if (userId) {
            getCartItems();
        }
    }, [userId]);

    if (!userId) {
        return <Redirect to="/marketplace" />;
    }

    return (
        <div
            className={pageClass}
            style={{ backgroundImage: `url(${BgLightsDarker})` }}>
            <PageTitle title="Shopping Cart" />
            <CartSellers
                loading={loading}
                items={cartItemPerSeller}
                fetchCartItems={getCartItems}
            />
        </div>
    );
};

export default Cart;

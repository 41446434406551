import React, { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import animateCSS from "../utils/animateCSS";

const Modal = ({
    title,
    preventESC = false,
    children: Children,
    closeModal = () => {},
    showClose = true,
    size = "xs",
    bgColor = "white",
    showTitleBorder = true,
    closeIcon = "cancel",
    modalCustomClass = "rounded",
    titleClass = "text-black text-xl font-semibold pr-2 mr-auto",
    zIndex = 999,
    isConfirmation = false,
}) => {
    const [showModal, setShowModal] = useState(true);
    const backdrop = useRef(null);
    const m = useRef(null);
    useEffect(() => {
        async function animateCloseModal() {
            if (!showModal) {
                await animateCSS(m?.current, "fadeOutUp").then(() => {
                    m?.current?.remove();
                });
                await animateCSS(backdrop?.current, "fadeOut").then(() => {
                    backdrop?.current?.remove();
                });
                closeModal();
            }
        }

        animateCloseModal();
    }, [showModal, closeModal]);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    function onClickCancel() {
        setShowModal(false);
    }

    function onKeyUp(e) {
        if (e?.key?.toLowerCase() === "escape" && !preventESC) {
            setShowModal(false);
        }
    }

    const modalClass = [
        `bg-${bgColor} animate__faster animate__animated animate__fadeInDown flex flex-col items-stretch`,
        size === "2xl" && "w-full",
        size === "xl" && "w-4/5",
        size === "lg" && "w-full lg:w-4/5 2xl:w-3/5",
        size === "md" && "w-full md:w-2/5 ",
        size === "sm" && "w-136",
        size === "xs" && "w-128",
        modalCustomClass,
    ].join(" ");

    const modalHeaderClass = [
        "flex items-center flex-shrink-0",
        showTitleBorder ? "border-b" : "",
        isConfirmation ? "px-3 pt-3" : "p-6",
    ].join(" ");

    return (
        <div
            className="fixed inset-0 flex items-center justify-center px-5 md:px-32 bg-gray-70-opaque-88"
            style={{ zIndex: zIndex }}>
            <div
                ref={m}
                tabIndex={-1}
                onKeyUp={onKeyUp}
                className={modalClass}
                style={{ zIndex: 1000, maxHeight: "80vh" }}>
                <div
                    className={modalHeaderClass}>
                    <span className={titleClass}>{title}</span>
                    {showClose && (
                        <button
                            type="button"
                            className="text-sm  ml-auto text-black px-2 py-1"
                            onClick={onClickCancel}>
                            {closeIcon === "cancel" ? (
                                <>Cancel</>
                            ) : (
                                <X className="w-5 h-5" />
                            )}
                        </button>
                    )}
                </div>
                <div className="w-full h-full overflow-y-auto">
                    <Children hideModal={onClickCancel} />
                </div>
            </div>
            <button
                onClick={onClickCancel}
                ref={backdrop}
                className="absolute inset-0 bg-black bg-opacity-75 animate__faster animate__animated animate__fadeIn"
                style={{ zIndex: 999 }}
            />
        </div>
    );
};

export default Modal;

import React, { forwardRef, Fragment } from 'react';

const SelectList = forwardRef(({ onChange, value, options }, ref) => (
    <Fragment>
        <label className="mb-1">Select list</label>
        <select
            ref={ref}
            className="p-2 mb-3 border-gray-50 border-2 focus:ring-primary focus:outline-none focus:border-primary text-black rounded-md w-full"
            onChange={onChange}
            value={value}
        >
            <option value=""></option>
            {options?.map((opt) => (
                <option key={opt.id} value={opt.id}>
                    {opt.name}
                </option>
            ))}
        </select>
    </Fragment>
));

export default SelectList;

import React, { useEffect, useState } from "react";
import Checkbox from "../../checkbox";
import { DEFAULT_CURRENCY } from "../../utils/constants";
import formatPrice from '../../utils/format_price';

const ShippingOption = ({
    userShippingMethod = [],
    fulfillmentType,
    description,
    onChange = () => {},
    disabled = false,
    hideCheckbox = false,
}) => {
    const defaultChecked = Boolean(userShippingMethod.length)
        ? userShippingMethod[0].isActive
        : false;
    const defaultDescription = userShippingMethod[0]?.description || "";
    const defaultShippingFee = userShippingMethod[0]?.shippingFee || null;
    const [checked, setChecked] = useState(defaultChecked);
    const [shippingData, setShippingData] = useState({});
    const [userShippingDescription, setDescription] =
        useState(defaultDescription);
    const [userShippingFee, setShippingFee] =
        useState(defaultShippingFee);

    useEffect(() => {
        setChecked(defaultChecked);
        setDescription(defaultDescription);
    }, [defaultChecked]);

    useEffect(() => {
        setShippingData({
            id: userShippingMethod[0]?.id || null,
            fulfillmentType: fulfillmentType,
            description: userShippingDescription,
            isActive: checked,
            shippingFee: parseFloat(userShippingFee)
        });
    }, [checked, userShippingDescription, userShippingFee]);

    useEffect(() => {
        onChange(shippingData, checked);
    }, [shippingData]);

    const handleChange = (e) => {
        const inputChecked = e.target.checked;
        setChecked(inputChecked);
    };

    const detailsContainerClass = [
        "w-full md:pl-13",
        !disabled ? "space-y-6" : "space-y-1"
    ].join(" ");

    return (
        <div className={`flex flex-col ${!disabled ? "space-y-3" : ""}`}>
            <Checkbox
                inputDimension="6"
                labelClass={`${
                    hideCheckbox ? "flex items-center" : "cursor-pointer"
                } camelize text-gray-30 space-x-4 text-sm`}
                label={description}
                checked={checked}
                onChange={(e) => handleChange(e)}
                disabled={disabled}
                hideCheckbox={hideCheckbox}
            />
            {checked && (
                <div className={detailsContainerClass}>
                    <div className="flex flex-col w-full lg:w-2/3 xl:w-1/2 2xl:w-1/4 text-sm">
                        {(defaultShippingFee && defaultShippingFee > 0 && disabled) ? (
                            <span className="pl-13 md:pl-0 ml-2">Rate{`: ${formatPrice(defaultShippingFee, "V C")}`}</span>
                        ) : null}
                        {!disabled && (
                            <div className="space-y-2">
                                <span>Amount</span>
                                <div className="flex justify-between items-center rounded bg-white text-gray-600 relative w-full">
                                    <span className="text-lg font-semibold px-4">$</span>
                                    <div className="flex justify-end items-center">
                                        <input
                                            name={`${fulfillmentType}_shipping_fee`}
                                            className="w-1/3 px-0 text-right no-spin-button placeholder-color-inherit rounded border-0 outline-none focus:outline-none focus:ring-0"
                                            type="number"
                                            min={0}
                                            step={0.01}
                                            placeholder="0.00"
                                            onChange={(e) => setShippingFee(e.target.value)}
                                            defaultValue={userShippingFee}
                                        />
                                        <span className="text-lg text-inherit px-2">{DEFAULT_CURRENCY}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col w-full xl:w-4/5 2xl:w-3/5 text-sm">
                        {(userShippingDescription && disabled) ? (
                            <span className="pl-13 md:pl-0 ml-2">Details{`: ${defaultDescription}`}</span>
                        ) : null}
                        {!disabled ? (
                            <div className="flex flex-col space-y-2">
                                <span>Details</span>
                                <input
                                    name={`${fulfillmentType}_description`}
                                    className="rounded text-gray-600 placeholder-color-inherit border-0 outline-none focus:outline-none focus:ring-0"
                                    type="text"
                                    placeholder="Any more information you want to add"
                                    onChange={(e) => setDescription(e.target.value)}
                                    defaultValue={userShippingDescription}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShippingOption;

import { gql, useMutation, useQuery } from '@apollo/client';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
    ChevronDown,
    ChevronRight,
    ChevronUp,
    Inbox,
    Loader,
    Plus,
} from 'react-feather';
import { Link } from 'react-router-dom';
import {
    ADD_CARDS_TO_HAVE_LIST,
    ADD_CARDS_TO_WANT_LIST,
    updateRemoveFromStash,
} from '../mutations';
import { PopupContext, POPUP_TYPES } from '../popup_context';
import StashedCard from './stashed_card';

const StashedCardCountText = ({ count }) => (
    <p className="mr-auto">
        {count} card
        {count !== 1 ? 's ' : ' '}
        stashed
    </p>
);

const GET_LIST_OPTIONS = gql`
    query GetListOptions($userId: ID!) {
        haveLists: getHaveLists(userId: $userId) {
            id
            name
        }

        wantLists: getWantLists(userId: $userId) {
            id
            name
        }
    }
`;

const HAVE_LIST = 'HAVE_LIST';
const WANT_LIST = 'WANT_LIST';

const Stash = ({ cards, userId }) => {
    const { setShowPopup, setPopupAction, setMessage } =
        useContext(PopupContext);
    const [expanded, setExpaned] = useState(false);
    const [showListSelection, setShowListSelection] = useState(false);
    const [selectedList, setSelectedList] = useState(HAVE_LIST);
    const [selectedCards, setSelectedCards] = useState([]);
    const [selectedListId, setSelectedListId] = useState('');
    const [addCardToHaveList, haveRes] = useMutation(ADD_CARDS_TO_HAVE_LIST, {
        variables: {
            input: {
                cardIds: selectedCards,
                haveListId: selectedListId,
                removeFromStash: true,
            },
        },
        update: (a, b) => updateRemoveFromStash(a, b, selectedCards),
        onCompleted: resetInput,
    });
    const [addCardToWantList, wantRes] = useMutation(ADD_CARDS_TO_WANT_LIST, {
        variables: {
            input: {
                cardIds: selectedCards,
                wantListId: selectedListId,
                removeFromStash: true,
            },
        },
        update: (a, b) => updateRemoveFromStash(a, b, selectedCards),
        onCompleted: resetInput,
    });

    const { loading, data } = useQuery(GET_LIST_OPTIONS, {
        variables: {
            userId,
        },
    });

    const stashedCardsCount = cards.length;

    const isWantList = selectedList === WANT_LIST;

    function addToList(id) {
        const newSelectedCards = selectedCards.concat(id);
        setSelectedCards(newSelectedCards);
    }

    function removeFromList(id) {
        const filteredCards = selectedCards.filter((c) => c !== id);
        setSelectedCards(filteredCards);
    }

    function quickAddToList() {
        if (isWantList) {
            addCardToWantList();
        } else {
            addCardToHaveList();
        }
    }

    function resetInput({ addCardToList }) {
        const { payPopupAction, payPopupMessage, errors } = addCardToList;
        setShowPopup(true);
        setSelectedListId('');
        if (errors.length) {
            setPopupAction(POPUP_TYPES.DANGER);
            setMessage(
                'Something went wrong when we were adding the cards to the list. Some of the cards might already be in your list.'
            );
        } else {
            setPopupAction(payPopupAction);
            setMessage(payPopupMessage);
        }
    }

    const btnDisabled = selectedCards.length === 0 || !selectedListId;
    const btnLoading = haveRes?.loading || wantRes?.loading;

    useEffect(() => {
        if (expanded) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [expanded]);

    function handleRadioChange(e) {
        setSelectedList(e.target.value);
        setSelectedListId('');
    }

    const haveListOptions = data?.haveLists?.map(({ id, name }) => ({
        id,
        name,
    }));
    const wantListOptions = data?.wantLists?.map(({ id, name }) => ({
        id,
        name,
    }));
    const selectedListOptions =
        selectedList === WANT_LIST ? wantListOptions : haveListOptions;

    if (!expanded) {
        return (
            <button
                type="button"
                onClick={() => setExpaned(true)}
                style={{ zIndex: 100 }}
                className="p-4 flex items-center fixed bottom-0 right-0 w-full md:w-96 text-black bg-primary"
            >
                <StashedCardCountText count={stashedCardsCount} />
                <ChevronUp />
            </button>
        );
    }

    return (
        <Fragment>
            <div
                className="fixed animate__animated animate__fadeIn right-0 bottom-0 top-0 w-96 bg-gray-500 text-white flex flex-col items-stretch"
                style={{ zIndex: 100 }}
            >
                <div className="p-4 w-full font-semibold flex items-center shadow-md">
                    <StashedCardCountText count={stashedCardsCount} />
                    <button
                        type="button"
                        onClick={() => setExpaned(false)}
                        className="text-white"
                    >
                        <ChevronDown />
                    </button>
                </div>
                <div className="w-full overflow-y-auto space-y-4 px-4 py-2 h-full border-b border-gray-600 border-opacity-25 flex-grow-0">
                    {cards.map((card, i) => (
                        <StashedCard
                            card={card}
                            key={i}
                            selected={selectedCards.includes(card?.id)}
                            addToList={addToList}
                            removeFromList={removeFromList}
                            width="w-full"
                        />
                    ))}
                </div>
                <div className="w-full shadow-md">
                    <button
                        disabled={loading}
                        type="button"
                        onClick={() => setShowListSelection(!showListSelection)}
                        className={`p-4 flex items-center w-full ${
                            loading ? 'text-gray-300 opacity-50' : ''
                        }`}
                    >
                        <span className="text-primary">
                            <Plus size={16} />
                        </span>
                        <p className="ml-2 mr-auto">
                            Quickly add cards to my lists
                        </p>
                        {showListSelection ? (
                            <ChevronDown size={16} />
                        ) : (
                            <ChevronRight size={16} />
                        )}
                    </button>
                    {showListSelection && (
                        <div className="px-4 pb-2">
                            <div className="space-x-2 py-2">
                                <input
                                    className="text-primary focus:ring-primary"
                                    name="addToList"
                                    onChange={handleRadioChange}
                                    value={HAVE_LIST}
                                    checked={selectedList === HAVE_LIST}
                                    type="radio"
                                    id={`radio-${HAVE_LIST}`}
                                />
                                <label htmlFor={`radio-${HAVE_LIST}`}>
                                    Add to{' '}
                                    <span className="font-semibold">
                                        Have List
                                    </span>
                                </label>
                            </div>
                            <div className="space-x-2 py-2">
                                <input
                                    className="text-primary focus:ring-primary"
                                    name="addToList"
                                    onChange={handleRadioChange}
                                    checked={selectedList === WANT_LIST}
                                    value={WANT_LIST}
                                    type="radio"
                                    id={`radio-${WANT_LIST}`}
                                />
                                <label htmlFor={`radio-${WANT_LIST}`}>
                                    Add to{' '}
                                    <span className="font-semibold">
                                        Want List
                                    </span>
                                </label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <select
                                    className="p-2 text-black rounded-md w-full"
                                    onChange={(e) =>
                                        setSelectedListId(e.target.value)
                                    }
                                    value={selectedListId}
                                >
                                    <option value="">Select list</option>
                                    {selectedListOptions.map((opt) => (
                                        <option key={opt.id} value={opt.id}>
                                            {opt.name}
                                        </option>
                                    ))}
                                </select>
                                <button
                                    disabled={btnDisabled || btnLoading}
                                    onClick={quickAddToList}
                                    type="button"
                                    style={{
                                        transition: 'all 0.3s ease-in-out',
                                    }}
                                    className={`font-semibold flex items-center justify-center ${
                                        btnDisabled
                                            ? 'bg-gray-300 text-gray-500'
                                            : 'bg-primary text-black'
                                    } rounded p-2 w-24`}
                                >
                                    {btnLoading && (
                                        <span className="mr-2 animate__animated animate__infinited animate__flash">
                                            <Loader size={24} />
                                        </span>
                                    )}
                                    <span>Add</span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <Link
                    to="/browse_cards/stash"
                    className="p-4 flex items-center w-full  shadow-md"
                >
                    <span className="text-primary">
                        <Inbox size={16} />
                    </span>
                    <p className="ml-2 mr-auto">
                        Organize cards into different lists
                    </p>
                    <ChevronRight size={16} />
                </Link>
            </div>
            <button
                onClick={() => setExpaned(false)}
                className="fixed inset-0 bg-black bg-opacity-60 animate__animated animate__fadeIn"
                style={{ zIndex: 99 }}
            />
        </Fragment>
    );
};

export default Stash;

import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const StashPortal = ({ children }) => {
    let ref = useRef(null);
    useEffect(() => {
        ref.current = document.createElement("div");
        ref.current.id = "stashPortalContainer";
        document.body.append(ref.current);

        return () => {
            if (ref?.current?.id === "stashPortalContainer") {
                document.body.removeChild(ref.current);
            }
        };
    }, []);

    if (!ref.current) {
        return null;
    }

    return createPortal(children, ref.current);
};

export default StashPortal;
